import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export abstract class AppAuthenticationService {
	abstract clearSessionStorage(): void;

	abstract getOAuthParams(): {};
}
