<app-dashboard-container
	class="dashboard-lesregistraties"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
	[exportTypes]="variant === 'Actueel' ? actueelExportTypes : historieExportTypes"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/lesregistratie"
		[permanentFilterExpressions]="permanentFilterExpressions"
	>
	</app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="['lr_nm_lesregistratie']" [leftOptionsTemplate]="leftOptions">
			<ng-template #leftOptions>
				@if (variant === "Historie") {
					<div class="weergave interval">
						Interval
						<dt-form-dropdown
							[opties]="intervalOpties"
							[selectedOptie]="getSelectedIntervalOptie()"
							(onOptieClicked)="qp.dispatch('interval', $event)"
						></dt-form-dropdown>
					</div>
				}
			</ng-template>
		</app-dashboard-header>
		@switch (variant) {
			@case (DashboardVariant.ACTUEEL) {
				<app-pivot-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="selectedGroups"
					[subgroups]="fixedSubgroups"
					[config]="this"
				></app-pivot-table>
			}
			@case (DashboardVariant.HISTORIE) {
				<app-linechart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="selectedGroups"
					[subgroups]="getHistorieSubgroups([intervalSubgroups, fixedSubgroups])"
					[batchLevels]="1"
					[intervalLevels]="nrIntervalSubgroups"
					[config]="this.linechartConfig"
				></app-linechart-table>
			}
		}
	</section>
</app-dashboard-container>
