import { AsyncPipe, formatDate, formatPercent } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { isEqual, memoize } from 'lodash-es';
import { lastValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { CalendarComponent, Day } from '../../../calendar/calendar.component';
import { Level, unnest } from '../../../services/data-tree';
import {
	AanwezigheidMeasure,
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../../services/data.service';
import { deelVeilig, formatDurationUrenMinuten, getSchooljaarBegin } from '@cumlaude/shared-utils';
import { DashboardLeerling, LeerlingDetail } from '../../Details';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { FilterName } from '../../../services/filter-config';
import { map, take } from 'rxjs/operators';
import { ExportOptions, ExportType } from '../../../services/export.service';
import { Table } from '@cumlaude/metadata';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';
import { Attributes, BaseDashboardConfig } from '../../../shared/dashboard/base-dashboard/base-dashboard-config';
import { noAgg0 } from '../../../services/aggregation';
import { createAggFunctions } from '../../../shared/dashboard/base-dashboard/base-dashboard.component';
import { lookupByKeys } from '../../../services/tabulate';
import { FilterBarComponent } from '../../../filter-bar/filter-bar.component';

interface AfwezigheidI extends Attributes {
	aw_nr_lesuren: number;
	aw_nr_minuten: number;
}

interface AfwezigheidA extends Attributes {
	aw_nr_lesuren: number;
	aw_nr_minuten: number;
}

@Component({
	selector: 'app-leerling-afwezigheid-details-panel',
	templateUrl: './leerling-afwezigheid-details-panel.component.html',
	styleUrls: ['./leerling-afwezigheid-details-panel.component.scss'],
	imports: [FilterPanelComponent, DashboardHeaderComponent, CalendarComponent, AsyncPipe, FilterBarComponent],
})
export class LeerlingAfwezigheidDetailsPanelComponent
	extends BaseDashboardConfig<AfwezigheidI, AfwezigheidA>
	implements OnInit, DetailsDashboard<LeerlingDetail>
{
	protected readonly dataService = inject(DataService);

	leerling$ = new ReplaySubject<DashboardLeerling>(1);

	filters: FilterName[] = ['aw_nm_schooljaar'];

	filterExpressions?: FilterExpression[];

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	override singleAggregators = {
		aw_nr_lesuren: noAgg0<'aw_nr_lesuren', AfwezigheidI>('aw_nr_lesuren'),
		aw_nr_minuten: noAgg0<'aw_nr_minuten', AfwezigheidI>('aw_nr_minuten'),
	};

	exportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.DATA];

	ngOnInit(): void {
		this.subscriptions.push(this.filterService.observeAsInput('aw_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val)));
	}

	getSchooljaar() {
		return this.filterExpressions
			?.filter((value) => value instanceof BasicFilterExpression)
			.find((value) => isEqual((<BasicFilterExpression<any>>value).attr, ['aw_nm_schooljaar']));
	}

	setSelected(selected: LeerlingDetail, schooljaar?: string): void {
		this.leerling$.next({
			leerling: selected,
			schooljaarInfo: schooljaar ?? selected.lb_nm_schooljaar,
		});
	}

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.leerling$.pipe(take(1))).then((leerling) =>
			this.doExport(this.filterExpressions!, this.getDashboardFilters(leerling.leerling), [], exportOptions)
		);
	}

	override getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getAanwezigheidExportData(options);
	}

	getBeginDateForSchooljaar() {
		const schooljaar = this.getSchooljaar();
		return of(getSchooljaarBegin(schooljaar!.getValueString()));
	}

	getCalendarData = memoize(this._getCalendarData, (f, pf) => JSON.stringify([f, pf]));

	private _getCalendarData(
		filterExpressions: FilterExpression[],
		permanentFilterExpressions: FilterExpression[]
	): Observable<Level<AfwezigheidA, number[]> | undefined> {
		const g: AttrPath[] = [['aw_d_datum'], ['aw_is_abs_geoorloofd']];
		const f = new CompoundFilterExpression([...filterExpressions, ...permanentFilterExpressions]);
		const m = [AanwezigheidMeasure.LESUREN];
		const r = [0, 1];
		return this.getData({ g, f, m, r }).pipe(
			map((resp) => {
				const { aggInit, aggCombine } = createAggFunctions<AfwezigheidI, AfwezigheidA, LeerlingAfwezigheidDetailsPanelComponent>(
					this,
					[],
					resp.measures
				);
				return unnest(resp.data, undefined, undefined, aggInit, aggCombine)?.[0][0];
			})
		);
	}

	getData(options: DataOptions) {
		return this.dataService.getAanwezigheidData(options);
	}

	factTable = Table.fac_aw_aanwezigheid;

	getDashboardFilters = memoize(LeerlingAfwezigheidDetailsPanelComponent._getDashboardFilters, (l) => l.lb_nr_leerling);

	private static _getDashboardFilters(leerling: LeerlingDetail): FilterExpression[] {
		return [new BasicFilterExpression(['aw_nr_leerling'], leerling.lb_nr_leerling)];
	}

	getSchooljaarFilterOverride = memoize(LeerlingAfwezigheidDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { aw_nm_schooljaar: schooljaarInfo };
	}

	generateDay(date: Date, dataRoot?: Level<AfwezigheidA, number[]>): Day {
		const geenLes = { date: new Date(date), classNames: { geenLes: true }, tooltip: 'Geen les' };
		if (dataRoot === undefined) return geenLes;

		const dagLvl = lookupByKeys(dataRoot, [formatDate(date, 'yyyy-MM-dd', 'nl-NL')]);
		if (!dagLvl) return geenLes;

		const totaalLesuren = dagLvl.a.aw_nr_lesuren;
		const geoorloofd = lookupByKeys(dagLvl, ['1']);
		const ongeoorloofd = lookupByKeys(dagLvl, ['0']);

		const classNames = {
			geoorloofd: geoorloofd !== undefined,
			ongeoorloofd: ongeoorloofd !== undefined,
		};

		const tooltip: TooltipElement[] = [];
		tooltip.push({
			label: 'Aantal lesuren',
			value: `${totaalLesuren} (${formatDurationUrenMinuten(dagLvl.a.aw_nr_minuten, 'u m')})`,
		});

		if (geoorloofd) {
			const geoorloofdLesuren = geoorloofd.a.aw_nr_lesuren;
			tooltip.push({
				label: 'Geoorloofd afwezig',
				value: `${geoorloofdLesuren} (${formatPercent(deelVeilig(geoorloofdLesuren, totaalLesuren), 'nl-NL', '1.0-1')} - ${formatDurationUrenMinuten(geoorloofd.a.aw_nr_minuten, 'u m')})`,
			});
		}

		if (ongeoorloofd) {
			const ongeoorloofdLesuren = ongeoorloofd.a.aw_nr_lesuren;
			tooltip.push({
				label: 'Ongeoorloofd afwezig',
				value: `${ongeoorloofdLesuren} (${formatPercent(deelVeilig(ongeoorloofdLesuren, totaalLesuren), 'nl-NL', '1.0-1')} - ${formatDurationUrenMinuten(ongeoorloofd.a.aw_nr_minuten, 'u m')})`,
			});
		}

		return { date: new Date(date), classNames, tooltip };
	}
}
