import { Directive, EventEmitter, inject, OnDestroy } from '@angular/core';
import { DetailElement, DetailsElementData, LijstElement, LijstElementData } from '../Details';
import { CompoundFilterExpression, DataService, FilterExpression, ListOptions, TableResponse } from '../../services/data.service';
import { BehaviorSubject, combineLatest, lastValueFrom, Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { distinctUntilChanged, filter, map, scan, startWith, switchMap, take, tap } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { DetailsDashboard } from './details.dashboard';
import { FilterService } from '../../services/filter.service';
import { ExportService } from '../../services/export.service';
import { LoadingService, LoadingType } from '../../services/loading.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { PageStateService, PsName } from '../../services/page-state.service';
import { LeerlingSelectieService } from '../../services/leerling-selectie.service';

export function convertDate(content: string): string {
	if (isNil(content)) return '-';
	else return formatDate(content, 'dd-MM-yyyy', 'nl-NL');
}

export function convert(content: string): string {
	if (isNil(content)) return '-';
	else return content;
}

const defaultLimit = 100;

@Directive()
export abstract class BaseDetailsPanelComponent<L extends LijstElement, D extends DetailElement> implements OnDestroy {
	readonly dataService = inject(DataService);
	readonly route = inject(ActivatedRoute);
	readonly filterService = inject(FilterService);
	readonly exportService = inject(ExportService);
	readonly loadingService = inject(LoadingService);
	readonly qp = inject(QueryParamStateService);
	readonly leerlingSelectieService = inject(LeerlingSelectieService);
	protected readonly pageStateService = inject(PageStateService);

	selectie$: Observable<number | undefined>;
	schooljaar$: Observable<string | undefined>;

	elementen: L[] = [];

	selectedLijstElement: L | undefined;

	schooljaar: string | undefined;

	total: number | undefined;

	selectedDetailElement?: D;

	fetch$ = new EventEmitter<number>();

	from: string | undefined = undefined;

	nextFetchReceived$ = new EventEmitter();

	changeElement$ = new EventEmitter<boolean>();

	searchInput$ = new BehaviorSubject<string>('');

	sidebarOpened$: Observable<boolean>;

	showSpinner$: Observable<boolean>;

	protected childDashboard?: DetailsDashboard<D>;

	protected subscriptions: Subscription[] = [];

	constructor() {
		this.sidebarOpened$ = this.filterService.filterPanelOpened$;
		this.showSpinner$ = this.loadingService.shouldShowLoadingIndicator(LoadingType.TABLE);

		const { from } = this.route.snapshot.queryParams;
		this.from = from;

		this.selectie$ = this.qp.observe('selectie');
		this.schooljaar$ = this.qp.observe('schooljaar');

		const selectieExpressions = this.leerlingSelectieService
			.observeDetailsList()
			.pipe(switchMap((selectieIds) => this.leerlingSelectieService.getExpressions(selectieIds)));

		this.subscriptions.push(
			combineLatest([this.searchInput$, selectieExpressions])
				.pipe(
					tap(() => {
						this.selectedLijstElement = undefined;
						this.total = undefined;
					}),
					switchMap(([searchInput, selectieExpressions]) =>
						this.fetch$.pipe(
							startWith(0),
							filter((off) => off !== this.total),
							switchMap((off) => {
								const zoekFilter = this.getFilters(searchInput);
								const f = new CompoundFilterExpression([
									...(zoekFilter ? [zoekFilter] : []),
									...(selectieExpressions?.length > 0 ? [new CompoundFilterExpression(selectieExpressions, 'or')] : []),
								]);
								return this.getLijstData({ lim: defaultLimit, off, f });
							}),
							tap((tableResponse) => {
								if (tableResponse.total !== undefined) this.total = tableResponse.total;
							}),
							map((tableResponse) => tableResponse.rows),
							scan((acc, cur) => [...acc, ...cur])
						)
					)
				)
				.subscribe((elementen) => {
					this.elementen = elementen;
					if (elementen.length > 0)
						lastValueFrom(this.selectie$.pipe(take(1))).then((val) => {
							if (val === undefined) this.selectElement(elementen[0]);
							else if (this.selectedLijstElement === undefined) this.setSelectedLijstElement(val);
						});
					this.nextFetchReceived$.emit();
				}),
			this.selectie$
				.pipe(
					distinctUntilChanged(),
					filter((key) => key !== undefined),
					switchMap((key) => {
						this.setSelectedLijstElement(key);
						return this.getDetailsData(key);
					})
				)
				.subscribe((selectedDetail) => {
					this.selectedDetailElement = selectedDetail.rows[0];
					this.childDashboard!.setSelected(this.selectedDetailElement, this.schooljaar);
				}),
			this.changeElement$.subscribe((next) => this.gotoNextOrPrevious(next)),
			this.schooljaar$.subscribe((value) => (this.schooljaar = value))
		);
	}

	setSelectedLijstElement<B>(key: B) {
		this.selectedLijstElement = this.elementen.find((element) => this.getElementKey(element) === key);

		const data = this.selectedLijstElement ? this.getLijstElementData(this.selectedLijstElement) : null;
		const subject = data ? `${data.title} (${data.subTitle})` : 'Onbekend';
		this.pageStateService.dispatch(PsName.subject, subject);
	}

	closeSidebar() {
		this.filterService.filterPanelOpened$.next(false);
	}

	abstract getUrlPart(): string;

	abstract getElementKey(element: L): number;

	selectElement(element: L) {
		this.qp.dispatch('selectie', this.getElementKey(element));
	}

	getCurrentIndex() {
		if (this.selectedLijstElement) return this.elementen.indexOf(this.selectedLijstElement);
		return -1;
	}

	getTotal(): number {
		return this.total ?? 0;
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	onActivate(componentReference: DetailsDashboard<D>) {
		this.childDashboard = componentReference;
		if (this.selectedDetailElement) this.childDashboard.setSelected(this.selectedDetailElement, this.schooljaar);
	}

	startExport() {
		this.exportService
			.startExport(this.childDashboard!.factTable, this.childDashboard!.exportTypes, this.getLegendaOptions())
			.subscribe((exportOptions) => {
				this.childDashboard!.doDetailsExport(exportOptions);
			});
	}

	private getLegendaOptions() {
		const childDashboard = this.childDashboard;
		if (!childDashboard) return undefined;

		if (childDashboard.dashboardHeaderComponent) return childDashboard.dashboardHeaderComponent.legendaComponent?.options;
		if (childDashboard.legendaComponent) return childDashboard.legendaComponent?.options;

		return undefined;
	}

	abstract getTitle(): string;

	abstract getType(): string;

	abstract getLijstElementData(input: L): LijstElementData;

	abstract getDetailsElementData(input: D): DetailsElementData;

	abstract getLijstData(options: Partial<ListOptions>): Observable<TableResponse<L>>;

	abstract getDetailsData(key: number): Observable<TableResponse<D>>;

	abstract getFilters(searchInput: string): FilterExpression | undefined;

	abstract isSelectionVisible(): boolean;

	private gotoNextOrPrevious(next: boolean) {
		const currentIndex = this.getCurrentIndex();

		let index;
		if (next) index = currentIndex + 1;
		else index = currentIndex - 1;

		if (index >= 0 && index < this.elementen.length) this.selectElement(this.elementen[index]);
		else if (index >= 0 && index < this.getTotal()) {
			const nextIndex = index;
			this.nextFetchReceived$.pipe(take(1)).subscribe(() => this.selectElement(this.elementen[nextIndex]));
			this.fetch$.emit(this.elementen.length);
		}
	}
}
