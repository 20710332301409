import { Component, inject } from '@angular/core';
import { isUndefined, memoize, uniqBy } from 'lodash-es';
import { RCumLaudeAccount } from '@cumlaude/service-contract';
import { DimVvVestigingVak } from '@cumlaude/metadata';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { BeheerMultiDropdownComponent } from '../../../beheer/beheer-multi-dropdown/beheer-multi-dropdown.component';
import { BeheerDropdownComponent } from '../../../beheer/beheer-dropdown/beheer-dropdown.component';
import { BaseDialogComponent, DialogHeaderComponent } from '@cumlaude/shared-components-dialogs';
import { accountLabelMetAfkorting, getAccountZoekString } from '@cumlaude/shared-utils';

export type SectieToekennenData = {
	account?: RCumLaudeAccount;
	alleAccounts: RCumLaudeAccount[];
	alleVakken: DimVvVestigingVak[];
};

@Component({
	selector: 'app-sectie-toekennen-dialog',
	templateUrl: './sectie-toekennen-dialog.component.html',
	styleUrls: ['./sectie-toekennen-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, BeheerDropdownComponent, BeheerMultiDropdownComponent, ButtonComponent],
})
export class SectieToekennenDialogComponent {
	protected data = inject<SectieToekennenData>(DIALOG_DATA);
	protected dialogRef = inject<DialogRef<RCumLaudeAccount>>(DialogRef);

	selectAccountEnabled: boolean;

	selectedVakken: DimVvVestigingVak[];

	constructor() {
		if (isUndefined(this.data.account)) {
			this.selectAccountEnabled = true;
			this.selectedVakken = [];
		} else {
			this.selectAccountEnabled = false;
			this.selectedVakken = this.getCurrentVakken(this.data.account);
		}
	}

	getAccountId(account: RCumLaudeAccount) {
		return account.id!;
	}

	getAccountLabel = accountLabelMetAfkorting;

	getCurrentVakken(account: RCumLaudeAccount) {
		return account.vakken
			.map((vakId) => this.data.alleVakken.find((vak) => vak.vv_nm_vak === vakId))
			.filter((x) => x)
			.map((x) => <DimVvVestigingVak>x);
	}

	getAlleVakken = memoize(this._getAlleVakken.bind(this), (account) => account?.id);

	_getAlleVakken(account?: RCumLaudeAccount): DimVvVestigingVak[] {
		if (!account) return uniqBy(this.data.alleVakken, this.getVakId.bind(this));

		const currentVakken = this.getCurrentVakken(account);
		const vestigingVakken = this.data.alleVakken.filter((vak) => account.vestigingen.includes(vak.vv_id_vestiging!));

		return uniqBy([...currentVakken, ...vestigingVakken], this.getVakId.bind(this));
	}

	getVakId(vak: DimVvVestigingVak) {
		return vak.vv_nm_vak!;
	}

	getVakZoekString({ vv_abb_vak, vv_nm_vak }: DimVvVestigingVak) {
		return vv_abb_vak! + vv_nm_vak!;
	}

	getVakLabel({ vv_nm_vak }: DimVvVestigingVak) {
		return vv_nm_vak!;
	}

	returnAccount(): RCumLaudeAccount {
		return { ...this.data.account!, vakken: this.selectedVakken.map((vak) => this.getVakId(vak)) };
	}
	protected readonly getAccountZoekString = getAccountZoekString;
}
