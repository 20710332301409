import { Injectable } from '@angular/core';
import { AppAuthenticationService } from '@cumlaude/shared-authentication';

@Injectable({
	providedIn: 'root',
})
export class UserAuthenticationService extends AppAuthenticationService {
	clearSessionStorage() {
		sessionStorage.removeItem('rol');
	}

	getOAuthParams() {
		return {};
	}
}
