import { Component, inject } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';
import { Prestatie } from '@cumlaude/metadata';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { Selection } from '../../../../dashboard/base-dashboard/base-dashboard-config';
import { UrlService } from '../../../../../services/url.service';
import { ExtendedDecimalPipe, ExtendedPercentPipe } from '@cumlaude/shared-pipes';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { RouterLink } from '@angular/router';

export type DoorstroomData = {
	count_records: number;
	ds_nr_weging: number;
	percentage?: number;
	ds_is_prognose: '1' | '0';
	ds_nm_idu: Prestatie | null;
	tooltip: string;
	selection: Selection;
};

@Component({
	selector: 'app-doorstroom-cell',
	templateUrl: './doorstroom-cell.component.html',
	styleUrls: ['./doorstroom-cell.component.scss'],
	imports: [RouterLink, TooltipDirective, ExtendedPercentPipe, ExtendedDecimalPipe],
})
export class DoorstroomCellComponent implements TableCellComponent<DoorstroomData | null> {
	protected readonly urlService = inject(UrlService);

	data!: DoorstroomData | null;
	format = '1.0-0';

	getClass() {
		if (this.data === null) return 'empty';
		return `${generateCssClassForString(this.data.ds_nm_idu ?? null) ?? 'onbekend'}${Number(this.data.ds_is_prognose) ? '-prognose' : ''}`;
	}

	getExportValue(data: DoorstroomData | null): any {
		if (data?.percentage) return data.percentage;
		return data?.ds_nr_weging;
	}
}
