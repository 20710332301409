/**
 * Abstracte class voor focusbare componenten in combinatie met de AutofocusDirective. Gebruik:
 *
 * ```typescript
 * @Component({
 *      selector: 'app-input',
 *      templateUrl: './input.component.html',
 *      providers: [{ provide: Focusable, useExisting: forwardRef(() => InputComponent) }],
 * })
 * export class InputComponent extends Focusable {
 *      @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
 *
 *      focus(): void {
 *          this.inputElement.nativeElement.focus();
 *      }
 * }
 */
export abstract class Focusable {
	abstract focus(): void;
}
