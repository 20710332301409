import { Injectable, inject } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { RLeerlingSelectie } from '@cumlaude/service-contract';
import { firstValueFrom, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { fromPairs, isEqual, orderBy } from 'lodash-es';
import { AuthService } from '@cumlaude/shared-authentication';
import { ToastrService } from 'ngx-toastr';
import {
	LeerlingselectieBewerkenSidebarComponent,
	LeerlingselectieBewerkenSidebarData,
} from '../sidebars/leerlingselectie/leerlingselectie-bewerken-sidebar/leerlingselectie-bewerken-sidebar.component';
import { NotifiableError } from '@bugsnag/js';
import { Selection } from '../shared/dashboard/base-dashboard/base-dashboard-config';
import { UserService } from './user.service';
import { CompoundFilterExpression, createSelectionExpression, FilterExpression } from './data.service';
import { BugsnagService } from '@cumlaude/bugsnag';
import { ActivatedRoute } from '@angular/router';
import { QueryParamStateService } from './query-param-state.service';
import { combineLatestEmptySafe } from '@cumlaude/shared-utils';
import { SidebarService } from '@cumlaude/shared-components-overlays';

export interface LeerlingSelectieId {
	id: string;
	naam: string;
}

export interface AdHocSelectieId extends LeerlingSelectieId {
	size: number;
	expression: FilterExpression;
}

export const AD_HOC_SELECTION_ID = '-1';

@Injectable({
	providedIn: 'root',
})
export class LeerlingSelectieService {
	private readonly restService = inject(RestService);
	private readonly toastr = inject(ToastrService);
	private readonly userService = inject(UserService);
	private readonly bugsnag = inject(BugsnagService);
	private readonly route = inject(ActivatedRoute);
	private readonly qp = inject(QueryParamStateService);
	private readonly sidebarService = inject(SidebarService);

	private selecties = new ReplaySubject<{ [id: string]: RLeerlingSelectie }>(1);

	public adHocSelectieId$: Observable<AdHocSelectieId | undefined>;

	constructor() {
		const authService = inject(AuthService);
		authService.loggedIn$.subscribe((loggedIn) => {
			if (loggedIn) this.refresh();
		});
		this.adHocSelectieId$ = this.route.queryParamMap.pipe(
			map((paramMap) => [paramMap.get('adhoc'), paramMap.get('from')]),
			distinctUntilChanged(isEqual),
			map(([adhocJSON, from]) => {
				if (!adhocJSON) return undefined;
				const adhoc = JSON.parse(adhocJSON) as Selection;
				return {
					id: AD_HOC_SELECTION_ID,
					naam: `Leerlingselectie - ${from}${adhoc.size ? ` (${adhoc.size})` : ''}`,
					size: adhoc.size,
					expression: adhoc.expression,
				};
			})
		);
	}

	observeDetailsList() {
		return this.qp.observe('details-list', this.route.snapshot.queryParams['from'] ? [{ id: AD_HOC_SELECTION_ID, naam: '' }] : []);
	}

	getLeerlingSelectieIds(): Observable<LeerlingSelectieId[]> {
		this.refresh();
		return this.selecties.pipe(
			map((obj) => {
				const ids = Object.values(obj).map(({ naam, id }) => ({ naam, id: id! }));
				return orderBy(ids, 'naam');
			})
		);
	}

	get(id: string): Observable<RLeerlingSelectie> {
		return this.selecties.pipe(
			map((sel) => {
				if (id in sel) return sel[id];
				throw new Error('not found');
			}),
			take(1),
			catchError(() => this.restService.getLeerlingSelectie(id))
		);
	}

	delete(id: string): Observable<void> {
		return this.restService.deleteLeerlingSelectie(id).pipe(
			switchMap(() => this.selecties),
			take(1),
			map(({ [id]: _, ...rest }) => this.selecties.next(rest))
		);
	}

	post(selectie: RLeerlingSelectie): Observable<RLeerlingSelectie> {
		return combineLatestEmptySafe([this.restService.postLeerlingSelectie(selectie), this.selecties]).pipe(
			take(1),
			map(([selectieWithId, current]) => {
				this.selecties.next({ ...current, [selectieWithId.id!]: selectieWithId });
				return selectieWithId;
			})
		);
	}

	put(selectie: RLeerlingSelectie): Observable<RLeerlingSelectie> {
		return this.restService.putLeerlingSelectie(selectie).pipe(
			switchMap(() => this.selecties),
			take(1),
			map((current) => {
				this.selecties.next({ ...current, [selectie.id!]: selectie });
				return selectie;
			})
		);
	}

	refresh() {
		firstValueFrom(this.restService.getLeerlingSelecties()).then((rLeerlingSelecties) => {
			this.selecties.next(fromPairs(rLeerlingSelecties.map((selectie) => [selectie.id, selectie])));
		});
	}

	getAdHocSelection(): Observable<Omit<Selection, 'detailUrl'> | undefined> {
		return this.adHocSelectieId$;
	}

	async slaSelectieOp(selection?: { expression: FilterExpression }) {
		const account = await lastValueFrom(this.userService.myAccount$.pipe(take(1)));

		const sidebarRef = this.sidebarService.open<RLeerlingSelectie, LeerlingselectieBewerkenSidebarData>(
			LeerlingselectieBewerkenSidebarComponent,
			{ data: { expression: selection?.expression, account } }
		);

		const rLeerlingSelectie = await lastValueFrom(sidebarRef.closed);
		if (!rLeerlingSelectie) return;

		try {
			const ret = await lastValueFrom(this.post(rLeerlingSelectie));
			this.toastr.success(`${rLeerlingSelectie.naam} is opgeslagen als leerlingselectie.`);
			return ret;
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het opslaan van leerlingselectie ${rLeerlingSelectie.naam}.`);
			return;
		}
	}

	getExpressions(selectieIds: LeerlingSelectieId[]) {
		return combineLatestEmptySafe(
			selectieIds.map(({ id }) => {
				if (id === AD_HOC_SELECTION_ID)
					return this.adHocSelectieId$.pipe(map((selectie) => selectie?.expression ?? new CompoundFilterExpression([])));
				else return this.get(id).pipe(map((rLeerlingSelectie) => createSelectionExpression(rLeerlingSelectie)));
			})
		);
	}
}
