<app-tab-menu (exportClicked)="startExport()" [exportVisible]="this.childDashboard?.factTable !== undefined" [shareVisible]="true"></app-tab-menu>
<app-selection-bar [showTerug]="from !== undefined" [terugPageNiet]="getUrlPart()" [showSelection]="isSelectionVisible()"></app-selection-bar>
<div class="sidebar">
	<div class="titlebar">
		<h3>{{ getTitle() }} ({{getTotal()}})</h3>
	</div>
	<app-instant-search-box (fire)="searchInput$.next($event)"></app-instant-search-box>
	<app-lijst
		(nextPage)="fetch$.emit($event)"
		(selectedChange)="selectElement($event)"
		[elementen]="elementen"
		[getData]="getLijstElementData"
		[selected]="selectedLijstElement"
	></app-lijst>
</div>
<app-detail-panel
	(changeElement)="changeElement$.emit($event)"
	[getData]="getDetailsElementData"
	[selected]="selectedDetailElement"
	[showNext]="elementen.length > 1 && getCurrentIndex() > 0"
	[showPrevious]="elementen.length > 1 && getCurrentIndex() < getTotal() - 1"
	[type]="getType()"
	data-exportable
></app-detail-panel>
<div (click)="closeSidebar()" [class.is-open]="sidebarOpened$ | async" class="overlay"></div>
<div [class.show-sidebar]="sidebarOpened$ | async" class="dashboard">
	<div class="spinner-overlay">
		@if (showSpinner$ | async) {
			<app-svg-spinner></app-svg-spinner>
		}
	</div>
	<router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
