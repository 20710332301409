import { Component, computed, input, inject } from '@angular/core';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { QpName, QpValue, QueryParamStateService } from '../../../services/query-param-state.service';

@Component({
	selector: 'app-weergave-optie',
	templateUrl: './weergave-optie.component.html',
	styleUrls: ['./weergave-optie.component.scss'],
	imports: [FormDropdownComponent],
})
export class WeergaveOptieComponent<N extends QpName> {
	protected readonly qp = inject(QueryParamStateService);

	param = input.required<N>();

	enum = input.required<{ [k: string]: QpValue<N> }>();

	value = input.required<QpValue<N>>();

	label = input.required<string>();

	allValues = computed(() => Object.values(this.enum()));

	opties = computed(() => this.allValues().map((val) => new Option(val)));

	optie = computed(() => this.opties().find((o) => o.value === this.value()));
}
