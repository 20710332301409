import { UitzonderingComponent } from './uitzondering.component';
import { AfterViewInit, Directive } from '@angular/core';
import { delay } from 'rxjs/operators';
import { getOnderwijsresultatenSchooljaarRangeString } from '@cumlaude/shared-utils';
import { FilterName } from '../../services/filter-config';
import { onderwijsresultatenDsFilterExcludes } from '../../services/exportable';

@Directive()
export abstract class UitzonderingOnderwijsresultatenComponent extends UitzonderingComponent implements AfterViewInit {
	override ngAfterViewInit() {
		super.ngAfterViewInit();
		this.subscriptions.push(
			this.filterService
				.observe('x_onderwijsresultaten_ds_schooljaar')
				.pipe(delay(0))
				.subscribe(([schooljaar, aantalJaren]) => {
					this.schooljaar = schooljaar;
					this.aantalJaren = aantalJaren;
				})
		);
	}

	override getSchooljarenCaption() {
		return this.schooljaar ? getOnderwijsresultatenSchooljaarRangeString(this.schooljaar, this.aantalJaren!, 'bbs') : '';
	}

	override filterExcludes(): FilterName[] {
		return onderwijsresultatenDsFilterExcludes;
	}
}
