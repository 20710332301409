import { Component } from '@angular/core';
import { AttrPath } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { onderbouwsnelheidFilters } from '../onderbouwsnelheid/onderbouwsnelheid.component';
import { UitzonderingOnderwijsresultatenComponent } from './uitzondering-onderwijsresultaten.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

@Component({
	selector: 'app-uitzondering-onderbouwsnelheid',
	templateUrl: './uitzondering.component.html',
	styleUrls: ['./uitzondering.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		TooltipDirective,
		SchooljaarIndicatorOverComponent,
		BarchartTableComponent,
		FilterBarComponent,
	],
})
export class UitzonderingOnderbouwsnelheidComponent extends UitzonderingOnderwijsresultatenComponent {
	groups: AttrPath[] = [['ds_nm_schooljaar_van'], ['ds_nm_obs_uitzondering_van'], ['ds_nm_status']];

	initialFilters: FilterName[] = [
		'x_onderwijsresultaten_ds_schooljaar',
		'ds_fk_br_vest_van.br_co_brin',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_nm_obs_uitzondering_van',
		'ds_nm_status',
		'ds_nm_idu',
		'ds_is_prognose',
	];

	permanentFilterExpressions = onderbouwsnelheidFilters;
}
