import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isUndefined } from 'lodash-es';

export enum PsName {
	prognose = 'prognose',
	subject = 'subject',
}

@Injectable({
	providedIn: 'root',
})
export class PageStateService {
	private state: Partial<{ [name in PsName]: BehaviorSubject<string | undefined> }> = {};

	observe(name: PsName): Observable<string | undefined> {
		return this.getBehaviorSubject(name);
	}

	private getBehaviorSubject(name: PsName) {
		let stateElement = this.state[name];
		if (isUndefined(stateElement)) {
			stateElement = new BehaviorSubject<string | undefined>(undefined);
			this.state[name] = stateElement;
		}

		return stateElement;
	}

	dispatch(name: PsName, value: string) {
		this.getBehaviorSubject(name).next(value);
	}
}
