@if (medewerker$ | async; as medewerker) {
	<div class="dashboard-toets">
		<app-filter-bar></app-filter-bar>
		<app-filter-panel
			[defaultFilters]="toetsFilters"
			[allFilters]="getAllFilters()"
			[leerlingselectie]="false"
			(filterExpressionsChange)="filterExpressions = $event"
			endpoint="/cijfers"
			[permanentFilterExpressions]="getDashboardFilters(getPermanentFilters(cijferkolomKeuze, dossier, []), medewerker.medewerker)"
			[overrideDefault]="getSchooljaarFilterOverride(medewerker.schooljaarInfo)"
		></app-filter-panel>
		<section class="dashboard">
			<app-dashboard-header [legenda]="['cf_nr_cijfer_afgerond']" [leftOptionsTemplate]="leftOptions">
				<ng-template #leftOptions>
					<div class="weergave cijfertype">
						Cijfertype:
						<dt-form-dropdown
							[opties]="cijferkolomKeuzeOpties()"
							[selectedOptie]="getSelectedCijfertypeOptie()"
							(onOptieClicked)="qp.dispatch('cijfertype', $event)"
						></dt-form-dropdown>
					</div>
					@if (cijferkolomKeuze !== "Gemiddelde" && userService.bron() === InstellingBron.Somtoday) {
						<div class="weergave dossier">
							Toetsdossier:
							<dt-form-dropdown
								[opties]="dossierOpties"
								[selectedOptie]="getSelectedDossierOptie()"
								(onOptieClicked)="qp.dispatch('dossier', $event)"
							></dt-form-dropdown>
						</div>
					}
				</ng-template>
			</app-dashboard-header>
			@if (medewerker) {
				<app-pivot-table
					[filters]="filterExpressions"
					[permanentFilters]="getDashboardFilters(getPermanentFilters(cijferkolomKeuze, dossier, []), medewerker.medewerker)"
					[tableGroups]="tableGroups"
					[defaultGroups]="[defaultGroup]"
					[groups]="getGroups(selectedGroup)"
					[subgroups]="subgroups"
					[columnSort]="sortToetsKolommen"
					[config]="this"
				></app-pivot-table>
			}
		</section>
	</div>
}
