import { Component, inject, Input, OnInit, TemplateRef, ViewChild, viewChildren } from '@angular/core';
import { MultiSelectCheckboxComponent, Option } from '@cumlaude/shared-components-inputs';
import { AD_HOC_SELECTION_ID, LeerlingSelectieId, LeerlingSelectieService } from '../../services/leerling-selectie.service';
import { Dialog } from '@angular/cdk/dialog';
import { lastValueFrom } from 'rxjs';
import { RLeerlingSelectie } from '@cumlaude/service-contract';
import { NotifiableError } from '@bugsnag/js';
import { BugsnagService } from '@cumlaude/bugsnag';
import { ToastrService } from 'ngx-toastr';
import {
	LeerlingselectieBewerkenSidebarComponent,
	LeerlingselectieBewerkenSidebarData,
} from '../../sidebars/leerlingselectie/leerlingselectie-bewerken-sidebar/leerlingselectie-bewerken-sidebar.component';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { MeatballMenuComponent, MeatballMenuItemComponent } from '@cumlaude/shared-components-menu';
import { MultiSelectFilterComponent } from '../multi-select-filter/multi-select-filter.component';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { isEqual, isUndefined, partition } from 'lodash-es';
import { combineLatestEmptySafe } from '@cumlaude/shared-utils';
import { SidebarService } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';

@Component({
	selector: 'app-leerling-selectie-filter',
	templateUrl: './leerling-selectie-filter.component.html',
	styleUrls: ['./leerling-selectie-filter.component.scss'],
	imports: [MeatballMenuComponent, MeatballMenuItemComponent, ConfirmDialogComponent, MultiSelectCheckboxComponent, ButtonComponent],
})
export class LeerlingSelectieFilterComponent extends MultiSelectFilterComponent<LeerlingSelectieId> implements OnInit {
	protected readonly leerlingSelectieService = inject(LeerlingSelectieService);
	private readonly dialog = inject(Dialog);
	private readonly sidebarService = inject(SidebarService);
	private readonly bugsnag = inject(BugsnagService);
	private readonly toastr = inject(ToastrService);
	private readonly qp = inject(QueryParamStateService);

	@ViewChild('confirmDeleteDialog')
	confirmDeleteDialog!: TemplateRef<LeerlingSelectieId>;

	/**
	 * Als false: werk via de FilterService zoals alle FilterComponents
	 *
	 * Als true: haal de currentValue uit de 'details-list' query parameter en de mogelijke opties direct uit de
	 * LeerlingSelectieService
	 */
	@Input()
	detailsList = false;

	meatballMenus = viewChildren(MeatballMenuComponent);

	override ngOnInit() {
		if (this.detailsList) {
			this.inDropdown = true;

			const header1 = { naam: '_header1', id: '_header1' };
			const header2 = { naam: '_header2', id: '_header2' };
			this.subscriptions.push(
				combineLatestEmptySafe([
					this.leerlingSelectieService.adHocSelectieId$,
					this.leerlingSelectieService.getLeerlingSelectieIds(),
				]).subscribe(([adhoc, vals]) => {
					const firstOptions: Option<LeerlingSelectieId>[] = adhoc
						? [
								new Option(header1, 'Selectie uit dashboard', 'header'),
								new Option(adhoc, adhoc.naam, 'last-of-group'),
								new Option(header2, 'Opgeslagen leerlingselecties', 'header'),
							]
						: [];
					const [selected, notSelected] = partition(vals, (val) => this.currentValue!.findIndex((cur) => cur.id === val.id) >= 0);
					const otherOptions = [...selected, ...notSelected].map((val) => new Option(val, val.naam, val.id));
					this.options.set([...firstOptions, ...otherOptions]);
				}),
				this.leerlingSelectieService.observeDetailsList().subscribe((vals) => (this.currentValue = vals))
			);
		} else {
			super.ngOnInit();
		}
	}

	override select(options: Option<LeerlingSelectieId>[]) {
		if (this.detailsList) {
			this.qp.dispatch(
				'details-list',
				options.map((opt) => opt.value)
			);
		} else {
			super.select(options);
		}
	}

	override getSelected(options: Option<LeerlingSelectieId>[]): Option<LeerlingSelectieId>[] {
		if (this.currentValue === undefined) return [];
		return options.filter((option) => !isUndefined(this.currentValue!.find((val) => isEqual(val.id, option.value.id))));
	}

	async deleteSelectie(id: LeerlingSelectieId, event: Event) {
		event.preventDefault();
		event.stopPropagation();
		this.meatballMenus().forEach((menu) => menu.hide());

		const dialogRef = this.dialog.open(this.confirmDeleteDialog, { data: id });
		if (!(await lastValueFrom(dialogRef.closed))) return;
		try {
			await lastValueFrom(this.leerlingSelectieService.delete(id.id));
			if (this.currentValue?.find((val) => val.id === id.id)) {
				this.select(this.getSelected(this.options().filter((option) => option.value.id != id.id)));
			}
			this.toastr.success(`Leerlingselectie ${id.naam} is verwijderd.`);
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het verwijderen van leerlingselectie ${id.naam}.`);
		}
	}

	async editSelectie(id: LeerlingSelectieId, event: Event) {
		event.preventDefault();
		event.stopPropagation();
		this.meatballMenus().forEach((menu) => menu.hide());

		const leerlingselectie = await lastValueFrom(this.leerlingSelectieService.get(id.id));
		const sidebarRef = this.sidebarService.open<RLeerlingSelectie, LeerlingselectieBewerkenSidebarData>(
			LeerlingselectieBewerkenSidebarComponent,
			{ data: { leerlingselectie, account: leerlingselectie.account } }
		);
		const rLeerlingSelectie = await lastValueFrom(sidebarRef.closed);
		if (!rLeerlingSelectie) return;
		try {
			await lastValueFrom(this.leerlingSelectieService.put(rLeerlingSelectie));
			this.toastr.success(`Leerlingselectie ${rLeerlingSelectie.naam} is aangepast.`);
			const selected = this.currentValue?.find((val) => val.id === id.id);
			if (selected) {
				selected.naam = rLeerlingSelectie.naam;
				this.options().find((opt) => opt.value.id === id.id)!.value.naam = rLeerlingSelectie.naam;
				this.select(this.getSelected(this.options()));
			}
		} catch (err) {
			this.bugsnag.notify(<NotifiableError>err);
			this.toastr.error(`Er ging iets fout bij het opslaan van leerlingselectie ${rLeerlingSelectie.naam}.`);
		}
	}

	isAdHoc(option: Option<LeerlingSelectieId>) {
		return option.value.id === AD_HOC_SELECTION_ID;
	}

	async nieuweLeerlingselectie() {
		const rLeerlingselectie = await this.leerlingSelectieService.slaSelectieOp();
		if (rLeerlingselectie && rLeerlingselectie.id) {
			// Selecteer direct de aangemaakte leerlingselectie
			const { id, naam } = rLeerlingselectie;
			const option = new Option({ id, naam }, rLeerlingselectie.naam, rLeerlingselectie.id);
			this.options.set([...this.options(), option]);
			this.allValues.push(option.value);
			this.select([option]);
		}
	}
}
