<app-dashboard-container
	class="dashboard-cohortdetails"
	[factTable]="factTable"
	[exportTypes]="exportTypes"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], updateExportOptions($event))"
>
	<app-filter-panel
		[defaultFilters]="getFilterNames()"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	>
	</app-filter-panel>
	@if (!fullScreen()) {
		<app-filter-bar></app-filter-bar>
	}
	<section class="dashboard">
		@if (!fullScreen()) {
			<app-dashboard-header
				[legenda]="['ds_fun_doorstroom_ext']"
				[legendaIncludeNull]="true"
				[legendaStyle]="'PILL'"
				[leftOptionsTemplate]="leftOptions"
				[rightOptionsTemplate]="rightOptions"
			>
				<ng-template #leftOptions>
					<div class="weergave cohortrendementtype">
						Type
						<dt-form-dropdown
							[opties]="typeOpties"
							[selectedOptie]="typeOptie"
							(onOptieClicked)="qp.dispatch('cohortrendementtype', $event)"
						></dt-form-dropdown>
					</div>
					<div class="weergave eenheid">
						Eenheid
						<dt-form-dropdown
							[opties]="eenheidOpties"
							[selectedOptie]="eenheidOptie"
							(onOptieClicked)="qp.dispatch('eenheid', $event)"
						></dt-form-dropdown>
					</div>
				</ng-template>
				<ng-template #rightOptions>
					<app-button icon="svg-full-screen" (clickit)="toggleFullScreen()" buttonType="secondary"></app-button>
				</ng-template>
			</app-dashboard-header>
			@if (subtitle) {
				<div class="subtitle">{{ subtitle }}</div>
			}
		} @else {
			<div class="floating">
				<app-button class="right" icon="svg-full-screen-off" (clickit)="toggleFullScreen()" buttonType="secondary"></app-button>
				<app-legenda class="legenda" [style]="'PILL'" [attribute]="['ds_fun_doorstroom_ext']" [includeNull]="true"></app-legenda>
			</div>
		}
		<app-cohort-graph
			[filters]="filterExpressions"
			[permanentFilters]="permanentFilterExpressions"
			[richting]="typeOptie.richting"
			[eenheid]="eenheidOptie.value"
		></app-cohort-graph>
	</section>
</app-dashboard-container>
