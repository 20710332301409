import { Component } from '@angular/core';
import { AttrPath } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { onderwijspositieFilters } from '../onderwijspositie/onderwijspositie.component';
import { UitzonderingOnderwijsresultatenComponent } from './uitzondering-onderwijsresultaten.component';
import { DRIE_SCHOOLJAREN_EXCL_2020, getOnderwijsresultatenSchooljaarRangeString } from '@cumlaude/shared-utils';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';
import { without } from 'lodash-es';
import { OnderwijspositieStatus } from '@cumlaude/metadata';

@Component({
	selector: 'app-uitzondering-onderwijspositie',
	templateUrl: './uitzondering.component.html',
	styleUrls: ['./uitzondering.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		TooltipDirective,
		SchooljaarIndicatorOverComponent,
		BarchartTableComponent,
		FilterBarComponent,
	],
})
export class UitzonderingOnderwijspositieComponent extends UitzonderingOnderwijsresultatenComponent {
	protected override readonly hideExclusief = DRIE_SCHOOLJAREN_EXCL_2020;

	override overrideDefault: Partial<{ [name in FilterName]: any }> = {
		ds_nm_op_status_van: without(Object.values(OnderwijspositieStatus), OnderwijspositieStatus.INFORMATIE_VOLLEDIG),
		ds_is_prognose: 0,
	};

	groups: AttrPath[] = [['ds_nm_schooljaar_van'], ['ds_nm_op_uitzondering_van'], ['ds_nm_op_status_van']];

	initialFilters: FilterName[] = [
		'x_onderwijsresultaten_ds_schooljaar',
		'ds_fk_br_vest_van.br_co_brin',
		'ds_nm_op_uitzondering_van',
		'ds_nm_op_status_van',
		'ds_nm_idu',
		'ds_is_prognose',
	];

	permanentFilterExpressions = onderwijspositieFilters;

	override getSchooljarenTooltip() {
		if (!this.schooljaar) return null;

		return `Let op: de onderwijsinspectie toont deze periode als ${getOnderwijsresultatenSchooljaarRangeString(this.schooljaar, this.aantalJaren!, 'op', true)}`;
	}
}
