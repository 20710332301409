@if (showTerug()) {
	<app-button (clickit)="goBack()" buttonType="text" icon="svg-chevron-left small">Terug</app-button>
}
@if (qp.hasQueryParams()) {
	<app-button (clickit)="resetDashboard()" buttonType="text" icon="svg-refresh">Resetten</app-button>
}
<app-button icon="icon-filter" (clickit)="openFilterPanel()">Filters</app-button>
<div class="filter-label">
	@for (filterName of filterNames(); track filterName) {
		@if (filterService.isVisible(filterName) | async) {
			<app-filter-label [filterName]="filterName"></app-filter-label>
		}
	}
</div>
@if (dataRootSelection()) {
	<div class="save-selection-button" (click)="leerlingSelectieService.slaSelectieOp(dataRootSelection()!)">
		<span class="svg-add-user"></span>
		<span>Opslaan als leerlingselectie ({{dataRootSelection()!.size}})</span>
	</div>
}
