import { formatPercent } from '@angular/common';
import { AfterViewInit, Component, computed, inject } from '@angular/core';
import { CohortstatusVanaf, Table } from '@cumlaude/metadata';
import { groupBy, memoize, sum } from 'lodash-es';
import { delay, Observable } from 'rxjs';
import { countRecords, maxOverMapped, noAgg0 } from '../../services/aggregation';
import { Level, Path } from '../../services/data-tree';
import {
	Attr,
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	DoorstroomMeasure,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { att, percOfRow } from '../../services/measures';
import { BarInfo } from '../../services/stacked-bars';
import { ColumnDef } from '../../shared/components/table/table/table.model';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { createMeasureColumn, DataRow, getInitialAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { Router } from '@angular/router';
import { CohortrendementType, CohortrendementWeergave, DashboardVariant } from '../../services/weergave-opties';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { PsName } from '../../services/page-state.service';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

export interface CohortrendementI extends Attributes {
	'ds_fk_ll.ll_is_cohortstatus_tot_volledig': string;
	ds_is_cohortrendement_tot_doublure: string;
	ds_is_cohortrendement_tot_succesvol: string;
	ds_is_cohortrendement_vanaf_doublure: string;
	ds_is_cohortrendement_vanaf_succesvol: string;
	ds_is_examenrendement_doublure: string;
	ds_is_examenrendement_succesvol: string;
	ds_is_opstroomrendement_doublure: string;
	ds_is_opstroomrendement_succesvol: string;
	ds_is_prognose: string;
	ds_is_examenprognose: string;
	ds_nm_cohortrendement_tot_cohortrendement: string;
	ds_nm_cohortrendement_tot_doublures: string;
	ds_nm_cohortrendement_tot_niveauverschil: string;
	ds_nm_cohortrendement_vanaf_cohortrendement: string;
	ds_nm_cohortrendement_vanaf_doublures: string;
	ds_nm_cohortrendement_vanaf_niveauverschil: string;
	ds_nm_cohortstatus_vanaf: CohortstatusVanaf;
	ds_nm_cohortstatus_examenopstroom: CohortstatusVanaf;
	ds_nm_examenrendement_cohortrendement: string;
	ds_nm_examenrendement_doublures: string;
	ds_nm_opstroomrendement_cohortrendement: string;
	ds_nm_opstroomrendement_doublures: string;
	ds_nm_opstroomrendement_niveauverschil: string;
	ds_nr_cohortrendement_tot_niveau: string;
	ds_nr_cohortrendement_vanaf_niveau: string;
	ds_nr_examenrendement_niveau: string;
	ds_nr_opstroomrendement_niveau: string;
	ds_nr_leerlingen: number;
}

export interface CohortrendementA extends Attributes {
	succesvol: number;
	doublures: number;
	niveauverschil: number;
	leerlingen: number;
	tussentijdsIngestroomd: number;
	tussentijdsUitgestroomd: number;
	nogBezig: number;
	ds_is_lb_voorlopig: number;
	ds_nr_leerlingen: number;
}

interface Weergave extends Option<CohortrendementWeergave> {
	partition(attrs: CohortrendementI, type: Type): any;
	partitionKeys: string[];
	partitionMeasure: (path: Path<CohortrendementA, number[]>) => number;
}

export const enum Richting {
	VANAF,
	TOT,
}

interface Type extends Option<CohortrendementType> {
	filters: FilterExpression[];
	richting: Richting;
	hasData(attrs: CohortrendementI): boolean;
	getSubgroups(weergave: CohortrendementWeergave): AttrPath[];
	getMeasure(): DoorstroomMeasure;
	getRendementwaarde(attrs: CohortrendementI, weergave: CohortrendementWeergave): string;
	getSuccesvol(attrs: CohortrendementI): string;
	getDoublure(attrs: CohortrendementI): string;
	getNiveauverschil(attrs: CohortrendementI): string;
	cohortstatusAttr?: keyof CohortrendementI & Attr;
	getLegendaAttr(weergave: CohortrendementWeergave): keyof CohortrendementI & Attr;
}

function sumFromType(getValue: (attrs: CohortrendementI) => string) {
	return {
		init: (attrs: CohortrendementI) => parseInt(getValue(attrs) ?? 0),
		combine: (as: number[]) => sum(as),
	};
}

export const cohortrendementDefaultFilters: FilterExpression[] = [new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1)];

export const cohortrendementTypeOpties: Type[] = [
	{
		text: 'Cohortrendement tot',
		value: 'Cohortrendement tot',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_fk_ll', 'll_is_cohortstatus_tot_volledig']];

			switch (weergave) {
				case 'Cohortrendement':
					subgroups.push(['ds_nm_cohortrendement_tot_cohortrendement']);
					break;
				case 'Doublures':
					subgroups.push(['ds_nm_cohortrendement_tot_doublures']);
					break;
				case 'Niveauverschil':
					subgroups.push(['ds_nm_cohortrendement_tot_niveauverschil']);
					break;
			}

			return subgroups;
		},
		getMeasure(): DoorstroomMeasure {
			return DoorstroomMeasure.COHORTRENDEMENT_TOT;
		},
		filters: cohortrendementDefaultFilters,
		richting: Richting.TOT,
		hasData(attrs: CohortrendementI): boolean {
			return attrs['ds_fk_ll.ll_is_cohortstatus_tot_volledig'] == '1';
		},
		getRendementwaarde(attrs, weergave): string {
			switch (weergave) {
				case 'Cohortrendement':
					return attrs.ds_nm_cohortrendement_tot_cohortrendement;
				case 'Doublures':
					return attrs.ds_nm_cohortrendement_tot_doublures;
				case 'Niveauverschil':
					return attrs.ds_nm_cohortrendement_tot_niveauverschil;
			}
		},
		getSuccesvol(attrs: CohortrendementI): string {
			return attrs.ds_is_cohortrendement_tot_succesvol;
		},
		getDoublure(attrs: CohortrendementI): string {
			return attrs.ds_is_cohortrendement_tot_doublure;
		},
		getNiveauverschil(attrs: CohortrendementI): string {
			return attrs.ds_nr_cohortrendement_tot_niveau;
		},
		getLegendaAttr(weergave: CohortrendementWeergave): keyof CohortrendementI & Attr {
			switch (weergave) {
				case 'Cohortrendement':
					return 'ds_nm_cohortrendement_tot_cohortrendement';
				case 'Doublures':
					return 'ds_nm_cohortrendement_tot_doublures';
				case 'Niveauverschil':
					return 'ds_nm_cohortrendement_tot_niveauverschil';
			}
		},
	},
	{
		text: 'Cohortrendement vanaf',
		value: 'Cohortrendement vanaf',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_nm_cohortstatus_vanaf']];

			switch (weergave) {
				case 'Cohortrendement':
					subgroups.push(['ds_nm_cohortrendement_vanaf_cohortrendement']);
					break;
				case 'Doublures':
					subgroups.push(['ds_nm_cohortrendement_vanaf_doublures']);
					break;
				case 'Niveauverschil':
					subgroups.push(['ds_nm_cohortrendement_vanaf_niveauverschil']);
					break;
			}

			return subgroups;
		},
		filters: cohortrendementDefaultFilters,
		richting: Richting.VANAF,
		getMeasure(): DoorstroomMeasure {
			return DoorstroomMeasure.COHORTRENDEMENT_VANAF;
		},
		hasData(attrs: CohortrendementI): boolean {
			return attrs.ds_nm_cohortstatus_vanaf == CohortstatusVanaf.VOLLEDIG_COHORT_GEVOLGD;
		},
		getRendementwaarde(attrs, weergave): string {
			switch (weergave) {
				case 'Cohortrendement':
					return attrs.ds_nm_cohortrendement_vanaf_cohortrendement;
				case 'Doublures':
					return attrs.ds_nm_cohortrendement_vanaf_doublures;
				case 'Niveauverschil':
					return attrs.ds_nm_cohortrendement_vanaf_niveauverschil;
			}
		},
		getSuccesvol(attrs: CohortrendementI): string {
			return attrs.ds_is_cohortrendement_vanaf_succesvol;
		},
		getDoublure(attrs: CohortrendementI): string {
			return attrs.ds_is_cohortrendement_vanaf_doublure;
		},
		getNiveauverschil(attrs: CohortrendementI): string {
			return attrs.ds_nr_cohortrendement_vanaf_niveau;
		},
		getLegendaAttr(weergave: CohortrendementWeergave): keyof CohortrendementI & Attr {
			switch (weergave) {
				case 'Cohortrendement':
					return 'ds_nm_cohortrendement_vanaf_cohortrendement';
				case 'Doublures':
					return 'ds_nm_cohortrendement_vanaf_doublures';
				case 'Niveauverschil':
					return 'ds_nm_cohortrendement_vanaf_niveauverschil';
			}
		},
	},
	{
		text: 'Examenrendement',
		value: 'Examenrendement',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_fk_ll', 'll_is_cohortstatus_tot_volledig']];

			switch (weergave) {
				case 'Cohortrendement':
					subgroups.push(['ds_nm_examenrendement_cohortrendement']);
					break;
				case 'Doublures':
					subgroups.push(['ds_nm_examenrendement_doublures']);
					break;
				case 'Niveauverschil':
					subgroups.push(['ds_nm_cohortrendement_tot_niveauverschil']);
					break;
			}

			return subgroups;
		},
		filters: [
			...cohortrendementDefaultFilters,
			new CompoundFilterExpression([
				new BasicFilterExpression(['ds_is_examenkandidaat_van'], 1),
				new CompoundFilterExpression(
					[new BasicFilterExpression(['ds_is_geslaagd'], 1), new BasicFilterExpression(['ds_is_afgewezen'], 1)],
					'or'
				),
			]),
		],
		richting: Richting.TOT,
		getMeasure(): DoorstroomMeasure {
			return DoorstroomMeasure.EXAMENRENDEMENT;
		},
		hasData(attrs: CohortrendementI): boolean {
			return attrs['ds_fk_ll.ll_is_cohortstatus_tot_volledig'] == '1';
		},
		getRendementwaarde(attrs, weergave): string {
			switch (weergave) {
				case 'Cohortrendement':
					return attrs.ds_nm_examenrendement_cohortrendement;
				case 'Doublures':
					return attrs.ds_nm_examenrendement_doublures;
				case 'Niveauverschil':
					return attrs.ds_nm_cohortrendement_tot_niveauverschil;
			}
		},
		getSuccesvol(attrs: CohortrendementI): string {
			return attrs.ds_is_examenrendement_succesvol;
		},
		getDoublure(attrs: CohortrendementI): string {
			return attrs.ds_is_examenrendement_doublure;
		},
		getNiveauverschil(attrs: CohortrendementI): string {
			return attrs.ds_nr_examenrendement_niveau;
		},
		getLegendaAttr(weergave: CohortrendementWeergave): keyof CohortrendementI & Attr {
			switch (weergave) {
				case 'Cohortrendement':
					return 'ds_nm_cohortrendement_tot_cohortrendement';
				case 'Doublures':
					return 'ds_nm_cohortrendement_tot_doublures';
				case 'Niveauverschil':
					return 'ds_nm_cohortrendement_tot_niveauverschil';
			}
		},
	},
	{
		text: 'Opstroomrendement',
		value: 'Opstroomrendement',
		getSubgroups(weergave: CohortrendementWeergave): AttrPath[] {
			const subgroups: AttrPath[] = [['ds_nm_cohortstatus_examenopstroom']];

			switch (weergave) {
				case 'Cohortrendement':
					subgroups.push(['ds_nm_opstroomrendement_cohortrendement']);
					break;
				case 'Doublures':
					subgroups.push(['ds_nm_opstroomrendement_doublures']);
					break;
				case 'Niveauverschil':
					subgroups.push(['ds_nm_opstroomrendement_niveauverschil']);
					break;
			}

			return subgroups;
		},
		filters: [
			...cohortrendementDefaultFilters,
			// Opstroomrendement wordt bepaald voor geslaagden die volgend jaar een hoger niveau gaan doen
			new CompoundFilterExpression([
				new BasicFilterExpression(['ds_is_examenkandidaat_van'], 1),
				new BasicFilterExpression(['ds_is_geslaagd'], 1),
				new BasicFilterExpression(['ds_is_opstroom'], 1),
			]),
		],
		richting: Richting.VANAF,
		cohortstatusAttr: 'ds_nm_cohortstatus_examenopstroom',
		getMeasure(): DoorstroomMeasure {
			return DoorstroomMeasure.OPSTROOMRENDEMENT;
		},
		hasData(attrs: CohortrendementI): boolean {
			return attrs.ds_nm_cohortstatus_examenopstroom == CohortstatusVanaf.VOLLEDIG_COHORT_GEVOLGD;
		},
		getRendementwaarde(attrs, weergave): string {
			switch (weergave) {
				case 'Cohortrendement':
					return attrs.ds_nm_opstroomrendement_cohortrendement;
				case 'Doublures':
					return attrs.ds_nm_opstroomrendement_doublures;
				case 'Niveauverschil':
					return attrs.ds_nm_opstroomrendement_niveauverschil;
			}
		},
		getSuccesvol(attrs: CohortrendementI): string {
			return attrs.ds_is_opstroomrendement_succesvol;
		},
		getDoublure(attrs: CohortrendementI): string {
			return attrs.ds_is_opstroomrendement_doublure;
		},
		getNiveauverschil(attrs: CohortrendementI): string {
			return attrs.ds_nr_opstroomrendement_niveau;
		},
		getLegendaAttr(weergave: CohortrendementWeergave): keyof CohortrendementI & Attr {
			switch (weergave) {
				case 'Cohortrendement':
					return 'ds_nm_cohortrendement_vanaf_cohortrendement';
				case 'Doublures':
					return 'ds_nm_cohortrendement_vanaf_doublures';
				case 'Niveauverschil':
					return 'ds_nm_cohortrendement_vanaf_niveauverschil';
			}
		},
	},
];

@Component({
	selector: 'app-cohortrendement',
	templateUrl: './cohortrendement.component.html',
	styleUrls: ['./cohortrendement.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FilterBarComponent,
	],
})
export class CohortrendementComponent extends BarchartTableConfig<CohortrendementI, CohortrendementA> implements AfterViewInit {
	protected readonly dataService = inject(DataService);
	protected readonly router = inject(Router);

	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	override availableGroups: AttrPath[] = [
		['ds_nm_klas_van'],
		['ds_nm_opleiding_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nr_leerjaar_van'],
	];

	actueelFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
	];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	filterExpressions?: FilterExpression[];

	weergaveOpties: Weergave[] = [
		{
			text: 'Cohortrendement',
			value: 'Cohortrendement',
			partitionKeys: ['true', 'false'],
			partition: (attrs, type) => Number(type.getSuccesvol(attrs)) > 0,
			partitionMeasure: percOfRow('succesvol', 'leerlingen'),
		},
		{
			text: 'Doublures',
			value: 'Doublures',
			partitionKeys: ['true', 'false'],
			partition: (attrs, type) => Number(type.getDoublure(attrs)) <= 0,
			partitionMeasure: percOfRow('doublures', 'leerlingen'),
		},
		{
			text: 'Niveauverschil',
			value: 'Niveauverschil',
			partitionKeys: ['-1', '0', '1'],
			partition: (attrs, type) => Math.sign(Number(type.getNiveauverschil(attrs))),
			partitionMeasure: percOfRow('niveauverschil', 'leerlingen'),
		},
	];

	typeOpties = cohortrendementTypeOpties;

	cohortrendementweergave = this.qp.signal('cohortrendementweergave');

	cohortrendementtype = this.qp.signal('cohortrendementtype');

	weergaveOptie = computed(() => {
		const weergave = this.cohortrendementweergave();
		return this.weergaveOpties.find((o) => o.value === weergave)!;
	});

	typeOptie = computed(() => {
		const weergave = this.cohortrendementtype();
		return this.typeOpties.find((o) => o.value === weergave)!;
	});

	measures = computed(() => [this.typeOptie().getMeasure(), DoorstroomMeasure.LEERLINGEN]);

	legenda = computed(() => this.typeOptie().getLegendaAttr(this.weergaveOptie().value));

	permanentFilterExpressions = computed(() => this.typeOptie().filters);

	groups = this.qp.signal_g(this.defaultGroups);

	subgroups = computed(() => this.typeOptie().getSubgroups(this.weergaveOptie().value));

	variant = this.qp.signal('variant');

	private schooljaar!: string;

	override ngAfterViewInit() {
		super.ngAfterViewInit();
		this.subscriptions.push(
			this.filterService
				.observe('ds_nm_schooljaar_van')
				.pipe(delay(0))
				.subscribe((schooljaar) => (this.schooljaar = schooljaar))
		);
	}

	override isHistorieBatchVariant(): boolean {
		return this.variant() === DashboardVariant.HISTORIE && this.groups().length > 0;
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData({ ...options, m: this.measures(), r: [0] });
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected override singleAggregators = {
		succesvol: sumFromType((attrs) => this.typeOptie().getSuccesvol(attrs)),
		doublures: sumFromType((attrs) => this.typeOptie().getDoublure(attrs)),
		niveauverschil: sumFromType((attrs) => this.typeOptie().getNiveauverschil(attrs)),
		leerlingen: countRecords((attrs: CohortrendementI) => this.typeOptie().hasData(attrs)),
		tussentijdsIngestroomd: countRecords((attrs: CohortrendementI) => attrs['ds_fk_ll.ll_is_cohortstatus_tot_volledig'] === '0'),
		tussentijdsUitgestroomd: countRecords(
			(attrs: CohortrendementI) =>
				attrs[this.typeOptie().cohortstatusAttr ?? 'ds_nm_cohortstatus_vanaf'] === CohortstatusVanaf.TUSSENTIJDS_UITGESTROOMD
		),
		nogBezig: countRecords(
			(attrs: CohortrendementI) => attrs[this.typeOptie().cohortstatusAttr ?? 'ds_nm_cohortstatus_vanaf'] === CohortstatusVanaf.NOG_BEZIG
		),
		ds_is_lb_voorlopig: maxOverMapped<CohortrendementI>((v) => Number(v.ds_is_examenprognose) + Number(v.ds_is_prognose)),
		ds_nr_leerlingen: noAgg0<'ds_nr_leerlingen', CohortrendementI>('ds_nr_leerlingen'),
	};

	override createMeasureColumns(
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): ColumnDef<DataRow<CohortrendementA>>[] {
		if (this.variant() === 'Historie') return [];

		return [
			createMeasureColumn('Rendement', percOfRow('succesvol', 'leerlingen'), { dataType: 'percentage' }),
			createMeasureColumn('Doublures', percOfRow('doublures', 'leerlingen'), { dataType: 'percentage' }),
			createMeasureColumn('Niveauverschil', percOfRow('niveauverschil', 'leerlingen'), { dataType: 'percentage', format: '+1.0-0' }),
			this.createLeerlingColumn(
				'Leerlingen',
				att('leerlingen'),
				{ context },
				undefined,
				this.typeOptie().richting === Richting.TOT
					? [new BasicFilterExpression(['ds_fk_ll', 'll_is_cohortstatus_tot_volledig'], 1)]
					: [new BasicFilterExpression(['ds_nm_cohortstatus_vanaf'], CohortstatusVanaf.VOLLEDIG_COHORT_GEVOLGD)]
			),
			this.createLeerlingColumn(
				'Tussentijds ingestroomd',
				att('tussentijdsIngestroomd'),
				{ context, visible: this.typeOptie().richting === Richting.TOT },
				undefined,
				[new BasicFilterExpression(['ds_fk_ll', 'll_is_cohortstatus_tot_volledig'], 0)]
			),
			this.createLeerlingColumn(
				'Tussentijds uitgestroomd',
				att('tussentijdsUitgestroomd'),
				{ context, visible: this.typeOptie().richting === Richting.VANAF },
				undefined,
				[
					new BasicFilterExpression(
						[this.typeOptie().cohortstatusAttr ?? 'ds_nm_cohortstatus_vanaf'],
						CohortstatusVanaf.TUSSENTIJDS_UITGESTROOMD
					),
				]
			),
			this.createLeerlingColumn('Nog bezig', att('nogBezig'), { context, visible: this.typeOptie().richting === Richting.VANAF }, undefined, [
				new BasicFilterExpression([this.typeOptie().cohortstatusAttr ?? 'ds_nm_cohortstatus_vanaf'], CohortstatusVanaf.NOG_BEZIG),
			]),
		];
	}

	override partitionBarData(
		rowRoot: Level<CohortrendementA, number[]>,
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): Path<CohortrendementA, number[]>[][] {
		const grouped = groupBy(rowRoot.r, (r) => {
			const attrs = getInitialAttributes<CohortrendementI>(context.subgroupNames, context.measureNames, r);
			return this.weergaveOptie().partition(attrs, this.typeOptie());
		});
		return this.weergaveOptie()
			.partitionKeys.map((key) => grouped[key])
			.filter((value) => value);
	}

	override makeBar(
		attrs: CohortrendementI,
		path: Path<CohortrendementA, number[]>,
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): BarInfo {
		const rendementWaarde = this.typeOptie().getRendementwaarde(attrs, this.weergaveOptie().value);
		if (!rendementWaarde) return { size: 0 };

		const leerlingen = path[path.length - this.subgroups().length - 1].a.leerlingen;
		const tooltipElements: TooltipElement[] = [
			{
				label: 'Cohortrendement',
				value: rendementWaarde,
			},
			{
				label: 'Leerlingen',
				value: `${attrs.count_records} van ${leerlingen} (${formatPercent(attrs.count_records / leerlingen, 'nl-NL', '0.0-0')})`,
			},
		];

		return {
			...super.makeBar(attrs, path, context),
			description: rendementWaarde,
			className: generateCssClassForString(rendementWaarde),
			tooltip: tooltipElements,
		};
	}

	// Ga naar Cohortdetails met gekozen schooljaar plus aangeklikte groepering als filter
	override handleRowClick(
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>,
		path: Path<CohortrendementA, number[]>
	) {
		const groups = context.groupNames;
		const filters = path
			.slice(1)
			.filter((_level, ix) => this.filterService.configs[<FilterName>groups[ix]] !== undefined)
			.map((level, ix) => ({ name: <FilterName>groups[ix], value: level.k }));
		const params = this.filterService.encodeFilters(filters, this.filterService.encodeFilterValue('ds_nm_schooljaar_van', this.schooljaar));
		this.urlService.redirect(['/doorstroom/cohort/details'], { ...params, variant: undefined });
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(CohortrendementComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(CohortrendementComponent._getHistorieSubgroups, (se, su) => JSON.stringify([se, su]));

	private static _getHistorieSubgroups(selectedGroups: AttrPath[], subgroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['ds_nm_schooljaar_van'], ...subgroups];
	}

	getPartitionMeasure = memoize(this._getPartitionMeasure, JSON.stringify);

	_getPartitionMeasure(weergaveOptie: Weergave): PartitionMeasure<CohortrendementA> {
		return {
			type: 'percentage',
			getValue: weergaveOptie.partitionMeasure,
		};
	}

	override onContextCreated(context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_lb_voorlopig)));
	}

	override getSelectionConfig(
		context: DashboardContext<CohortrendementI, CohortrendementA, CohortrendementComponent>
	): SelectionConfig<CohortrendementA> | undefined {
		return { ...super.getSelectionConfig(context)!, getSize: att('ds_nr_leerlingen') };
	}

	protected readonly DashboardVariant = DashboardVariant;
}
