import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { createModel, TableModel } from '../../shared/components/table/table/table.model';
import { AccountRow, AccountScreen } from '../account-screen';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent, MultiSelectDropdownComponent } from '@cumlaude/shared-components-inputs';
import { CumLaudeLicentieInfo, LicentieRol, RCumLaudeAccount, RRol } from '@cumlaude/service-contract';
import { map } from 'rxjs/operators';

function makeRow(account: RCumLaudeAccount): AccountRow {
	return { account };
}

@Component({
	selector: 'app-bestuur',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	imports: [InstantSearchBoxComponent, ButtonComponent, MultiSelectDropdownComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class BestuurComponent extends AccountScreen<AccountRow> {
	override moduleIsEnabled$ = this.userService.instelling$.pipe(map((instelling) => instelling.bestuurEnabled));

	override getRol(): RRol {
		return RRol.BESTUUR;
	}

	override getModuleName(): string | undefined {
		return 'Bestuur';
	}

	override getRolLabel() {
		return 'Bestuur';
	}

	override getRolDescription() {
		return 'Bestuurbreed geautoriseerde gebruikers kunnen gegevens van alle vestigingen bekijken.';
	}

	override getLicentieRol(): LicentieRol | undefined {
		return LicentieRol.BESTUUR;
	}

	override getLicentieInfo(info: CumLaudeLicentieInfo, _licentieRol: LicentieRol) {
		return {
			tekst: [
				`Licenties in gebruik: ${info[LicentieRol.BESTUUR]?.inGebruik ?? 0}`,
				`Totaal licenties in gebruik door bestuur: ${info[LicentieRol.BESTUURTOTAAL]?.inGebruik ?? 0} ${info[LicentieRol.BESTUURTOTAAL]?.totaal ? `van de ${info[LicentieRol.BESTUURTOTAAL]?.totaal}` : ''}`,
			],
			warning: (info[LicentieRol.BESTUURTOTAAL]?.totaal ?? Number.POSITIVE_INFINITY) < (info[LicentieRol.BESTUURTOTAAL]?.inGebruik ?? 0),
			info: (info[LicentieRol.BESTUURTOTAAL]?.totaal ?? Number.POSITIVE_INFINITY) === (info[LicentieRol.BESTUURTOTAAL]?.inGebruik ?? 0),
		};
	}

	protected override getVestigingen(): Observable<string[] | undefined> {
		return of(undefined);
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<AccountRow>> {
		const model = createModel(accounts.map(makeRow), (row) => row.account.id!);
		model.columnDefs = [this.getNaamColumn(), this.createRolIntrekkenColumn()];
		return of(model);
	}
}
