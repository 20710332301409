import { Component, OnInit, inject } from '@angular/core';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	DoorstroomMeasure,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { Observable } from 'rxjs';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att, attParent, percOfParent, percOfRow } from '../../services/measures';
import { ExamenStatusMetPrognose, Table } from '@cumlaude/metadata';
import { countRecords, maxOverMapped, noAgg0 } from '../../services/aggregation';
import { Path } from '../../services/data-tree';
import { formatPercent } from '@angular/common';
import { FilterName } from '../../services/filter-config';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { memoize, range } from 'lodash-es';
import { DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { DashboardVariant } from '../../services/weergave-opties';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { PsName } from '../../services/page-state.service';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface GeslaagdI extends Attributes {
	ds_map_examenstatus_met_prognose: ExamenStatusMetPrognose;
	ds_is_examenprognose: string;
	ds_nr_leerlingen: number;
}

interface GeslaagdA extends Attributes {
	geslaagd: number;
	ds_is_examenprognose: number;
	ds_nr_leerlingen: number;
}

@Component({
	selector: 'app-geslaagd',
	templateUrl: './geslaagd.component.html',
	styleUrls: ['./geslaagd.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FilterBarComponent,
	],
})
export class GeslaagdComponent extends BarchartTableConfig<GeslaagdI, GeslaagdA> implements OnInit {
	protected readonly dataService = inject(DataService);

	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	groups: AttrPath[] = this.defaultGroups;

	override availableGroups: AttrPath[] = [
		['ds_fk_ll', 'll_nm_basisschooladvies_uni'],
		['ds_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
		['ds_fun_basisschooladvies_duo'],
		['ds_nm_klas_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nm_opleiding_van'],
		['ds_fk_ilt_van', 'ilt_nm_opleiding'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_ll', 'll_nm_geslacht'],
	];

	actueelFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_map_examenstatus_met_prognose',
	];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression<number>(['ds_fk_lb_van', 'lb_is_examenopleiding'], 1),
		new BasicFilterExpression<number>(['ds_is_relevante_doorstroom'], 1),
		new BasicFilterExpression<number>(['ds_fk_ilt_van', 'ilt_is_internationaal'], 0),
		new BasicFilterExpression<number>(['ds_fk_ilt_van', 'ilt_is_vakmanschap'], 0),
		new BasicFilterExpression<number>(['ds_fk_ilt_van', 'ilt_is_vavo'], 0),
		new BasicFilterExpression<number>(['ds_map_pro_volgend_sj'], 0),
		new BasicFilterExpression<number>(['ds_map_uitstroom_hoger_onderwijs_geen_uitslag'], 0),
		new BasicFilterExpression<number>(['ds_map_uitstroom_speciaal_onderwijs'], 0),
	];

	filterExpressions?: FilterExpression[];

	subgroups: AttrPath[] = [['ds_map_examenstatus_met_prognose']];

	variant!: DashboardVariant;

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups))
		);
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData({ ...options, m: [DoorstroomMeasure.LEERLINGEN], r: range(0, this.getRowLevel() + 1) });
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	override makeBar(attrs: GeslaagdI, path: Path<GeslaagdA, number[]>, context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>): BarInfo {
		const { count_records, ds_map_examenstatus_met_prognose } = attrs;
		const percentage = formatPercent(percOfParent('count_records')(path), 'nl-NL');
		const total = attParent('count_records')(path);
		const examenstatus = this.displayService.display(ds_map_examenstatus_met_prognose);

		const tooltipElements: TooltipElement[] = [
			{ label: `Examenstatus:`, value: `${examenstatus}` },
			{ label: `Leerlingen:`, value: `${count_records} van ${total}` },
			{ label: `Leerlingen (%):`, value: `${percentage}` },
		];

		return {
			...super.makeBar(attrs, path, context),
			description: examenstatus,
			className: generateCssClassForString(ds_map_examenstatus_met_prognose),
			tooltip: tooltipElements,
		};
	}

	protected override singleAggregators = {
		geslaagd: countRecords(
			({ ds_map_examenstatus_met_prognose }: GeslaagdI) =>
				ds_map_examenstatus_met_prognose === ExamenStatusMetPrognose.GESLAAGD ||
				ds_map_examenstatus_met_prognose === ExamenStatusMetPrognose.GESLAAGD_PROGNOSE
		),
		ds_is_examenprognose: maxOverMapped<GeslaagdI>((v) => Number(v.ds_is_examenprognose)),
		ds_nr_leerlingen: noAgg0<'ds_nr_leerlingen', GeslaagdI>('ds_nr_leerlingen'),
	};

	override createMeasureColumns(context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>): ColumnDef<DataRow<GeslaagdA>>[] {
		if (this.variant === 'Historie') return [];

		return [
			this.createLeerlingColumn(
				'Percentage',
				percOfRow('geslaagd'),
				{ context, dataType: 'percentage' },
				att('geslaagd'),
				this.getGeslaagdFilters()
			),
			this.createLeerlingColumn('Geslaagd', att('geslaagd'), { context }, undefined, this.getGeslaagdFilters()),
			this.createLeerlingColumn('Leerlingen', att('count_records'), { context }),
		];
	}

	override isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	private getGeslaagdFilters() {
		return [new BasicFilterExpression(['ds_is_geslaagd'], 1, '=')];
	}

	partitionMeasure: PartitionMeasure<GeslaagdA> = { type: 'percentage', getValue: percOfRow('geslaagd') };

	override enrichTableModel(_context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>, tableModel: TableModel<DataRow<GeslaagdA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	getRowLevel() {
		return this.variant === DashboardVariant.ACTUEEL ? this.groups.length : this.getHistorieGroups(this.groups).length;
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(GeslaagdComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(groups: AttrPath[]) {
		return groups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(GeslaagdComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([groups, stacks]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...groups.slice(-1), ['ds_nm_schooljaar_van'], ...stacks];
	}

	override onContextCreated(context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_examenprognose)));
	}

	override getSelectionConfig(context: DashboardContext<GeslaagdI, GeslaagdA, GeslaagdComponent>): SelectionConfig<GeslaagdA> | undefined {
		return { ...super.getSelectionConfig(context)!, getSize: att('ds_nr_leerlingen') };
	}

	protected readonly DashboardVariant = DashboardVariant;
}
