import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { UserService } from '../services/user.service';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@cumlaude/shared-authentication';
import { AutorisatieService } from '../services/autorisatie.service';

export const rolGuard: CanMatchFn = (_, segments) => {
	const authService = inject(AuthService);
	if (!authService.isLoggedIn()) {
		return of(true); // auth-guard onthoudt route en doet de redirect
	}

	const userService = inject(UserService);
	const autorisatieService = inject(AutorisatieService);
	return combineLatest([userService.myAccount$, userService.rolChanged$, userService.rVestigingen$]).pipe(
		map(() => autorisatieService.checkUrlForRol(`/${segments.join('/')}`))
	);
};
