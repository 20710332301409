import { Component, inject } from '@angular/core';
import { Option, CheckboxComponent, FormDropdownComponent } from '@cumlaude/shared-components-inputs';
import { ExportOptions, ExportType, Orientation } from '../../../services/export.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

@Component({
	selector: 'app-more-options-dialog',
	templateUrl: './more-options-dialog.component.html',
	styleUrls: ['./more-options-dialog.component.scss'],
	imports: [BaseDialogComponent, FormDropdownComponent, CheckboxComponent, ButtonComponent],
})
export class MoreOptionsDialogComponent {
	protected options = inject<ExportOptions>(DIALOG_DATA);
	protected dialogRef = inject<DialogRef<void>>(DialogRef);

	orientationOptions: Option<Orientation>[];

	constructor() {
		this.orientationOptions = Object.values(Orientation).map((val) => new Option<Orientation>(val));
	}

	findOption(orientation: Orientation): Option<Orientation> {
		return this.orientationOptions.find(({ value }) => orientation === value)!;
	}

	protected readonly ExportType = ExportType;
}
