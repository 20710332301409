import { Categorie, CumLaudeTableMetadata, FieldMetadata, ForeignKeyMetadata, metadataMap, Table } from '@cumlaude/metadata';
import { groupBy, orderBy } from 'lodash-es';
import { Att, Attr, DWHTable } from './data.service';
import { FilterName } from './filter-config';
import { isDefined } from '@cumlaude/shared-utils';

type ForeignKeyTableMetadata = {
	[attr in keyof DWHTable]: CumLaudeTableMetadata;
};

const foreignKeyMetadataMap: ForeignKeyTableMetadata = {};

function build() {
	for (const tableData of Object.values(metadataMap)) {
		for (const [column, metadata] of Object.entries(tableData)) {
			const targetMetadata = metadataMap[(metadata as ForeignKeyMetadata)?.type];
			if (!targetMetadata) continue;

			foreignKeyMetadataMap[column as keyof DWHTable] = targetMetadata;
		}
	}
}

build();

export function getFieldsByForeignKey(foreignKey: Attr) {
	const tableDataByForeignKey = foreignKeyMetadataMap[foreignKey as keyof DWHTable];
	if (!tableDataByForeignKey) return undefined;

	const keys = [];
	for (const fieldKey of Object.keys(tableDataByForeignKey)) {
		const field = getFieldMetadata(tableDataByForeignKey, fieldKey);
		if (field) keys.push(fieldKey);
	}

	return keys;
}

export type CategorieField = {
	att: string;
	categorie?: Categorie;
	label?: string;
	filter: boolean;
	grouping: boolean;
	exportable: boolean;
	searchKeys?: string[];
	example?: string;
};

export type CategorieFields = {
	name: string;
	atts: CategorieField[];
};

export function getAllFilters() {
	return Object.values(Table)
		.filter((table) => table.startsWith('fac'))
		.flatMap((table) => getFilters(table, []));
}

export function getFilters(table: Table, exclude: FilterName[]): CategorieFields[] {
	return getFromMetadata(table).map((categorie) => {
		return {
			...categorie,
			atts: categorie.atts.filter((att) => att.filter && !exclude.includes(<FilterName>att.att)),
		};
	});
}

export function getExportables(table: Table, exclude: (value: Att) => boolean): CategorieFields[] {
	return getFromMetadata(table).map((categorie) => {
		return {
			...categorie,
			atts: categorie.atts.filter((att) => att.exportable && !exclude(<Att>att.att)),
		};
	});
}

export function getGroups(table: Table): CategorieFields[] {
	return getFromMetadata(table).map((categorie) => {
		return {
			...categorie,
			atts: categorie.atts.filter((att) => att.grouping),
		};
	});
}

function getSubFieldLabel(subFieldMetadata: FieldMetadata, foreignKeyMetadata: ForeignKeyMetadata, subKey: string) {
	let label = subFieldMetadata.label;

	const labelOverrideLabel = foreignKeyMetadata.labelOverride?.[subKey];
	if (labelOverrideLabel) label = labelOverrideLabel;

	const labelSuffix = foreignKeyMetadata.labelSuffix;
	if (labelSuffix) label += ` ${foreignKeyMetadata.labelSuffix}`;
	return label;
}

function getForeignKeyField(
	foreignKeyMetadata: ForeignKeyMetadata,
	subKey: string,
	foreignKeyTableMetadata: CumLaudeTableMetadata,
	key: string
): CategorieField | undefined {
	if (foreignKeyMetadata.exclude?.includes(subKey)) return undefined;
	if (foreignKeyMetadata.include && !foreignKeyMetadata.include.includes(subKey)) return undefined;

	const subFieldMetadata = getFieldMetadata(foreignKeyTableMetadata, subKey);
	if (!subFieldMetadata || (!subFieldMetadata.filter && !subFieldMetadata.grouping && !subFieldMetadata.exportable)) return undefined;

	const label = getSubFieldLabel(subFieldMetadata, foreignKeyMetadata, subKey);

	return {
		att: key + '.' + subKey,
		categorie: foreignKeyMetadata.categoryOverride?.[subKey] ?? subFieldMetadata.category,
		label: label,
		filter: subFieldMetadata.filter,
		grouping: subFieldMetadata.grouping,
		exportable: subFieldMetadata.exportable,
		searchKeys: subFieldMetadata.searchKeys,
		example: subFieldMetadata.example,
	};
}

function getMetadataForForeignKey(key: string, foreignKeyMetadata: ForeignKeyMetadata): CategorieField[] {
	const foreignKeyTableMetadata = foreignKeyMetadataMap[key as keyof DWHTable];
	if (foreignKeyTableMetadata) {
		return Object.keys(foreignKeyTableMetadata)
			.map((subKey) => getForeignKeyField(foreignKeyMetadata, subKey, foreignKeyTableMetadata, key))
			.filter(isDefined);
	}
	return [];
}

function getFromMetadata(table: Table): CategorieFields[] {
	const tableMetadata = metadataMap[table];
	if (!tableMetadata) return [];

	const fields = [];
	for (const key of Object.keys(tableMetadata)) {
		const fieldMetadata = getFieldMetadata(tableMetadata, key);
		if (fieldMetadata) {
			if (!fieldMetadata.filter && !fieldMetadata.grouping && !fieldMetadata.exportable) continue;

			fields.push({
				att: key,
				categorie: fieldMetadata.category,
				label: fieldMetadata.label,
				filter: fieldMetadata.filter,
				grouping: fieldMetadata.grouping,
				exportable: fieldMetadata.exportable,
				searchKeys: fieldMetadata.searchKeys,
				example: fieldMetadata.example,
			});
		}

		const foreignKeyMetadata = getForeignKeyMetadata(tableMetadata, key);
		if (foreignKeyMetadata) {
			fields.push(...getMetadataForForeignKey(key, foreignKeyMetadata));
		}
	}
	return orderBy(
		Object.entries(groupBy(orderBy(fields, 'label'), 'categorie')).map(([key, value]) => ({ name: key, atts: value })),
		'name'
	);
}

function getFieldMetadata(tableData: CumLaudeTableMetadata, key: string): FieldMetadata | undefined {
	const metadata = tableData[key];
	if (!metadata) return undefined;

	return 'type' in metadata ? undefined : metadata;
}

function getForeignKeyMetadata(tableData: CumLaudeTableMetadata, key: string): ForeignKeyMetadata | undefined {
	const metadata = tableData[key];
	if (!metadata) return undefined;

	return 'type' in metadata ? metadata : undefined;
}
