<div class="dashboard-doorstroom">
	<section class="dashboard">
		<app-dashboard-header [title]="dashboardName" [legenda]="['ds_nm_idu']" [legendaIncludeNull]="true"></app-dashboard-header>
		<div class="scrollport">
			<div class="dummy"></div>
			<div #anchor class="anchor"></div>
			<div class="paths">
				@for (path of paths; track path) {
					<app-doorstroom-path [path]="path" data-exportable></app-doorstroom-path>
				}
			</div>
		</div>
	</section>
</div>
