import { Component, Input, inject } from '@angular/core';
import { OnderwijsresultatenRow } from '../card-or-cell/card-or-cell.component';
import { DashboardVariant } from '../../../services/weergave-opties';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { ExtendedDecimalPipe } from '@cumlaude/shared-pipes';
import { PercentPipe } from '@angular/common';
import { LinechartComponent } from '../../../shared/dashboard/linechart-table/linechart/linechart.component';

@Component({
	selector: 'app-card-or-single-cell',
	templateUrl: './card-or-single-cell.component.html',
	styleUrls: ['./card-or-single-cell.component.scss'],
	imports: [LinechartComponent, PercentPipe, ExtendedDecimalPipe],
})
export class CardOrSingleCellComponent {
	readonly qp = inject(QueryParamStateService);

	@Input()
	row!: OnderwijsresultatenRow;

	@Input()
	variant!: DashboardVariant;

	toonTrend = this.qp.signal('toon-trend');
}
