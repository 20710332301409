import { Component, HostBinding, inject, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AttrPath } from '../services/data.service';
import { LegendaEnumNames } from '../core/services/enum.service';
import { QueryParamStateService } from '../services/query-param-state.service';
import { DashboardVariant } from '../services/weergave-opties';
import { UrlService } from '../services/url.service';
import { NgTemplateOutlet } from '@angular/common';
import { ToggleButtonsComponent } from '@cumlaude/shared-components-buttons';
import { LegendaComponent, LegendaStyle } from '../legenda/legenda.component';
import { DatasnapUrlService } from '../services/datasnap-url.service';

@Component({
	selector: 'app-dashboard-header',
	templateUrl: './dashboard-header.component.html',
	styleUrls: ['./dashboard-header.component.scss'],
	imports: [LegendaComponent, ToggleButtonsComponent, NgTemplateOutlet],
})
export class DashboardHeaderComponent implements OnDestroy {
	protected readonly qp = inject(QueryParamStateService);
	protected readonly urlService = inject(UrlService);
	protected readonly datasnapUrlService = inject(DatasnapUrlService);

	dashboardName!: string;

	@Input()
	legenda?: AttrPath | [LegendaEnumNames];

	@Input()
	legendaKeyIsClassName = false;

	@Input()
	legendaIncludeNull = false;

	@Input()
	legendaExclude: string[] = [];

	@Input()
	legendaStyle?: LegendaStyle;

	@Input()
	variant?: DashboardVariant;

	@ViewChild(LegendaComponent)
	legendaComponent?: LegendaComponent;

	@Input()
	leftOptionsTemplate!: TemplateRef<any>;

	@Input()
	rightOptionsTemplate!: TemplateRef<any>;

	@HostBinding('class.disabled')
	get disabled() {
		return this.datasnapUrlService.isViewingSnapshot();
	}

	protected subscriptions: Subscription[] = [];

	varianten: DashboardVariant[] = Object.values(DashboardVariant);

	constructor() {
		this.subscriptions.push(this.urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title)));
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
