import { Component, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../services/user.service';
import { RDatasnapUrl, RRol } from '@cumlaude/service-contract';
import { DataRequestCollector } from '../../services/data-request-collector.service';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { DatasnapUrlService } from '../../services/datasnap-url.service';
import { Router } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.scss'],
	imports: [ButtonComponent, AsyncPipe],
})
export class SupportComponent {
	protected readonly userService = inject(UserService);
	protected readonly datasnapUrlService = inject(DatasnapUrlService);
	protected readonly dataRequestCollector = inject(DataRequestCollector);
	protected readonly router = inject(Router);

	/**
	 * Verzamelt alle URLs van data requests en stuurt ze naar de REST backend.
	 */
	async collectUrls() {
		const instelling = await firstValueFrom(this.userService.instelling$);

		const datasnapUrls: RDatasnapUrl[] = [];
		for await (const { dashboardUrl, dataUrl } of this.dataRequestCollector.collectRequests()) {
			datasnapUrls.push({ $type: 'RDatasnapUrl', instelling, dashboardUrl, dataUrl, actief: true });
		}
		await this.datasnapUrlService.updateUrls(datasnapUrls);
		await this.router.navigateByUrl('/beheer/support');
	}
}
