<app-dashboard-container
	class="dashboard-plaatsing-advies"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant" [legenda]="['ds_fun_verschil_basisschooladvies_van']" [leftOptionsTemplate]="leftOptions">
			<ng-template #leftOptions>
				<div class="weergave">
					Aantal leerlingen:
					<dt-form-dropdown
						[opties]="thresholdOpties"
						[selectedOptie]="getSelectedThresholdOptie()"
						(onOptieClicked)="qp.dispatch('threshold', $event)"
					></dt-form-dropdown>
				</div>
				<div class="weergave adviestype">
					Advies:
					<app-attribute-selector
						[value]="adviesType"
						(valueChange)="qp.dispatch('adviesType', $event!)"
						[attrPaths]="adviesTypeOpties"
					></app-attribute-selector>
				</div>
			</ng-template>
		</app-dashboard-header>
		@switch (variant) {
			@case ("Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="groups"
					[subgroups]="determineSubgroups(adviesType)"
					[config]="this"
				></app-barchart-table>
			}
			@case ("Historie") {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(groups)"
					[subgroups]="getHistorieSubgroups([groups, adviesType])"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[partitionMeasure]="partitionMeasure"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
				></app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
