import { Component, inject, OnInit } from '@angular/core';
import { AttrPath, BasicFilterExpression, FilterExpression } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { SortHeader } from '../../shared/dashboard/card-list/card-sort-headers/card-sort-headers.component';
import { isNil } from 'lodash-es';
import { CijferMeasureAAttributes, CijferMeasureIAttributes, CijfersCardListConfig } from '../../shared/dashboard/card-list/cijfers-card-list-config';
import { CardListComponent, ExportCardListColumnDef } from '../../shared/dashboard/card-list/card-list.component';
import { DisplayService } from '../../services/display.service';
import { VbarSeriesComponent } from '../../shared/dashboard/vbarchart-table/vbar-series/vbar-series.component';
import { CardCellFooterComponent } from '../../shared/dashboard/card-list/card-cell-footer/card-cell-footer.component';
import { HbarPartitionComponent } from '../../shared/components/hbar/hbar-partition.component';
import { CardCellHeaderComponent } from '../../shared/dashboard/card-list/card-cell-header/card-cell-header.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { BaseDashboardConfig, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';

interface CijfersDocentG {
	'cf_fks_mw.mw_abb_medewerker': string;
	'cf_fks_mw.mw_nm_achternaam': string;
	'cf_fks_mw.mw_nm_roepnaam': string;
	'cf_fks_mw.mw_nm_voorvoegsel': string;
	'cf_fks_mw.mw_pk_key': string;
}

@Component({
	selector: 'app-cijfers-docent',
	templateUrl: './cijfers-docent.component.html',
	styleUrls: ['./cijfers-docent.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		CardListComponent,
		CardCellHeaderComponent,
		HbarPartitionComponent,
		CardCellFooterComponent,
		VbarSeriesComponent,
		FilterBarComponent,
	],
})
export class CijfersDocentComponent extends CijfersCardListConfig<CijfersDocentG> implements OnInit {
	protected readonly displayService = inject(DisplayService);

	override clickable = true;

	permanentFilterExpressions = [new BasicFilterExpression(['cf_fun_nr_aantal_docenten'], null, '<>')];

	filterExpressions?: FilterExpression[];

	actueelFilters: FilterName[] = [
		'cf_nm_schooljaar',
		'cf_co_brin',
		'cf_nm_vestiging',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nr_leerjaar',
		'x_cijfertype',
		'cf_fun_periode',
		'cf_nm_vak',
		'cf_nm_lesgroep',
		'cf_is_alternatievenormering',
	];

	historieFilters: FilterName[] = ['x_cijfer_schooljaar_historie', 'x_cijfer_multiselect_schooljaar', ...this.actueelFilters];

	groups: AttrPath[] = [
		['cf_fks_mw', 'mw_nm_achternaam'],
		['cf_fks_mw', 'mw_nm_roepnaam'],
		['cf_fks_mw', 'mw_nm_voorvoegsel'],
		['cf_fks_mw', 'mw_abb_medewerker'],
		['cf_fks_mw', 'mw_pk_key'],
	];

	subGroups: AttrPath[] = [['cf_nm_schooljaar']];

	actueelSortHeaders: SortHeader[] = [
		{ sortTarget: ['cf_nr_cijfer'], defaultDirection: 'desc', label: 'Cijfer' },
		{ sortTarget: ['cf_fks_mw', 'mw_nm_achternaam'], defaultDirection: 'asc', label: 'Docent' },
	];

	historieSortHeaders: SortHeader[] = [{ sortTarget: ['cf_fks_mw', 'mw_nm_achternaam'], defaultDirection: 'asc', label: 'Docent' }];

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	override subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe('variant').subscribe((variant) => (this.variant = variant)));
	}

	getGroupTitle(group: CijfersDocentG) {
		let title =
			this.displayService.display(group['cf_fks_mw.mw_nm_achternaam']) + ', ' + this.displayService.display(group['cf_fks_mw.mw_nm_roepnaam']);

		const voorvoegsel = group['cf_fks_mw.mw_nm_voorvoegsel'];
		if (!isNil(voorvoegsel)) title += ' ' + voorvoegsel;

		return title;
	}

	override getSelectionConfig(
		context: DashboardContext<
			CijferMeasureIAttributes,
			CijferMeasureAAttributes,
			BaseDashboardConfig<CijferMeasureIAttributes, CijferMeasureAAttributes>
		>
	): SelectionConfig<CijferMeasureAAttributes> {
		return { ...super.getSelectionConfig(context)!, detailUrl: '/details/docent/cijfers' };
	}

	protected override getExportGroupColumnDefs(): ExportCardListColumnDef<
		CijferMeasureIAttributes,
		CijfersDocentG,
		CijferMeasureAAttributes,
		CijfersDocentComponent
	>[] {
		return [
			{
				name: 'Docent',
				type: 'string',
				getValue: (path, context) => {
					const get = (g: string) => path[context.groupNames.indexOf(g) + 1].k;
					let docent = get('cf_fks_mw.mw_nm_achternaam');
					if (get('cf_fks_mw.mw_nm_roepnaam')) docent += ', ' + get('cf_fks_mw.mw_nm_roepnaam');
					if (get('cf_fks_mw.mw_nm_voorvoegsel')) docent += ' ' + get('cf_fks_mw.mw_nm_voorvoegsel');
					if (get('cf_fks_mw.mw_abb_medewerker')) docent += ` (${get('cf_fks_mw.mw_abb_medewerker')})`;
					return docent ?? 'Onbekend';
				},
			},
		];
	}
}
