@if (leerling$ | async; as leerling) {
	<div class="dashboard-lesregistraties">
		<app-filter-bar></app-filter-bar>
		<app-filter-panel
			[defaultFilters]="filters"
			[allFilters]="getAllFilters()"
			[leerlingselectie]="false"
			(filterExpressionsChange)="filterExpressions = $event"
			endpoint="/lesregistratie"
			[permanentFilterExpressions]="getDashboardFilters(leerling.leerling)"
			[overrideDefault]="getSchooljaarFilterOverride(leerling.schooljaarInfo)"
		></app-filter-panel>
		<section class="dashboard">
			<app-dashboard-header [legenda]="['lr_nm_lesregistratie']"></app-dashboard-header>
			<app-barchart-table
				[filters]="filterExpressions"
				[permanentFilters]="getDashboardFilters(leerling.leerling)"
				[groups]="groups"
				[config]="this"
				[barchartWidth]="200"
			></app-barchart-table>
		</section>
	</div>
}
