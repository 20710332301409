import { Component, inject } from '@angular/core';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { ToggleButtonComponent } from '@cumlaude/shared-components-buttons';
import { BaseDialogComponent } from '@cumlaude/shared-components-dialogs';

@Component({
	selector: 'app-onderwijsresultaten-options-dialog',
	templateUrl: './onderwijsresultaten-options-dialog.component.html',
	styleUrl: './onderwijsresultaten-options-dialog.component.scss',
	imports: [BaseDialogComponent, ToggleButtonComponent],
})
export class OnderwijsresultatenOptionsDialogComponent {
	readonly qp = inject(QueryParamStateService);

	useInspectieData = this.qp.signal('inspectie-data');

	toonTrend = this.qp.signal('toon-trend');
}
