import { inject, Injectable } from '@angular/core';
import { AccountFilter } from '../filters/AccountFilter';
import { Observable, switchMap } from 'rxjs';
import {
	CumLaudeLicentieInfo,
	CumLaudeVersionInfo,
	RBasisvaardighedenNormTable,
	RBestuur,
	RBestuurPrimer,
	RCumLaudeAccount,
	RCumLaudeAccountAdditionalObjectKey,
	RDatasnapResult,
	RDatasnapUrl,
	RFavoriet,
	RFavorietMap,
	RFeedback,
	RGedeeldePaginaPersoon,
	RGedeeldePaginaUrl,
	RInstelling,
	RInstellingAdditionalObjectKey,
	RInstellingPrimer,
	RLeerlingSelectie,
	RMagisterInstelling,
	RVakUitsluiting,
	RVestiging,
} from '@cumlaude/service-contract';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ErrorMessage, ErrorMessageEnum } from '@cumlaude/metadata';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { BugsnagService } from '@cumlaude/bugsnag';
import { VestigingFilter } from '../filters/VestigingFilter';
import { omit } from 'lodash-es';
import { AuthService } from '@cumlaude/shared-authentication';

type RequestParams =
	| HttpParams
	| {
			[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
	  };

@Injectable({
	providedIn: 'root',
})
export class RestService {
	protected readonly http = inject(HttpClient);
	protected readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);
	protected readonly bugsnag = inject(BugsnagService);
	protected readonly authService = inject(AuthService);

	getMyAccount(): Observable<RCumLaudeAccount> {
		const url = `${this.envConfig.restUrl}/account/me`;
		const params = { additional: 'autorisaties' };
		return this.getSingle(url, params);
	}

	getAccountsByInstelling(instelling: RInstelling): Observable<RCumLaudeAccount[]> {
		const params = {
			sort: ['desc-support', 'asc-naam'],
			instelling: `${instelling.id}`,
			actief: 'true',
		};
		return this.getAccounts(params);
	}

	getAccountsFromFilter(
		{ quicksearch, rol, excludeRol, vestiging }: AccountFilter,
		additionals: RCumLaudeAccountAdditionalObjectKey[] = []
	): Observable<RCumLaudeAccount[]> {
		const params = {
			sort: 'asc-naam',
			...(quicksearch ? { quicksearch } : {}),
			...(rol ? { rol } : {}),
			...(excludeRol ? { excludeRol } : {}),
			...(vestiging ? { vestiging } : {}),
			...(additionals.length > 0 ? { additional: additionals } : {}),
			support: 'false',
			actief: 'true',
		};
		return this.getAccounts(params);
	}

	private getAccounts(params: RequestParams): Observable<RCumLaudeAccount[]> {
		const url = `${this.envConfig.restUrl}/account`;
		return this.get(url, params);
	}

	getAccountMedewerkerExternIds(): Observable<string[]> {
		const url = `${this.envConfig.restUrl}/account/medewerkerExternIds`;
		return this.getStringList(url);
	}

	postAccount(account: RCumLaudeAccount, linkCredentials: boolean): Observable<RCumLaudeAccount> {
		const url = `${this.envConfig.restUrl}/account`;
		const params = linkCredentials ? { link_credentials: true } : undefined;
		return this.postSingle(url, account, params);
	}

	putAccount(account: RCumLaudeAccount, linkCredentials: boolean): Observable<RCumLaudeAccount> {
		const url = `${this.envConfig.restUrl}/account/${account.id}`;
		const params = linkCredentials ? { link_credentials: true } : undefined;
		return this.put(url, account, params);
	}

	deleteAccount(account: RCumLaudeAccount): Observable<void> {
		const url = `${this.envConfig.restUrl}/account/${account.id}`;
		return this.delete(url);
	}

	resetAccountPassword(account: RCumLaudeAccount): Observable<void> {
		const url = `${this.envConfig.restUrl}/account/${account.id}/resetPassword`;
		return this.postVoid(url);
	}

	resetAccountTotp(account: RCumLaudeAccount): Observable<void> {
		const url = `${this.envConfig.restUrl}/account/${account.id}/remove2FA`;
		return this.postVoid(url);
	}

	getBasisvaardighedenNormen(schooljaar: string[], vaardigheid: string, vestigingId: string[]): Observable<RBasisvaardighedenNormTable[]> {
		const url = `${this.envConfig.restUrl}/basisvaardigheden_norm/table`;
		const params = {
			schooljaar,
			vaardigheid,
			vestigingId,
		};
		return this.get(url, params);
	}

	postBasisvaardighedenNormen(tables: RBasisvaardighedenNormTable[]): Observable<RBasisvaardighedenNormTable[]> {
		const url = `${this.envConfig.restUrl}/basisvaardigheden_norm/table`;
		return this.post(url, tables);
	}

	getBestuur(id: number): Observable<RBestuur> {
		const url = `${this.envConfig.restUrl}/bestuur/${id}`;
		return this.getSingle(url);
	}

	getBesturen(): Observable<RBestuur[]> {
		const url = `${this.envConfig.restUrl}/bestuur`;
		const params = {
			sort: ['asc-naam'],
		};
		return this.get(url, params);
	}

	postBestuur(bestuur: RBestuur): Observable<RBestuur> {
		const url = `${this.envConfig.restUrl}/bestuur`;
		return this.postSingle(url, bestuur);
	}

	putBestuur(bestuur: Partial<RBestuur | RBestuurPrimer>): Observable<RBestuur> {
		const url = `${this.envConfig.restUrl}/bestuur/${bestuur.id}`;
		return this.put(url, <Partial<RBestuur>>{
			...omit(bestuur, 'additionalObjects', 'instellingen'),
			$type: 'bestuur.RBestuur',
		});
	}

	deleteBestuur(bestuur: RBestuur): Observable<void> {
		const url = `${this.envConfig.restUrl}/bestuur/${bestuur.id}`;
		return this.http.delete<void>(url).pipe(
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	getDataserviceVersionInfo(): Observable<CumLaudeVersionInfo> {
		const url = `${this.envConfig.dataUrl!}/version`;
		return this.getSingle(url);
	}

	getDatasnapResults(urlId: string): Observable<RDatasnapResult[]> {
		const url = `${this.envConfig.restUrl}/datasnap_url/${urlId}/result`;
		const params = {
			sort: 'desc-tsRequest',
		};
		return this.get(url, params);
	}

	getDatasnapUrls(): Observable<RDatasnapUrl[]> {
		const url = `${this.envConfig.restUrl}/datasnap_url`;
		const params = {
			actief: true,
		};
		return this.get(url, params);
	}

	postDatasnapUrls(datasnapUrls: RDatasnapUrl[]): Observable<RDatasnapUrl[]> {
		const url = `${this.envConfig.restUrl}/datasnap_url`;
		return this.post(url, datasnapUrls);
	}

	getFavorieten(): Observable<RFavoriet[]> {
		const url = `${this.envConfig.restUrl}/favoriet`;
		const params = {
			sort: 'asc-naam',
		};
		return this.get(url, params);
	}

	postFavoriet(favoriet: Partial<RFavoriet>): Observable<RFavoriet> {
		const url = `${this.envConfig.restUrl}/favoriet`;
		return this.postSingle(url, favoriet);
	}

	putFavoriet(favoriet: Partial<RFavoriet>): Observable<RFavoriet> {
		const url = `${this.envConfig.restUrl}/favoriet/${favoriet.id}`;
		return this.put(url, favoriet);
	}

	deleteFavoriet(favoriet: RFavoriet): Observable<void> {
		const url = `${this.envConfig.restUrl}/favoriet/${favoriet.id}`;
		return this.delete(url);
	}

	getFavorietMappen(): Observable<RFavorietMap[]> {
		const url = `${this.envConfig.restUrl}/favoriet_map`;
		const params = {
			sort: 'asc-naam',
		};
		return this.get(url, params);
	}

	postFavorietMap(favorietMap: Partial<RFavorietMap>): Observable<RFavorietMap> {
		const url = `${this.envConfig.restUrl}/favoriet_map`;
		return this.postSingle(url, favorietMap);
	}

	postFavorietMappen(favorietMappen: RFavorietMap[]): Observable<RFavorietMap[]> {
		const url = `${this.envConfig.restUrl}/favoriet_map`;
		return this.post(url, favorietMappen);
	}

	putFavorietMap(favorietMap: Partial<RFavorietMap>): Observable<RFavorietMap> {
		const url = `${this.envConfig.restUrl}/favoriet_map/${favorietMap.id}`;
		return this.put(url, favorietMap);
	}

	deleteFavorietMap(favorietMap: RFavorietMap): Observable<void> {
		const url = `${this.envConfig.restUrl}/favoriet_map/${favorietMap.id}`;
		return this.delete(url);
	}

	postFeedback(feedback: RFeedback): Observable<void> {
		const url = `${this.envConfig.restUrl}/feedback`;
		return this.http.post<void>(url, feedback);
	}

	getGedeeldePaginaPersonen(ontvanger: RCumLaudeAccount): Observable<RGedeeldePaginaPersoon[]> {
		const url = `${this.envConfig.restUrl}/gedeelde_pagina_persoon`;
		const params = {
			sort: 'desc-verzonden',
			ontvanger: ontvanger.id!,
		};
		return this.get(url, params);
	}

	postGedeeldePaginaPersoon(gedeeldePaginaPersoon: Partial<RGedeeldePaginaPersoon>): Observable<RGedeeldePaginaPersoon> {
		const url = `${this.envConfig.restUrl}/gedeelde_pagina_persoon`;
		return this.postSingle(url, gedeeldePaginaPersoon);
	}

	putGedeeldePaginaPersoon(gedeeldePaginaPersoon: Partial<RGedeeldePaginaPersoon>): Observable<RGedeeldePaginaPersoon> {
		const url = `${this.envConfig.restUrl}/gedeelde_pagina_persoon/${gedeeldePaginaPersoon.id}`;
		return this.put(url, gedeeldePaginaPersoon);
	}

	deleteGedeeldePaginaPersoon(gedeeldePaginaPersoon: RGedeeldePaginaPersoon): Observable<void> {
		const url = `${this.envConfig.restUrl}/gedeelde_pagina_persoon/${gedeeldePaginaPersoon.id}`;
		return this.http.delete<void>(url).pipe(
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	getGedeeldePaginaUrl(uuid: string): Observable<RGedeeldePaginaUrl | undefined> {
		const url = `${this.envConfig.restUrl}/gedeelde_pagina_url`;
		const params = {
			uuid,
		};
		return this.get<RGedeeldePaginaUrl>(url, params).pipe(map((items) => (items.length > 0 ? items[0] : undefined)));
	}

	postGedeeldePaginaUrl(gedeeldePaginaUrl: RGedeeldePaginaUrl): Observable<RGedeeldePaginaUrl> {
		const url = `${this.envConfig.restUrl}/gedeelde_pagina_url`;
		return this.postSingle(url, gedeeldePaginaUrl);
	}

	getInstelling(idOrPrimer: number | string | RInstellingPrimer, additionals: RInstellingAdditionalObjectKey[] = []): Observable<RInstelling> {
		const id = typeof idOrPrimer === 'object' ? idOrPrimer.id : idOrPrimer;
		const url = `${this.envConfig.restUrl}/instelling/${id}`;
		const params = {
			...(additionals.length > 0 ? { additional: additionals } : {}),
		};
		return this.getSingle(url, params);
	}

	getInstellingen(bestuur?: RBestuur, additionals: RInstellingAdditionalObjectKey[] = []): Observable<RInstelling[]> {
		const url = `${this.envConfig.restUrl}/instelling`;
		const params = {
			verwijderd: false,
			...(bestuur ? { bestuur: bestuur.id } : {}),
			...(additionals.length > 0 ? { additional: additionals } : {}),
			sort: ['desc-actief', 'asc-naam'],
		};
		return this.get(url, params);
	}

	putInstelling(instelling: Partial<RInstelling | RInstellingPrimer>): Observable<RInstelling> {
		const url = `${this.envConfig.restUrl}/instelling/${instelling.id}`;
		return this.put(url, <Partial<RInstelling>>{
			...omit(instelling, 'additionalObjects'),
			$type: 'instelling.RInstelling',
		});
	}

	getLeerlingSelectie(id: string): Observable<RLeerlingSelectie> {
		const url = `${this.envConfig.restUrl}/leerlingselectie/${id}`;
		return this.getSingle(url);
	}

	getLeerlingSelecties(): Observable<RLeerlingSelectie[]> {
		const url = `${this.envConfig.restUrl}/leerlingselectie`;
		const params = {
			sort: 'asc-naam',
		};
		return this.get(url, params);
	}

	postLeerlingSelectie(leerlingSelectie: Partial<RLeerlingSelectie>): Observable<RLeerlingSelectie> {
		const url = `${this.envConfig.restUrl}/leerlingselectie`;
		return this.postSingle(url, leerlingSelectie);
	}

	putLeerlingSelectie(leerlingSelectie: Partial<RLeerlingSelectie>): Observable<RLeerlingSelectie> {
		const url = `${this.envConfig.restUrl}/leerlingselectie/${leerlingSelectie.id}`;
		return this.put(url, leerlingSelectie);
	}

	deleteLeerlingSelectie(id: string): Observable<void> {
		const url = `${this.envConfig.restUrl}/leerlingselectie/${id}`;
		return this.delete(url);
	}

	getLicentieInfo(): Observable<CumLaudeLicentieInfo> {
		const url = `${this.envConfig.restUrl}/licentie`;
		return this.getSingle(url);
	}

	getMagisterInstelling(id: number): Observable<RMagisterInstelling> {
		const url = `${this.envConfig.restUrl}/magister_instelling/${id}`;
		return this.getSingle(url);
	}

	postMagisterInstelling(instelling: Partial<RMagisterInstelling>): Observable<RMagisterInstelling> {
		const url = `${this.envConfig.restUrl}/magister_instelling`;
		return this.postSingle(url, instelling);
	}

	putMagisterInstelling(instelling: Partial<RMagisterInstelling>): Observable<RMagisterInstelling> {
		const url = `${this.envConfig.restUrl}/magister_instelling/${instelling.id}`;
		return this.put(url, instelling);
	}

	getReleaseNotes(): Observable<string[]> {
		const url = `${this.envConfig.restUrl}/release-notes`;
		return this.getStringList(url);
	}

	getRestVersionInfo(): Observable<CumLaudeVersionInfo> {
		const url = `${this.envConfig.restUrl}/version`;
		return this.getSingle(url);
	}

	getVakUitsluitingen(): Observable<RVakUitsluiting[]> {
		const url = `${this.envConfig.restUrl}/vak_uitsluiting`;
		const params = {
			sort: 'asc-vakNaam',
		};
		return this.get(url, params);
	}

	postVakUitsluiting(vakUitsluiting: RVakUitsluiting): Observable<RVakUitsluiting> {
		const url = `${this.envConfig.restUrl}/vak_uitsluiting`;
		return this.postSingle(url, vakUitsluiting);
	}

	putVakUitsluiting(vakUitsluiting: RVakUitsluiting): Observable<RVakUitsluiting> {
		const url = `${this.envConfig.restUrl}/vak_uitsluiting/${vakUitsluiting.id}`;
		return this.put(url, vakUitsluiting);
	}

	getVestigingen({ cumlaudeActief, instelling, vanBestuur }: VestigingFilter): Observable<RVestiging[]> {
		const url = `${this.envConfig.restUrl}/vestiging${vanBestuur ? '/bestuurVestigingen' : ''}`;
		const params = {
			sort: ['desc-actief', 'asc-naam'],
			...(cumlaudeActief ? { cumlaudeActief: 'TRUE' } : {}),
			...(instelling ? { instelling: instelling.id } : {}),
		};
		return this.get(url, params);
	}

	getBeheerVestigingen(): Observable<RVestiging[]> {
		const url = `${this.envConfig.restUrl}/vestiging/beheerderVestigingen`;
		const params = {
			sort: ['desc-actief', 'asc-naam'],
			cumlaudeActief: 'TRUE',
		};
		return this.get(url, params);
	}

	putVestiging(vestiging: Partial<RVestiging>): Observable<RVestiging> {
		const url = `${this.envConfig.restUrl}/vestiging/${vestiging.id}`;
		return this.put(url, vestiging);
	}

	private getSingle<T>(url: string, params?: RequestParams): Observable<T> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() =>
				this.http.get<T>(url, {
					params,
					observe: 'body',
					responseType: 'json',
				})
			),
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	private getStringList(url: string, params?: RequestParams): Observable<string[]> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() =>
				this.http.get<string[]>(url, {
					params,
					observe: 'body',
					responseType: 'json',
				})
			),
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	private get<T>(url: string, params?: RequestParams): Observable<T[]> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() =>
				this.http.get<{ items: T[] }>(url, {
					params,
					observe: 'body',
					responseType: 'json',
				})
			),
			map((resp) => resp.items),
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	private put<T>(url: string, item: Partial<T>, params?: RequestParams): Observable<T> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.http.put<T>(url, item, { params })),
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	private postSingle<T>(url: string, item: Partial<T>, params?: RequestParams): Observable<T> {
		return this.post<T>(url, [item], params).pipe(map((items) => items[0]));
	}

	private post<T>(url: string, items: Partial<T>[], params?: RequestParams): Observable<T[]> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.http.post<{ items: T[] }>(url, { items: items }, { params })),
			map((accs) => accs.items),
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	private postVoid(url: string): Observable<void> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.http.post<void>(url, {})),
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	private delete(url: string): Observable<void> {
		return this.authService.refreshTokenIfNeeded().pipe(
			switchMap(() => this.http.delete<void>(url)),
			catchError((resp) => {
				throw this.parseRestErrorResponse(resp);
			})
		);
	}

	protected parseRestErrorResponse(resp: HttpErrorResponse): ErrorMessage {
		if (resp.error?.applicationError) return { error: resp.error.applicationError, parameters: resp.error.applicationErrorParameters };
		if (resp.status == 401) return { error: ErrorMessageEnum.NO_ACCESS };

		this.bugsnag.notify(resp);
		return { error: ErrorMessageEnum.UNKNOWN };
	}
}
