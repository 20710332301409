import { flatMap, sum } from 'lodash-es';
import { Selection } from '../shared/dashboard/base-dashboard/base-dashboard-config';
import { TooltipType } from '@cumlaude/shared-components-overlays';

export type BarInfo = {
	size: number;
	text?: string;
	description?: string;
	selection?: Selection;
	className?: string;
	tooltip?: TooltipType;
};

export type BarInfoPct = BarInfo & {
	pct: number;
	pctStart: number;
};

/**
 * Converteert een 2D BarInfo-array van naar een 1D array waar evt. ook gutters
 * in zitten en waarbij de size wordt vertaald naar een percentage.
 */
export function buildPctStack(stacks: BarInfo[][], totalPx: number, gutterPx: number, gutterBar?: BarInfo): BarInfoPct[] {
	if (totalPx == 0) return flatMap(stacks, (stack) => stack.map((bar) => ({ ...bar, pct: 0, pctStart: 0 })));

	const gutterPct = 100 * (gutterPx / totalPx);
	const ngutters = Math.max(stacks.length - 1, 0);
	const availablePct = 100 - gutterPct * ngutters;
	const totalSize = sum(stacks.map((bars) => sum(bars.map((r) => r.size))));

	const bars = [];

	let pctStart = 0;

	for (const stack of stacks) {
		for (const bar of stack) {
			const pct = (bar.size / totalSize) * availablePct;
			bars.push({ ...bar, pct, pctStart });
			pctStart += pct;
		}
		if (gutterBar) bars.push({ ...gutterBar, pct: gutterPct, pctStart });
		pctStart += gutterPct;
	}
	if (gutterBar && stacks.length > 0) {
		bars.pop(); // remove last gutter
	}
	return bars;
}
