import { computed, inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { InstellingBron, RRol } from '@cumlaude/service-contract';
import { isUndefined } from 'lodash-es';
import { UserService } from './user.service';
import { toSignal } from '@angular/core/rxjs-interop';

interface UrlAllowed {
	name: string;

	allowed?: (vo: boolean, vso: boolean) => boolean;
	sub?: UrlAllowed[];
}

const schoolbreedTeamleiderAllowed: UrlAllowed[] = [
	{ name: 'afwezigheid' },
	{ name: 'basisvaardigheden' },
	{ name: 'cijfers' },
	{
		name: 'details',
		sub: [
			{ name: 'leerling' },
			{ name: 'docent' },
			{
				name: 'uitzondering',
				sub: [
					{ name: 'doorstroom' },
					{ name: 'onderbouwsnelheid', allowed: (vo, _vso) => vo },
					{ name: 'onderwijspositie', allowed: (vo, _vso) => vo },
					{ name: 'bovenbouwsucces', allowed: (vo, _vso) => vo },
					{ name: 'einduitstroom', allowed: (_vo, vso) => vso },
					{ name: 'uitstroom-iq', allowed: (_vo, vso) => vso },
					{ name: 'tussentijdse-uitstroom', allowed: (_vo, vso) => vso },
				],
			},
		],
	},
	{ name: 'doorstroom' },
	{ name: 'leerling' },
	{ name: 'onderwijsresultaten', allowed: (vo, _vso) => vo },
	{ name: 'prestatieanalyse', allowed: (_vo, vso) => vso },
];

const allowedMap: Partial<{ [rol in RRol]: UrlAllowed[] }> = {
	[RRol.SCHOOLBREED]: schoolbreedTeamleiderAllowed,
	[RRol.TEAMLEIDER]: schoolbreedTeamleiderAllowed,
	[RRol.BESTUUR]: [
		{ name: 'basisvaardigheden' },
		{ name: 'cijfers', sub: [{ name: 'overzicht' }, { name: 'se-ce' }, { name: 'examens' }] },
		{
			name: 'details',
			sub: [
				{
					name: 'uitzondering',
					sub: [
						{ name: 'doorstroom' },
						{ name: 'onderbouwsnelheid', allowed: (vo, _vso) => vo },
						{ name: 'onderwijspositie', allowed: (vo, _vso) => vo },
						{ name: 'bovenbouwsucces', allowed: (vo, _vso) => vo },
						{ name: 'einduitstroom', allowed: (_vo, vso) => vso },
						{ name: 'uitstroom-iq', allowed: (_vo, vso) => vso },
						{ name: 'tussentijdse-uitstroom', allowed: (_vo, vso) => vso },
					],
				},
			],
		},
		{ name: 'doorstroom' },
		{ name: 'leerling' },
		{ name: 'onderwijsresultaten', allowed: (vo, _vso) => vo },
		{ name: 'prestatieanalyse', allowed: (_vo, vso) => vso },
	],
	[RRol.SECTIELEIDER]: [
		{ name: 'afwezigheid' },
		{ name: 'basisvaardigheden' },
		{ name: 'cijfers' },
		{
			name: 'details',
			sub: [
				{
					name: 'leerling',
					sub: [
						{ name: 'afwezigheid' },
						{ name: 'afwezigheidsredenen' },
						{ name: 'basisvaardigheden' },
						{ name: 'cijferlijst' },
						{ name: 'lesregistraties' },
					],
				},
				{ name: 'docent' },
			],
		},
	],
	[RRol.DOCENT]: [
		{ name: 'basisvaardigheden' },
		{ name: 'cijfers', sub: [{ name: 'overzicht' }, { name: 'toets' }, { name: 'toets-details' }, { name: 'se-ce' }, { name: 'examens' }] },
		{
			name: 'details',
			sub: [
				{ name: 'leerling', sub: [{ name: 'cijferlijst' }, { name: 'basisvaardigheden' }] },
				{ name: 'docent', sub: [{ name: 'toets' }] },
			],
		},
	],
	[RRol.MENTOR]: [
		{ name: 'afwezigheid' },
		{ name: 'basisvaardigheden' },
		{ name: 'cijfers', sub: [{ name: 'overzicht' }, { name: 'toets' }, { name: 'cijferlijst' }] },
		{ name: 'details', sub: [{ name: 'leerling' }] },
	],
};

@Injectable({
	providedIn: 'root',
})
export class AutorisatieService {
	readonly router = inject(Router);
	readonly userService = inject(UserService);

	account = toSignal(this.userService.myAccount$);

	activeRol = toSignal(this.userService.rolChanged$);

	rollen = toSignal(this.userService.rollen$, { initialValue: [] });

	vestigingen = toSignal(this.userService.rVestigingen$, { initialValue: [] });

	vo = computed(() => {
		const vestigingen = this.vestigingen();
		return vestigingen.some((vestiging) => vestiging.vo);
	});

	vso = computed(() => {
		const vestigingen = this.vestigingen();
		return vestigingen.some((vestiging) => vestiging.vso);
	});

	/**
	 * Checkt of een gebruiker bij een bepaalde url mag komen.
	 * Geeft true of false terug.
	 */
	isUrlAllowed(urlRequested: string): boolean {
		const result = this.checkUrlForRol(urlRequested);
		return result instanceof UrlTree ? true : result;
	}

	isUrlAllowedForRollen(urlRequested: string, rollen: RRol[]): boolean {
		for (const rol of rollen) {
			const allowedList = allowedMap[rol];
			if (!allowedList) continue;

			const allowed = this.checkAllowedForUrl(urlRequested, allowedList);
			if (allowed) return true;
		}

		return false;
	}

	/**
	 * Checkt of een gebruiker bij een bepaalde url mag komen.
	 * Geeft true of false terug indien de gebruiker hier wel of niet bij mag komen.
	 * Indien alleen bij specifieke suburls, geeft dan een UrlTree terug naar die specifieke suburl.
	 */
	checkUrlForRol(urlRequested: string): boolean | UrlTree {
		if (this.isAllowedWithoutRol(urlRequested)) return true;
		if (this.isBeheerAllowed(urlRequested)) return true;

		const activeRol = this.activeRol();
		if (isUndefined(activeRol)) return false;

		const allowedList = allowedMap[activeRol];
		if (allowedList) return this.checkAllowedForUrl(urlRequested, allowedList);

		return false;
	}

	/**
	 * Zonder actieve rol mag je bij root en alles onder dashboard.
	 */
	private isAllowedWithoutRol(urlRequested: string): boolean {
		return urlRequested.startsWith('/dashboard') || urlRequested.startsWith('/shared') || urlRequested == '/' || urlRequested == '';
	}

	/**
	 * Als je de rol beheerder hebt toegewezen gekregen, mag je bij alles dat onder /beheer valt.
	 */
	private isBeheerAllowed(urlRequested: string) {
		const rollen = this.rollen();
		const bron = this.userService.bron();
		return (
			rollen.includes(RRol.BEHEERDER) &&
			urlRequested.startsWith('/beheer') &&
			!(
				bron !== InstellingBron.Magister &&
				(urlRequested.startsWith('/beheer/cijferinstellingen') || urlRequested.startsWith('/beheer/vakken_uitsluiten'))
			) &&
			!(urlRequested.startsWith('/beheer/support') && !this.account()?.support)
		);
	}

	/**
	 * Haalt de slash aan het begin weg, splits de url vervolgens in stukjes en start daarna het checken van de url.
	 */
	private checkAllowedForUrl(urlRequested: string, urlAllowed: UrlAllowed[]): boolean | UrlTree {
		const urlParts = urlRequested.substring(1).split('/');
		return this.checkUrlAllowed(urlRequested, urlParts, urlAllowed);
	}

	/**
	 * Doet een aantal checks op de url om te controleren of de gebruiker de url mag gebruiken.
	 *  1. Is het eerste deel van de url sowieso toegestaan voor de gebruiker.
	 *  2. Check of de gebruiker's school matched met het gewenste onderwijstype bij specifieke domeinen.
	 *  3. Checkt of de url toestemming gaat over de alle suburl's of alleen specifieke suburl's.
	 *  4. Checkt of we nu onderaan de url zijn en toch nog suburl's als opties hebben, zo ja, redirecten naar eerste suburl.
	 *  5. Vervolgt bij de volgende url deel met checks.
	 */
	private checkUrlAllowed(urlRequested: string, urlParts: string[], urlAllowed: UrlAllowed[]): boolean | UrlTree {
		const urlPart = urlParts[0];

		const allowedUrl = urlAllowed.find((part) => part.name == urlPart);
		if (!allowedUrl) return false;

		const vo = this.vo();
		const vso = this.vso();
		if (allowedUrl.allowed && !allowedUrl.allowed(vo, vso)) return false;

		if (!allowedUrl.sub) return true;

		if (urlParts.length == 1) {
			const dashboard = allowedUrl.sub[0].name;
			return this.router.parseUrl(`${urlRequested}/${dashboard}`);
		}

		return this.checkUrlAllowed(urlRequested, urlParts.slice(1), allowedUrl.sub);
	}
}
