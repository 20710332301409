import { Component, DestroyRef, EventEmitter, signal, TemplateRef, ViewChild, WritableSignal, inject } from '@angular/core';
import { AuthService } from '@cumlaude/shared-authentication';
import {
	Attr,
	BasicFilterExpression,
	CompoundFilterExpression,
	createSelectionExpression,
	DataService,
	FilterExpression,
	FilterStaticSelectionExpression,
	InFilterExpression,
} from '../../../services/data.service';
import { RCumLaudeAccount, RLeerlingSelectie } from '@cumlaude/service-contract';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { BaseSidebarComponent, SIDEBAR_DATA, SidebarRef } from '@cumlaude/shared-components-overlays';
import { BehaviorSubject, combineLatest, filter, map, scan, startWith, switchMap, tap } from 'rxjs';
import { Leerling } from '../../../details/Details';
import { LeerlingChecklistComponent } from '../leerling-checklist/leerling-checklist.component';
import { AutofocusDirective, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { parseSearchInputToNumbers } from '@cumlaude/shared-utils';

export type LeerlingRecord = { nummer: number; naam: string; pasfoto: string };

export type LeerlingselectieBewerkenSidebarData = { leerlingselectie?: RLeerlingSelectie; expression?: FilterExpression; account: RCumLaudeAccount };

function getLeerlingRecord({ ll_nm_achternaam, ll_nm_roepnaam, ll_nm_voorvoegsel, ll_nr_leerling, ll_pic_pasfoto }: Leerling): LeerlingRecord {
	return {
		naam: [ll_nm_roepnaam, ll_nm_voorvoegsel, ll_nm_achternaam].filter(Boolean).join(' '),
		nummer: ll_nr_leerling,
		pasfoto: ll_pic_pasfoto,
	};
}

const defaultLimit = 100;

const lijstDataCol: Attr[][] = [['ll_nm_achternaam'], ['ll_nm_voorvoegsel'], ['ll_nm_roepnaam'], ['ll_nr_leerling'], ['ll_pic_pasfoto']];

@Component({
	selector: 'app-leerlingselectie-bewerken-sidebar',
	templateUrl: './leerlingselectie-bewerken-sidebar.component.html',
	styleUrl: './leerlingselectie-bewerken-sidebar.component.scss',
	imports: [
		ButtonComponent,
		BaseSidebarComponent,
		LeerlingChecklistComponent,
		InstantSearchBoxComponent,
		AutofocusDirective,
		ConfirmDialogComponent,
		AsyncPipe,
	],
})
export class LeerlingselectieBewerkenSidebarComponent {
	protected readonly sidebarRef = inject<SidebarRef<RLeerlingSelectie>>(SidebarRef);
	protected readonly data = inject<LeerlingselectieBewerkenSidebarData>(SIDEBAR_DATA);
	protected readonly authService = inject(AuthService);
	private readonly dataService = inject(DataService);
	private readonly dialog = inject(Dialog);
	private readonly destroyRef = inject(DestroyRef);

	/** Oorspronkelijke leerlingselectie */
	leerlingselectie: RLeerlingSelectie;

	/** Alle leerlingnummers: default de oorspronkelijke, maar gebruiker kan toevoegen. */
	alleLeerlingnummers = new BehaviorSubject<number[] | undefined>(undefined);

	naam: WritableSignal<string>;

	/**
	 * De leerlingnummers in de uiteindelijke leerlingselectie. Dit zijn default alle leerlingen in de oorspronkelijke leerlingselectie,
	 * maar de gebruiker kan leerlingen uitvinken en nieuwe leerlingen toevoegen.
	 */
	selectedLeerlingnummers = signal<number[]>([]);

	/** Geeft aan of het leerlingen-toevoegen-panel actief is */
	leerlingenToevoegen = signal(false);

	searchInput$ = new BehaviorSubject<string>('');
	fetch$ = new EventEmitter<number>();
	total: number | undefined;
	elementen = signal<LeerlingRecord[]>([]);

	searchInputAdd$ = new BehaviorSubject<string>('');
	fetchAdd$ = new EventEmitter<number>();
	totalAdd: number | undefined;
	elementenAdd = signal<LeerlingRecord[]>([]);

	/** Leerlingnummers die door de gebruiker zijn aangevinkt voor het handmatig toevoegen */
	addedLeerlingnummers = signal<number[]>([]);

	modified = signal(false);

	/** Guard dialog om te stoppen met bewerken */
	@ViewChild('confirmCancelDialog')
	confirmCancelDialog!: TemplateRef<void>;

	constructor() {
		this.leerlingselectie = this.data.leerlingselectie ?? {
			$type: 'RLeerlingSelectie',
			account: this.data.account,
			instelling: this.data.account.instelling,
			naam: '',
			selectionProvider: 'values',
			selectionFilter: JSON.stringify({ val: [] }),
		};
		this.naam = signal(this.leerlingselectie.naam);
		this.dataService
			.getLeerlingen<{
				ll_nr_leerling: number;
			}>({
				f: this.data.expression ?? createSelectionExpression(this.leerlingselectie),
				col: [['ll_nr_leerling']],
			})
			.pipe(takeUntilDestroyed())
			.subscribe((response) => {
				const alleLeerlingnummers = response.rows.map(({ ll_nr_leerling }) => ll_nr_leerling);
				this.alleLeerlingnummers.next(alleLeerlingnummers);
				this.selectedLeerlingnummers.set(alleLeerlingnummers);
			});

		combineLatest([this.searchInput$, this.alleLeerlingnummers])
			.pipe(
				filter(([_, nummers]) => Boolean(nummers)),
				switchMap(([searchInput, alleLeerlingnummers]) =>
					// Haal de leerlingdata (incl pasfoto's) gepagineerd op
					this.fetch$.pipe(
						startWith(-1),
						filter((off) => off !== this.total),
						switchMap((off) =>
							this.dataService.getLeerlingen<Leerling>({
								lim: defaultLimit,
								off,
								f: new CompoundFilterExpression([
									new FilterStaticSelectionExpression(alleLeerlingnummers!),
									...this.getSearchFilter(searchInput),
								]),
								col: lijstDataCol,
							})
						),
						tap((tableResponse) => {
							if (tableResponse.total !== undefined) this.total = tableResponse.total;
						}),
						map((tableResponse) => tableResponse.rows),
						scan((acc, cur) => [...acc, ...cur])
					)
				),
				takeUntilDestroyed()
			)
			.subscribe((elementen) => {
				this.elementen.set(elementen.map(getLeerlingRecord));
			});

		combineLatest([this.searchInputAdd$, toObservable(this.leerlingenToevoegen)])
			.pipe(
				filter(([_searchInput, leerlingenToevoegen]) => leerlingenToevoegen),
				tap(() => (this.totalAdd = undefined)),
				switchMap(([searchInput]) =>
					this.fetchAdd$.pipe(
						startWith(-1),
						filter((off) => off !== this.totalAdd),
						switchMap((off) =>
							this.dataService.getLeerlingen<Leerling>({
								lim: defaultLimit,
								off,
								f: this.getFiltersForAdd(searchInput),
								col: lijstDataCol,
							})
						),
						tap((tableResponse) => {
							if (tableResponse.total !== undefined) this.totalAdd = tableResponse.total;
						}),
						map((tableResponse) => tableResponse.rows),
						scan((acc, cur) => [...acc, ...cur])
					)
				),
				takeUntilDestroyed()
			)
			.subscribe((elementen) => {
				this.elementenAdd.set(elementen.map(getLeerlingRecord));
			});

		this.sidebarRef
			.closeRequested()
			.pipe(takeUntilDestroyed())
			.subscribe(({ allowClose, result }) => {
				// Gewijzigd en op annuleren/kruisje/mask gedrukt? Toon guard en sluit sidebar alleen als deze true teruggeeft.
				if (this.modified() && !result) {
					const dialogRef = this.dialog.open<boolean>(this.confirmCancelDialog);
					dialogRef.closed.subscribe((close) => allowClose.next(close ?? false));
				} else allowClose.next(true);
			});
	}

	getResult(): RLeerlingSelectie {
		const selectionFilter = this.createSelectionFilter(this.selectedLeerlingnummers());
		return { ...this.leerlingselectie, naam: this.naam(), selectionProvider: 'values', selectionFilter };
	}

	getTitle() {
		if (this.leerlingenToevoegen()) return 'Leerlingen toevoegen';
		if (this.leerlingselectie.id) return 'Leerlingselectie bewerken';

		return this.data.expression ? 'Leerlingselectie opslaan' : 'Nieuwe leerlingselectie';
	}

	createSelectionFilter(leerlingnummers: number[]): string {
		return JSON.stringify(new InFilterExpression(['x_values'], leerlingnummers));
	}

	isNotEmpty() {
		return this.naam().trim().length > 0 && this.selectedLeerlingnummers().length > 0;
	}

	saveAndClose() {
		if (this.isNotEmpty()) this.sidebarRef.requestClose(this.getResult());
	}

	selectAllAdd() {
		// elementenAdd is gepagineerd en bevat dus niet alle leerlingen. Daarom hier een aparte query om alle leerlingen op te halen.
		this.dataService
			.getLeerlingen<Leerling>({
				f: this.getFiltersForAdd(this.searchInputAdd$.getValue()),
				col: [['ll_nr_leerling']],
			})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((response) => {
				this.addedLeerlingnummers.set(response.rows.map(({ ll_nr_leerling }) => ll_nr_leerling));
			});
	}

	getFiltersForAdd(searchInput: string): FilterExpression {
		// Toon alleen leerlingen die je nog niet had
		const nieuweLeerlingen = new BasicFilterExpression(['ll_nr_leerling'], this.alleLeerlingnummers.getValue(), 'not in');
		return new CompoundFilterExpression([nieuweLeerlingen, ...this.getSearchFilter(searchInput)]);
	}

	getSearchFilter(searchInput: string) {
		if (searchInput == '') return [];

		// Als we meerdere nummers herkennen in de input, zoek dan alleen op exact nummer. Anders op (een deel van) nummer of naam.
		const nummers = parseSearchInputToNumbers(searchInput);
		if (nummers.length > 1) {
			return [new BasicFilterExpression(['ll_nr_leerling'], nummers, 'in')];
		}

		return [
			new CompoundFilterExpression(
				[
					new BasicFilterExpression(['ll_nr_leerling'], `%${searchInput}%`, 'like'),
					new BasicFilterExpression(['ll_fun_volledige_naam'], `%${searchInput}%`, 'like_unaccent'),
				],
				'or'
			),
		];
	}

	openAddLeerlingenPanel() {
		this.leerlingenToevoegen.set(true);
	}

	addLeerlingen() {
		if (this.addedLeerlingnummers().length === 0) return;

		// Voeg aangevinkte leerlingen toe aan zowel de volledige lijst leerlingen als de aangevinkte. Verwijder eventuele dubbelingen.
		this.alleLeerlingnummers.next([...new Set([...(this.alleLeerlingnummers.getValue() ?? []), ...this.addedLeerlingnummers()])]);
		this.selectedLeerlingnummers.update((huidigeSelectie) => [...new Set([...huidigeSelectie, ...this.addedLeerlingnummers()])]);
		this.addedLeerlingnummers.set([]);
		this.leerlingenToevoegen.set(false);
		this.modified.set(true);
	}

	changeSelectedLeerlingnummers(leerlingnummers: number[]) {
		this.selectedLeerlingnummers.set(leerlingnummers);
		this.modified.set(true);
	}

	trackByIx(index: number, _item?: any) {
		return index;
	}
}
