import { Component, OnInit, inject } from '@angular/core';
import { CardListConfig } from '../../shared/dashboard/card-list/card-list-config';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { lastValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DataRow, ExportColumnDef, getGroupAttributes, getInitialAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { FilterName } from '../../services/filter-config';
import { IndicatorNaamPrestatieanalyse, Table, VergelijkGroepPrestatieanalyse } from '@cumlaude/metadata';
import { getLeafA, getPathKey, Level, Path } from '../../services/data-tree';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { CardPaCellComponent, PrestatieanalyseCellData } from './card-pa-cell/card-pa-cell.component';
import { formatNumber, formatPercent } from '@angular/common';
import { concat, dropWhile, flatMap, isFunction, isUndefined, last, memoize, omitBy, reverse, tail, zip } from 'lodash-es';
import { take } from 'rxjs/operators';
import { CardPaHistoryCellComponent, PrestatieanalyseHistoryCellData } from './card-pa-history-cell/card-pa-history-cell.component';
import { PointInfo } from '../../shared/dashboard/linechart-table/linechart/linechart.component';
import { MultiAggregator, noAgg0 } from '../../services/aggregation';
import { lookup } from '../../services/tabulate';
import { ExportType } from '../../services/export.service';
import { getSchooljaarKort } from '@cumlaude/shared-utils';
import { createYAxis } from '../../services/axis';
import { DashboardVariant } from '../../services/weergave-opties';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { CardListComponent } from '../../shared/dashboard/card-list/card-list.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

export interface PrestatieanalyseI extends Attributes {
	pv_fun_vest_brin: string;
	pv_nm_indicator: IndicatorNaamPrestatieanalyse;
	pv_nm_vergelijkgroep: VergelijkGroepPrestatieanalyse | null;
	pv_nm_schooljaar?: string;
	pv_nr_score: number;
	ppv_nr_p25: number;
	ppv_nr_p50: number;
	ppv_nr_p75: number;
}

export interface PrestatieanalyseA extends Attributes {
	minScoreNorm: number;
	maxScoreNorm: number;
	score: number;
	percentiel25: number;
	percentiel50: number;
	percentiel75: number;
}

@Component({
	selector: 'app-prestatieanalyse',
	templateUrl: './prestatieanalyse.component.html',
	styleUrls: ['./prestatieanalyse.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		CardListComponent,
		CardPaCellComponent,
		CardPaHistoryCellComponent,
		FilterBarComponent,
	],
})
export class PrestatieanalyseComponent extends CardListConfig<PrestatieanalyseI, Attributes, PrestatieanalyseA> implements OnInit {
	protected readonly dataService = inject(DataService);
	protected readonly router = inject(Router);

	factTable = Table.fac_pv_prestatieanalyse_vso;

	protected exportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.TABEL];

	variant!: DashboardVariant;

	actueelFilters: FilterName[] = ['pv_nm_schooljaar', 'pv_fun_vest_brin'];

	historieFilters: FilterName[] = ['x_pv_schooljaar_historie', 'x_pv_multiselect_schooljaar', 'pv_fun_vest_brin'];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression(['pv_nm_schooljaar'], null, '<>'),
		new CompoundFilterExpression(
			[
				new BasicFilterExpression(['pv_nm_schooljaar'], '2022/2023', '<'),
				new BasicFilterExpression(['pv_nm_indicator'], 'Uitstroom i.r.t. het IQ', '<>'),
			],
			'or'
		),
	];

	filterExpressions?: FilterExpression[];

	groups: AttrPath[] = [['pv_fun_vest_brin']];

	actueelSubgroups: AttrPath[] = [['pv_nm_indicator'], ['pv_nm_vergelijkgroep']];

	historieSubgroups: AttrPath[] = [['pv_nm_indicator'], ['pv_nm_schooljaar'], ['pv_nm_vergelijkgroep']];

	protected override singleAggregators = {
		score: noAgg0('pv_nr_score'),
		percentiel25: noAgg0('ppv_nr_p25'),
		percentiel50: noAgg0('ppv_nr_p50'),
		percentiel75: noAgg0('ppv_nr_p75'),
	};

	protected override multiAggregators: MultiAggregator<keyof PrestatieanalyseA, PrestatieanalyseI, PrestatieanalyseA, number>[] = [
		{
			attribute: 'minScoreNorm',
			init: ({ pv_nr_score, ppv_nr_p25 }) => Math.min(pv_nr_score ?? Infinity, ppv_nr_p25 ?? Infinity),
			combine: (as) => Math.min(...as.map((a) => a.minScoreNorm)),
		},
		{
			attribute: 'maxScoreNorm',
			init: ({ pv_nr_score, ppv_nr_p75 }) => Math.max(pv_nr_score ?? -Infinity, ppv_nr_p75 ?? -Infinity),
			combine: (as) => Math.max(...as.map((a) => a.maxScoreNorm)),
		},
	];

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe('variant').subscribe((variant) => (this.variant = variant)));
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getPrestatieanalyseVsoData(options);
	}

	getRecords(
		path: Path<PrestatieanalyseA, number[]>,
		context: DashboardContext<PrestatieanalyseI, PrestatieanalyseA, PrestatieanalyseComponent>,
		variant: DashboardVariant
	): Path<PrestatieanalyseA, number[]>[] {
		if (variant === DashboardVariant.ACTUEEL) {
			return joinRecords(path, <Level<PrestatieanalyseA, number[]>>context.orderRoot);
		}

		const vestigingLvl = last(path)!;
		return vestigingLvl.c.map((indicatorLvl: Level<PrestatieanalyseA, number[]>) => [...path, indicatorLvl]);
	}

	makeActueelCell(
		path: Path<PrestatieanalyseA, number[]>,
		context: DashboardContext<PrestatieanalyseI, PrestatieanalyseA, PrestatieanalyseComponent>
	): PrestatieanalyseCellData {
		const { subgroupNames, measureNames } = context;
		const attrs = getInitialAttributes<PrestatieanalyseI>(subgroupNames, measureNames, path);
		const { ppv_nr_p25, ppv_nr_p75 } = attrs;
		const tooltipItems = getTooltipItems(attrs, false);
		const is_onvoldoende = isOnvoldoende(attrs);
		const p25_p75: [number, number] | null = ppv_nr_p25 === null || ppv_nr_p75 === null ? null : [ppv_nr_p25, ppv_nr_p75];
		return { ...attrs, p25_p75, tooltip: tooltipItems, is_onvoldoende };
	}

	makeHistorieCell = memoize(
		(p: Path<PrestatieanalyseA, number[]>) =>
			memoize((c: DashboardContext<PrestatieanalyseI, PrestatieanalyseA, PrestatieanalyseComponent>) => _makeHistorieCell(p, c)),
		(p) => getPathKey(p)
	);

	onClick(path: Path<PrestatieanalyseA, number[]>, context: DashboardContext<PrestatieanalyseI, PrestatieanalyseA, PrestatieanalyseComponent>) {
		// gebruik het path op het diepste niveau, anders werkt getInitialAttributes niet goed
		const deepPath = last(path)!.r[0];
		const { pv_fun_vest_brin } = getGroupAttributes(context, deepPath);
		const { subgroupNames, measureNames } = context;
		const { pv_nm_indicator } = getInitialAttributes<PrestatieanalyseI>(subgroupNames, measureNames, deepPath);
		const dashboard = this.getDashboard(pv_nm_indicator);
		lastValueFrom(this.filterService.getFilterState('pv_nm_schooljaar').pipe(take(1)))
			.catch((err) => (err.name == 'EmptyError' ? Promise.resolve(undefined) : Promise.reject(err)))
			.then((schooljaar) => {
				let params = {};

				if (pv_fun_vest_brin)
					Object.assign(params, this.filterService.encodeFilterValue('ds_fk_br_vest_van.br_co_brin', pv_fun_vest_brin.split(' (')[0]));

				if (schooljaar) Object.assign(params, this.filterService.encodeFilterValue('x_prestatieanalyse_ds_schooljaar', schooljaar));

				this.urlService.redirect([dashboard], params);
			});
	}

	trackByPathKey(index: number, path: Path<PrestatieanalyseA, number[]>) {
		return getPathKey(path);
	}

	private getDashboard(indicator: IndicatorNaamPrestatieanalyse): string {
		switch (indicator) {
			case IndicatorNaamPrestatieanalyse.UITSTROOM_I_R_T_HET_IQ:
				return 'prestatieanalyse/uitstroom-iq';
			case IndicatorNaamPrestatieanalyse.EINDUITSTROOM:
				return 'prestatieanalyse/einduitstroom';
			case IndicatorNaamPrestatieanalyse.TUSSENTIJDSE_UITSTROOM:
				return 'prestatieanalyse/tussentijdse-uitstroom';
		}
	}

	override getExportTable(context: DashboardContext<PrestatieanalyseI, PrestatieanalyseA, PrestatieanalyseComponent>, options: ExportDataOptions) {
		const VERGELIJKGROEPEN = {
			[VergelijkGroepPrestatieanalyse.GROEP_VA_80]: '>80% IQ<70',
			[VergelijkGroepPrestatieanalyse.GROEP_TM_80]: '≤80% IQ<70',
		};

		const type = (row: DataRow<PrestatieanalyseA>) => {
			const indicator = row._path[2].k;
			if (indicator === 'Uitstroom i.r.t. het IQ') return 'number';
			else return 'percentage';
		};

		const format = (row: DataRow<PrestatieanalyseA>) => {
			const indicator = row._path[2].k;
			if (indicator === 'Uitstroom i.r.t. het IQ') return '1.2-2';
			else return '1.0-0';
		};

		// Het path dat getValue meekrijgt, bevat [root, vestiging, indicator, (schooljaar indien historie), vergelijkgroep]
		const columns = concat<ExportColumnDef<PrestatieanalyseA> & { getValue: (path: Path<PrestatieanalyseA, number[]>) => any }>(
			[{ name: 'Vestiging', type: 'string', getValue: (path) => path[1].k }],
			this.variant === DashboardVariant.HISTORIE ? [{ name: 'Schooljaar', type: 'string', getValue: (path) => path[3].k }] : [],
			[
				{ name: 'Indicator', type: 'string', getValue: (path) => path[2].k },
				{ name: 'Vergelijkgroep', type: 'string', getValue: (path) => VERGELIJKGROEPEN[<VergelijkGroepPrestatieanalyse>last(path)!.k] },
				{ name: 'Score', type, format, getValue: (path) => getLeafA(path).score },
				{ name: 'Percentiel 25', type, format, getValue: (path) => getLeafA(path).percentiel25 },
				{ name: 'Percentiel 50', type, format, getValue: (path) => getLeafA(path).percentiel50 },
				{ name: 'Percentiel 75', type, format, getValue: (path) => getLeafA(path).percentiel75 },
			]
		);
		const rows = flatMap(
			context.dataRoot!.r.map((path) =>
				last(path)!.xr!.map((path) =>
					columns.map((col, ix) => {
						const format = isFunction(col.format) ? col.format({ _id: ix.toString(), _path: path }) : undefined;
						const type = isFunction(col.type) ? col.type({ _id: ix.toString(), _path: path }) : undefined;

						let value = col.getValue(path);
						if (type === 'percentage') value = value / 100;

						if (format || type) {
							return omitBy({ value, format, type }, isUndefined);
						}
						return value;
					})
				)
			)
		);
		return { data: [{ columns, rows }], options };
	}
}

function _makeHistorieCell(
	path: Path<PrestatieanalyseA, number[]>,
	context: DashboardContext<PrestatieanalyseI, PrestatieanalyseA, PrestatieanalyseComponent>
): PrestatieanalyseHistoryCellData {
	const { subgroupNames, measureNames } = context;
	const pv_nm_indicator = <IndicatorNaamPrestatieanalyse>last(path)!.k;
	const scoreData: { label: string; data: PointInfo[]; tooltip: TooltipElement[] }[] = [];
	const areas: PointInfo[][] = [];

	const recs = joinRecords(
		path,
		(<Level<PrestatieanalyseA, number[]>>context.orderRoot).c.find((indicatorLvl) => indicatorLvl.k === last(path)!.k)!
	);
	const lastRecIx = recs.length - 1;
	let is_onvoldoende = false;

	recs.forEach((rec, recIx) => {
		const attrs = getInitialAttributes<PrestatieanalyseI>(subgroupNames, measureNames, rec);
		const { pv_nm_schooljaar, pv_nr_score, ppv_nr_p25, ppv_nr_p50, ppv_nr_p75 } = attrs;
		const data = pv_nr_score === null ? [] : [{ lineClass: 'pv_nr_score', qty: pv_nr_score }];
		const label = getSchooljaarKort(pv_nm_schooljaar!);
		const tooltip = pv_nr_score === null ? [] : getTooltipItems(attrs, true);
		scoreData.push({ label, data, tooltip });
		const percentielData =
			ppv_nr_p25 === null || ppv_nr_p75 === null || ppv_nr_p50 === null
				? []
				: [
						{ lineClass: 'p25-area', qty: ppv_nr_p25 },
						{ lineClass: 'p50-area', qty: ppv_nr_p50 },
						{ lineClass: 'p75-area', qty: ppv_nr_p75 },
					];
		areas.push(percentielData);
		if (recIx === lastRecIx) is_onvoldoende = isOnvoldoende(attrs);
	});
	const minMax = getMinMax(pv_nm_indicator, path);
	const yAxis = createYAxis(minMax);
	const linechartProps = {
		data: [{ label: '', data: scoreData }],
		areas,
		yAxis,
		lineNames: {},
	};
	return { pv_nm_indicator, is_onvoldoende, linechartProps };
}

function getTooltipItems(attrs: PrestatieanalyseI, reversed: boolean): TooltipElement[] {
	const { pv_nr_score, ppv_nr_p25, ppv_nr_p50, ppv_nr_p75 } = attrs;
	const formatter =
		attrs['pv_nm_indicator'] === IndicatorNaamPrestatieanalyse.UITSTROOM_I_R_T_HET_IQ
			? (x: number) => formatNumber(x, 'nl_NL', '1.1-1')
			: (x: number) => formatPercent(x / 100, 'nl_NL', '1.2-2');
	const [score_fmt, p25_fmt, p50_fmt, p75_fmt] = [pv_nr_score, ppv_nr_p25, ppv_nr_p50, ppv_nr_p75].map((x) => (x === null ? 'Onb' : formatter(x)));
	const tooltipItems: TooltipElement[] = [{ label: 'Score', value: score_fmt, bold: false }];

	function scoreBetween(lo: number, hi: number) {
		if (pv_nr_score === null) return false;
		return pv_nr_score >= lo && pv_nr_score < hi;
	}

	if (ppv_nr_p25 !== null) {
		const percentielItems: TooltipElement[] = [
			{ label: 'Percentiel 0-25', value: `... - ${p25_fmt}`, bold: scoreBetween(-Infinity, ppv_nr_p25) },
			{ label: 'Percentiel 25-50', value: `${p25_fmt} - ${p50_fmt}`, bold: scoreBetween(ppv_nr_p25, ppv_nr_p50) },
			{ label: 'Percentiel 50-75', value: `${p50_fmt} - ${p75_fmt}`, bold: scoreBetween(ppv_nr_p50, ppv_nr_p75) },
			{ label: 'Percentiel 75-100', value: `${p75_fmt} - ...`, bold: scoreBetween(ppv_nr_p75, Infinity) },
		];

		if (reversed) reverse(percentielItems);

		tooltipItems.push(...percentielItems);

		if (attrs['pv_nm_indicator'] === IndicatorNaamPrestatieanalyse.UITSTROOM_I_R_T_HET_IQ) {
			const groep = attrs['pv_nm_vergelijkgroep'] === VergelijkGroepPrestatieanalyse.GROEP_TM_80 ? '≤80% IQ <70' : '>80% IQ <70';
			tooltipItems.push({ label: 'Vergelijkingsgroep', value: groep, bold: false });
		}
	}

	return tooltipItems;
}

function getMinMax(pv_nm_indicator: IndicatorNaamPrestatieanalyse, path: Path<PrestatieanalyseA, number[]>): [number, number] {
	if (pv_nm_indicator === IndicatorNaamPrestatieanalyse.UITSTROOM_I_R_T_HET_IQ) {
		const max = Math.max(6, last(path)!.a.maxScoreNorm);
		return [0, max];
	}

	return [0, 100];
}

/**
 * Maakt records op basis van orderRoot.
 * Pakt zo mogelijk voor elk pad onder deze node het bijbehorende vervolg onder dataPath.
 * Als dat er niet is, maak dan een pad van dataPath + orderPath.
 *
 * Filter, voor de twee paden die alleen in een andere vergelijkgroep eindigen, er eentje uit:
 * hou die uit de echte data over, en als die er niet is de tm_80 groep.
 */
function joinRecords(dataPath: Path<PrestatieanalyseA, number[]>, orderRoot: Level<PrestatieanalyseA, number[]>) {
	const dataLvl = last(dataPath)!;
	const newRecords = flatMap(orderRoot.r, (orderPath) => {
		const orderPathEnd = tail(dropWhile(orderPath, (lvl) => lvl.k !== orderRoot.k));
		const found = lookup(dataLvl, orderPathEnd);
		if (found) return [found.r[0]];

		if (last(orderPath)!.k === VergelijkGroepPrestatieanalyse.GROEP_VA_80) return [];

		return [fixRecord(dataPath, orderPathEnd)];
	});
	const pairs = <[Path<PrestatieanalyseA, number[]>, Path<PrestatieanalyseA, number[]> | undefined][]>zip(newRecords, tail(newRecords));
	return flatMap(pairs, ([path1, path2]) =>
		path1
			.slice(0, -1)
			.map((lvl) => lvl.k)
			.join(';') ===
		path2
			?.slice(0, -1)
			.map((lvl) => lvl.k)
			.join(';')
			? []
			: [path1]
	);
}

/**
 * Maak een nieuw Path uit dataPath + orderPathEnd.
 * Eigenlijk zouden we ook de attributen van alle Levels van dit nieuwe Path moeten updaten maar dat doen we niet.
 * Het r-attribuut van het laaste Level wordt wel gebruikt (in onClick) dus die passen we aan.
 */
function fixRecord<A, D>(dataPath: Path<A, D>, orderPathEnd: Path<A, D>): Path<A, D> {
	const newRecord = [...dataPath, ...orderPathEnd];
	newRecord[newRecord.length - 1] = { ...newRecord[newRecord.length - 1], r: [newRecord] };
	return newRecord;
}

function isOnvoldoende(attrs: PrestatieanalyseI) {
	const { pv_nr_score, ppv_nr_p25 } = attrs;
	return pv_nr_score !== null && ppv_nr_p25 !== null && pv_nr_score < ppv_nr_p25;
}
