<div class="top-bar-container">
	@if (icon()) {
		<i [class]="icon()" class="icon" (click)="iconClick.emit($event)"></i>
	}
	<h3 class="label">{{ title() }}</h3>
	<i class="icon-exit close-button" (click)="close.emit($event)"></i>
</div>
<div class="content-container">
	<ng-content></ng-content>
</div>
@if (bottomBarTemplate) {
	<div class="bottom-bar-container">
		<ng-container [ngTemplateOutlet]="bottomBarTemplate"></ng-container>
	</div>
}
