import { Injectable, Signal } from '@angular/core';
import { Params, QueryParamsHandling } from '@angular/router';

export interface TopLevelSubMenuItem {
	name: string;
	url: string;
	queryParams?: Params;
	queryParamsHandling?: QueryParamsHandling;
}

export interface TopLevelMenuItem {
	name: string;
	url: string;
	queryParams?: Params;
	queryParamsHandling?: QueryParamsHandling;
	subMenus?: TopLevelSubMenuItem[];
}

export interface TabMenuItem {
	name: string;
	url: string;
	queryParams?: Params;
	queryParamsHandling?: QueryParamsHandling;
}

@Injectable({
	providedIn: 'root',
})
export abstract class AppMenuService {
	abstract topLevelMenu: Signal<TopLevelMenuItem[]>;
	abstract tabMenu: Signal<TabMenuItem[]>;
}
