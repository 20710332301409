import { Component, inject } from '@angular/core';
import { RestService } from '@cumlaude/shared-services';
import { BehaviorSubject, combineLatest, NEVER, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { CheckboxComponent, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { BaseDialogComponent, DialogHeaderComponent } from '@cumlaude/shared-components-dialogs';
import { LicentieRol, RCumLaudeAccount, RRol } from '@cumlaude/service-contract';
import { accountLabelMetAfkorting, getAccountZoekString, includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';
import { LicentieRolNaam } from '../../../beheer/account-screen';

@Component({
	selector: 'app-rol-toekennen-dialog',
	templateUrl: './rol-toekennen-dialog.component.html',
	styleUrls: ['./rol-toekennen-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, InstantSearchBoxComponent, CheckboxComponent, ButtonComponent, AsyncPipe],
})
export class RolToekennenDialogComponent {
	protected readonly data = inject<{
		rol: RRol;
		rolLabel: string;
		licentieRol: LicentieRol;
		vestigingen: string[];
	}>(DIALOG_DATA);
	private readonly restService = inject(RestService);
	protected readonly dialogRef = inject<DialogRef<RCumLaudeAccount[]>>(DialogRef);

	filter$ = new BehaviorSubject('');

	filtered$: Observable<RCumLaudeAccount[]>;

	selection = new Set<string>();

	allAccounts: RCumLaudeAccount[] = [];

	licentieMelding$: Observable<{ tekst: string; warning: boolean }>;

	private selectionUpdated$ = new BehaviorSubject(0);

	constructor() {
		this.filtered$ = this.restService.getAccountsFromFilter({ vestiging: this.data.vestigingen, excludeRol: this.data.rol }).pipe(
			tap((accounts) => {
				this.allAccounts = accounts;
			}),
			switchMap((restAccounts) => this.filter$.pipe(map((zoek) => restAccounts.filter((account) => matchesFilter(account, zoek)))))
		);

		const licentieRol = this.data.licentieRol;
		if (licentieRol) {
			this.licentieMelding$ = combineLatest([this.restService.getLicentieInfo(), this.selectionUpdated$]).pipe(
				map(([info, count]) => ({
					tekst: `${(info[licentieRol]?.inGebruik ?? 0) + count} ${
						info[licentieRol]?.totaal ? `van de ${info[licentieRol]?.totaal}` : ''
					} ${LicentieRolNaam[licentieRol]} in gebruik`,
					warning: (info[licentieRol]?.totaal ?? Number.POSITIVE_INFINITY) < (info[licentieRol]?.inGebruik ?? 0) + count,
				}))
			);
		} else this.licentieMelding$ = NEVER;
	}

	toggle(account: RCumLaudeAccount) {
		if (this.selection.has(account.id!)) this.selection.delete(account.id!);
		else this.selection.add(account.id!);
		this.selectionUpdated$.next(this.selection.size);
	}

	getSelection(): RCumLaudeAccount[] {
		return this.allAccounts.filter((account) => this.selection.has(account.id!));
	}

	getAccountNaam = accountLabelMetAfkorting;
}

function matchesFilter(account: RCumLaudeAccount, zoek: string) {
	return includesIgnoreCaseAndDiacritics(getAccountZoekString(account), zoek);
}
