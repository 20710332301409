import { effect, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { findLast, isUndefined } from 'lodash-es';
import { Location } from '@angular/common';
import { CurrentUrlService } from './current-url.service';

@Injectable({
	providedIn: 'root',
})
export class HistoryService {
	protected readonly router = inject(Router);
	private readonly location = inject(Location);
	readonly urlService = inject(CurrentUrlService);

	history: string[] = [];

	constructor() {
		effect(() => {
			const url = this.urlService.currentUrl();
			if (url) this.history.push(url);
		});
	}

	backToPageNot(urlPart: string) {
		const targetUrl = findLast(this.history, (url) => !url.startsWith(urlPart));
		this.navigate(targetUrl);
	}

	back(defaultUrl = '/') {
		this.history.pop();
		if (this.history.length > 0) {
			this.location.back();
		} else {
			this.navigate(defaultUrl);
		}
	}

	private navigate(targetUrl: string | undefined) {
		if (isUndefined(targetUrl)) return;

		this.router.navigateByUrl(targetUrl);
	}
}
