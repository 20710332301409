import { computed, inject, Injectable } from '@angular/core';
import { AppMenuService, TabMenuItem, TopLevelMenuItem, TopLevelSubMenuItem } from '@cumlaude/shared-components-menu';
import { Params, QueryParamsHandling, Router } from '@angular/router';
import { AutorisatieService } from './autorisatie.service';
import { QueryParamStateService } from './query-param-state.service';
import { CurrentUrlService } from '@cumlaude/shared-services';
import { UrlService } from './url.service';
import { CumLaudeRoute, CumLaudeRouteData } from '../app.routes';

interface MenuItemDef {
	name: string;
	url: string;
	top: boolean;
	base: string;
	startUrl?: string;
	weergaveOpties?: string[];
	keepGroepering: boolean;
	subMenus?: MenuItemDef[];
}

interface TopLevelMenu {
	name: string;
	menus: TopLevelMenuItem[];
}

interface TabMenu {
	menus: TabMenuItem[];
}

@Injectable({
	providedIn: 'root',
})
export class UserMenuService extends AppMenuService {
	readonly router = inject(Router);
	readonly autorisatieService = inject(AutorisatieService);
	readonly qp = inject(QueryParamStateService);
	readonly currentUrlService = inject(CurrentUrlService);
	readonly urlService = inject(UrlService);

	menus = computed(() => this.findMenusInRoutes(this.router.config, true));

	authorisedMenus = computed(() => {
		//Dependencies van onderliggende autorisatie checks
		this.autorisatieService.vo();
		this.autorisatieService.vso();
		this.autorisatieService.activeRol();

		return this.findAuthorisedMenus(this.menus());
	});

	topLevelMenus = computed(() => this.getTopLevelMenus(this.authorisedMenus()));

	topLevelMenu = computed(() => {
		const currentUrl = this.currentUrlService.currentUrl();
		if (!currentUrl) return <TopLevelMenuItem[]>[];

		const topLevelMenuItems = this.topLevelMenus().find((topLevelMenu) => this.matchesTopLevelMenu(topLevelMenu, currentUrl.substring(1)))?.menus;
		return topLevelMenuItems ?? <TopLevelMenuItem[]>[];
	});

	tabMenus = computed(() => this.getTabMenus(this.authorisedMenus()));

	tabMenu = computed(() => {
		const currentUrl = this.currentUrlService.currentUrl();
		if (!currentUrl) return <TabMenuItem[]>[];

		const tabMenuItems = this.tabMenus().find((tabMenu) => this.matchesTabMenu(tabMenu, currentUrl.substring(1)))?.menus;
		return tabMenuItems ?? <TabMenuItem[]>[];
	});

	private findMenusInRoutes(routes: CumLaudeRoute[], root: boolean): MenuItemDef[] {
		const menus: MenuItemDef[] = [];
		for (const route of routes) {
			if (root) {
				const rootMenus = this.findMenusInRootRoutes(route);
				if (rootMenus) menus.push(...rootMenus);
			} else {
				const normalMenus = this.findMenusInNormalRoutes(route);
				if (normalMenus) menus.push(...normalMenus);
			}
		}
		return menus;
	}

	private findMenusInNormalRoutes(route: CumLaudeRoute): MenuItemDef[] | undefined {
		const children = route.children;
		let subMenus;
		if (children) subMenus = this.findMenusInRoutes(children, false);
		if (subMenus && subMenus.length === 0) subMenus = undefined;

		const data = route.data;
		if (!data) return;

		const path = route.path;
		if (!path) return;

		if (data.root && subMenus) return subMenus.map((menu) => ({ ...menu, startUrl: path }));

		const name = data.tab ?? data.name;
		if (!name) return;

		return [
			{
				name: name,
				url: path,
				top: data.top ?? data.base !== undefined,
				base: data.base ?? name,
				weergaveOpties: this.getWeergaveOpties(data),
				keepGroepering: data.keepGroepering ?? false,
				subMenus: subMenus,
			},
		];
	}

	private findMenusInRootRoutes(route: CumLaudeRoute): MenuItemDef[] | undefined {
		const children = route.children;
		if (!children) return;

		const subMenus = children ? this.findMenusInRoutes(children, false) : undefined;
		const data = route.data;
		if (!data && subMenus && subMenus.length > 0) return subMenus;
		else return this.findMenusInNormalRoutes(route);
	}

	private findAuthorisedMenus(originalMenus: MenuItemDef[], parentUrl?: string[]): MenuItemDef[] {
		const menus: MenuItemDef[] = [];
		for (const menu of originalMenus) {
			const originalSubMenus = menu.subMenus;
			const url = this.getUrl(menu, parentUrl);
			if (originalSubMenus) {
				const subMenus = this.findAuthorisedMenus(originalSubMenus, url);
				if (subMenus.length > 0) {
					menus.push({
						...menu,
						subMenus: subMenus,
					});
				}
			} else {
				const urlAllowed = this.autorisatieService.isUrlAllowed(`/${url.join('/')}`);
				if (urlAllowed) {
					menus.push(menu);
				}
			}
		}
		return menus;
	}

	private getTopLevelMenus(authorisedMenus: MenuItemDef[]): TopLevelMenu[] {
		const topLevelMenus: TopLevelMenu[] = [];
		for (const menu of authorisedMenus) {
			let topLevelMenu = topLevelMenus.find((topLevelMenu) => topLevelMenu.name === menu.base);
			if (!topLevelMenu) {
				topLevelMenu = {
					name: menu.base,
					menus: [],
				};
				topLevelMenus.push(topLevelMenu);
			}
			let topLevelSubMenus = this.getTopLevelSubMenus(menu);

			let url = [menu.url];
			if (menu.startUrl) url = [menu.startUrl, ...url];

			let queryParams;
			if (topLevelSubMenus) queryParams = topLevelSubMenus[0].queryParams;
			else {
				const subMenus = menu.subMenus;
				queryParams = subMenus ? this.matchQueryParams(subMenus[0].weergaveOpties) : undefined;
			}

			topLevelMenu.menus.push({
				name: menu.name,
				url: `/${url.join('/')}`,
				queryParams: queryParams,
				queryParamsHandling: queryParams ? 'merge' : undefined,
				subMenus: topLevelSubMenus,
			});
		}
		return topLevelMenus;
	}

	private getTopLevelSubMenus(menu: MenuItemDef): TopLevelSubMenuItem[] | undefined {
		const subMenus = menu.subMenus;
		if (!subMenus) return;

		let topLevelSubMenus = subMenus
			.filter((subMenu) => subMenu.top)
			.map((subMenu) => {
				const subSubMenus = subMenu.subMenus;
				const queryParams = subSubMenus ? this.matchQueryParams(subSubMenus[0].weergaveOpties) : undefined;
				return {
					name: subMenu.name,
					url: `/${[...this.getUrl(menu), subMenu.url].join('/')}`,
					queryParams: queryParams,
					queryParamsHandling: queryParams ? <QueryParamsHandling>'merge' : undefined,
				};
			});

		return topLevelSubMenus.length > 0 ? topLevelSubMenus : undefined;
	}

	private getTabMenus(menuItemDefs: MenuItemDef[], parentUrl?: string[]): TabMenu[] {
		const tabMenus: TabMenu[] = [];
		for (const menuItem of menuItemDefs) {
			const url = this.getUrl(menuItem, parentUrl);
			const subMenus = menuItem.subMenus;
			if (subMenus) {
				const subTop = subMenus.some((subMenus) => subMenus.top);
				if (subTop) {
					tabMenus.push(...this.getTabMenus(subMenus, url));
				} else {
					tabMenus.push({
						menus: this.getTabSubMenus(url, subMenus),
					});
				}
			}
		}
		return tabMenus;
	}

	private getTabSubMenus(url: string[], subMenus: MenuItemDef[]): TabMenuItem[] {
		return subMenus.map((subMenu) => {
			const queryParams = this.matchQueryParams(this.getTabMenuQueryParams(subMenu.weergaveOpties, subMenu.keepGroepering));
			return {
				name: subMenu.name,
				url: `/${[...url, subMenu.url].join('/')}`,
				queryParams: queryParams,
				queryParamsHandling: queryParams ? <QueryParamsHandling>'merge' : undefined,
			};
		});
	}

	private getUrl(menu: MenuItemDef, parentUrl?: string[]): string[] {
		let url = [menu.url];
		if (parentUrl) url = [...parentUrl, ...url];
		else if (menu.startUrl) url = [menu.startUrl, ...url];
		return url;
	}

	private matchesTopLevelMenu(topLevelMenu: TopLevelMenu, currentUrl: string): boolean {
		const matchingUrl = this.urlService.getDashboard(currentUrl).split('/')[0];
		return topLevelMenu.menus.some((menu) => menu.url.substring(1).split('/')[0] === matchingUrl);
	}

	private matchesTabMenu(tabMenu: TabMenu, currentUrl: string): boolean {
		const matchingUrl = this.urlService.getDashboard(currentUrl);
		return tabMenu.menus.some((menu) => {
			const url = menu.url.substring(1);
			return url === matchingUrl || matchingUrl.startsWith(url);
		});
	}

	//TODO CL-7183 dashboard specifieke filters niet meer weghalen via menu
	removeFilters = [
		'ds_nm_bbs_uitzondering_van',
		'ds_nm_obs_uitzondering_van',
		'ds_nm_op_uitzondering_van',
		'ds_nm_plaatsing_uitzondering',
		'ds_nm_prestatieanalyse_vso_uitzondering',
		'ds_nm_einduitstroom_vso_uitzondering',
		'ds_nm_tussentijdse_uitstroom_vso_uitzondering',
		'ds_fun_uitstroom_iq_status',
	];

	private matchQueryParams(weergaveOpties?: string[]): Params {
		const filteredQueryParams = this.qp.getNotThisDashboardQueryParamOptions(weergaveOpties);

		return this.qp.getQueryParametersNulled([...filteredQueryParams, ...this.removeFilters]);
	}

	private getWeergaveOpties(data: CumLaudeRouteData): string[] {
		return [...(data.fixedWeergaveOpties ?? []), ...(data.flexibelWeergaveOpties ?? [])];
	}

	private getTabMenuQueryParams(weergaveOpties: string[] | undefined, keepGroepering: boolean) {
		if (!keepGroepering) return weergaveOpties;
		if (!weergaveOpties) return ['g'];

		return [...weergaveOpties, 'g'];
	}
}
