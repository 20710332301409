import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export const phoneQuery = '(max-width: 479px)';
export const tabletQuery = '(min-width: 768px) and (max-width: 1279px)';
export const tabletPortraitQuery = '(min-width: 480px) and (max-width: 767px)';
export const desktopQuery = '(min-width: 1080px)';

@Injectable({
	providedIn: 'root',
})
export class DeviceService {
	private mediaQueryDesktop: MediaQueryList = window.matchMedia(desktopQuery);
	private mediaQueryTabletPortrait: MediaQueryList = window.matchMedia(tabletPortraitQuery);
	private mediaQueryTablet: MediaQueryList = window.matchMedia(tabletQuery);
	private mediaQueryPhone: MediaQueryList = window.matchMedia(phoneQuery);

	public onDeviceChange$: Observable<BreakpointState>;

	constructor() {
		const breakpointObserver = inject(BreakpointObserver);
		this.onDeviceChange$ = breakpointObserver.observe([desktopQuery]).pipe(shareReplay(1));
	}

	public isPhone(): boolean {
		return this.mediaQueryPhone.matches;
	}

	public isTabletPortrait(): boolean {
		return this.mediaQueryTabletPortrait.matches;
	}

	public isTablet(): boolean {
		return this.mediaQueryTablet.matches;
	}

	public isDesktop(): boolean {
		return this.mediaQueryDesktop.matches;
	}

	public isPhoneOrTabletPortrait(): boolean {
		return this.isPhone() || this.isTabletPortrait();
	}

	public isPhoneOrTablet(): boolean {
		return this.isPhone() || this.isTabletPortrait() || this.isTablet();
	}

	public isTabletOrDesktop(): boolean {
		return this.isTabletPortrait() || this.isTablet() || this.isDesktop();
	}

	public isTabletPortraitOrTablet(): boolean {
		return this.isTabletPortrait() || this.isTablet();
	}

	public isPhoneOnly(): boolean {
		return this.isPhone() && !this.isTabletPortrait() && !this.isTablet() && !this.isDesktop();
	}

	public isTouch(): boolean {
		return this.isPhone() || this.isTabletPortraitOrTablet();
	}
}
