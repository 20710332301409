<app-base-sidebar
	[title]="getTitle()"
	[bottomBarTemplate]="bottomBar"
	[icon]="leerlingenToevoegen() ? 'svg-chevron-left' : undefined"
	(close)="sidebarRef.requestClose()"
	(iconClick)="leerlingenToevoegen.set(false)"
>
	@if (!leerlingenToevoegen()) {
		<div class="content" (keyup.esc)="sidebarRef.requestClose()" (keyup.enter)="saveAndClose()">
			<div class="row">
				<div class="row-title">
					<span>Naam selectie </span>
					<span class="required-icon">*</span>
				</div>
				<input
					#inp
					class="name-input"
					required="true"
					type="text"
					(input)="naam.set(inp.value)"
					[value]="naam()"
					placeholder="Typ een naam"
					autofocus
				/>
			</div>
			<div class="row selectie">
				<div class="row-title">
					<span>Geselecteerd ({{ selectedLeerlingnummers().length }})</span>
				</div>
				@if ((alleLeerlingnummers | async)?.length) {
					<div class="row">
						<app-instant-search-box
							(fire)="searchInput$.next($event)"
							[value]="searchInput$.getValue()"
							placeholder="Zoek op naam of leerlingnummer(s)"
						></app-instant-search-box>
					</div>
				}
				<app-button buttonType="text" icon="svg-plus-button" (clickit)="openAddLeerlingenPanel()">Leerlingen toevoegen</app-button>
				<app-leerling-checklist
					[elementen]="elementen()"
					(nextPage)="fetch$.emit($event)"
					[selectie]="selectedLeerlingnummers()"
					(selectieChange)="changeSelectedLeerlingnummers($event)"
					[emptyState]="(searchInput$ | async)?.length ? 'Geen resultaten gevonden' : undefined"
				></app-leerling-checklist>
			</div>
		</div>
	} @else {
		<div class="content" (keyup.esc)="leerlingenToevoegen.set(false)" (keyup.enter)="addLeerlingen()">
			<div class="row">
				<app-instant-search-box
					#searchInputAdd
					(fire)="searchInputAdd$.next($event)"
					[value]="searchInputAdd$.getValue()"
					placeholder="Zoek op naam of leerlingnummer(s)"
					autofocus
				></app-instant-search-box>
			</div>
			<div class="row selectie">
				<app-leerling-checklist
					[elementen]="elementenAdd()"
					(nextPage)="fetchAdd$.emit($event)"
					[(selectie)]="addedLeerlingnummers"
				></app-leerling-checklist>
			</div>
		</div>
	}
	<ng-template #bottomBar>
		@if (!leerlingenToevoegen()) {
			<app-button buttonType="secondary" (clickit)="sidebarRef.requestClose()">Annuleren</app-button>
			<app-button [enabled]="isNotEmpty()" (clickit)="saveAndClose()">Opslaan</app-button>
		} @else {
			@if (addedLeerlingnummers().length > 0) {
				<app-button class="left" buttonType="text" (clickit)="addedLeerlingnummers.set([])">Deselecteer alle</app-button>
			} @else {
				@if (elementenAdd().length > 0 && searchInputAdd$.getValue()) {
					<app-button class="left" buttonType="text" (clickit)="selectAllAdd()">Selecteer alle</app-button>
				}
			}
			<app-button buttonType="secondary" (clickit)="leerlingenToevoegen.set(false)">Annuleren</app-button>
			<app-button [enabled]="addedLeerlingnummers().length > 0" (clickit)="addLeerlingen()">
				Toevoegen ({{ addedLeerlingnummers().length }})
			</app-button>
		}
	</ng-template>
</app-base-sidebar>

<ng-template #confirmCancelDialog let-selectie>
	<app-confirm-dialog caption="Wijzigingen zijn niet opgeslagen" action="Ja, stoppen" secondaryAction="Annuleren">
		Weet je zeker dat je wilt stoppen met het bewerken van de leerlingselectie?
	</app-confirm-dialog>
</ng-template>
