import { Component, signal, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { DialogRef } from '@angular/cdk/dialog';
import { VersionService } from '../../../services/version.service';
import { AsyncPipe, DatePipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { DialogSelectionBarComponent, DialogHeaderComponent, BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { map } from 'rxjs/operators';

enum HelpSelection {
	SUPPORT = 'Support',
	DETAILS = 'Details',
}

@Component({
	selector: 'app-help-dialog',
	templateUrl: './help-dialog.component.html',
	styleUrls: ['./help-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, DialogSelectionBarComponent, ButtonComponent, DatePipe, AsyncPipe],
})
export class HelpDialogComponent {
	protected readonly versionService = inject(VersionService);
	protected readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);
	readonly dialogRef = inject<DialogRef<void>>(DialogRef);

	currentSelection = signal<HelpSelection>(HelpSelection.SUPPORT);
	selections: HelpSelection[] = Object.values(HelpSelection);

	latestVersion$: Observable<string>;
	etlLaatsteSuccesvolleRunDateTime$: Observable<string | undefined>;

	constructor() {
		this.latestVersion$ = this.versionService.latestVersion$;
		this.etlLaatsteSuccesvolleRunDateTime$ = this.versionService.restVersionInfo$.pipe(
			map((restVersionInfo) => restVersionInfo.etlLaatsteSuccesvolleRunDateTime)
		);
	}

	protected readonly HelpSelection = HelpSelection;
}
