import { Component, OnDestroy, OnInit } from '@angular/core';
import { TussentijdseUitstroomVso, UitstroomtypeVso } from '@cumlaude/metadata';
import { Attr } from '../../services/data.service';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { uitstroomVsoActueelFilters, UitstroomVsoComponent } from './uitstroom-vso.component';
import { FilterName } from '../../services/filter-config';
import { VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface TussentijdseUitstroomI extends Attributes {
	ds_nm_tussentijdse_uitstroom_vso: TussentijdseUitstroomVso;
}

@Component({
	selector: 'app-tussentijdse-uitstroom',
	templateUrl: './uitstroom-vso.component.html',
	styleUrls: ['./uitstroom-vso.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FilterBarComponent,
	],
})
export class TussentijdseUitstroomComponent extends UitstroomVsoComponent<TussentijdseUitstroomI> implements OnInit, OnDestroy {
	protected attr: keyof TussentijdseUitstroomI & Attr = 'ds_nm_tussentijdse_uitstroom_vso';
	protected positiefWaarde = TussentijdseUitstroomVso.REGULIER_ONDERWIJS;
	protected className = 'dashboard-tussentijdse-uitstroom';
	protected positiefLabel = 'Regulier';

	override actueelFilters: FilterName[] = [...uitstroomVsoActueelFilters, this.attr];

	protected getUitstroomtype(): UitstroomtypeVso {
		return UitstroomtypeVso.TUSSENTIJDSE_UITSTROOM;
	}

	protected getUitzonderingUrl(): string {
		return '/details/uitzondering/tussentijdse-uitstroom';
	}
}
