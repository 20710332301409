@if (showTerug()) {
	<app-button (clickit)="goBack()" buttonType="text" icon="svg-chevron-left small">Terug</app-button>
}
@if ((currentSelection | async) && showSelection()) {
	<app-filter-label-details-list></app-filter-label-details-list>
}
@if (showSelection() && adHocSelection()) {
	<div class="save-selection-button" (click)="slaSelectieOp()">
		<span class="svg-add-user"></span>
		<span>{{ getSaveSelectionLabel() }}</span>
	</div>
}
