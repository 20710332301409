import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	signal,
	SimpleChanges,
	WritableSignal,
	inject,
} from '@angular/core';
import {
	BasicFilterExpression,
	CompoundFilterExpression,
	DataService,
	FilterDynamicSelectionExpression,
	FilterExpression,
	InFilterExpression,
} from '../../../services/data.service';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { flatMap, intersection, max, min, orderBy, range, reverse, sumBy } from 'lodash-es';
import { ReplaySubject, Subscription } from 'rxjs';
import { DataGraph, DataNode, Edge, EdgePath, getOrCreate, makeGraph } from '../../../services/data-graph';
import { DoorstroomExt, Niveau } from '@cumlaude/metadata';
import { generateCssClassForCohortdetails } from '../../../services/css-generate-metadata';
import { sortLike } from '../../../services/labels';
import { deelVeilig, getBeginjaar, getSchooljaarString } from '@cumlaude/shared-utils';
import { UrlService } from '../../../services/url.service';
import { Eenheid } from '../../../services/weergave-opties';
import { formatPercent, KeyValuePipe } from '@angular/common';
import { Richting } from '../../../dashboards/cohortrendement/cohortrendement.component';
import { isErrorContext, parseErrorResponse } from '../base-dashboard/dashboard-context';
import { UserService } from '../../../services/user.service';
import { DisplayService } from '../../../services/display.service';
import { BugsnagService } from '@cumlaude/bugsnag';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { AutorisatieService } from '../../../services/autorisatie.service';

type Blok = {
	id: string;
	schooljaar: number;
	niveau: string;
	leerjaar: number;
	in: Map<string | null, Pill>; // links pills per idu-in
	out: Map<string | null, Pill>; // rechts pills per idu-uit
	top: number;
	left: number;
	height: number; // hoogte van het blok incl. pills
};

type Pill = {
	blok: Blok;
	idu: string | null | undefined;
	records: Map<string | null, EdgePath<number>[]>; // leerfase van/naar
	top: number;
	left: number;
	className: string;
	blocky: boolean; // externe in/uitstroom heeft een blokkerige vorm
	tooltip?: TooltipElement[];
};

type Lijn = {
	van: Pill;
	naar: Pill;
};

// deze verschillende IDU's worden samengevoegd in 1 pill
const afgewezenOrDoublure = 'Doublure/Afgewezen (extern)';
const afgewezenOrDoublureOptions: (string | null)[] = ['Afgewezen (extern)', 'Doublure (extern)'];

const blokGap = 12;
const niveauGap = 16;
const pillGap = 4;
const marginTop = 16;
const marginBottom = 16;

@Component({
	selector: 'app-cohort-graph',
	templateUrl: './cohort-graph.component.html',
	styleUrls: ['./cohort-graph.component.scss'],
	imports: [TooltipDirective, KeyValuePipe],
})
export class CohortGraphComponent implements OnChanges, OnDestroy {
	private readonly container = inject(ElementRef);
	private readonly urlService = inject(UrlService);
	private readonly dataService = inject(DataService);
	private readonly displayService = inject(DisplayService);

	/**
	 * Filters op doorstroom die de leerlingen van het cohort bepalen.
	 */
	@Input()
	filters?: FilterExpression[];

	/**
	 * Permanent ingestelde filters. Deze worden bepaald door het dashboard en kunnen niet gezien of aangepast worden door de gebruiker.
	 */
	@Input()
	permanentFilters: FilterExpression[] = [];

	@Input()
	richting: Richting = Richting.TOT;

	@Input()
	eenheid: Eenheid = Eenheid.AANTAL;

	selected: WritableSignal<Blok | undefined> = signal(undefined);

	protected filters$ = new ReplaySubject<CompoundFilterExpression>(1);

	protected subscriptions: Subscription[] = [];

	message?: string;

	blokken: { [nodeId: string]: Blok } = {};

	// Matrix waarin de blokken georganiseerd zijn:
	// - buitenste Map zijn de niveaus (verticaal)
	// - binnenste Map zijn de schooljaren (horizontaal)
	// - in elke cell zitten blokken voor de verschillende leerjaren
	niveaus: Map<string, Map<number, Blok[]>> = new Map();

	lijnen: Lijn[] = [];

	schooljaren: number[] = [];

	totaal = 0; // totaal van het geselecteerde cohort

	totalen: Map<number, number> = new Map(); // totaal aantal per schooljaar

	headerFooterHeight = 32;
	schooljaarWidth = 266;

	blokWidth = 140;
	blokHeight = 58;

	pillHeight = 20;
	pillWidth = 35;

	width = 0;
	height = 0;

	startDragX = 0;
	startDragY = 0;

	scrollable = false;

	pillsClickable: boolean = true;

	private scrollCheck$ = new EventEmitter<void>();

	constructor() {
		const userService = inject(UserService);
		const autorisatieService = inject(AutorisatieService);
		const bugsnag = inject(BugsnagService);

		this.subscriptions.push(
			this.filters$
				.pipe(
					switchMap((f) => {
						return this.dataService.getDoorstroomCohortdetailsGraphData({ f }).pipe(
							map((resp) => ({ resp, f })),
							catchError((response) => parseErrorResponse(response, bugsnag))
						);
					})
				)
				.subscribe((result) => {
					if (isErrorContext(result)) {
						this.message = 'Er is een onbekende fout opgetreden.';
					} else {
						this.message = undefined;
						this.makePresentation(makeGraph(result.resp.data), this.getCohortJaar(result.f));

						if (this.schooljaren.length == 0) this.message = 'De huidige selectie levert geen resultaten op.';
					}
				}),
			// het debouncen dient 2 doelen:
			// 1. niet zo veel render loops tijdens het resizen
			// 2. na makePresentation wachten totdat alles daadwerkelijk gerendered is
			this.scrollCheck$.pipe(debounceTime(50)).subscribe(() => this.setScrollable()),
			userService.rolChanged$.subscribe(() => {
				this.pillsClickable = Boolean(autorisatieService.checkUrlForRol('/details/leerling'));
			})
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		// als er alleen maar een weergave-optie (eenheid) veranderd is, hoeft niet de hele graaf opnieuw gebouwd te worden
		if (intersection(Object.keys(changes), ['permanentFilters', 'filters', 'groups', 'subgroups', 'richting']).length == 0) return;

		if (this.filters === undefined) return;

		const filters = new CompoundFilterExpression([...this.permanentFilters, ...this.filters]);
		this.filters$.next(filters);
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.scrollCheck$.emit();
	}

	makePresentation(graph: DataGraph<number>, cohortJaar: number) {
		const { nodes, edges } = graph;
		this.blokken = {};
		this.niveaus = new Map();
		this.lijnen = [];

		for (const node of nodes.values()) {
			const blok = this.maakBlok(node, cohortJaar);
			if (blok) {
				this.blokken[node.id] = blok;
				getOrCreate(
					getOrCreate(this.niveaus, blok.niveau, () => new Map()),
					blok.schooljaar,
					() => []
				).push(blok);
			}
		}

		for (const edge of edges.values()) {
			const child = this.blokken[edge.c.id];
			const parent = this.blokken[edge.p.id];

			for (const [idu, records] of groupRecordsByIdu(edge)) {
				let childPill: Pill;
				let parentPill: Pill;

				if (child) {
					childPill = getOrCreate(child.in, idu, () => this.maakPill(child, idu));
					getOrCreate(childPill.records, leerfaseOrNull(parent), () => []).push(...records);
				}

				if (parent) {
					parentPill = getOrCreate(parent.out, idu, () => this.maakPill(parent, idu));
					getOrCreate(parentPill.records, leerfaseOrNull(child), () => []).push(...records);
				}

				if (child && parent) this.lijnen.push({ van: parentPill!, naar: childPill! });
			}
		}

		this.determineSchooljaren();
		this.determineTotalen(cohortJaar);
		this.updateTooltips(this.totaal);
		const niveauHeights = this.calculateHeights();
		this.calculatePositions(niveauHeights);
		this.scrollCheck$.emit();
	}

	/**
	 * Maakt alleen een blok voor een DataNode die ook echt een leerfase in een schooljaar voorstelt.
	 * De root-, toor- en schooljaar-nodes worden genegeerd.
	 */
	maakBlok(node: DataNode, cohortJaar: number): Blok | undefined {
		const [schooljaarString, leerfase] = node.id.split(':');
		if (leerfase === undefined) return;

		const schooljaar = Number(schooljaarString);
		if ((this.richting == Richting.TOT && schooljaar > cohortJaar) || (this.richting == Richting.VANAF && schooljaar < cohortJaar)) return;

		const { niveau, leerjaar } = splitLeerfase(leerfase);
		if (leerjaar === null || niveau === null) return;

		return { id: node.id, niveau, leerjaar, schooljaar, in: new Map(), out: new Map(), left: 0, top: 0, height: 0 };
	}

	maakPill(blok: Blok, idu: string | null | undefined): Pill {
		const { className, blocky } = generateCssClassForCohortdetails(idu === undefined ? DoorstroomExt.INSTROOM : idu);
		return { blok, idu, records: new Map(), top: 0, left: 0, className, blocky };
	}

	private determineSchooljaren() {
		const eersteSchooljaar = min(Object.values(this.blokken).map((blok) => blok.schooljaar));
		const laatsteSchooljaar = max(Object.values(this.blokken).map((blok) => blok.schooljaar));
		this.schooljaren = eersteSchooljaar === undefined ? [] : range(eersteSchooljaar, laatsteSchooljaar! + 1);
	}

	private determineTotalen(cohortJaar?: number) {
		this.totalen = new Map();
		for (const blok of Object.values(this.blokken)) {
			this.totalen.set(blok.schooljaar, (this.totalen.get(blok.schooljaar) ?? 0) + this.getBlokAantal(blok, this.selected()).filtered);
		}
		if (cohortJaar) this.totaal = this.totalen.get(cohortJaar) ?? 0;
	}

	updateTooltips(totaal: number) {
		for (const blok of Object.values(this.blokken)) {
			for (const [idu, pill] of blok.in) {
				const iduItem = {
					label: 'IDU',
					value: idu === undefined ? 'Instroom' : this.displayService.display(idu),
				};
				pill.tooltip = [iduItem, ...this.getLeerfaseAantallen(pill, totaal)];
			}

			for (const [idu, pill] of blok.out) {
				const iduItem = {
					label: 'IDU',
					value: this.displayService.display(idu),
				};
				pill.tooltip = [iduItem, ...this.getLeerfaseAantallen(pill, totaal)];
			}
		}
	}
	getLeerfaseAantallen(pill: Pill, totaal: number): TooltipElement[] {
		const leerfases = [...pill.records.keys()].map(splitLeerfase);
		const leerfasesOrdered = orderBy(
			leerfases,
			[({ niveau }) => (niveau == null ? Infinity : Object.values(Niveau).indexOf(<Niveau>niveau)), 'leerfase'],
			['desc', 'desc']
		);

		return leerfasesOrdered.map((lf) => {
			const leerfase = joinLeerfase(lf);
			const records = pill.records.get(leerfase);
			const aantal = sumBy(records, (path) => path[2]);
			const perc = formatPercent(deelVeilig(aantal, totaal), 'nl_NL', `1.1-1`);
			return { label: this.displayService.display(leerfase), value: `${formatDecimal(aantal, '1.0-2')} (${perc})` };
		});
	}

	/**
	 * - stelt blok.height in op alle blokken
	 * - berekent per niveau de benodigde hoogte
	 * - stelt op basis daarvan this.height in
	 */
	private calculateHeights() {
		const niveauHeights = new Map<string, number>();

		for (const [niveau, schooljaarMap] of this.niveaus) {
			let niveauHeight = 0;
			for (const schooljaarBlokken of schooljaarMap.values()) {
				let y = 0;
				for (const blok of schooljaarBlokken) {
					// een blok kan hoger worden dan blokHeight als hij veel pills heeft
					blok.height = Math.max(this.blokHeight, Math.max(blok.in.size, blok.out.size) * (this.pillHeight + pillGap) - pillGap);
					y += y == 0 ? 0 : blokGap;
					y += blok.height;
				}
				niveauHeight = Math.max(niveauHeight, y);
			}
			niveauHeights.set(niveau, niveauHeight);
		}

		this.height = sumBy([...niveauHeights.values()]) + (niveauHeights.size - 1) * niveauGap + marginTop + marginBottom;
		return niveauHeights;
	}

	/**
	 * - stelt blok.top, blok.left, pill.top, pill.left in (top obv de eerder berekende heights, left obv de schooljaren)
	 */
	private calculatePositions(niveauHeights: Map<string, number>) {
		this.width = this.schooljaarWidth * this.schooljaren.length;

		const niveauVolgorde = sortLike([...this.niveaus.keys()], Object.values(Niveau));

		// niveaus worden van onder naar boven doorlopen
		let niveauTop = this.height - marginBottom;
		for (const niveau of niveauVolgorde) {
			let x = (this.schooljaarWidth - this.blokWidth) / 2;
			const niveauHeight = niveauHeights.get(niveau)!;
			niveauTop -= niveauHeight;
			const schooljaarMap = this.niveaus.get(niveau)!;
			// schooljaren worden van links naar rechts doorlopen
			for (const schooljaar of this.schooljaren) {
				// leerjaren (blokken) worden van boven naar onder doorlopen
				const blokken = orderBy(schooljaarMap.get(schooljaar) ?? [], ['leerjaar'], ['desc']);
				let y = niveauTop + (niveauHeight - (sumBy(blokken, (blok) => blok.height) + (blokken.length - 1) * blokGap)) / 2;
				for (const blok of blokken) {
					blok.left = x;
					blok.top = y;
					y += blok.height + blokGap;
					this.calculatePillPositions(blok, false);
					this.calculatePillPositions(blok, true);
				}
				x += this.schooljaarWidth;
			}
			niveauTop -= niveauGap;
		}
	}

	private calculatePillPositions(blok: Blok, out: boolean) {
		const pillMap = out ? blok.out : blok.in;

		const iduVolgorde = ['Afstroom', afgewezenOrDoublure, 'Doublure', 'Doorstroom', 'Geslaagd', 'Opstroom'];
		const inVolgorde = [...flatMap(iduVolgorde, (x) => [x, `${x} (extern)`]), null];
		const outVolgorde = [...flatMap(reverse([...iduVolgorde]), (x) => [x, `${x} (extern)`]), null];
		const volgorde = out ? outVolgorde : inVolgorde;

		let pillTop = blok.top + (blok.height - pillMap.size * (this.pillHeight + pillGap) + pillGap) / 2;
		for (const pillKey of sortLike([...pillMap.keys()], volgorde)) {
			const pill = pillMap.get(pillKey)!;
			pill.top = pillTop;
			pill.left = out ? blok.left + this.blokWidth + pillGap : blok.left - this.pillWidth - pillGap;
			pillTop += this.pillHeight + pillGap;
		}
	}

	getViewBox() {
		return `0 0 ${this.width} ${this.height}`;
	}

	getBlokTransform(blok: Blok) {
		return `translate(${blok.left} ${blok.top})`;
	}

	getBlokAantal(blok: Blok, selected?: Blok): { filtered: number; all: number } {
		const pillResults = [...blok.in.values()].map((pill) => this.getPillAantal(pill, selected));
		return { filtered: sumBy(pillResults, 'filtered'), all: sumBy(pillResults, 'all') };
	}

	getBlokInfo(blok: Blok, selected?: Blok): { filtered?: boolean; measure: string } {
		const { filtered, all } = this.getBlokAantal(blok, selected);
		const aantal = filtered > 0 ? filtered : all;
		return { filtered: selected ? filtered > 0 : undefined, measure: this.aantalOfPercentage(aantal) };
	}

	getPillAantal(pill: Pill, selected?: Blok): { filtered: number; all: number } {
		const records = flatMap([...pill.records.values()]);
		return {
			filtered: sumBy(
				records.filter((edgePath) => pathContains(edgePath, selected)),
				(record) => record[2]
			),
			all: sumBy(records, (record) => record[2]),
		};
	}

	getPillInfo(pill: Pill, selected?: Blok): { filtered?: boolean; measure: string } {
		const { filtered, all } = this.getPillAantal(pill, selected);
		const aantal = filtered > 0 ? filtered : all;
		return { filtered: selected ? filtered > 0 : undefined, measure: this.aantalOfPercentage(aantal) };
	}

	isLijnFiltered(lijn: Lijn, selected?: Blok) {
		if (selected === undefined) return true;
		return this.getPillInfo(lijn.van, selected).filtered && this.getPillInfo(lijn.naar, selected).filtered;
	}

	getSchooljaarMeasure(schooljaar: number) {
		const aantal = this.totalen.get(schooljaar) ?? 0;
		return this.aantalOfPercentage(aantal);
	}

	aantalOfPercentage(aantal: number) {
		switch (this.eenheid) {
			case Eenheid.AANTAL:
				return formatDecimal(aantal, '1.0-2');
			case Eenheid.PERCENTAGE:
				return formatPercent(deelVeilig(aantal, this.totaal), 'nl_NL', `1.0-0`);
		}
	}

	getPath(lijn: Lijn) {
		const van = { x: lijn.van.left + this.pillWidth, y: lijn.van.top + this.pillHeight / 2 };
		const naar = { x: lijn.naar.left, y: lijn.naar.top + this.pillHeight / 2 };
		return `M ${van.x} ${van.y} C ${van.x + 20} ${van.y} ${naar.x - 20} ${naar.y} ${naar.x} ${naar.y}`;
	}

	navigateToDetails(pill: Pill, from: boolean) {
		const instroom = !from && pill.idu === undefined;

		let idu: FilterExpression;
		if (pill.idu === afgewezenOrDoublure) {
			idu = new InFilterExpression(['ds_fun_doorstroom_ext'], afgewezenOrDoublureOptions);
		} else {
			idu = new BasicFilterExpression(['ds_fun_doorstroom_ext'], pill.idu);
		}

		const leerfase = new BasicFilterExpression(
			instroom || from ? ['ds_fk_lb_van', 'lb_nm_leerfase'] : ['ds_fk_lb_naar', 'lb_nm_leerfase'],
			leerfaseOrNull(pill.blok)
		);
		const beginSj = pill.blok.schooljaar;
		const schooljaar = new BasicFilterExpression(
			instroom || from ? ['ds_nm_schooljaar_van'] : ['ds_nm_schooljaar_naar'],
			getSchooljaarString(beginSj)
		);
		const relevanteDoorstroom = new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1);

		const instroomFilter = new BasicFilterExpression(['ds_fk_lb_vorig_sj'], null);
		const stapFilters = instroom
			? [
					new FilterDynamicSelectionExpression(
						'cohortdetails',
						new CompoundFilterExpression([instroomFilter, relevanteDoorstroom, schooljaar, leerfase])
					),
				]
			: [new FilterDynamicSelectionExpression('cohortdetails', new CompoundFilterExpression([relevanteDoorstroom, schooljaar, leerfase, idu]))];

		const selectedBlok = this.selected();
		if (selectedBlok) {
			const selectedFilter = new FilterDynamicSelectionExpression(
				'cohortdetails',
				new CompoundFilterExpression([
					new BasicFilterExpression(['ds_nm_schooljaar_van'], getSchooljaarString(selectedBlok.schooljaar)),
					new BasicFilterExpression(['ds_fk_lb_van', 'lb_nm_leerfase'], leerfaseOrNull(selectedBlok)),
					relevanteDoorstroom,
				])
			);
			stapFilters.push(selectedFilter);
		}

		const cohortFilter = new FilterDynamicSelectionExpression(
			'doorstroom',
			new CompoundFilterExpression([...this.permanentFilters, ...this.filters!])
		);

		const { filtered, all } = this.getPillAantal(pill, selectedBlok);
		const size = filtered > 0 ? filtered : all;

		const selection = { detailUrl: '/details/leerling', expression: new CompoundFilterExpression([cohortFilter, ...stapFilters]), size };
		this.urlService.navigateToSelection(selection);
	}

	getCohortJaar(f: CompoundFilterExpression): number {
		const schooljaar = this.dataService.getFilterVal(f, ['ds_nm_schooljaar_van'])!;
		return getBeginjaar(schooljaar);
	}

	startDrag(event: MouseEvent) {
		const containerElt = this.container.nativeElement;
		this.startDragX = containerElt.scrollLeft + event.clientX;
		this.startDragY = containerElt.scrollTop + event.clientY;
	}

	drag(event: MouseEvent) {
		if (event.buttons === 1) {
			const containerElt = this.container.nativeElement;
			containerElt.scrollLeft = this.startDragX - event.clientX;
			containerElt.scrollTop = this.startDragY - event.clientY;
		}
	}

	setScrollable() {
		const elt = this.container.nativeElement;
		this.scrollable = elt.scrollWidth > elt.clientWidth || elt.scrollHeight > elt.clientHeight;
	}

	select($event: Event, blok: Blok | undefined) {
		$event.stopPropagation();
		if (this.selected()?.id === blok?.id) this.selected.set(undefined);
		else this.selected.set(blok);
		this.determineTotalen();
	}
}

/**
 * Een edge van leerfase naar leerfase zal bijna altijd dezelfde Idu hebben.
 * Voor leerfase onbekend kan een edge echter verschillende Idu's hebben; hier moeten dan verschillende pills en lijnen voor worden aangemaakt.
 * De functie doet bijna hetzelfde als lodash groupBy, maar resulteert in een Map ipv een object.
 */
function groupRecordsByIdu<A>(edge: Edge<A>): Map<string | null, EdgePath<A>[]> {
	const ret = new Map<string | null, EdgePath<A>[]>();
	for (const path of edge.r) {
		getOrCreate(ret, getIdu(edge, path), () => []).push(path);
	}

	return ret;
}
function getIdu(edge: Edge<unknown>, path: EdgePath<unknown>): string | null {
	const [edges, subgroups] = path;
	const idu = subgroups[2 * (edges.findIndex((e) => e.id === edge.id) - 2)];
	return afgewezenOrDoublureOptions.includes(idu) ? afgewezenOrDoublure : idu;
}

function leerfaseOrNull(blok: Blok | undefined) {
	if (blok === undefined) return null;
	return `${blok.niveau} ${blok.leerjaar}`;
}

function splitLeerfase(leerfase: string | null): { niveau: string | null; leerjaar: number | null } {
	if (leerfase === null) return { niveau: null, leerjaar: null };

	const ix = leerfase.lastIndexOf(' ');
	const niveau = leerfase.substring(0, ix);
	const leerjaar = Number(leerfase.substring(ix + 1));
	if (isNaN(leerjaar)) return { niveau: null, leerjaar: null };

	return { niveau, leerjaar };
}

function joinLeerfase({ niveau, leerjaar }: { niveau: string | null; leerjaar: number | null }): string | null {
	if (niveau == null || leerjaar == null) return null;
	return `${niveau} ${leerjaar}`;
}

function pathContains(path: EdgePath<number>, selected?: Blok): boolean {
	if (selected === undefined) return true;

	return path[0].some((edge) => edge.p.id === selected.id || edge.c.id === selected.id);
}
