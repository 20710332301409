import { Directive, EventEmitter, OnDestroy, OnInit, signal, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateRangeComponentParams, FilterName, MultiSelectComponentParams, SingleSelectComponentParams } from '../../services/filter-config';
import { FilterComponent, FilterData } from '../../services/data.service';
import { DEFAULT_EMPTY_STATE, Option } from '@cumlaude/shared-components-inputs';
import { FilterService } from '../../services/filter.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual, isNull } from 'lodash-es';
import { SortOrder } from '../../services/sort-order';

const BEZIG_MET_OPHALEN_EMPTY_STATE = 'Bezig met ophalen...';

@Directive()
export abstract class BaseSelectFilterComponent<T, I> implements OnInit, OnDestroy, FilterComponent<I> {
	protected readonly filterService = inject(FilterService);

	protected subscriptions: Subscription[] = [];

	protected options = signal<Option<T>[]>([]);

	filterName!: FilterName;

	filterData?: FilterData;

	inDropdown = false;

	showEmpty: string = BEZIG_MET_OPHALEN_EMPTY_STATE;

	searchInput: string | undefined;

	searchInputChange$ = new EventEmitter<string>();

	/**
	 * Bevat de default waardes van een filter.
	 * Worden gebruikt, indien aanwezig, als er nog geen filter waardes van de backend binnen zijn.
	 */
	defaultValues?: T[];

	/**
	 * Bevat de vaste waardes van een filter.
	 * Indien aanwezig worden deze gebruikt en worden er geen waardes opgehaald bij de backend.
	 */
	fixedValues?: T[];

	/**
	 * Bevat alle waardes van een filter.
	 * Indien vaste waardes van een filter aanwezig zijn, dan identiek daaraan.
	 * Indien data van de backend komt, dan actieve + inactieve waardes van het filter.
	 */
	allValues: T[] = [];

	ngOnInit(): void {
		const { componentParams, valueAllowed } = this.filterService.configs[this.filterName]!;

		if (componentParams) this.processComponentParams(componentParams, valueAllowed);
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	abstract processComponentParams(
		componentParams: SingleSelectComponentParams<any> | MultiSelectComponentParams<any> | DateRangeComponentParams,
		valueAllowed: ((val: any) => boolean) | undefined
	): void;

	processFixedAndDefaultOptions(
		valueAllowed: ((val: any) => boolean) | undefined,
		fixedOptions: T[] | undefined,
		_defaultOptions: T[] | undefined
	) {
		if (fixedOptions) {
			const values = valueAllowed ? fixedOptions.filter((val) => valueAllowed(val)) : fixedOptions;
			this.allValues = values;
			this.fixedValues = values;
			this.showEmpty = DEFAULT_EMPTY_STATE;
		} else {
			this.subscriptions.push(
				this.filterService.options[this.filterName]!.pipe(distinctUntilChanged(isEqual)).subscribe((fv) => {
					this.filterData = fv;
					this.allValues = fv.allValues;
					this.showEmpty = DEFAULT_EMPTY_STATE;

					// Uitgezet ivm verder uitzoeken gedrag bij default Values
					// if (this.options.length == 0)
					this.options.set(this.generateOptions());
				})
			);
		}

		// Uitgezet ivm verder uitzoeken gedrag bij default Values
		// if (!isUndefined(defaultOptions)) {
		// 	this.defaultValues = defaultOptions.filter((val) => (valueAllowed ? valueAllowed(val) : true));
		// 	this.showEmpty = DEFAULT_EMPTY_STATE;
		// }

		this.options.set(this.generateOptions());
	}

	protected generateOptions() {
		const filterConfig = this.filterService.configs[this.filterName]!;
		const showNullValue = filterConfig.showNullValue;

		const values = this.getActiveValues();
		const inactiveValues = this.getInactiveValues();

		const inactiveOptions = inactiveValues.filter((value) => showNullValue || !isNull(value)).map((value) => this.mapValueToOption(value, true));
		const activeOptions = values.filter((value) => showNullValue || !isNull(value)).map((value) => this.mapValueToOption(value, false));

		const options = activeOptions.concat(inactiveOptions);

		const sortOrder = filterConfig.sortOrder;
		if (filterConfig.fixedOrder) {
			options.sort((a, b) => {
				const compare = a.text.localeCompare(b.text);
				return sortOrder === SortOrder.DESC ? -compare : compare;
			});
		}

		return options;
	}

	getInactiveValues(): T[] {
		return this.filterData ? this.filterData.inactiveValues : [];
	}

	getActiveValues(): T[] {
		if (this.fixedValues) return this.fixedValues;
		else if (this.filterData) return this.filterData.activeValues;
		else return this.defaultValues ?? [];
	}

	protected abstract mapValueToOption(val: T, inactive: boolean): Option<T>;
}
