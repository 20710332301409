import { Component } from '@angular/core';
import { AccountScreen, AccountVestigingenRow, getAccountVestigingen } from '../account-screen';
import { createColumnDef, createModel, TableModel } from '../../shared/components/table/table/table.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent, MultiSelectDropdownComponent } from '@cumlaude/shared-components-inputs';
import { LicentieRol, RCumLaudeAccount, RRol, RVestiging } from '@cumlaude/service-contract';

function makeRow(account: RCumLaudeAccount, alleVestigingen: RVestiging[]): AccountVestigingenRow {
	const vestigingen = getAccountVestigingen(account, alleVestigingen);
	return { account, vestigingen };
}

@Component({
	selector: 'app-schoolbreed',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	imports: [InstantSearchBoxComponent, ButtonComponent, MultiSelectDropdownComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class SchoolbreedComponent extends AccountScreen<AccountVestigingenRow> {
	override getRol(): RRol {
		return RRol.SCHOOLBREED;
	}

	override getLicentieRol(): LicentieRol {
		return LicentieRol.SCHOOLBREEDTEAM;
	}

	override getRolLabel() {
		return 'Schoolbreed';
	}

	override getRolDescription() {
		return 'Schoolbreed geautoriseerde gebruikers kunnen alle gegevens van hun eigen vestigingen bekijken.';
	}

	makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<AccountVestigingenRow>> {
		return this.alleVestigingen$.pipe(
			map((vestigingen) => {
				const model = createModel(
					accounts.map((account) => makeRow(account, vestigingen)),
					(row) => row.account.id!
				);
				model.columnDefs = [this.getNaamColumn(), createColumnDef('vestigingen', 'Vestigingen'), this.createRolIntrekkenColumn()];
				return model;
			})
		);
	}
}
