<div class="header">
	<span>Filters</span>
	<i class="icon-exit" (click)="close()"></i>
</div>
@if (nonDefaultFilters$ | async; as nonDefaultFilters) {
	@if (nonDefaultFilters.length > 1) {
		<div class="tabs">
			<a [class.active]="defaultTabSelected" (click)="switchTab()">Voorgesteld</a>
			<a [class.active]="!defaultTabSelected" (click)="switchTab()">
				Alle
				@if (nonDefaultFilters[0][1].length > 0) {
					<div class="circle">
						<span>{{ nonDefaultFilters[0][1].length }}</span>
					</div>
				}
			</a>
		</div>
	}
}
@if (defaultTabSelected) {
	<div class="filterTab">
		@if (visibleDefaultFilters$ | async; as visibleDefaultFilters) {
			<div class="list">
				@for (filterName of visibleDefaultFilters; track filterName) {
					<app-filter-container [filterName]="filterName"></app-filter-container>
				}
			</div>
		}
	</div>
} @else {
	<div class="filterTab">
		@if ({ value: filterFilter$ | async }; as filterFilter) {
			<div class="filterFilter">
				<app-instant-search-box [value]="filterFilter.value!" (fire)="filterFilter$.next($event)" #searchBox></app-instant-search-box>
			</div>
		}
		@if (nonDefaultFilters$ | async; as nonDefaultFilters) {
			<div class="list">
				@for (pair of nonDefaultFilters; track trackByCategory($index, pair)) {
					@if (pair[1].length > 0) {
						<div class="categorie">{{ pair[0] }}</div>
					}
					@for (filterName of pair[1]; track filterName) {
						<app-filter-container [filterName]="filterName"></app-filter-container>
					}
				}
			</div>
		}
	</div>
}
