import { Component, inject } from '@angular/core';
import { RCumLaudeAccount } from '@cumlaude/service-contract';
import { isUndefined, memoize, pull, range, uniqBy } from 'lodash-es';
import { DimKlKlas, Niveau } from '@cumlaude/metadata';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { BeheerMultiDropdownComponent } from '../../../beheer/beheer-multi-dropdown/beheer-multi-dropdown.component';
import { BeheerDropdownComponent } from '../../../beheer/beheer-dropdown/beheer-dropdown.component';
import { BaseDialogComponent, DialogHeaderComponent } from '@cumlaude/shared-components-dialogs';
import { accountLabelMetAfkorting } from '@cumlaude/shared-utils';

export type TeamToekennenData = {
	account?: RCumLaudeAccount;
	alleAccounts: RCumLaudeAccount[];
	alleKlassen: DimKlKlas[];
};

@Component({
	selector: 'app-team-toekennen-dialog',
	templateUrl: './team-toekennen-dialog.component.html',
	styleUrls: ['./team-toekennen-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, BeheerDropdownComponent, BeheerMultiDropdownComponent, ButtonComponent],
})
export class TeamToekennenDialogComponent {
	protected data = inject<TeamToekennenData>(DIALOG_DATA);
	protected dialogRef = inject<DialogRef<RCumLaudeAccount>>(DialogRef);

	selectAccountEnabled: boolean;

	selectedNiveaus: string[];

	selectedLeerjaren: number[];

	selectedKlassen: DimKlKlas[];

	alleNiveaus: Niveau[] = pull(Object.values(Niveau), Niveau.VSO, Niveau.TE_BREED, Niveau.VMBO_G_T_HAVO_VWO);

	alleLeerjaren = range(1, 7);

	constructor() {
		const account = this.data.account;
		if (isUndefined(account)) {
			this.selectAccountEnabled = true;
			this.selectedNiveaus = [];
			this.selectedLeerjaren = [];
			this.selectedKlassen = [];
		} else {
			this.selectAccountEnabled = false;
			this.selectedNiveaus = [...account.niveaus];
			this.selectedLeerjaren = [...account.leerjaren];
			this.selectedKlassen = this.getCurrentKlassen(account);
		}
	}

	getAccountId(account: RCumLaudeAccount) {
		return account.id!;
	}

	getAccountZoekString(account: RCumLaudeAccount) {
		return account.naam + (account.email ?? '') + (account.afkorting ?? '');
	}

	getAccountLabel = accountLabelMetAfkorting;

	getKlasId(klas: DimKlKlas) {
		return klas.kl_nm_klas!;
	}

	getKlasZoekString(klas: DimKlKlas) {
		return klas.kl_nm_klas!;
	}

	getKlasLabel(klas: DimKlKlas) {
		return klas.kl_nm_klas!;
	}

	getCurrentKlassen(account: RCumLaudeAccount): DimKlKlas[] {
		return account.klassen
			.map((klasNaam) => this.data.alleKlassen.find((klas) => klas.kl_nm_klas === klasNaam))
			.filter((x) => x)
			.map((x) => <DimKlKlas>x);
	}

	getAlleKlassen = memoize(this._getAlleKlassen.bind(this), (account) => account?.id);

	_getAlleKlassen(account?: RCumLaudeAccount): DimKlKlas[] {
		if (!account) return uniqBy(this.data.alleKlassen, this.getKlasId.bind(this));

		const currentKlassen = this.getCurrentKlassen(account);
		const vestigingKlassen = this.data.alleKlassen.filter((klas) => account.vestigingen.includes(klas.kl_id_vestiging!));
		return uniqBy([...currentKlassen, ...vestigingKlassen], this.getKlasId.bind(this));
	}

	returnAccount(): RCumLaudeAccount {
		return {
			...this.data.account!,
			niveaus: this.selectedNiveaus,
			leerjaren: this.selectedLeerjaren,
			klassen: this.selectedKlassen.map((klas) => klas.kl_nm_klas!),
		};
	}
}
