import { Component, computed, ElementRef, inject, signal, viewChild } from '@angular/core';
import { BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AttrPath } from '../../../services/data.service';
import { attrLabel } from '../../../services/labels';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { GroeperingSorterenDialogComponent } from '../groepering-sorteren-dialog/groepering-sorteren-dialog.component';
import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { firstValueFrom } from 'rxjs';
import { SortDirection } from '../../../shared/components/table/table/table.component';
import { CategorieFields } from '../../../services/metadata';
import { Categorie, Groepering, GroeperingListComponent } from '../groepering-list/groepering-list.component';
import { AlleGroeperingenDialogComponent, AlleGroeperingenOptions } from '../alle-groeperingen-dialog/alle-groeperingen-dialog.component';

export interface GroeperingOptions {
	activeGroepering?: AttrPath;
	defaultGroeperingen: AttrPath[];
	alleGroeperingen: CategorieFields[];
	userGroups: AttrPath[];
	deletable: boolean;
}

@Component({
	selector: 'app-groepering-dialog',
	templateUrl: './groepering-dialog.component.html',
	styleUrls: ['./groepering-dialog.component.scss'],
	imports: [BaseDialogComponent, GroeperingListComponent],
})
export class GroeperingDialogComponent {
	protected readonly options = inject<GroeperingOptions>(DIALOG_DATA);
	protected readonly dialogRef = inject<DialogRef>(DialogRef);
	protected readonly qp = inject(QueryParamStateService);
	private readonly dialog = inject(Dialog);
	private readonly overlay = inject(Overlay);

	groeperingen = computed<Categorie[]>(() => {
		const { activeGroepering, defaultGroeperingen, userGroups } = this.options;

		const groeperingen = defaultGroeperingen
			.filter((groepering) => {
				const groupString = groepering.join('.');
				if (activeGroepering?.join('.') === groupString) return true;

				return !userGroups.some((userGroep) => userGroep.join('.') === groupString);
			})
			.map((groepering) => {
				return <Groepering>{
					attrPath: groepering,
					naam: attrLabel(groepering),
					current: activeGroepering?.join('.') === groepering.join('.'),
				};
			})
			.sort((groupA, groupB) => groupA.naam.localeCompare(groupB.naam));

		return [{ groeperingen }];
	});

	hasAlleGroeperingen = computed<boolean>(() => this.options.alleGroeperingen.length > 0);

	sorteerElement = viewChild<ElementRef>('sorteren');
	containerElement = viewChild<ElementRef>('container');

	menuSorterenLaatste = signal(false);
	menuAlleGroeperingenLaatste = signal(false);

	protected sorteerDialogRef?: DialogRef<SortDirection, GroeperingSorterenDialogComponent>;
	protected alleGroeperingenDialogRef?: DialogRef<Groepering, AlleGroeperingenDialogComponent>;

	select(groepering: Groepering) {
		if (!groepering.current) this.updateGroeperingen(groepering.attrPath);

		this.dialogRef.close();
	}

	async openAlleGroeperingenDialog() {
		const containerElement = this.containerElement();
		if (!containerElement) return;

		const menuAlleGroeperingenLaatste = this.menuAlleGroeperingenLaatste();
		if (menuAlleGroeperingenLaatste) return;

		this.closeSubMenus();
		this.menuAlleGroeperingenLaatste.set(true);

		let position: ConnectedPosition = {
			originX: 'end',
			originY: 'top',
			overlayX: 'start',
			overlayY: 'top',
			offsetX: 1,
			offsetY: 1,
		};

		const boundingClientRect = containerElement.nativeElement.getBoundingClientRect();
		let height = window.innerHeight - boundingClientRect.top - 32;
		if (height < 250) {
			position = {
				originX: 'end',
				originY: 'top',
				overlayX: 'start',
				overlayY: 'top',
				offsetX: 1,
				offsetY: 1,
			};
			height = boundingClientRect.top - 32;
		}

		this.alleGroeperingenDialogRef = this.dialog.open<Groepering, AlleGroeperingenOptions, AlleGroeperingenDialogComponent>(
			AlleGroeperingenDialogComponent,
			{
				data: {
					activeGroepering: this.options.activeGroepering,
					alleGroeperingen: this.options.alleGroeperingen,
					userGroups: this.options.userGroups,
				},
				maxHeight: `${height}px`,
				hasBackdrop: false,
				positionStrategy: this.overlay
					.position()
					.flexibleConnectedTo(containerElement)
					.withFlexibleDimensions(false)
					.withPositions([position]),
			}
		);

		const groepering = await firstValueFrom(this.alleGroeperingenDialogRef.closed);
		if (!groepering) return;

		this.select(groepering);
	}

	async openSortDialog() {
		const sorteerElement = this.sorteerElement();
		if (!sorteerElement) return;

		const activeGroepering = this.options.activeGroepering;
		if (!activeGroepering) return;

		const menuSorterenLaatste = this.menuSorterenLaatste();
		if (menuSorterenLaatste) return;

		this.closeSubMenus();
		this.menuSorterenLaatste.set(true);

		this.sorteerDialogRef = this.dialog.open<SortDirection, void, GroeperingSorterenDialogComponent>(GroeperingSorterenDialogComponent, {
			hasBackdrop: false,
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(sorteerElement)
				.withFlexibleDimensions(false)
				.withPositions([
					{
						originX: 'end',
						originY: 'top',
						overlayX: 'start',
						overlayY: 'top',
						offsetX: 1,
						offsetY: 0,
					},
				]),
		});

		const sortOrder = await firstValueFrom(this.sorteerDialogRef.closed);
		if (!sortOrder) return;

		const newSort = { direction: sortOrder, active: activeGroepering.join('.') };
		this.qp.dispatch('sortOrder', newSort);
	}

	delete() {
		this.updateGroeperingen();
		this.dialogRef.close();
	}

	private updateGroeperingen(groepering?: AttrPath) {
		let changedGroeperingen = [...this.options.userGroups];
		const activeGroepering = this.options.activeGroepering;
		if (activeGroepering) {
			const ix = this.options.userGroups.findIndex((group) => group.join('.') === activeGroepering.join('.'));
			if (groepering) {
				changedGroeperingen[ix] = groepering;
			} else {
				changedGroeperingen.splice(ix, 1);
			}
		} else if (groepering) {
			changedGroeperingen = [...changedGroeperingen, groepering];
		}

		this.qp.dispatch_g(changedGroeperingen);
	}

	closeSubMenus() {
		this.menuSorterenLaatste.set(false);
		this.menuAlleGroeperingenLaatste.set(false);
		this.sorteerDialogRef?.close();
		this.alleGroeperingenDialogRef?.close();
	}
}
