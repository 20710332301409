<div class="barchart" [style.width]="data.barWidth | px" [class]="className">
	@if (data.axis.areas) {
		<div class="shades">
			@for (area of getCSSAreas(); track area) {
				<div class="shade" [class]="area.className" [style.width]="area.width | px"></div>
			}
		</div>
	}
	@if (data.axis.ticks) {
		<div class="marks">
			@for (tick of getCSSTicks(); track tick) {
				<div class="mark" [style.width]="tick.width | px" [class.line]="tick.showLine ?? true">
					<div class="label">{{ tick.label }}</div>
				</div>
			}
		</div>
	}
	@if (data.axis.min < 0) {
		<div class="filler" [style.width]="getFillerWidth() | px"></div>
	}
	<div [class.axis]="axis"></div>
	<div class="partition" [class.negative]="(data.qty ?? 0) < 0" [style.width]="qtyToPx(data.qty) | px">
		@for (rec of bars; track rec; let ix = $index) {
			<div class="anchor">
				<div
					class="underlay bar"
					[style.width]="getWidthInPixels(data.qty ?? 0, rec.pct) + 'px'"
					[class]="rec.className"
					data-princexml-ignore
					data-html2canvas-ignore
				></div>
				<div class="label" [style.width]="getWidthInPixels(data.qty ?? 0, rec.pct) + 'px'">
					<span>{{ rec.text }}</span>
				</div>
			</div>
			<div
				class="bar"
				[appTooltip]="rec.tooltip"
				[class]="rec.className"
				[style.width]="rec.pct + '%'"
				[routerLink]="urlService.getRouterLink(rec.selection)"
				[queryParams]="urlService.getSelectionQueryParams(rec.selection)"
				[class.clickable]="rec.selection"
			></div>
		}
	</div>
</div>
@if (data.text) {
	<div class="text" [style.left]="-data.barWidth + qtyToPx(data.qty) + 'px'">{{ data.text }}</div>
}
@if (heeftSelecties()) {
	<app-meatball-menu orientation="V" [rounded]="true" [style.left]="getMeatballPosition() | px">
		<div class="menu" [class]="getDashboardClasses()">
			@for (rec of bars; track rec; let ix = $index) {
				@if (rec.size > 0) {
					<div class="menu-item"
						[routerLink]="urlService.getRouterLink(rec.selection)"
						[queryParams]="urlService.getSelectionQueryParams(rec.selection)"
					>
						<div class="legenda" [class]="rec.className"></div>
						<div class="description">{{ rec.description ?? rec.text }}</div>
						@if (rec.selection) {
							<div class="save-selection" (click)="slaSelectieOp(rec, $event)" title="Opslaan als leerlingselectie">
								<div class="selection-size">({{rec.selection!.size }})</div>
								<div class="button svg-add-user"></div>
							</div>
						}
					</div>
				}
			}
		</div>
	</app-meatball-menu>
}
