import { AfterViewInit, Component, OnInit, signal, Signal } from '@angular/core';
import { delay } from 'rxjs/operators';
import { ColumnOptions, createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { getOnderwijsresultatenSchooljaarRangeString } from '@cumlaude/shared-utils';
import { AttrPath, BasicFilterExpression, ExportFilter, FilterExpression, InFilterExpression } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { DoorstroomA, DoorstroomComponent, DoorstroomI, OPTIONAL_2_DECIMALS } from '../doorstroom/doorstroom.component';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { DashboardVariant } from '../../services/weergave-opties';
import { memoize } from 'lodash-es';
import { indicatorOverOpties, SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { OnderwijsresultatenUitsluitenFilterComponent } from '../../onderwijsresultaten-uitsluiten-filter/onderwijsresultaten-uitsluiten-filter.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { attrLabel } from '../../services/labels';
import { att, percOfRow } from '../../services/measures';
import { onderwijsresultatenDsFilterExcludes } from '../../services/exportable';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

const permanentTable: AttrPath[] = [['ds_fun_niveau_bbs_van']];

const permanentGroups: AttrPath[] = [['ds_nr_leerjaar_van']];

// aantal leerlingen voor het betreffende schooljaar voor de betreffende bekostigingings-BRIN moet >= 20 zijn
const uitsluitenFilter = new BasicFilterExpression(['ds_fk_or_bbscl', 'or_q_leerlingen'], 20, '>=');

const permanentFilters: FilterExpression[] = [
	new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1),
	new BasicFilterExpression(['ds_fk_br_vest_van', 'br_is_vso'], 0),
	new InFilterExpression(['ds_nr_leerjaar_van'], [3, 4, 5, 6]),
	new BasicFilterExpression(['ds_fk_lb_van', 'lb_is_pl_voorlopig'], 0),
];

export const bovenbouwsuccesFilters: FilterExpression[] = [...permanentFilters, uitsluitenFilter];

@Component({
	selector: 'app-bovenbouwsuccess',
	templateUrl: './bovenbouwsucces.component.html',
	styleUrls: ['./bovenbouwsucces.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		SchooljaarIndicatorOverComponent,
		OnderwijsresultatenUitsluitenFilterComponent,
		CheckboxComponent,
		BarchartTableComponent,
		FilterBarComponent,
	],
})
export class BovenbouwsuccesComponent extends DoorstroomComponent implements OnInit, AfterViewInit {
	table: AttrPath[] = permanentTable;

	override flexibleMaxGroups = 0;

	actueelGroups: AttrPath[] = permanentGroups;

	override historieGroups: Signal<AttrPath[]> = signal([
		...permanentGroups, //
		['ds_nm_schooljaar_van'],
	]);

	override actueelFilters: FilterName[] = [
		'x_onderwijsresultaten_ds_schooljaar',
		'ds_fk_br_vest_van.br_co_brin',
		'ds_fun_niveau_bbs_van',
		'ds_nr_leerjaar_van',
		'ds_nm_bbs_uitzondering_van',
		'ds_nm_idu',
	];

	override filterExpressions?: FilterExpression[];

	schooljaar?: string;

	aantalJaren!: number;

	uitsluiten!: boolean;

	override ngAfterViewInit() {
		super.ngAfterViewInit();
		this.subscriptions.push(
			this.filterService
				.observe('x_onderwijsresultaten_ds_schooljaar')
				.pipe(delay(0))
				.subscribe(([schooljaar, aantalJaren]) => {
					this.schooljaar = schooljaar;
					this.aantalJaren = aantalJaren;
				})
		);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe('or-uitsluiten').subscribe((uitsluiten) => (this.uitsluiten = uitsluiten)));
	}

	override getAlternatingGroupLevel(): number {
		return this.variant() === DashboardVariant.ACTUEEL ? 1 : 2;
	}

	getSchooljarenCaption() {
		return this.schooljaar ? getOnderwijsresultatenSchooljaarRangeString(this.schooljaar, this.aantalJaren, 'bbs') : '';
	}

	protected override getFixedBeforeGroups(): number {
		return (this.variant() === DashboardVariant.ACTUEEL ? this.actueelGroups.length : this.historieGroups.length) + 1;
	}

	override getRollupLevels() {
		return [...super.getRollupLevels(), this.table.length];
	}

	override createMeasureColumns(context: DashboardContext<DoorstroomI, DoorstroomA, BovenbouwsuccesComponent>): ColumnDef<DataRow<DoorstroomA>>[] {
		return [
			this.createLeerlingColumn(
				'% ' + attrLabel(this.succesConditie.attr),
				percOfRow(this.succesConditie.weging, 'bekend_weging'),
				{ context, dataType: 'percentage', format: '1.2-2' },
				att(this.succesConditie.lln),
				this.getBevorderdSuccesvolFilters(this.succesConditie.attr, '=')
			),
			this.createLeerlingColumn(
				attrLabel(this.succesConditie.attr),
				att(this.succesConditie.weging),
				{ context, format: OPTIONAL_2_DECIMALS },
				att(this.succesConditie.lln),
				this.getBevorderdSuccesvolFilters(this.succesConditie.attr, '=')
			),
			this.createLeerlingColumn(
				'Aantal',
				att('bekend_weging'),
				{ context, format: OPTIONAL_2_DECIMALS },
				att('bekend_lln'),
				this.getBekendFilters()
			),
			createMeasureColumn('Onbekend', att('onbekend_weging'), {
				context,
				format: OPTIONAL_2_DECIMALS,
				clickHandler: (path) => this.handleUitzonderingRedirect(path, context),
			}),
		];
	}

	override enrichTableModel(
		_context: DashboardContext<DoorstroomI, DoorstroomA, BovenbouwsuccesComponent>,
		tableModel: TableModel<DataRow<DoorstroomA>>
	) {
		tableModel.showFooters = true;
	}

	protected override createGroupingColumn(
		group: string,
		ix: number,
		groeperingMenu: boolean,
		options?: Partial<ColumnOptions<DoorstroomI, DoorstroomA>>
	): ColumnDef<DataRow<DoorstroomA>> {
		const col = super.createGroupingColumn(group, ix, groeperingMenu, options);
		if (ix === 1)
			return {
				...col,
				footer: {
					...col.footer,
					getValue: (model: TableModel<DataRow<DoorstroomA>>) => {
						const value = col.footer.getValue(model);
						if (this.aantalJaren === 1) return value;
						return {
							text: value,
							tooltip: 'Leerlingen kunnen in meer dan 1 schooljaar meetellen voor dit totaal.',
							icon: 'svg-info',
						};
					},
				},
			};
		return col;
	}

	override getUitzonderingUrl(): string {
		return '/details/uitzondering/bovenbouwsucces';
	}

	protected override getCountRecordsHeader() {
		return `Aantal`;
	}

	override filterExcludes(): FilterName[] {
		return onderwijsresultatenDsFilterExcludes;
	}

	getPermanentExpressions = memoize(this._getPermanentExpressions, JSON.stringify);

	protected _getPermanentExpressions(uitsluiten: boolean) {
		return uitsluiten ? [...permanentFilters, uitsluitenFilter] : permanentFilters;
	}

	getDisplayOptions(): ExportFilter[] {
		return [
			{ label: 'Indicator over', value: indicatorOverOpties[this.aantalJaren] },
			{ label: 'Meetellende vestigingen', value: this.uitsluiten ? 'Minimaal 20 leerlingen' : 'Alle' },
		];
	}
}
