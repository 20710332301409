import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
	private readonly loadingService = inject(LoadingService);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const loadingType = this.loadingService.getLoadingType(req.url);
		this.loadingService.start(loadingType);
		return next.handle(req).pipe(finalize(() => this.loadingService.stop(loadingType)));
	}
}
