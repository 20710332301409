import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
	BARCHART_COLUMN_NAME,
	ColumnType,
	DataRow,
	DataTreeTableComponent,
	getInitialAttributes,
	getRowLevel,
	TOTAAL_LABEL,
} from '../data-tree-table/data-tree-table';
import { ColumnDef, createColumnDef as createDefaultColumnDef } from '../../components/table/table/table.model';
import { HbarPartitionComponent } from '../../components/hbar/hbar-partition.component';
import { BarInfo } from '../../../services/stacked-bars';
import { BarchartTableConfig } from './barchart-table-config';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { AsyncPipe } from '@angular/common';
import { TableComponent } from '../../components/table/table/table.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
	selector: 'app-barchart-table',
	templateUrl: '../data-tree-table/data-tree-table.html',
	styleUrls: ['../data-tree-table/data-tree-table.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: DataTreeTableComponent, useExisting: BarchartTableComponent }], // zorgt dat dit component als subclass van DataTreeTableComponent wordt herkend voor het ViewChild-attribuut van DataTreeTableConfigConfig
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, TableComponent, AsyncPipe],
})
export class BarchartTableComponent<I extends Attributes, A extends Attributes, C extends BarchartTableConfig<I, A>> extends DataTreeTableComponent<
	I,
	A,
	C
> {
	protected override createDataColumns(context: DashboardContext<I, A, BarchartTableConfig<I, A>>): ColumnDef<DataRow<A>>[] {
		return [this.createBarchartColumn(context)];
	}

	private createBarchartColumn(context: DashboardContext<I, A, BarchartTableConfig<I, A>>): ColumnDef<DataRow<A>> {
		const { groupNames, subgroupNames, measureNames, config } = context;
		const coldef: ColumnDef<DataRow<A>> = createDefaultColumnDef(BARCHART_COLUMN_NAME, '');
		coldef.body.component = HbarPartitionComponent;
		coldef.body.getValue = (row) => {
			const pathss = config.partitionBarData(getRowLevel(row._path), context);
			const stacks: BarInfo[][] = pathss
				.map((paths) =>
					paths
						.map((path) => {
							const attrs = getInitialAttributes<I>(subgroupNames, measureNames, path);
							return config.makeBar(attrs, path, context);
						})
						.filter((bar) => bar.size)
				)
				.filter((stack) => stack.length);

			return {
				marks: [],
				shades: [],
				left: 0,
				qty: config.getBarchartQty(row._path, context),
				axis: config.createXAxis(context),
				stacks,
				barWidth: this.barchartWidth,
			};
		};
		if (groupNames.length === 0 && config.showTotaalFooter()) {
			coldef.footer.getValue = () => TOTAAL_LABEL;
		} else {
			coldef.footer.component = HbarPartitionComponent;
			coldef.footer.getValue = () => {
				return {
					stacks: [],
					axis: { ...config.createXAxis(context), areas: [] },
					barWidth: this.barchartWidth,
				};
			};
			coldef.footer.getClassName = (_) => 'scale';

			coldef.header.component = HbarPartitionComponent;
			coldef.header.getValue = () => {
				return {
					stacks: [],
					axis: { ...config.createXAxis(context), areas: [] },
					barWidth: this.barchartWidth,
				};
			};
			coldef.header.getClassName = (_) => 'scale';
		}

		coldef.type = ColumnType.BARCHART;
		return coldef;
	}
}
