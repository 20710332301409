import { Injectable, inject } from '@angular/core';
import { AuthConfig, NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { Router, UrlTree } from '@angular/router';
import { from, map, of, ReplaySubject } from 'rxjs';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { BugsnagService } from '@cumlaude/bugsnag';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private readonly bugsnag = inject(BugsnagService);
	private readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);
	private readonly oauthService = inject(OAuthService);
	private readonly router = inject(Router);

	loggedIn$ = new ReplaySubject<boolean>(1);

	constructor() {
		this.initOAuthService();
		this.loggedIn$.next(this.isLoggedIn());
	}

	private get authServerURI() {
		return this.envConfig.authUrl;
	}

	private get oAuthEndpoint() {
		return this.authServerURI + '/oauth2';
	}

	public get requestedUrl() {
		return sessionStorage.getItem('requestedUrl');
	}

	public get dashboardUrlTree(): UrlTree {
		return this.router.parseUrl('/dashboard');
	}

	public get oauthUrlTree(): UrlTree {
		return this.router.parseUrl('/oauth');
	}

	private get authConfig() {
		return {
			clientId: this.envConfig.clientId,

			customQueryParams: {
				claims: '{ "id_token": { "name": null} }',
			},
			issuer: this.authServerURI,
			loginUrl: this.oAuthEndpoint + '/authorize',
			tokenEndpoint: this.oAuthEndpoint + '/token',
			logoutUrl: this.oAuthEndpoint + '/logout',
			redirectUri: this.callBackURI,
			scope: 'openid',
			oidc: true,
			postLogoutRedirectUri: this.postLogoutRedirectUri,
			responseType: 'code',
		} as AuthConfig;
	}

	private get callBackURI() {
		return this.protocol() + '//' + this.host() + '/oauth';
	}

	private get postLogoutRedirectUri() {
		return this.protocol() + '//' + this.host();
	}

	public storeRequestedUrl(currentUrl: string) {
		if (currentUrl.indexOf('oauth') === -1) {
			sessionStorage.setItem('requestedUrl', currentUrl);
		}
	}

	public isLoggedIn(): boolean {
		return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
	}

	public getUserDisplayName(): string {
		const claims: any = this.oauthService.getIdentityClaims();
		return claims ? `${claims.name}` : '';
	}

	public clearSessionStorage() {
		const storage = sessionStorage;
		storage.removeItem('access_token');
		storage.removeItem('id_token');
		storage.removeItem('refresh_token');
		storage.removeItem('expires_at');
		storage.removeItem('id_token_claims_obj');
		storage.removeItem('id_token_expires_at');
		storage.removeItem('id_token_stored_at');
		storage.removeItem('access_token_stored_at');
	}

	public logoff() {
		this.oauthService.logOut();
	}

	public host() {
		return window.location.host;
	}

	public protocol() {
		return window.location.protocol;
	}

	public refreshTokenIfNeeded() {
		if (this.oauthService.hasValidAccessToken()) return of(true);

		return from(this.oauthService.refreshToken()).pipe(map(() => true));
	}

	private initOAuthService() {
		this.oauthService.configure(this.authConfig);
		this.oauthService.tokenValidationHandler = new NullValidationHandler();
		this.oauthService.setupAutomaticSilentRefresh();
	}

	public login(oauthParams: {}) {
		return this.oauthService
			.tryLogin({ disableOAuth2StateCheck: true })
			.then(() => {
				if (this.isLoggedIn()) {
					this.loggedIn$.next(true);
					const info = this.oauthService.state ? { toastr: this.oauthService.state } : undefined;
					return this.router.navigateByUrl(this.requestedUrl ?? this.dashboardUrlTree, { info });
				} else {
					this.oauthService.initCodeFlow(undefined, oauthParams);
					return Promise.resolve(true);
				}
			})
			.catch(({ type }) => {
				console.error(`Error during login: ${type}`);

				this.bugsnag.notify({
					name: 'authentication_error',
					message: `Authenticatie error ${type}`,
				});
				return Promise.resolve(false);
			});
	}
}
