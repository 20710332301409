import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Path } from '../../services/data-tree';
import { AttrPath, BasicFilterExpression, DataOptions, DataResponse, DataService, FilterExpression } from '../../services/data.service';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att } from '../../services/measures';
import { FilterName } from '../../services/filter-config';
import { createMeasureColumn, DataRow, getGroupAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Table } from '@cumlaude/metadata';
import { Axis } from '../../services/axis';
import { Sort } from '../../shared/components/table/table/table.component';
import { maxOver, noAgg0 } from '../../services/aggregation';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface CijferkolommenI extends Attributes {
	count_resultaatkolom: number;
}

interface CijferkolommenA extends Attributes {
	count_resultaatkolom: number;
	max_count: number;
}

@Component({
	selector: 'app-cijferkolommen',
	templateUrl: './cijferkolommen.component.html',
	styleUrls: ['./cijferkolommen.component.scss'],
	imports: [DashboardContainerComponent, FilterPanelComponent, DashboardHeaderComponent, BarchartTableComponent, FilterBarComponent],
})
export class CijferkolommenComponent extends BarchartTableConfig<CijferkolommenI, CijferkolommenA> {
	private readonly dataService = inject(DataService);

	selectedGroups: AttrPath[] = [['ck_abb_kolomkop']];

	override fixedBeforeGroups = 1;

	defaultFilters: FilterName[] = ['ck_nm_schooljaar', 'ck_nm_vestiging'];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [new BasicFilterExpression(['ck_is_any_laatste_gemiddelde'], 1)];

	factTable = Table.fac_ck_cijferkolommen;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijferkolommenData(options);
	}

	protected override singleAggregators = {
		count_resultaatkolom: noAgg0('count_resultaatkolom'),
		max_count: maxOver('count_resultaatkolom'),
	};

	override handleRowClick(
		context: DashboardContext<CijferkolommenI, CijferkolommenA, CijferkolommenComponent>,
		path: Path<CijferkolommenA, number[]>
	) {
		const { ck_abb_kolomkop } = getGroupAttributes(context, path);

		let params = this.filterService.encodeFilters([{ name: 'ck_abb_kolomkop', value: ck_abb_kolomkop }], {});
		this.urlService.redirect(['beheer/cijferinstellingen/cijferkolommen-details'], params);
	}

	override createMeasureColumns(): ColumnDef<DataRow<CijferkolommenA>>[] {
		return [
			createMeasureColumn<Attributes, CijferkolommenA>('Aantal kolommen', att<'count_resultaatkolom', CijferkolommenA>('count_resultaatkolom')),
		];
	}

	override createXAxis(context: DashboardContext<CijferkolommenI, CijferkolommenA, CijferkolommenComponent>): Axis {
		return { min: 0, max: context.dataRoot!.a.max_count, ticks: [] };
	}

	override getDefaultSort(): Sort {
		return { active: 'Aantal kolommen', direction: 'desc' };
	}

	override getBarchartQty(path: Path<CijferkolommenA, number[]>) {
		return att<'count_resultaatkolom', CijferkolommenA>('count_resultaatkolom')(path);
	}

	override enrichTableModel(
		_context: DashboardContext<CijferkolommenI, CijferkolommenA, CijferkolommenComponent>,
		tableModel: TableModel<DataRow<CijferkolommenA>>
	) {
		tableModel.showFooters = false;
	}
}
