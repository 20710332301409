import { computed, inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationExtras, Params, QueryParamsHandling, Router } from '@angular/router';
import { isArray, isNil, last } from 'lodash-es';
import { Selection } from '../shared/dashboard/base-dashboard/base-dashboard-config';
import { combineLatestWith, filter, ReplaySubject, Subscription } from 'rxjs';
import { QueryParamStateService } from './query-param-state.service';
import { PageStateService, PsName } from './page-state.service';
import { FilterDynamicSelectionExpression, FilterExpression, flattenFilters } from './data.service';
import { AutorisatieService } from './autorisatie.service';
import { CurrentUrlService } from '@cumlaude/shared-services';
import { CumLaudeRouteData } from '../app.routes';

export interface RouteData {
	title: string;
	from: string;
	path: string;
}

@Injectable({
	providedIn: 'root',
})
export class UrlService implements OnDestroy {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly autorisatieService = inject(AutorisatieService);
	readonly currentUrlService = inject(CurrentUrlService);
	readonly router = inject(Router);

	private readonly subscriptions: Subscription[] = [];
	routeTree$ = new ReplaySubject<string[]>(1);
	routeData$ = new ReplaySubject<RouteData>(1);

	isBeheer = computed(() => {
		const currentUrl = this.currentUrlService.currentUrl();
		return currentUrl?.substring(1).startsWith('beheer') ?? false;
	});

	constructor() {
		const qp = inject(QueryParamStateService);
		const ps = inject(PageStateService);

		this.subscriptions.push(
			this.router.events
				.pipe(filter((event) => event instanceof ActivationEnd))
				.subscribe((event) => this.routeTree$.next(this.getRouteTree(event.snapshot.root))),
			this.routeTree$
				.pipe(combineLatestWith(qp.observe('variant'), ps.observe(PsName.prognose), ps.observe(PsName.subject)))
				.subscribe(([routeTree, variant, prognose, subject]) => this.updateRouteData(routeTree, variant, prognose === 'true', subject))
		);
	}

	private updateRouteData(routeTree: string[], variant?: string, prognose?: boolean, subject?: string) {
		let tree = [...routeTree];
		if (subject) tree = tree.map((value) => value.replace('%subject%', subject));

		let path = tree.join(' - ');
		if (prognose) path = path.replace('%prognose%', 'Prognose');
		else path = path.replace('%prognose%', '').trim();

		if (variant) path += ` - ${variant}`;

		const from = tree[0] === 'Details' ? tree[1] : tree[0];

		const title = tree[tree.length - 1]?.replace('%prognose%', prognose ? 'Prognose' : '').trim();

		const routeData = <RouteData>{
			title,
			from,
			path,
		};
		this.routeData$.next(routeData);
	}

	getDashboard(url: string) {
		const queryStringIndex = url.indexOf('?');
		return queryStringIndex == -1 ? url : url.substring(0, queryStringIndex);
	}

	getFrom(): string {
		const root = this.activatedRoute.snapshot.root;
		const tree = this.getRouteTree(root);
		return tree[0] === 'Details' ? tree[1] : tree[0];
	}

	private getRouteTree(route: ActivatedRouteSnapshot): string[] {
		const firstChild = route.firstChild;
		const childTree = isNil(firstChild) ? [] : this.getRouteTree(firstChild);
		const routeData = route.data as CumLaudeRouteData;
		const routeTree = [routeData?.name];

		const vaardigheid = routeData['bv_nm_vaardigheid'];
		if (vaardigheid) routeTree.push(vaardigheid);

		return [...routeTree, ...childTree].filter((value): value is string => value !== undefined);
	}

	getRouterLink(selection?: Selection): string | undefined {
		if (!selection) return undefined;

		const urlRequested = selection.detailUrl;
		if (!urlRequested) return undefined;

		const urlAllowed = this.autorisatieService.checkUrlForRol(urlRequested);
		if (urlAllowed === true) return urlRequested;
		if (urlAllowed === false) return undefined;
		return urlAllowed.toString();
	}

	navigateToSelection(selection?: Selection) {
		if (!selection) return;

		const { detailUrl } = selection;
		const urlAllowed = this.autorisatieService.checkUrlForRol(detailUrl);
		if (urlAllowed === false) return;
		const urlTree = urlAllowed === true ? this.router.parseUrl(detailUrl) : urlAllowed;
		const queryParams = this.getSelectionQueryParams(selection);

		this.router.navigateByUrl(Object.assign(urlTree, { queryParams, queryParamHandling: 'merge' }));
	}

	getSelectionQueryParams(selection?: Selection) {
		if (!selection) return;

		const expression = selection.expression as FilterDynamicSelectionExpression;
		const from = this.getFrom();
		const schooljaar = this.getSchooljaarFromFilter(expression.sf);

		return { adhoc: JSON.stringify(selection), ...(schooljaar ? { schooljaar } : {}), from };
	}

	private getSchooljaarFromFilter(filter?: FilterExpression | FilterExpression[]): string | undefined {
		if (!filter) return undefined;

		const filters = flattenFilters(filter);
		let schooljaar = last(filters.filter((filter) => filter.attr.join('.').includes('nm_schooljaar_van')));
		if (!schooljaar) schooljaar = last(filters.filter((filter) => filter.attr.join('.').includes('nm_schooljaar')));

		if (!schooljaar) return undefined;

		const value = schooljaar.val;
		return isArray(value) ? last(value) : value;
	}

	redirect(url: string[], queryParams: Params, queryParamsHandling: QueryParamsHandling = 'merge', extras: NavigationExtras = {}) {
		return this.router.navigate(url, { ...extras, queryParams, queryParamsHandling });
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
