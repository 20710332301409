import { Directive, inject } from '@angular/core';
import { FilterName } from '../../services/filter-config';
import { AttrPath, DataOptions, DataResponse, DataService, ExportDataOptions, FilterExpression } from '../../services/data.service';
import { ColumnType, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Observable } from 'rxjs';
import { count_records, percOfTotal } from '../../services/measures';
import { ColumnDef } from '../../shared/components/table/table/table.model';
import { maxOver } from '../../services/aggregation';
import { Path } from '../../services/data-tree';
import { ActivatedRoute } from '@angular/router';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Table } from '@cumlaude/metadata';
import { DRIE_SCHOOLJAREN_EXCL_2019 } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';

interface UitzonderingA extends Attributes {
	max: number;
}

@Directive()
export abstract class UitzonderingComponent extends BarchartTableConfig<Attributes, UitzonderingA> {
	private readonly dataService = inject(DataService);

	protected readonly hideExclusief: number = DRIE_SCHOOLJAREN_EXCL_2019;

	abstract groups: AttrPath[];

	abstract initialFilters: FilterName[];

	abstract permanentFilterExpressions: FilterExpression[];

	filterExpressions?: FilterExpression[];

	overrideDefault?: Partial<{ [name in FilterName]: any }> = { ds_nm_idu: [null], ds_is_prognose: 0 };

	from?: string;

	aantalJaren: number | null = null;

	schooljaar?: string;

	constructor() {
		super();

		const route = inject(ActivatedRoute);
		const { from } = route.snapshot.queryParams;
		this.from = from;
	}

	factTable = Table.fac_ds_doorstroom;

	protected override getFixedBeforeGroups(): number {
		return this.groups.length;
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected override singleAggregators = {
		max: maxOver('count_records'),
	};

	override createMeasureColumns(context: DashboardContext<Attributes, UitzonderingA, UitzonderingComponent>): ColumnDef<DataRow<UitzonderingA>>[] {
		return [this.createLeerlingColumn('Leerlingen', count_records, { context })];
	}

	override makeBar(
		attrs: Attributes,
		path: Path<UitzonderingA, number[]>,
		context: DashboardContext<Attributes, UitzonderingA, UitzonderingComponent>
	): BarInfo {
		return {
			...super.makeBar(attrs, path, context),
			description: 'Leerlingen',
		};
	}

	override getBarchartQty(path: Path<UitzonderingA, number[]>) {
		return 100 * percOfTotal('count_records', 'max')(path);
	}

	protected getSchooljarenCaption(): string | null {
		return null;
	}

	protected getSchooljarenTooltip(): string | null {
		return null;
	}

	override columnOrder: ColumnType[] = [ColumnType.GROUP, ColumnType.MEASURE, ColumnType.BARCHART];
}
