<span class="tab-button svg-chevron-left" [class.visible]="showLeft" (click)="scrollLeft()"></span>
<section #viewport>
	<div>
		@for (menuItem of appMenuService.tabMenu(); track menuItem.url) {
			<a
				[routerLink]="menuItem.url"
				[queryParams]="menuItem.queryParams"
				[queryParamsHandling]="menuItem.queryParamsHandling"
				routerLinkActive="active"
			>
				{{ menuItem.name }}
			</a>
		}
	</div>
</section>
<span class="tab-button svg-chevron-right" [class.visible]="showRight" (click)="scrollRight()"></span>
@if (datasnapUrlService.resultsForCurrentUrl$ | async; as datasnapResults) {
	<span class="snapshot-title" (click)="openHistory(datasnapResults)">{{ snapshotTitle() | async }}</span>
	<span class="tab-button svg-history" [class.visible]="datasnapResults.length" [class.active]="datasnapUrlService.isViewingSnapshot()" (click)="openHistory(datasnapResults)"></span>
}
<span class="tab-button svg-share" [class.visible]="shareVisible" (click)="sharePagina()"></span>
<div class="tab-button spinner">
	@if (showExportSpinner$ | async) {
		<app-css-spinner></app-css-spinner>
	}
</div>
<span class="tab-button svg-export" [class.visible]="exportVisible" [class.hidden]="showExportSpinner$ | async" (click)="exportClicked.emit()"></span>
