import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService, LoadingType } from '../services/loading.service';
import { DataRequestCollector } from '../services/data-request-collector.service';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';

/**
 * Geeft alle URLs van data requests door aan de DataRequestCollector.
 */
@Injectable()
export class DataRequestInterceptor implements HttpInterceptor {
	private readonly loadingService = inject(LoadingService);
	private readonly requestCollector = inject(DataRequestCollector);
	private readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.loadingService.getLoadingType(req.url) === LoadingType.TABLE) {
			this.requestCollector.registerUrl(req.urlWithParams.replace(this.envConfig.dataUrl!, ''));
		}
		return next.handle(req);
	}
}
