import { Component, computed, ElementRef, EventEmitter, HostListener, inject, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map } from 'rxjs/operators';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { LoadingService, LoadingType } from '../../services/loading.service';
import { Dialog } from '@angular/cdk/dialog';
import { PaginaDelenDialogComponent } from '../../dialogs/pagina-delen/pagina-delen-dialog/pagina-delen-dialog.component';
import { Overlay } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { CssSpinnerComponent } from '../css-spinner/css-spinner.component';
import { AppMenuService } from '@cumlaude/shared-components-menu';
import { DatasnapUrlService } from '../../services/datasnap-url.service';
import { RDatasnapResult } from '@cumlaude/service-contract';
import { SidebarService } from '@cumlaude/shared-components-overlays';
import { DatasnapResultSidebarComponent } from '../../sidebars/datasnap-result-sidebar.component';
import { dutchDate } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-tab-menu',
	templateUrl: './tab-menu.component.html',
	styleUrls: ['./tab-menu.component.scss'],
	imports: [RouterLinkActive, RouterLink, AsyncPipe, CssSpinnerComponent],
})
export class TabMenuComponent implements OnDestroy {
	readonly dialog = inject(Dialog);
	private readonly overlay = inject(Overlay);
	readonly appMenuService = inject(AppMenuService);
	readonly datasnapUrlService = inject(DatasnapUrlService);
	private readonly sidebarService = inject(SidebarService);

	private readonly subscriptions: Subscription[] = [];

	private readonly scrollCheck$ = new EventEmitter<void>();

	showExportSpinner$: Observable<boolean>;

	@Input()
	exportVisible = false;

	@Input()
	shareVisible = false;

	@Output()
	exportClicked = new EventEmitter<void>();

	@ViewChild('viewport')
	viewport?: ElementRef;

	showLeft = false;
	showRight = false;

	constructor() {
		const router = inject(Router);
		const loadingService = inject(LoadingService);

		this.showExportSpinner$ = loadingService.shouldShowLoadingIndicator(LoadingType.EXPORT);
		this.subscriptions.push(
			router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
				this.scrollCheck$.emit();
			})
		);
		// het debouncen dient 2 doelen:
		// 1. niet zo veel render loops tijdens het resizen
		// 2. bij een nieuwe route wachten totdat render loop uitgevoerd is en we de clientWidth weten
		this.subscriptions.push(this.scrollCheck$.pipe(debounceTime(50)).subscribe(() => this.calcScrollButtons()));
	}

	@HostListener('window:resize')
	onWindowResize() {
		this.scrollCheck$.emit();
	}

	calcScrollButtons() {
		const elt = this.viewport?.nativeElement;
		if (!elt) return;

		this.showLeft = elt.scrollLeft > 0;
		this.showRight = elt.scrollWidth > elt.scrollLeft + elt.clientWidth;
	}

	scrollLeft() {
		const elt = this.viewport?.nativeElement;
		if (!elt) return;

		elt.scrollLeft = Math.max(0, elt.scrollLeft - elt.clientWidth);
		this.calcScrollButtons();
	}

	scrollRight() {
		const elt = this.viewport?.nativeElement;
		if (!elt) return;

		elt.scrollLeft = Math.min(elt.scrollLeft + elt.clientWidth, elt.scrollWidth - elt.clientWidth);
		this.calcScrollButtons();
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	sharePagina() {
		this.dialog.open<void>(PaginaDelenDialogComponent, {
			positionStrategy: this.overlay.position().global().top('7rem').right('4rem'),
		});
	}

	openHistory(datasnapResults: RDatasnapResult[]) {
		this.sidebarService.open(DatasnapResultSidebarComponent, { data: datasnapResults });
	}

	// als we dit als normale functie in de template aanroepen en de REST-call gaat fout
	// blijft hij re-renderen
	snapshotTitle = computed(() => {
		const key = this.datasnapUrlService.currentSnapshotKey();
		if (key === undefined) return of('');

		return this.datasnapUrlService.getResult(key).pipe(
			map((result) => dutchDate(result.tsRequest)),
			catchError(() => of('[kan snapshot niet laden]'))
		);
	});
}
