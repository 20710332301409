import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { LeerlingDetail } from '../../Details';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { delay, switchMap, take } from 'rxjs/operators';
import { BasicFilterExpression, DataService, DoorstroomPath, ExportDataOptions, ExportFilter, Plaatsing } from '../../../services/data.service';
import { ExportOptions } from '../../../services/export.service';
import { Dashboard } from '../../../shared/dashboard/base-dashboard/dashboard';
import { Table } from '@cumlaude/metadata';
import { ExportColumnDef, ExportTable } from '../../../shared/dashboard/data-tree-table/data-tree-table';
import { DoorstroomPathComponent } from '../doorstroom-path/doorstroom-path.component';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';

@Component({
	selector: 'app-leerling-doorstroom-details-panel',
	templateUrl: './leerling-doorstroom-details-panel.component.html',
	styleUrls: ['./leerling-doorstroom-details-panel.component.scss'],
	imports: [DoorstroomPathComponent, DashboardHeaderComponent],
})
export class LeerlingDoorstroomDetailsPanelComponent extends Dashboard implements DetailsDashboard<LeerlingDetail> {
	private readonly dataService = inject(DataService);

	nr_leerling$ = new ReplaySubject<number>(1);

	paths: DoorstroomPath[] = [];

	dashboardName!: string;

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	constructor() {
		super();

		const data$ = this.nr_leerling$.pipe(switchMap((nr_leerling) => this.dataService.getDoorstroomDetails(nr_leerling)));

		this.subscriptions.push(
			data$.subscribe((paths) => {
				this.paths = paths;
			}),
			data$.pipe(delay(0)).subscribe((_) => {
				const elt = this.anchor?.nativeElement;
				if (elt) {
					elt.scrollIntoView();
				}
			}),
			this.urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title))
		);
	}

	@ViewChild('anchor')
	anchor?: ElementRef;

	factTable = Table.fac_ds_doorstroom;

	doDetailsExport(exportOptions: ExportOptions) {
		lastValueFrom(this.nr_leerling$.pipe(take(1))).then((nr) => {
			const filters = [new BasicFilterExpression(['ds_nr_leerling'], nr), new BasicFilterExpression(['ds_is_plaatsing_opeenvolgend'], 1)];
			this.doExport([], filters, [], exportOptions);
		});
	}

	override getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getDoorstroomExportData(options);
	}

	setSelected(selected: LeerlingDetail): void {
		this.nr_leerling$.next(selected.lb_nr_leerling);
	}

	protected override getExportFilters(_exportFilters: ExportFilter[]): ExportFilter[] {
		// De gebruiker kan geen filters instellen op het Leerling Details Doorstroom dashboard, dus zijn ook niet relevant om te exporteren
		return [];
	}

	override exportAsTable(options: ExportDataOptions): Observable<Blob> {
		const columns: (ExportColumnDef & { getValue: (plaatsing: Plaatsing) => any })[] = [
			{ name: 'Schooljaar', type: 'string', getValue: (plaatsing: Plaatsing) => plaatsing.lb_nm_schooljaar },
			{ name: 'Klas', type: 'string', getValue: (plaatsing: Plaatsing) => plaatsing.lb_nm_klas },
			{ name: 'Opleiding', type: 'string', getValue: (plaatsing: Plaatsing) => plaatsing.lb_nm_opleiding },
			{ name: 'Bekostigingsopleiding', type: 'string', getValue: (plaatsing: Plaatsing) => plaatsing.lb_nm_opleiding_bekostiging },
			{ name: 'Leerjaar', type: 'number', getValue: (plaatsing: Plaatsing) => plaatsing.lb_nr_leerjaar },
			{ name: 'Niveau', type: 'string', getValue: (plaatsing: Plaatsing) => plaatsing.ilt_nm_niveau },
			{ name: 'Profiel', type: 'string', getValue: (plaatsing: Plaatsing) => plaatsing.ilt_nm_profiel },
			{ name: 'Vestiging', type: 'string', getValue: (plaatsing: Plaatsing) => plaatsing.vs_nm_vestiging },
			{ name: 'Vanaf', type: 'date', getValue: (plaatsing: Plaatsing) => plaatsing.lb_d_plaatsing_va },
			{ name: 'Tot', type: 'date', getValue: (plaatsing: Plaatsing) => plaatsing.lb_d_plaatsing_tm },
			{
				name: 'Bijzonderheden',
				type: 'string',
				getValue: (plaatsing: Plaatsing) => {
					const bijzonderheden: string[] = [];
					plaatsing.children.forEach((c) => {
						if (c.ds_is_uitstroom_extern) bijzonderheden.push('Uitstroom (ext)');
						if (c.ds_nm_idu) bijzonderheden.push(c.ds_nm_idu + (c.ds_is_examenprognose ? ' (prognose)' : ''));
					});
					return bijzonderheden.join(', ');
				},
			},
		];
		const table: ExportTable = {
			data: this.paths.map((path) => ({ columns, rows: path.map((plaatsing) => columns.map((col) => col.getValue(plaatsing))) })),
			options,
		};
		return this.dataService.getTableExport(table);
	}
}
