@for (groeperingCategorie of groeperingCategorieen(); track groeperingCategorie.naam) {
	@if (groeperingCategorie.naam) {
		<div class="categorie">
			{{ groeperingCategorie.naam }}
		</div>
	}
	@for (groepering of groeperingCategorie.groeperingen; track groepering.attrPath) {
		<div class="groepering" [class.actief]="groepering.current" (click)="select(groepering)">
			{{ groepering.naam }}
		</div>
	}
}
