import { Component, input, output } from '@angular/core';
import { AttrPath } from '../../../services/data.service';

export interface Groepering {
	naam: string;
	attrPath: AttrPath;
	current: boolean;
}

export interface Categorie {
	naam?: string;
	groeperingen: Groepering[];
}

@Component({
	selector: 'app-groepering-list',
	templateUrl: './groepering-list.component.html',
	styleUrls: ['./groepering-list.component.scss'],
	imports: [],
})
export class GroeperingListComponent {
	groeperingCategorieen = input.required<Categorie[]>();

	selected = output<Groepering>();

	select(groepering: Groepering) {
		this.selected.emit(groepering);
	}
}
