<app-dashboard-container
	class="dashboard-se-ce-verschil"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, getPermanentExpressions(uitsluiten), getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="initialFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijfers"
		[permanentFilterExpressions]="getPermanentExpressions(uitsluiten)"
	></app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header
			[variant]="variant"
			[legenda]="['cf_fun_cijferverschil_se_ce_afgerond']"
			[legendaKeyIsClassName]="true"
			[leftOptionsTemplate]="leftOptions"
		>
			<ng-template #leftOptions>
				<app-schooljaar-indicator-over schooljaarFilterName="x_onderwijsresultaten_cf_schooljaar"></app-schooljaar-indicator-over>
				<app-onderwijsresultaten-uitsluiten-filter [isLeerlingen]="false"></app-onderwijsresultaten-uitsluiten-filter>
			</ng-template>
		</app-dashboard-header>
		{{ getSchooljarenCaption() }}
		<app-barchart-table
			[filters]="filterExpressions"
			[permanentFilters]="getPermanentExpressions(uitsluiten)"
			[tableGroups]="table"
			[defaultGroups]="[defaultGroup]"
			[groups]="getGroups(variant, selectedGroup)"
			[config]="this"
		></app-barchart-table>
	</section>
</app-dashboard-container>
