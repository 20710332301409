/**
 * Atts / AttrPaths die eindigen op deze attributen worden niet getoond als filter/groepering als het een Magister school is.
 */
import { Attr } from './data.service';
import { ExtraFilterNames } from './filter-config';
import { Cijferkolomtype } from '@cumlaude/metadata';

export const magisterBlocklist: (Attr | ExtraFilterNames)[] = ['cf_is_alternatievenormering'];

export const magisterUnsupportedCijferkolomtypes = [Cijferkolomtype.RAPPORTCIJFER, Cijferkolomtype.LAATSTE_RAPPORTCIJFER, Cijferkolomtype.ADVIES];
