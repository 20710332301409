import { merge, Observable, of } from 'rxjs';
import { createColumnDef, TableModel } from '../shared/components/table/table/table.model';
import { AccountFilter, RestService } from '@cumlaude/shared-services';
import { Directive, effect, inject, Signal, signal, WritableSignal } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/data.service';
import { AccountsService } from '../services/accounts.service';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ActionCellComponent } from '../shared/components/table/cells/action-cell/action-cell.component';
import { RolToekennenDialogComponent } from '../dialogs/account/rol-toekennen-dialog/rol-toekennen-dialog.component';
import { pull } from 'lodash-es';
import { nonBreakingSpace } from '../services/labels';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { Dialog } from '@angular/cdk/dialog';
import { UserService } from '../services/user.service';
import { CumLaudeLicentieInfo, LicentieRol, RCumLaudeAccount, RCumLaudeModule, RRol, RVestiging } from '@cumlaude/service-contract';
import { accountLabelMetAfkorting, prettyCount } from '@cumlaude/shared-utils';
import { Option } from '@cumlaude/shared-components-inputs';
import { toSignal } from '@angular/core/rxjs-interop';

export function getAccountVestigingen(account: RCumLaudeAccount, alleVestigingen: RVestiging[]) {
	return alleVestigingen
		.filter((vestiging) => account.vestigingen.includes(vestiging.vestigingId))
		.map((vestiging) => vestiging.naam)
		.join(', ');
}

export const LicentieRolNaam: { [key in LicentieRol]: string } = {
	schoolbreedTeam: 'schoolbreed-/teamlicenties',
	sectie: 'sectie-licenties',
	docentMentor: 'docent-/mentorlicenties',
	bestuur: '',
	bestuurTotaal: '',
};

export interface AccountRow {
	account: RCumLaudeAccount;
}

export interface AccountVestigingenRow extends AccountRow {
	vestigingen: string;
}

@Directive()
export abstract class AccountScreen<R extends AccountRow> {
	protected readonly accountsService = inject(AccountsService);
	protected readonly dataService = inject(DataService);
	protected readonly dialog = inject(Dialog);
	protected readonly envConfig: EnvConfiguration = inject(ENV_CONFIG);
	protected readonly restService = inject(RestService);
	protected readonly toastr = inject(ToastrService);
	protected readonly userService = inject(UserService);

	alleVestigingen$: Observable<RVestiging[]>;
	vestigingOpties: Signal<Option<RVestiging>[]>;
	accountTableModel$: Observable<TableModel<R>>;

	filter: WritableSignal<AccountFilter & { vestiging?: string[] }> = signal({ quicksearch: '', rol: this.getRol() });

	licentieMelding$?: Observable<{ tekst: string[]; warning: boolean; info: boolean }>;

	public moduleIsEnabled$: Observable<boolean> = of(true);

	constructor() {
		this.alleVestigingen$ = this.restService.getVestigingen({ cumlaudeActief: true }).pipe(shareReplay(1));
		this.vestigingOpties = toSignal(
			this.alleVestigingen$.pipe(
				map((vestigingen) => vestigingen.map((vestiging) => new Option(vestiging, vestiging.naam, vestiging.actief ? undefined : 'inactive')))
			),
			{ initialValue: [] }
		);

		this.accountTableModel$ = this.accountsService.list$.pipe(switchMap((accounts) => this.makeAccountTableModel(accounts)));

		const licentieRol = this.getLicentieRol();
		if (licentieRol) {
			this.licentieMelding$ = merge(this.accountsService.createLocal$, this.accountsService.deleteLocal$).pipe(
				startWith(undefined),
				switchMap(() => this.restService.getLicentieInfo().pipe(map((info) => this.getLicentieInfo(info, licentieRol))))
			);
		}

		const module = this.getModule();
		if (module) this.moduleIsEnabled$ = this.userService.modules$.pipe(map((modules) => modules.includes(module)));

		effect(() => {
			this.accountsService.filter$.next(this.filter());
		});
	}

	getLicentieInfo(info: CumLaudeLicentieInfo, licentieRol: LicentieRol) {
		return {
			tekst: [
				`${info[licentieRol]?.inGebruik ?? 0} ${info[licentieRol]?.totaal ? `van de ${info[licentieRol]?.totaal}` : ''} ${
					LicentieRolNaam[licentieRol]
				} in gebruik`,
			],
			warning: (info[licentieRol]?.totaal ?? Number.POSITIVE_INFINITY) < (info[licentieRol]?.inGebruik ?? 0),
			info: (info[licentieRol]?.totaal ?? Number.POSITIVE_INFINITY) === (info[licentieRol]?.inGebruik ?? 0),
		};
	}

	getRol(): RRol | undefined {
		return undefined;
	}

	getRolDescription(): string {
		return '';
	}

	getLicentieRol(): LicentieRol | undefined {
		return undefined;
	}

	getRolLabel() {
		return '';
	}

	getModule(): RCumLaudeModule | undefined {
		return undefined;
	}

	getModuleName(): string | undefined {
		return this.getModule();
	}

	getResultCount(model: TableModel<R>): string {
		return prettyCount(model.data, 'resultaat', 'resultaten');
	}

	isToekennen() {
		const rol = this.getRol();
		return rol && [RRol.SCHOOLBREED, RRol.TEAMLEIDER, RRol.SECTIELEIDER, RRol.BESTUUR].includes(rol);
	}

	abstract makeAccountTableModel(accounts: RCumLaudeAccount[]): Observable<TableModel<R>>;

	getNaamColumn() {
		return createColumnDef('account.naam', 'Naam', false, (model: R) => accountLabelMetAfkorting(model.account));
	}

	createActionColumn(actionName: string, title: string, iconName: string, callback: (row: R) => void, visible: (row: R) => boolean) {
		// de kolomnaam vertaalt zich automatisch in een css class "mat-column-action-xxxxx", en op deze classes hebben we custom styling zitten
		const coldef = createColumnDef<R>(`action-${actionName}`, title);
		coldef.body.component = ActionCellComponent;
		coldef.body.getValue = (row) =>
			visible(row)
				? {
						icon: iconName,
						callback: () => callback(row),
					}
				: undefined;
		return coldef;
	}

	openRolToekennenDialog() {
		this.getVestigingen()
			.pipe(
				switchMap((vestigingen) => {
					const dialogRef = this.dialog.open<RCumLaudeAccount[]>(RolToekennenDialogComponent, {
						data: { rol: this.getRol(), rolLabel: this.getRolLabel(), licentieRol: this.getLicentieRol(), vestigingen },
					});
					return dialogRef.closed;
				})
			)
			.subscribe((accounts) => {
				if (!accounts) return;

				this.resetFilter();
				accounts.forEach((account) => {
					account.rollen.push(this.getRol()!);
					this.accountsService.update$.next([
						account,
						() => this.accountsService.createLocal$.next(account),
						(err) => {
							this.toastr.error(`Er ging iets fout bij het toekennen van rollen: ${err.error}`);
						},
					]);
				});
			});
	}

	/**
	 * @returns Vestigingen die de juiste modules afnemen van CumLaude
	 */
	protected getVestigingen(): Observable<string[] | undefined> {
		const module = this.getModule();
		return this.userService.beheerRVestigingen$.pipe(
			map((vestigingen) => vestigingen.filter((vestiging) => !module || vestiging.modules.includes(module)).map((v) => v.vestigingId))
		);
	}

	selecteerVestigingen(vestigingen: RVestiging[]) {
		this.filter.update((filter) => ({ ...filter, vestiging: vestigingen.map((rVestiging) => rVestiging.vestigingId) }));
	}

	getSelectedVestigingen(): RVestiging[] {
		return this.vestigingOpties()
			.filter((option) => this.filter().vestiging?.includes(option.value.vestigingId))
			.map((option) => option.value);
	}

	createRolIntrekkenColumn() {
		return this.createActionColumn(
			'intrekken',
			'Rol intrekken'.replace(' ', nonBreakingSpace),
			'icon-error',
			({ account }) => this.trekRolIn(account),
			() => true
		);
	}

	trekRolIn(account: RCumLaudeAccount) {
		pull(account.rollen, this.getRol()!);
		this.accountsService.update$.next([
			account,
			() => this.accountsService.deleteLocal$.next(account.id!),
			(err) => {
				this.toastr.error(`Er ging iets fout bij het intrekken van rollen: ${err.error}`);
			},
		]);
	}

	resetFilter() {
		if ((this.filter().quicksearch ?? '') === '' && !this.filter().vestiging) return;

		this.filter.set({ quicksearch: '', rol: this.getRol() });
	}

	search(searchString: string) {
		this.filter.update((filter) => ({ ...filter, quicksearch: searchString }));
	}
}
