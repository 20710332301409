@if (elementen().length == 0 && emptyState()) {
	<ul class="elementen no-elementen">
		<li class="element">
			<div class="element__text">
				<span class="secondary">{{ emptyState() }}</span>
			</div>
		</li>
	</ul>
}
<cdk-virtual-scroll-viewport #scroller [itemSize]="40">
	<ul class="elementen">
		<li *cdkVirtualFor="let element of elementen(); trackBy: trackByIx" class="element" (click)="click(element)">
			<app-checkbox [value]="isChecked(element)" />
			<app-pasfoto [pasfoto]="element.pasfoto" size="size-2"></app-pasfoto>
			<div class="naam">{{ element.naam }}</div>
			<div class="leerlingnummer">{{ element.nummer }}</div>
		</li>
	</ul>
</cdk-virtual-scroll-viewport>
