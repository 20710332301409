import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '@cumlaude/shared-authentication';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserAuthenticationService } from '../../services/user-authentication.service';

@Component({
	selector: 'app-somtoday',
	templateUrl: './somtoday.component.html',
	styleUrls: ['./somtoday.component.scss'],
})
export class SomtodayComponent implements OnInit {
	private readonly authService = inject(AuthService);
	private readonly oAuthService = inject(OAuthService);
	private readonly userAuthenticationService = inject(UserAuthenticationService);

	ngOnInit(): void {
		this.authService.clearSessionStorage();
		this.userAuthenticationService.clearSessionStorage();
		this.oAuthService.initCodeFlow('', { federate: 'somtoday' });
	}
}
