// uit connect

import { Directive, ViewContainerRef, inject } from '@angular/core';

@Directive({
	selector: '[appContentHost]',
})
export class ContentHostDirective {
	readonly viewContainerRef = inject(ViewContainerRef);
}
