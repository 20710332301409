@if (options.length > 30) {
	<cdk-virtual-scroll-viewport [itemSize]="20" [style.height]="inDropdown ? '12.5rem' : '17.5rem'" [style.min-width]="'8rem'">
		<ul class="options">
			<li
				*cdkVirtualFor="let option of options"
				class="option {{ option.style }}"
				[class.legenda]="legenda"
				[title]="isBlocked(option) ? (blockedHover ?? '') : ''"
				(click)="onSelected(option, $event)"
			>
				@if (isHeader(option)) {
					<li class="header">{{ option.text }}</li>
				} @else {
					<app-checkbox [value]="isSelected(option)" [enabled]="!isBlocked(option)"></app-checkbox>
					@if (legenda) {
						<div class="legenda" [class]="option.legendaClass"></div>
					}
					<span class="label" [class.oneline]="inDropdown">{{ option.text }}</span>
					@if (menuTemplate) {
						<div class="menu"><ng-container *ngTemplateOutlet="menuTemplate; context: { option }"></ng-container></div>
					}
				}
			</li>
		</ul>
	</cdk-virtual-scroll-viewport>
} @else {
	<ul class="options">
		@for (option of options; track option.value) {
			@if (isHeader(option)) {
				<li class="header">{{ option.text }}</li>
			} @else {
				<li
					class="option {{ option.style }}"
					[class.legenda]="legenda"
					[title]="isBlocked(option) ? (blockedHover ?? '') : ''"
					(click)="onSelected(option, $event)"
				>
					<app-checkbox [value]="isSelected(option)" [enabled]="!isBlocked(option)"></app-checkbox>
					@if (legenda) {
						<div class="legenda" [class]="option.legendaClass"></div>
					}
					<span class="label" [class.oneline]="inDropdown">{{ option.text }}</span>
					@if (menuTemplate) {
						<div class="menu"><ng-container *ngTemplateOutlet="menuTemplate; context: { option }"></ng-container></div>
					}
				</li>
			}
		} @empty {
			<li class="option no-options">
				<span class="label">{{ showEmpty }}</span>
			</li>
		}
	</ul>
}
