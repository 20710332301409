import { Component, Input } from '@angular/core';
import { Level, PartialPath, Path } from '../../../services/data-tree';
import { ColKeyFunction, ColSortFunction, tabulate } from '../../../services/tabulate';
import { DataTreeTableComponent, getInitialAttributes } from '../data-tree-table/data-tree-table';
import { PivotTableConfig } from './pivot-table-config';
import { Attributes } from '../base-dashboard/base-dashboard-config';
import { DashboardContext } from '../base-dashboard/dashboard-context';
import { last } from 'lodash-es';
import { AsyncPipe } from '@angular/common';
import { TableComponent } from '../../components/table/table/table.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 * In een pivot table staat elke rij voor een unieke combinatie van waarden van de "groups" attributen, en elke kolom voor een unieke
 * combinatie van waarden voor de "subgroups" attributen (of, als "columnKeys" gevuld is, een gedeelte daarvan).
 */
@Component({
	selector: 'app-pivot-table',
	templateUrl: '../data-tree-table/data-tree-table.html',
	styleUrls: ['../data-tree-table/data-tree-table.scss'],
	providers: [{ provide: DataTreeTableComponent, useExisting: PivotTableComponent }], // zorgt dat dit component als subclass van DataTreeTableComponent wordt herkend voor het ViewChild-attribuut van DataTreeTableConfigConfig
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, TableComponent, AsyncPipe],
})
export class PivotTableComponent<I extends Attributes, A extends Attributes, C extends PivotTableConfig<I, A>> extends DataTreeTableComponent<
	I,
	A,
	C
> {
	/**
	 * optionele config voor de "tabulate" functie (zie aldaar)
	 */
	@Input()
	columnKeys: undefined | number | ColKeyFunction;

	/**
	 * optionele config voor de "tabulate" functie (zie aldaar)
	 */
	@Input()
	columnSort: undefined | Level<A, number[]> | ColSortFunction;

	protected override createColumnsPerType(context: DashboardContext<I, A, PivotTableConfig<I, A>>, tableRoot: Level<A, number[]>) {
		const columnTree = tabulate(tableRoot, this.columnSort, this.columnKeys, context.aggCombine);
		return {
			...super.createColumnsPerType(context, tableRoot),
			pivot: context.config.createPivotColumns(columnTree, context),
		};
	}
}

/**
 * Vind, gegeven het pad naar de rij en het kolomnummer (zoals gegenereerd door tabulate), het pad naar de cell in die kolom.
 */
export function getCellPath<A extends Attributes, D>(path: PartialPath<A, D>, colIndex: number): Path<A, D> | undefined {
	return last(path)!.xr!.find((childPath) => last(childPath)!.i == colIndex);
}

export function getInitialAttributesForCell<I extends Attributes, A extends Attributes>(
	subgroupNames: string[],
	measureNames: string[],
	path: PartialPath<A, number[]>,
	colIndex: number
): I | undefined {
	const cellPath = getCellPath(path, colIndex);
	if (!cellPath) {
		return undefined;
	}
	return getInitialAttributes<I>(subgroupNames, measureNames, cellPath);
}
