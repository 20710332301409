import { Component, OnInit, signal, inject } from '@angular/core';
import { CijferHistogram, getCijferSize, partitionHistogram } from '../cijfers-overzicht/cijfers-overzicht.component';
import {
	Att,
	AttrPath,
	BasicFilterExpression,
	CijferMeasure,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	ExportFilter,
	FilterDynamicSelectionExpression,
	FilterExpression,
	InFilterExpression,
	isBasicFilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { ColumnOptions, createMeasureColumn, DataRow, getGroupAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att, percOfRow } from '../../services/measures';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { get, last, memoize, range, sum } from 'lodash-es';
import { Cijferkolomtype, Table } from '@cumlaude/metadata';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { BarInfo } from '../../services/stacked-bars';
import { formatPercent } from '@angular/common';
import { Attributes, BaseDashboardConfig, Selection, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { CijferkolomKeuze, Dossier } from '../../services/weergave-opties';
import { generateCssClassForCijfer } from '@cumlaude/shared-utils';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { MultiAggregator, noAgg, noAgg0, SingleAggregator, sumOver, weightedAverage } from '../../services/aggregation';
import { Observable } from 'rxjs';
import { PartitionMeasure } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { Axis } from '../../services/axis';
import { InstellingBron } from '@cumlaude/service-contract';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { DataCellComponent } from '../../shared/components/table/cells/data-cell/data-cell.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface ToetsDetailsI extends CijferHistogram {
	cf_nr_cijfer_afgerond: number;
	cf_nr_cijfer: number;
	cf_nr_cijfer_vorig_sj: number;
	cf_nr_gem_cijfer_andere_vakken: number;
	cf_nr_leerlingen: number;
	cf_nr_cijfer_mediaan: number;
	cf_nr_sectiegemiddelde: number;
	cf_fun_plaatsing_advies_score: number;
	cf_nr_aantal_toetsen: number;
	cf_is_gevuld: number;
	cf_nr_label_onvoldoendes: number;
	cf_nm_lichting_vak: any;
	cf_co_kolom: any;
	cf_abb_kolomkop: any;
}

interface ToetsDetailsA extends Attributes {
	cijfer: number | null;
	aantalLeerlingen: number;
	onvoldoendes: number;
	cijferAfgerondOV: any;
	cijferMediaan: number | null;
	aantalCijfers: number;
	cf_nr_label_onvoldoendes: number | null;
}

@Component({
	selector: 'app-toets-details',
	templateUrl: './toets-details.component.html',
	styleUrls: ['./toets-details.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		BarchartTableComponent,
		FilterBarComponent,
	],
})
export class ToetsDetailsComponent extends BarchartTableConfig<ToetsDetailsI, ToetsDetailsA> implements OnInit {
	protected readonly dataService = inject(DataService);

	InstellingBron = InstellingBron;

	factTable = Table.fac_cf_cijfer;

	tableGroups: AttrPath[] = [['cf_nm_vak'], ['cf_fun_leerjaar_niveau_lesgroep'], ['cf_nm_lesgroep_docenten']];

	override fixedBeforeGroups = 4;

	fixedGroups: AttrPath[] = [['cf_fun_periode'], ['cf_fun_kolomgroep'], ['cf_fun_toets_rank'], ['cf_nr_weging'], ['cf_des_kolom']];

	fixedSubgroups: AttrPath[] = [['cf_nm_lichting_vak'], ['cf_co_kolom'], ['cf_abb_kolomkop']];

	actueelFilters: FilterName[] = [
		'cf_nm_schooljaar',
		'cf_co_brin',
		'x_cijfer_niveau',
		'x_cijfer_leerjaar',
		'cf_nm_vestiging',
		'cf_nm_vak',
		'cf_nm_lesgroep',
		'cf_fun_periode',
		'cf_is_alternatievenormering',
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions: FilterExpression[] = [];

	dossier!: Dossier;

	dossierOpties = Object.values(Dossier).map((val) => new Option(val));

	cijferkolomKeuze!: CijferkolomKeuze;

	cijferkolomKeuzeOpties = signal<Option<CijferkolomKeuze>[]>(
		[CijferkolomKeuze.ALLE, CijferkolomKeuze.TOETS, CijferkolomKeuze.GEMIDDELDE].map((val) => new Option(val))
	);

	protected override singleAggregators: Partial<{ [ai in keyof ToetsDetailsA]: SingleAggregator<ToetsDetailsI, ToetsDetailsA[ai]> }> = {
		aantalLeerlingen: noAgg0('cf_nr_leerlingen'),
		onvoldoendes: {
			init: (i: ToetsDetailsI) => i.cf_nr_label_onvoldoendes ?? sum(range(0, 6).map((n) => get(i, 'cf_nr_aantal_' + n, 0))),
			combine: (as: number[]) => sum(as),
		},
		aantalCijfers: sumOver('cf_is_gevuld'),
		cf_nr_label_onvoldoendes: sumOver('cf_nr_label_onvoldoendes'),
		cijferMediaan: noAgg('cf_nr_cijfer_mediaan'),
	};

	protected override multiAggregators: MultiAggregator<keyof ToetsDetailsA, ToetsDetailsI, ToetsDetailsA, number | null>[] = [
		weightedAverage('cijfer', 'cf_nr_cijfer', 'aantalCijfers'),
	];

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('cijfertype').subscribe((cijfertype) => (this.cijferkolomKeuze = cijfertype)),
			this.qp.observe('dossier').subscribe((dossier) => (this.dossier = dossier))
		);
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijfersData({
			...options,
			m: [CijferMeasure.HISTOGRAM, CijferMeasure.RELATIE, CijferMeasure.STATS],
			r: this.getRollupMarkers(),
		});
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getCijfersExportData(options);
	}

	override getBarchartQty(path: Path<ToetsDetailsA, number[]>): number {
		return Math.min(getLeafA(path).cijfer ?? 0, 10);
	}

	override createXAxis(_context: DashboardContext<ToetsDetailsI, ToetsDetailsA, ToetsDetailsComponent>): Axis {
		return { min: 0, max: 10, ticks: [] };
	}

	override partitionBarData(rowRoot: Level<ToetsDetailsA, number[]>): Path<ToetsDetailsA, number[]>[][] {
		return partitionHistogram(rowRoot);
	}

	partitionMeasure: PartitionMeasure<ToetsDetailsA> = {
		type: 'number',
		getValue: (path) => getLeafA(path).cijfer,
		format: '1.1-1',
	};

	// memoize, otherwise new array keeps triggering change detection
	getPermanentFilters = memoize(this._getPermanentFilters, (c, d) => JSON.stringify([c, d]));

	private _getPermanentFilters(cijferkolomKeuze: CijferkolomKeuze, dossier: Dossier): FilterExpression[] {
		const filters: FilterExpression[] = [];
		if (this.userService.bron() === InstellingBron.Somtoday && cijferkolomKeuze !== CijferkolomKeuze.GEMIDDELDE) {
			filters.push(
				dossier === Dossier.VOORTGANG
					? new BasicFilterExpression(['cf_is_voortgangsdossier'], 1)
					: new BasicFilterExpression(['cf_is_examendossier'], 1)
			);
		}

		switch (cijferkolomKeuze) {
			case CijferkolomKeuze.ALLE:
				filters.push(new BasicFilterExpression(['cf_nm_kolomtype'], Cijferkolomtype.ADVIES, '<>'));
				break;
			case CijferkolomKeuze.TOETS:
				filters.push(new BasicFilterExpression(['cf_nm_kolomtype'], Cijferkolomtype.TOETS, '='));
				break;
			case CijferkolomKeuze.GEMIDDELDE:
				filters.push(
					new InFilterExpression(
						['cf_nm_kolomtype'],
						Object.values(Cijferkolomtype).filter((value) => ![Cijferkolomtype.ADVIES, Cijferkolomtype.TOETS].includes(value))
					)
				);
				break;
			default:
		}

		return filters;
	}

	getDisplayOptions(): ExportFilter[] {
		return [
			...(this.userService.bron() === InstellingBron.Somtoday ? [{ label: 'Toetsdossier', value: this.dossier }] : []),
			{ label: 'Cijfertype', value: this.cijferkolomKeuze === CijferkolomKeuze.ALLE ? 'Alle' : this.cijferkolomKeuze },
		];
	}

	protected getRollupMarkers() {
		// 1 = hoogste niveau tables
		// 4 = periode
		// 5 = toets
		// overige groepen hebben geen sortering nodig
		return [0, 1, 4, 5, this.fixedGroups.length + this.tableGroups.length];
	}

	override createMeasureColumns(
		context: DashboardContext<ToetsDetailsI, ToetsDetailsA, ToetsDetailsComponent>
	): ColumnDef<DataRow<ToetsDetailsA>>[] {
		return [
			createMeasureColumn('Gem.', att('cijfer'), { context, format: '1.2-2' }),
			createMeasureColumn('Mediaan', att('cijferMediaan'), { context, format: '1.1-1' }),
			createMeasureColumn('% onv.', percOfRow('onvoldoendes', 'count_records'), { context, dataType: 'percentage' }),
			this.createLeerlingColumn('lln', att('aantalLeerlingen'), { context }),
		];
	}

	override createGroupingColumns(
		context: DashboardContext<ToetsDetailsI, ToetsDetailsA, ToetsDetailsComponent>
	): ColumnDef<DataRow<ToetsDetailsA>>[] {
		const visibleGroups: { att: Att; options?: Partial<ColumnOptions<ToetsDetailsI, ToetsDetailsA>> }[] = [
			{ att: 'cf_fun_periode', options: { header: 'Per.' } },
			{ att: 'cf_fun_kolomgroep' },
		];
		if (this.cijferkolomKeuze !== CijferkolomKeuze.GEMIDDELDE) {
			visibleGroups.push({
				att: 'cf_nr_weging',
				options: { header: 'Weg.', dataType: 'number', component: DataCellComponent, format: '1.0-2' },
			});
			visibleGroups.push({ att: 'cf_des_kolom', options: { header: 'Naam' } });
		}
		return visibleGroups.map(({ att, options }) => this.createGroupingColumn(att, context.groupNames.indexOf(att) + 1, false, options));
	}

	override getSelectionConfig(
		context: DashboardContext<ToetsDetailsI, ToetsDetailsA, ToetsDetailsComponent>
	): SelectionConfig<ToetsDetailsA> | undefined {
		return { ...super.getSelectionConfig(context)!, getSize: att('aantalLeerlingen') };
	}

	override createSelection(
		context: DashboardContext<ToetsDetailsI, ToetsDetailsA, BaseDashboardConfig<ToetsDetailsI, ToetsDetailsA>>,
		path: Path<ToetsDetailsA, number[]>,
		overrideConfig: Partial<SelectionConfig<ToetsDetailsA>> = {}
	): Selection | undefined {
		const selection = super.createSelection(context, path, overrideConfig)!;
		const { sf, sp } = selection.expression as FilterDynamicSelectionExpression;
		const newFilters = (sf as CompoundFilterExpression).filters.filter((f) => !(isBasicFilterExpression(f) && f.attr[0] === 'cf_fun_toets_rank'));
		return { ...selection, expression: new FilterDynamicSelectionExpression(sp, new CompoundFilterExpression(newFilters)) };
	}

	override makeBar(
		attrs: ToetsDetailsI,
		path: Path<ToetsDetailsA, number[]>,
		context: DashboardContext<ToetsDetailsI, ToetsDetailsA, ToetsDetailsComponent>
	): BarInfo {
		const { cf_co_kolom, cf_abb_kolomkop, cf_des_kolom, cf_nr_weging, cf_nr_leerlingen, cf_nm_lichting_vak } = <
			ToetsDetailsI & { cf_des_kolom: string; cf_nr_weging: number }
		>{
			...attrs,
			...getGroupAttributes(context, path),
		};

		const tooltipInfo: TooltipElement[] = [
			{ label: 'Kolom', value: cf_co_kolom },
			{ label: 'Kolomkop', value: cf_abb_kolomkop },
			{ label: 'Omschrijving', value: cf_des_kolom },
		];

		if (cf_nm_lichting_vak) tooltipInfo.push({ label: 'Lichting (Vak)', value: cf_nm_lichting_vak });

		if (cf_nr_weging !== null) tooltipInfo.push({ label: 'Weging', value: `${cf_nr_weging}` });

		tooltipInfo.push({ label: '# lln', value: `${cf_nr_leerlingen}` });

		const perc_onvoldoende = percOfRow('onvoldoendes', 'count_records')(path);
		tooltipInfo.push({ label: 'onvoldoendes', value: formatPercent(perc_onvoldoende, 'nl_NL') });

		const size = getCijferSize(attrs, path);
		const className = generateCssClassForCijfer(last(path)!.a.cijferAfgerondOV);
		return {
			className,
			size,
			tooltip: tooltipInfo,
		};
	}

	override enrichTableModel(
		_context: DashboardContext<ToetsDetailsI, ToetsDetailsA, ToetsDetailsComponent>,
		tableModel: TableModel<DataRow<ToetsDetailsA>>
	) {
		tableModel.showFooters = false;
	}

	getSelectedDossierOptie() {
		return this.dossierOpties.find((optie) => optie.value === this.dossier)!;
	}

	getSelectedCijfertypeOptie() {
		return this.cijferkolomKeuzeOpties().find((optie) => optie.value === this.cijferkolomKeuze)!;
	}
}
