import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	FilterDynamicSelectionExpression,
	FilterExpression,
} from '../../../services/data.service';
import { Observable } from 'rxjs';
import { MultiAggregator, SingleAggregator, sumOver } from '../../../services/aggregation';
import { Level, Path, pathTo } from '../../../services/data-tree';
import { FilterName } from '../../../services/filter-config';
import { DashboardContext } from './dashboard-context';
import { BarInfo } from '../../../services/stacked-bars';
import { Dashboard } from './dashboard';
import { inject, signal, WritableSignal } from '@angular/core';
import { QueryParamStateService } from '../../../services/query-param-state.service';
import { UserService } from '../../../services/user.service';
import { Table } from '@cumlaude/metadata';
import { count_records } from '../../../services/measures';

export interface Attributes {
	count_records: number;
}

export type Filter = {
	name: FilterName;
	value: string | null;
};

export abstract class BaseDashboardConfig<I extends Attributes, A extends Attributes> extends Dashboard {
	protected qp = inject(QueryParamStateService);
	protected userService = inject(UserService);

	/**
	 * Deze overriden in je subclass. Geeft meer type safety dan getSingleAggregators() overriden zou geven.
	 */
	protected singleAggregators: Partial<{ [ai in keyof A]: SingleAggregator<I, A[ai]> }> = {};

	/**
	 * Deze overriden in je subclass. Geeft meer type safety dan getMultiAggregators() overriden zou geven.
	 */
	protected multiAggregators: MultiAggregator<keyof A, I, A, A[keyof A]>[] = [];

	dataRootSelection: WritableSignal<Selection | undefined> = signal(undefined);

	abstract getData(options: DataOptions): Observable<DataResponse<number[]>>;

	/**
	 * Methode om optioneel extra afhandeling te doen met de dashboard data.
	 */
	onContextCreated(_context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): void {
		//Implementatie in child
	}

	getSingleAggregators(): Partial<{ [ai in keyof A]: SingleAggregator<I, A[ai]> }> {
		const defaults = <Partial<{ [ai in keyof A]: SingleAggregator<I, A[ai]> }>>{ count_records: sumOver('count_records') };
		return { ...defaults, ...this.singleAggregators };
	}

	getMultiAggregators(): MultiAggregator<keyof A, I, A, A[keyof A]>[] {
		const defaults: MultiAggregator<keyof A, I, A, A[keyof A]>[] = [];
		return [...defaults, ...this.multiAggregators];
	}

	/**
	 * Organiseert de data voor een rij als "partition", dwz een verzameling stacks waarbij elke stack een verzameling bars is.
	 */
	partitionBarData(rowRoot: Level<A, number[]>, _context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): Path<A, number[]>[][] {
		return [rowRoot.r];
	}

	/**
	 * Bepaalt de presentatie-attributen voor een enkele bar.
	 */
	makeBar(attrs: I, path: Path<A, number[]>, context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): BarInfo {
		return {
			size: attrs.count_records,
			selection: this.createSelection(context, path),
			className: 'dashboard-default',
		};
	}

	getSelectionConfig(context: DashboardContext<I, A, BaseDashboardConfig<I, A>>): SelectionConfig<A> | undefined {
		const map: Partial<{ [t in Table]: [string, string] }> = {
			fac_ds_doorstroom: ['doorstroom', '/details/leerling/doorstroom'],
			fac_cf_cijfer: ['cijfers', '/details/leerling/cijferlijst'],
			fac_aw_aanwezigheid: ['aanwezigheid', '/details/leerling/afwezigheid'],
			fac_bv_basisvaardigheden: ['basisvaardigheden', 'details/leerling/basisvaardigheden'],
			fac_lr_lesregistratie: ['lesregistratie', '/details/leerling/lesregistraties'],
			fac_vkk_vakkeuze: ['vakkeuze', '/details/leerling/vakken'],
		};
		const [provider, detailUrl] = map[this.factTable] ?? [];
		if (provider === undefined || detailUrl === undefined) return undefined;

		return { detailUrl, provider, getSize: count_records, extraFilters: [] };
	}

	isSelectionAllowed(context: DashboardContext<I, A, BaseDashboardConfig<I, A>>) {
		const selectionConfig = this.getSelectionConfig(context);
		if (!selectionConfig) return false;
		return this.autorisatieService.isUrlAllowed(selectionConfig.detailUrl);
	}

	createSelection(
		context: DashboardContext<I, A, BaseDashboardConfig<I, A>>,
		path: Path<A, number[]>,
		overrideConfig: Partial<SelectionConfig<A>> = {}
	): Selection | undefined {
		if (!this.isSelectionAllowed(context)) return undefined;

		const dashboardConfig = this.getSelectionConfig(context)!;
		return createSelection(context, path, { ...dashboardConfig, ...overrideConfig });
	}

	createDataRootSelection(
		context: DashboardContext<I, A, BaseDashboardConfig<I, A>> & {
			dataRoot: NonNullable<DashboardContext<I, A, BaseDashboardConfig<I, A>>['dataRoot']>;
		},
		overrideConfig: Partial<SelectionConfig<A>> = {}
	) {
		return this.createSelection(context, pathTo(context.dataRoot), overrideConfig);
	}
}

export type SelectionConfig<A> = {
	detailUrl: string;
	provider: string;
	getSize: (path: Path<A, number[]>) => number;
	extraFilters: FilterExpression[];
};

export type Selection = { detailUrl: string; expression: FilterExpression; size: number };

export function createSelection<I extends Attributes, A extends Attributes>(
	context: DashboardContext<I, A, BaseDashboardConfig<I, A>>,
	path: Path<A, number[]>,
	properties: SelectionConfig<A>
): Selection {
	const levelFilters = getLevelFilters(context, path);
	const { detailUrl, provider, getSize, extraFilters } = properties;
	const sf = new CompoundFilterExpression([context.filter, ...levelFilters, ...extraFilters]);
	const size = getSize(path);
	return { detailUrl, expression: new FilterDynamicSelectionExpression(provider, sf), size };
}

export function getLevelFilters<I extends Attributes, A extends Attributes>(
	context: DashboardContext<I, A, BaseDashboardConfig<I, A>>,
	path: Level<unknown, number[]>[]
) {
	const levelNames = [...context.groupNames, ...context.subgroupNames];
	return path.slice(1).map((level, ix) => new BasicFilterExpression(levelNames[ix].split('.') as AttrPath, level.k));
}
