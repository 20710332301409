<app-base-dialog sizeClass="size-12-25" [noPadding]="true" [useParentHeight]="true">
	<div class="container" #container>
		<app-groepering-list class="groeperingen" [groeperingCategorieen]="groeperingen()" (mouseenter)="closeSubMenus()" (selected)="select($event)">
		</app-groepering-list>
		@if (hasAlleGroeperingen()) {
			<div class="element allGroups" [class.active]="menuAlleGroeperingenLaatste()" (mouseenter)="openAlleGroeperingenDialog()">
				<span>Alle groeperingen</span>
				<div class="svg-chevron-right small grey"></div>
			</div>
		}
		@if (options.activeGroepering) {
			<div class="element sorteren" [class.active]="menuSorterenLaatste()" (mouseenter)="openSortDialog()" #sorteren>
				<div class="svg-sort-asc grey"></div>
				<span>Sorteren</span>
				<div class="svg-chevron-right small grey"></div>
			</div>
			@if (options.deletable) {
				<div class="element delete" (mouseenter)="closeSubMenus()" (click)="delete()">
					<div class="svg-delete"></div>
					<span>Verwijderen</span>
				</div>
			}
		}
	</div>
</app-base-dialog>
