<app-dashboard-container [shareVisible]="false" [factTable]="factTable" [exportTypes]="[]">
	<app-filter-panel
		[defaultFilters]="defaultFilters"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijferkolommen"
		[permanentFilterExpressions]="permanentFilterExpressions"
		[leerlingselectie]="false"
	></app-filter-panel>
	<app-filter-bar></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header></app-dashboard-header>
		<div class="sub-title">
			CumLaude bepaalt aan de hand van een aantal regels welke cijferkolommen als het 'laatste gemiddelde' worden getoond.
			<br />
			Hieronder kun je zien welke kolommen CumLaude op dit moment gebruikt. Onder <i>Aantal keer gebruikt</i> staat het aantal cijfers waarbij
			de betreffende kolom als 'laatste gemiddelde' wordt aangemerkt.
			<br />
			In deze
			<a href="https://cumlaude.zendesk.com/hc/nl/articles/15994249565841-Magister-Cijferinstellingen" target="_blank">handleiding</a>
			lees je hoe je de kolommen kunt aanpassen, die als 'laatste gemiddelde' worden getoond.
		</div>
		<app-data-tree-table
			[filters]="filterExpressions"
			[permanentFilters]="permanentFilterExpressions"
			[groups]="selectedGroups"
			[config]="this"
		></app-data-tree-table>
	</section>
</app-dashboard-container>
