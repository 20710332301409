import { NiveauVerschil } from './label-enums';

/**
 * lb_fun_verschil bestaat uit de strings "-25" t/m "25" (in stappen van 5) en
 * de speciale gevallen:
 * "+5" voor advies HAVO en plaatsing HAVO/VWO,
 * "null" voor onbekend advies.
 *
 * Output bestaat uit de getallen -10, 0, 5 of 10. Deel deze door 10 voor de bijdrage van een leerling
 * aan de onderwijspositie.
 */
export function onderwijspositie_0_5_10(lb_fun_verschil: string | null): number {
	switch (lb_fun_verschil) {
		case null:
			return 0;
		case '+5':
			return 5;
		default:
			return Math.sign(Math.ceil(Number(lb_fun_verschil) / 10)) * 10;
	}
}

export function getNiveauVerschil(fun_verschil: string): NiveauVerschil {
	if (fun_verschil === '0')
		return NiveauVerschil.NIVEAU_GELIJK_AAN_ADVIES;
	if (fun_verschil.startsWith('-'))
		return NiveauVerschil[`_${left_pad_zero_abs(fun_verschil)}_NIVEAUS_LAGER_DAN_ADVIES` as keyof typeof NiveauVerschil];
	if (fun_verschil === '+5')
		return NiveauVerschil._05_HAVO_VWO_PLAATSING_BIJ_HAVO_ADVIES;
	else
		return NiveauVerschil[`_${left_pad_zero_abs(fun_verschil)}_NIVEAUS_HOGER_DAN_ADVIES` as keyof typeof NiveauVerschil];
}

function left_pad_zero_abs(input: string) {
	return String(Math.abs(Number(input))+100).substring(1);
}

