import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	ExportFilter,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { ExportColumnDef } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { CardListConfig } from '../../shared/dashboard/card-list/card-list-config';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { Observable } from 'rxjs';
import { concat, flatMap, isNil, isUndefined, last, memoize } from 'lodash-es';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Router } from '@angular/router';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { aggArrayString, countTrueAtLevel, maxOver, MultiAggregator, noAgg0, noAggString } from '../../services/aggregation';
import {
	DRIE_SCHOOLJAREN_EXCL_2019,
	DRIE_SCHOOLJAREN_EXCL_2020,
	getOnderwijsresultatenSchooljaarRangeString,
	getSchooljaarTovHuidig,
	getOnderwijsresultatenSchooljarenRange,
	isPeiljaarMetExclusief,
	getSchooljarenRange,
	getPeiljaar,
	roundToDecimals,
} from '@cumlaude/shared-utils';
import { CardOrCellComponent, OnderwijsresultatenInputRow } from './card-or-cell/card-or-cell.component';
import { Table } from '@cumlaude/metadata';
import { SchooljaarHistorieOption } from '../../schooljaar-historie-filter/schooljaar-historie-option';
import { LinechartProps, PointInfo } from '../../shared/dashboard/linechart-table/linechart/linechart.component';
import { formatNumber, formatPercent } from '@angular/common';
import { createYAxis } from '../../services/axis';
import { DashboardVariant } from '../../services/weergave-opties';
import { indicatorOverOpties, SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { PageStateService, PsName } from '../../services/page-state.service';
import { OnderwijsresultatenOptionsDialogComponent } from '../../dialogs/overig/onderwijsresultaten-options-dialog/onderwijsresultaten-options-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CardOrRowCellComponent } from './card-or-row-cell/card-or-row-cell.component';
import { CardOrSingleCellComponent } from './card-or-single-cell/card-or-single-cell.component';
import { CardListComponent } from '../../shared/dashboard/card-list/card-list.component';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface OnderwijsresultatenI extends Attributes {
	or_nm_cl_schooljaren: string;
	or_nm_inspectie_schooljaren: string;
	or_nm_vestigingen: string;
	or_nr_norm_gecorrigeerd: number;
	or_nr_norm_landelijk: number;
	or_nr_score: number;
	or_nr_verschil: number;
	or_q_leerlingen: number;
	pj_nr_peiljaar: number;
	or_is_prognose: number;
}

export interface OnderwijsresultatenA extends Attributes {
	or_nm_cl_schooljaren: string;
	or_nm_inspectie_schooljaren: string;
	or_nm_vestigingen: string;
	or_nr_norm_gecorrigeerd: number;
	or_nr_norm_landelijk: number;
	or_nr_score: number;
	or_nr_verschil: number;
	or_q_leerlingen: number;
	pj_nr_peiljaar: number;
	aantal_onvoldoendes: number;
	minScoreNorm: number;
	maxScoreNorm: number;
	/**
	 * De bron van de data in een bitset (eerste bit is wel/niet cumlaude, tweede bit is wel/niet inspectie)
	 */
	bron: number;
	or_is_prognose: number;
}

@Component({
	selector: 'app-onderwijsresultaten',
	templateUrl: './onderwijsresultaten.component.html',
	styleUrls: ['./onderwijsresultaten.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		ButtonComponent,
		TooltipDirective,
		SchooljaarIndicatorOverComponent,
		CardListComponent,
		CardOrCellComponent,
		CardOrSingleCellComponent,
		CardOrRowCellComponent,
		FilterBarComponent,
	],
})
export class OnderwijsresultatenComponent extends CardListConfig<OnderwijsresultatenI, Attributes, OnderwijsresultatenA> {
	protected readonly dataService = inject(DataService);
	protected readonly router = inject(Router);
	protected readonly pageStateService = inject(PageStateService);
	private readonly dialog = inject(Dialog);
	private readonly overlay = inject(Overlay);

	actueelFilters: FilterName[] = [
		'x_onderwijsresultaten_or_schooljaar', //
		'or_co_brinvest',
		'or_nm_soort_onderwijs',
	];

	historieFilters: FilterName[] = [
		'x_onderwijsresultaten_or_schooljaar', // is onzichtbaar en filtert niet, maar is alleen om het schooljaar van de actueel-variant vast te houden
		'x_onderwijsresultaten_or_schooljaar_historie',
		'x_onderwijsresultaten_or_multiselect_schooljaar',
		'or_co_brinvest',
		'or_nm_soort_onderwijs',
	];

	filterExpressions?: FilterExpression[];

	groups: AttrPath[] = [['or_co_brinvest']];

	subGroups: AttrPath[] = [['or_co_indicator'], ['or_nm_soort_onderwijs'], ['pj_nr_peiljaar']];

	aantalJaren!: number;

	useInspectieData = this.qp.signal('inspectie-data');
	variant = this.qp.signal('variant');

	schooljaar?: string = undefined;

	peiljaar?: number = undefined;

	peiljaren: number[] = [];

	niveau?: string[] = undefined;

	bron: string = 'Onbekend';

	@ViewChild('instellingen', { read: ElementRef })
	instellingen!: ElementRef;

	isTweeOnvoldoendesMeasure = (row: Path<OnderwijsresultatenA, unknown>) => (getLeafA(row).aantal_onvoldoendes > 1 ? 1 : 0);

	constructor() {
		super();

		this.subscriptions.push(
			this.filterService.observe('x_onderwijsresultaten_or_schooljaar').subscribe(([schooljaar, aantalJaren]) => {
				if (this.variant() === DashboardVariant.HISTORIE) return;

				this.schooljaar = schooljaar;
				this.aantalJaren = aantalJaren;
				this.peiljaar = Number(schooljaar.split('/')[1]) + 1;
			}),
			this.filterService.observe('x_onderwijsresultaten_or_schooljaar_historie').subscribe(([{ option, inclHuidig }, aantalJaren]) => {
				if (this.variant() === DashboardVariant.ACTUEEL) return;

				if (option === SchooljaarHistorieOption.AANGEPAST) return;

				this.aantalJaren = aantalJaren;

				let aantal = 0;
				switch (option) {
					case SchooljaarHistorieOption.AFGELOPEN_3:
						aantal = 3;
						break;
					case SchooljaarHistorieOption.AFGELOPEN_5:
						aantal = 5;
						break;
					case SchooljaarHistorieOption.AFGELOPEN_10:
						aantal = 10;
						break;
				}

				const laatsteJaar = getSchooljaarTovHuidig(inclHuidig ? 0 : -1);
				this.peiljaren = getSchooljarenRange(laatsteJaar, aantal).map((schooljaar) => Number(schooljaar.split('/')[1]) + 1);
				this.peiljaar = Number(laatsteJaar.split('/')[1]) + 1;
			}),
			this.filterService.observe('x_onderwijsresultaten_or_multiselect_schooljaar').subscribe((value) => {
				if (isUndefined(value)) return;

				if (this.variant() === DashboardVariant.ACTUEEL) return;

				const [schooljaren, aantalJaren]: [string[], number] = value;

				this.aantalJaren = aantalJaren;
				this.peiljaren = schooljaren.map((schooljaar: string) => Number(schooljaar.split('/')[1]) + 1).sort((a, b) => a - b);
				this.peiljaar = Math.max(...this.peiljaren);
			}),
			this.filterService.observe('or_nm_soort_onderwijs').subscribe((niveau) => (this.niveau = niveau))
		);
	}

	factTable = Table.fac_or_onderwijsresultaten;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getOnderwijsresultatenData({
			...options,
			peiljaarThreshold: this.variant() === DashboardVariant.ACTUEEL ? this.peiljaar : undefined,
			schooljaarThreshold: this.aantalJaren >= 3 ? 2 : undefined,
		});
	}

	protected override singleAggregators = {
		or_nr_norm_gecorrigeerd: noAgg0('or_nr_norm_gecorrigeerd'),
		or_nr_norm_landelijk: noAgg0('or_nr_norm_landelijk'),
		or_nr_score: noAgg0('or_nr_score'),
		or_nr_verschil: noAgg0('or_nr_verschil'),
		or_q_leerlingen: noAgg0('or_q_leerlingen'),
		pj_nr_peiljaar: noAgg0('pj_nr_peiljaar'),
		or_nm_cl_schooljaren: noAggString('or_nm_cl_schooljaren'),
		or_nm_inspectie_schooljaren: noAggString('or_nm_inspectie_schooljaren'),
		or_nm_vestigingen: aggArrayString('or_nm_vestigingen'),
		aantal_onvoldoendes: countTrueAtLevel((attrs: OnderwijsresultatenI) => attrs.or_nr_verschil < 0 && attrs.pj_nr_peiljaar == this.peiljaar, 1),
		bron: {
			init: (v: OnderwijsresultatenI) => {
				const cumlaude = isNil(v.or_nm_cl_schooljaren) ? 0 : 1;
				const inspectie = isNil(v.or_nm_inspectie_schooljaren) ? 0 : 1;
				return cumlaude | (inspectie << 1);
			},
			combine: (as: number[]) => as.reduce((a, b) => a | b, 0),
		},
		or_is_prognose: maxOver('or_is_prognose'),
	};

	protected override multiAggregators: MultiAggregator<keyof OnderwijsresultatenA, OnderwijsresultatenI, OnderwijsresultatenA, number | string>[] =
		[
			{
				attribute: 'minScoreNorm',
				init: ({ or_nr_score, or_nr_norm_gecorrigeerd, or_nr_norm_landelijk }) =>
					Math.min(or_nr_score, or_nr_norm_gecorrigeerd, or_nr_norm_landelijk),
				combine: (as) => Math.min(...as.map((a) => a.minScoreNorm)),
			},
			{
				attribute: 'maxScoreNorm',
				init: ({ or_nr_score, or_nr_norm_gecorrigeerd, or_nr_norm_landelijk }) =>
					Math.max(or_nr_score, or_nr_norm_gecorrigeerd, or_nr_norm_landelijk),
				combine: (as) => Math.max(...as.map((a) => a.maxScoreNorm)),
			},
		];

	getPermanentFilters = memoize(OnderwijsresultatenComponent._getPermanentFilters, (uID) => JSON.stringify([uID]));

	getFilterPanelPermanentFilters = memoize(OnderwijsresultatenComponent._getFilterPanelPermanentFilters, (uID) => JSON.stringify([uID]));

	private static _getPermanentFilters(useInspectieData: boolean): FilterExpression[] {
		const permanentFilters = [new BasicFilterExpression(['or_co_indicator'], 'cv', '<>')];
		if (useInspectieData) return permanentFilters;
		else return [new BasicFilterExpression(['or_nm_bron'], 'cl', '='), ...permanentFilters];
	}

	private static _getFilterPanelPermanentFilters(useInspectieData: boolean): FilterExpression[] {
		return [new BasicFilterExpression(['or_nr_peiljaar'], null, '='), ...OnderwijsresultatenComponent._getPermanentFilters(useInspectieData)];
	}

	getSchooljarenCaption() {
		return this.schooljaar ? getOnderwijsresultatenSchooljaarRangeString(this.schooljaar, this.aantalJaren, 'bbs') : '';
	}

	getSchooljarenTooltip() {
		if (!isPeiljaarMetExclusief(this.schooljaar) || this.aantalJaren < DRIE_SCHOOLJAREN_EXCL_2020) {
			const onderwijspositieSchooljarenInspectie = getOnderwijsresultatenSchooljaarRangeString(this.schooljaar!, this.aantalJaren, 'op', true);
			return `Let op: bij de indicator Onderwijspositie toont onderwijsinspectie deze periode als ${onderwijspositieSchooljarenInspectie}`;
		}

		const tooltipElements: TooltipElement[] = [];
		for (const indicator of ['op', 'obs', 'bbs', 'exc']) {
			const indicatorNaam = this.getIndicatorNaam(indicator);

			let indicatorTekst = getOnderwijsresultatenSchooljaarRangeString(this.schooljaar!, this.aantalJaren, indicator);
			if (indicator === 'op') {
				const inspectieSchooljaren = getOnderwijsresultatenSchooljaarRangeString(this.schooljaar!, this.aantalJaren, 'op', true);
				indicatorTekst += ` (Inspectie toont dit als ${inspectieSchooljaren})`;
			} else if (indicator === 'exc' && [2022, 2023, 2024].includes(getPeiljaar(this.schooljaar!))) {
				indicatorTekst = 'Onvoldoende data';
			}

			tooltipElements.push({ label: indicatorNaam, value: indicatorTekst });
		}

		return tooltipElements;
	}

	onClick(path: Path<OnderwijsresultatenA, number[]>, indicator: string) {
		const dashboard = this.getDashboard(indicator);
		if (!dashboard) return;

		this.urlService.redirect([dashboard], this.getQueryParameters(path, indicator));
	}

	override onContextCreated(context: DashboardContext<OnderwijsresultatenI, OnderwijsresultatenA, OnderwijsresultatenComponent>) {
		const dataRoot = context.dataRoot;
		if (isUndefined(dataRoot)) {
			this.bron = 'Onbekend';
			return;
		}

		this.bron = ['Bronsysteem', 'Inspectie', 'Bronsysteem & Inspectie'][dataRoot.a.bron - 1];
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(dataRoot.a.or_is_prognose)));
	}

	private getQueryParameters(path: Path<OnderwijsresultatenA, number[]>, indicator: string) {
		let params = {};
		const exc = ['exc'].includes(indicator);

		const vestiging = path[1].k;
		if (vestiging) {
			const vestigingBrinFilter = exc ? 'cf_fk_br_vest.br_co_brin' : 'ds_fk_br_vest_van.br_co_brin';

			const valueParts = vestiging.split('(');
			Object.assign(params, this.filterService.encodeFilterValue(vestigingBrinFilter, valueParts[0].trim()));
		}

		let schooljaar = this.schooljaar;
		const variant = this.variant();
		if (variant === DashboardVariant.HISTORIE) {
			const peiljaar = path[4].k;
			schooljaar = `${Number(peiljaar) - 2}/${Number(peiljaar) - 1}`;
		}

		if (schooljaar) {
			const filterName = exc ? 'x_onderwijsresultaten_cf_schooljaar' : 'x_onderwijsresultaten_ds_schooljaar';
			Object.assign(params, this.filterService.encodeFilterValue(filterName, schooljaar));
		}

		const niveau = this.niveau;
		if (niveau) {
			const filterName = exc ? 'cf_fk_ilt.ilt_nm_niveau' : 'ds_fk_ilt_van.ilt_nm_niveau';
			Object.assign(params, this.filterService.encodeFilterValue(filterName, niveau));
		}

		return params;
	}

	private getDashboard(indicator: string): string | undefined {
		switch (indicator) {
			case 'op':
				return 'onderwijsresultaten/onderwijspositie';
			case 'obs':
				return 'onderwijsresultaten/onderbouwsnelheid';
			case 'bbs':
				return 'onderwijsresultaten/bovenbouwsucces';
			case 'exc':
				return 'onderwijsresultaten/examencijfers';
		}
		return undefined;
	}

	getCellRows = memoize(this._getCellRows, (variant, row, ind, pj, pjn, a, f) =>
		JSON.stringify([variant, row.map((level) => level.k), ind, pj, pjn, a, f])
	);

	private _getCellRows(
		variant: DashboardVariant,
		row: Path<OnderwijsresultatenA, number[]>,
		indicator: string,
		peiljaar: number,
		peiljaren: number[],
		aantalJaren: number,
		_filter: FilterExpression
	): OnderwijsresultatenInputRow[] {
		if (variant == DashboardVariant.ACTUEEL) return this.getActueelCellRows(row, indicator, peiljaar, aantalJaren);
		else return this.getHistorieCellRows(row, indicator, peiljaar, peiljaren);
	}

	private getActueelCellRows(
		row: Path<OnderwijsresultatenA, number[]>,
		indicator: string,
		peiljaar: number,
		aantalJaren: number
	): OnderwijsresultatenInputRow[] {
		const indicatorRows = row[1].c.find((level) => level.k == indicator);
		if (isUndefined(indicatorRows)) return [];

		const schooljaar1 = peiljaar;
		const schooljaar2 = peiljaar - 1;
		const schooljaar3 = peiljaar - 2;
		// Voor onderwijspositie slaan we 2019/2020 over omdat de inspectie dat 2020/2021 noemt (en dat jaar dus overslaat)
		const gecorrigeerdAantalJaren = indicator === 'op' && aantalJaren === DRIE_SCHOOLJAREN_EXCL_2020 ? DRIE_SCHOOLJAREN_EXCL_2019 : aantalJaren;

		return indicatorRows.c
			.filter((cell) => !isUndefined(cell.c.find((level) => Number(level.k) == schooljaar1)))
			.map((cell) => {
				const jaar1 = cell.c.find((level) => Number(level.k) == schooljaar1)!;
				const jaar2 = cell.c.find((level) => Number(level.k) == schooljaar2);
				const jaar3 = cell.c.find((level) => Number(level.k) == schooljaar3);
				const bron_schooljaren = this.getBronSchooljaren(jaar1, indicator, peiljaar, gecorrigeerdAantalJaren);

				return {
					indicator: indicator,
					key: cell.k,
					or_nr_verschil: jaar1.a.or_nr_verschil,
					or_nr_verschil_vorig: jaar2 ? jaar2.a.or_nr_verschil : undefined,
					actueelRow: {
						bron_schooljaren,
						or_nr_norm_gecorrigeerd: jaar1.a.or_nr_norm_gecorrigeerd,
						or_nr_norm_landelijk: jaar1.a.or_nr_norm_landelijk,
						or_nr_score: jaar1.a.or_nr_score,
						or_nr_score_vorig: jaar2 ? jaar2.a.or_nr_score : undefined,
						or_nr_verschil_tov: jaar2 ? this.getVerschilTov(indicator, jaar1, jaar2) : undefined,
						or_nr_verschil2_tov: jaar2 && jaar3 ? this.getVerschilTov(indicator, jaar2, jaar3) : undefined,
						or_q_leerlingen: jaar1.a.or_q_leerlingen,
						schooljaar1,
						schooljaar2,
					},
				};
			});
	}

	private getVerschilTov(indicator: string, jaar1: Level<OnderwijsresultatenA, number[]>, jaar2: Level<OnderwijsresultatenA, number[]>) {
		const keer = indicator === 'exc' ? 1 : 100;
		return jaar1.a.or_nr_score * keer - jaar2.a.or_nr_score * keer;
	}

	private getBronSchooljaren(jaar1: Level<OnderwijsresultatenA, number[]>, indicator: string, peiljaar: number, aantalJaren: number) {
		const inspectieSchooljaren = (jaar1.a.or_nm_inspectie_schooljaren || '').split(',');
		const cumlaudeSchooljaren = (jaar1.a.or_nm_cl_schooljaren || '').split(',');
		return getOnderwijsresultatenSchooljarenRange(`${peiljaar - 2}/${peiljaar - 1}`, aantalJaren, indicator).map((schooljaar) => {
			let bron = 'Geen data';
			if (inspectieSchooljaren.includes(schooljaar)) bron = 'Inspectiedata';
			else if (cumlaudeSchooljaren.includes(schooljaar)) bron = 'Bronsysteem';

			return [schooljaar, bron];
		});
	}

	private getHistorieCellRows(
		row: Path<OnderwijsresultatenA, number[]>,
		indicator: string,
		peiljaar: number,
		peiljaren: number[]
	): OnderwijsresultatenInputRow[] {
		const indicatorRows = row[1].c.find((level) => level.k == indicator);
		if (isUndefined(indicatorRows)) return [];

		const schooljaar1 = peiljaar;
		const schooljaar2 = peiljaar - 1;

		return indicatorRows.c.map((cell) => {
			const jaar1 = cell.c.find((level) => Number(level.k) == schooljaar1);
			const jaar2 = cell.c.find((level) => Number(level.k) == schooljaar2);

			const chartData = peiljaren.map((peiljaar) => {
				const peiljaarCell = cell.c.find((level) => Number(level.k) == peiljaar);

				let dataPoints: PointInfo[] = [];
				if (!isUndefined(peiljaarCell)) {
					dataPoints.push({ lineClass: 'landelijke-norm', qty: peiljaarCell.a.or_nr_norm_landelijk });
					if (indicator !== 'op') dataPoints.push({ lineClass: 'gecorrigeerde-norm', qty: peiljaarCell.a.or_nr_norm_gecorrigeerd });
					dataPoints.push({ lineClass: 'score', qty: peiljaarCell.a.or_nr_score });
				}

				return { label: `${peiljaar}`, data: dataPoints, path: peiljaarCell?.r[0] };
			});

			const minMax: [number, number] = [indicatorRows.a.minScoreNorm, indicatorRows.a.maxScoreNorm];
			const formatter = OnderwijsresultatenComponent.getFormatter(indicator);

			const linechartProps: LinechartProps<OnderwijsresultatenA> = {
				yAxis: createYAxis(minMax, 5, formatter),
				data: [{ label: '', path: cell.r[0], data: chartData }],
				lineNames: { 'landelijke-norm': 'Landelijke norm', 'gecorrigeerde-norm': 'Gecorrigeerde norm', score: 'Score' },
				formatter,
				onClick: (path) => this.onClick(path, indicator),
			};

			return {
				indicator: indicator,
				key: cell.k,
				or_nr_verschil: jaar1 ? jaar1.a.or_nr_verschil : undefined,
				or_nr_verschil_vorig: jaar1 && jaar2 ? jaar2.a.or_nr_verschil : undefined,
				linechartProps,
			};
		});
	}

	private static getFormatter(indicator: string): (x: number) => string {
		if (indicator === 'exc') return (x) => formatNumber(x, 'nl_NL', '1.2-2');

		return (x) => formatPercent(x, 'nl_NL', '1.1-1');
	}

	override getExportTable(
		context: DashboardContext<OnderwijsresultatenI, OnderwijsresultatenA, CardListConfig<OnderwijsresultatenI, Attributes, OnderwijsresultatenA>>,
		options: ExportDataOptions
	) {
		const INDICATOREN: { [code: string]: string } = {
			op: 'Onderwijspositie',
			obs: 'Onderbouwsnelheid',
			bbs: 'Bovenbouwsucces',
			exc: 'Examencijfers',
		};
		// Het path dat getValue meekrijgt, bevat [root, vestiging, indicator, niveau, peiljaar]
		const columns = concat<ExportColumnDef<OnderwijsresultatenA> & { getValue: (path: Path<OnderwijsresultatenA, number[]>) => any }>(
			[
				{ name: 'Vestiging', type: 'string', getValue: (path) => path[1].k },
				{ name: 'Niveau', type: 'string', getValue: (path) => path[3].k },
			],
			this.variant() === DashboardVariant.HISTORIE
				? [
						{
							name: 'Schooljaar',
							type: 'string',
							getValue: (path) => {
								const jaar = Number(last(path)!.a.pj_nr_peiljaar);
								return `${jaar - 2}/${jaar - 1}`;
							},
						},
					]
				: [],
			[
				{ name: 'Indicator', type: 'string', getValue: (path) => INDICATOREN[<string>path[2].k] },
				{
					name: 'Score',
					type: 'number',
					format: '1.2-2',
					getValue: (path) => last(path)!.a.or_nr_score * (path[2].k === 'exc' ? 1 : 100),
				},
				{
					name: 'Norm na correctie',
					type: 'number',
					format: '1.2-2',
					getValue: (path) => last(path)!.a.or_nr_norm_gecorrigeerd * (path[2].k === 'exc' ? 1 : 100),
				},
				{
					name: 'Kwalificatie',
					type: 'string',
					getValue: (path) => {
						const { or_nr_norm_gecorrigeerd, or_nr_score } = last(path)!.a;
						// De inspectie vergelijkt afgeronde waarden afgerond op 2 decimalen. 0,00% is "boven de norm"
						// 2 decimalen van een percentage is 4 decimalen van de breuk
						const decimalen = path[2].k === 'exc' ? 2 : 4;
						const or_nr_norm_gecorrigeerd_afgerond = roundToDecimals(or_nr_norm_gecorrigeerd, decimalen);
						const or_nr_score_afgerond = roundToDecimals(or_nr_score, decimalen);
						if (or_nr_score_afgerond >= or_nr_norm_gecorrigeerd_afgerond) return 'Boven de norm';
						return 'Onder de norm';
					},
				},
			]
		);
		const rows = flatMap(
			context.dataRoot!.r.map((path) =>
				last(path)!
					.xr!.filter((path) => this.variant() === DashboardVariant.HISTORIE || last(path)!.a.pj_nr_peiljaar == this.peiljaar)
					.map((path) => columns.map((col) => col.getValue(path)))
			)
		);
		return { data: [{ columns, rows }], options };
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getOnderwijsresultatenExportData(options);
	}

	getDisplayOptions(): ExportFilter[] {
		return [
			{ label: 'Indicator over', value: indicatorOverOpties[this.aantalJaren] },
			{ label: 'Brondata', value: this.bron },
		];
	}

	openInstellingen() {
		this.dialog.open(OnderwijsresultatenOptionsDialogComponent, {
			backdropClass: 'cdk-overlay-transparent-backdrop',
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(this.instellingen)
				.withFlexibleDimensions(false)
				.withPositions([
					{
						originX: 'end',
						originY: 'bottom',
						overlayX: 'end',
						overlayY: 'top',
						offsetX: 0,
						offsetY: 8,
					},
				]),
		});
	}

	private getIndicatorNaam(indicator: string): string {
		switch (indicator) {
			case 'op':
				return 'Onderwijspositie';
			case 'obs':
				return 'Onderbouwsnelheid';
			case 'bbs':
				return 'Bovenbouwsucces';
			case 'exc':
				return 'Examencijfers';
			default:
				throw new Error(`Niet herkende indicator ${indicator}`);
		}
	}
}
