<app-base-sidebar
	title="Data snapshots"
	sizeClass="size-25"
	[bottomBarTemplate]="bottomBar"
	(close)="sidebarRef.requestClose()"
>
	<div class="content">
		<div class="result" (click)="loadOrClearSnapshot()">
			<span class="bullet" [class.visible]="!datasnapUrlService.isViewingSnapshot()">☞ </span>
			<span [class.current]="!datasnapUrlService.isViewingSnapshot()">[ live data ]</span>
		</div>
		@for (result of data; track result.id) {
			<div class="result" (click)="loadOrClearSnapshot(result)">
				<span class="bullet" [class.visible]="datasnapUrlService.isViewing(result)()">☞ </span>
				<span [class.current]="datasnapUrlService.isViewing(result)()">{{ resultTitle(result) }}</span>
			</div>
		}
	</div>
	<ng-template #bottomBar>
		<app-button buttonType="secondary" (clickit)="sidebarRef.requestClose()">Sluiten</app-button>
	</ng-template>
</app-base-sidebar>

