import { FilterName } from './filter-config';

// {k: [v1,v2]}: k wordt weggehaald als v1 of v2 in de defaults zit
export const interferingFilters: Partial<{ [name in FilterName]: FilterName[] }> = {
	ds_nm_status: ['ds_nm_op_status_van'],
	ds_nm_op_status_van: ['ds_nm_status'],
	ds_nm_tussentijdse_uitstroom_vso: ['ds_nm_einduitstroom_vso'], // CL-2564
	ds_nm_einduitstroom_vso: ['ds_nm_tussentijdse_uitstroom_vso'], // CL-2564
	ds_nm_cohortstatus_vanaf: ['ds_fk_ll.ll_is_cohortstatus_tot_volledig'],
	ds_nm_cohortstatus_examenopstroom: ['ds_fk_ll.ll_is_cohortstatus_tot_volledig'],
	'ds_fk_ll.ll_is_cohortstatus_tot_volledig': ['ds_nm_cohortstatus_vanaf', 'ds_nm_cohortstatus_examenopstroom'],
};
