@if (isEmptyLeerlingselectie() | async) {
	<app-button (clickit)="toggleDropdown()" buttonType="text-hover" [hovering]="showDropdown">+ Kies leerlingselectie</app-button>
} @else {
	<div class="label" [class]="getClassNames()" [class.selected]="showDropdown" (click)="toggleDropdown()" [class.ephemeral]="containsAdHoc() | async">
		<span [title]="getTitle() | async" class="text-content-semi">{{ value | async }}</span>
		@if (isOptional()) {
			<i class="icon-exit" (click)="removeFilter()"></i>
		}
	</div>
}
@if (showDropdown) {
	<div class="dropdown-anchor">
		<div class="dropdown-border" [class.empty-leerlingselectie]="isEmptyLeerlingselectie() | async" (clickOutside)="showDropdown = false" [clickOutsideEvents]="'click,touchend'" [delayClickOutsideInit]="true">
			<div class="dropdown-viewport">
				<div class="dropdown-content">
					@if (isDetailsList()) {
						<app-leerling-selectie-filter [detailsList]="true"></app-leerling-selectie-filter>
					} @else {
						<ng-template appFilterInput in-dropdown [filterName]="filterName"></ng-template>
					}
				</div>
			</div>
		</div>
	</div>
}
