import { Component, input, inject } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { QueryParamStateService } from '../services/query-param-state.service';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { HistoryService } from '@cumlaude/shared-services';
import { LeerlingSelectieId, LeerlingSelectieService } from '../services/leerling-selectie.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { FilterLabelDetailsListComponent } from '../filter-label/filter-label-details-list.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-selection-bar',
	templateUrl: './selection-bar.component.html',
	styleUrls: ['./filter-bar.component.scss'],
	imports: [ButtonComponent, AsyncPipe, FilterLabelDetailsListComponent],
})
export class SelectionBarComponent {
	protected readonly filterService = inject(FilterService);
	protected readonly qp = inject(QueryParamStateService);
	protected readonly historyService = inject(HistoryService);
	protected readonly leerlingSelectieService = inject(LeerlingSelectieService);

	showTerug = input(false);
	terugPageNiet = input<string>();
	showSelection = input.required<boolean>();

	adHocSelection = toSignal(this.leerlingSelectieService.getAdHocSelection());

	currentSelection: Observable<LeerlingSelectieId[]>;

	constructor() {
		this.currentSelection = this.leerlingSelectieService.observeDetailsList();
	}

	goBack() {
		const urlPart = this.terugPageNiet();
		if (urlPart) this.historyService.backToPageNot(urlPart);
	}

	async slaSelectieOp() {
		const saved = await this.leerlingSelectieService.slaSelectieOp(this.adHocSelection()!);
		if (saved) {
			const { id, naam } = saved;
			this.qp.dispatch('details-list', [{ id: id!, naam }]);
		}
	}

	getSaveSelectionLabel() {
		const { size } = this.adHocSelection()!;
		return `Leerlingselectie opslaan${size ? ` (${size})` : ''}`;
	}
}
