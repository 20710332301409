<app-dashboard-container
	class="dashboard-uitzondering"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="initialFilters"
		[allFilters]="getAllFilters()"
		[overrideDefault]="overrideDefault"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<app-filter-bar [showTerug]="from !== undefined" [terugPageNiet]="'/details'" [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header [leftOptionsTemplate]="leftOptions">
			<ng-template #leftOptions>
				@if (aantalJaren) {
					<app-schooljaar-indicator-over [hideExclusief]="hideExclusief"></app-schooljaar-indicator-over>
				}
			</ng-template>
		</app-dashboard-header>
		@if (aantalJaren) {
			<div class="caption">
				{{ getSchooljarenCaption() }}
				@if (getSchooljarenTooltip()) {
					<span class="svg-info" [appTooltip]="getSchooljarenTooltip()!"></span>
				}
			</div>
		}
		<app-barchart-table
			[filters]="filterExpressions"
			[permanentFilters]="permanentFilterExpressions"
			[groups]="groups"
			[config]="this"
			[barchartWidth]="200"
		></app-barchart-table>
	</section>
</app-dashboard-container>
