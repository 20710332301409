import { Component, inject } from '@angular/core';
import { CellDataType, getFormattedValue, TableCellComponent } from '../../table/table.model';
import { isNumeric } from '@cumlaude/shared-utils';
import { TooltipDirective, TooltipType } from '@cumlaude/shared-components-overlays';
import { Selection } from '../../../../dashboard/base-dashboard/base-dashboard-config';
import { MeatballMenuComponent } from '@cumlaude/shared-components-menu';
import { LeerlingSelectieService } from '../../../../../services/leerling-selectie.service';

export type LeerlingAantalCellData = {
	selection?: Selection;
	/**
	 * Het aantal dat getoond wordt. Kan afwijken van selection.size als het om een percentage gaat of een prognose (nr_weging)
	 */
	displayAantal: number;
};

@Component({
	selector: 'app-leerling-aantal-cell',
	templateUrl: './leerling-aantal-cell.component.html',
	styleUrls: ['./leerling-aantal-cell.component.scss'],
	imports: [TooltipDirective, MeatballMenuComponent],
})
export class LeerlingAantalCellComponent implements TableCellComponent<LeerlingAantalCellData> {
	protected readonly leerlingSelectieService = inject(LeerlingSelectieService);

	data!: LeerlingAantalCellData;
	dataType: CellDataType = 'number';
	format?: string;
	tooltip?: TooltipType;

	getFormattedValue(): string {
		return getFormattedValue(this.data.displayAantal, this.dataType, this.format);
	}

	getExportValue(data: LeerlingAantalCellData) {
		if (['number', 'percentage', 'minutes'].includes(this.dataType) && isNumeric(data.displayAantal)) return Number(data.displayAantal);
		return data.displayAantal;
	}

	slaSelectieOp() {
		this.leerlingSelectieService.slaSelectieOp(this.data.selection!);
	}

	protected readonly Boolean = Boolean;
}
