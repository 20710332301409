import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { combineLatestWith, Subscription } from 'rxjs';
import { HistoryService } from '@cumlaude/shared-services';
import { get, isEmpty } from 'lodash-es';
import { RestLoggingService } from './services/rest.logging.service';
import { HttpCancelService } from './core/services/http-cancel-service';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { UserService } from './services/user.service';
import { ToastrService } from 'ngx-toastr';
import { messages } from './services/toastr-messages';
import { HamburgerMenuComponent } from '@cumlaude/shared-components-menu';
import { HeaderComponent } from './layout/header/header.component';
import { GtmService } from './services/gtm.service';
import { UrlService } from './services/url.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	imports: [RouterOutlet, HamburgerMenuComponent, RouterLink, HeaderComponent],
})
export class AppComponent implements OnInit, OnDestroy {
	// noinspection JSUnusedGlobalSymbols
	/**
	 * HistoryService moet in het hoofd component geinject worden zodat altijd de history getracked wordt tijdens het gebruiken van de applicatie.
	 * En op die manier dan de terug knoppen kunnen werken.
	 */
	readonly historyService = inject(HistoryService);
	readonly toastr = inject(ToastrService);
	readonly urlService = inject(UrlService);
	private readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);
	private readonly gtmService = inject(GtmService);
	private readonly httpCancelService = inject(HttpCancelService);
	private readonly router = inject(Router);
	private readonly userService = inject(UserService);

	private readonly subscriptions: Subscription[] = [];

	constructor() {
		const restLoggingService = inject(RestLoggingService);

		this.subscriptions.push(
			this.router.events
				.pipe(
					filter((event) => event instanceof ResolveEnd),
					map((event) => event.urlAfterRedirects)
				)
				.subscribe((url) => restLoggingService.currentDashboard.set(this.urlService.getDashboard(url))),

			this.router.events.subscribe((event) => {
				// Bij wegnavigeren naar ander dashboard of aanpassen van queryparams (incl filters) worden alle lopende requests gecancelled
				// door zowel frontend als backend.
				if (event instanceof NavigationStart) {
					this.httpCancelService.cancelPendingRequests(restLoggingService.currentDashboard(), this.urlService.getDashboard(event.url));
				}
			}),

			this.router.events.subscribe((event) => {
				if (event instanceof ResolveEnd) {
					const msg_key = (this.router.getCurrentNavigation()?.extras?.info as { toastr?: string })?.toastr;
					if (msg_key) {
						const { type, message } = messages[msg_key];
						if (message) this.toastr.show(message, undefined, undefined, this.toastr.toastrConfig.iconClasses[type]);
					}
				}
			})
		);
	}

	ngOnInit() {
		this.gtmService.init();
		this.initAppcues();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	private initAppcues() {
		const { appcuesAccountId, environmentName } = this.envConfig;

		if (!isEmpty(appcuesAccountId)) {
			const scriptTag = document.createElement('script');
			scriptTag.src = `//fast.appcues.com/${appcuesAccountId}.js`;
			scriptTag.addEventListener('load', () => {
				this.addAppcuesEventListeners(environmentName);
			});
			const head = document.getElementsByTagName('head')[0];
			head.appendChild(scriptTag);
		}
	}

	private addAppcuesEventListeners(environmentName: string) {
		const appcues = get(window, 'Appcues');
		if (!appcues) return;

		this.subscriptions.push(
			this.userService.myAccount$.pipe(combineLatestWith(this.userService.instelling$)).subscribe(([account, instelling]) => {
				appcues.identify(account.id, {
					naam: account.naam,
					support: account.support,
					rollen: account.rollen,
					omgeving: environmentName,
				});

				appcues.group(instelling.id, {
					naam: instelling.naam,
					bron: instelling.bron,
					modules: instelling.modules,
				});
			}),
			this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
				get(window, 'Appcues')?.page();
			})
		);
	}
}
