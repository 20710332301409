import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { last, memoize } from 'lodash-es';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { maxOver } from '../../../services/aggregation';
import { Path } from '../../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../../services/data.service';
import { att, percOfTotal } from '../../../services/measures';
import { BarInfo } from '../../../services/stacked-bars';
import { createMeasureColumn, DataRow } from '../../../shared/dashboard/data-tree-table/data-tree-table';
import { ColumnDef } from '../../../shared/components/table/table/table.model';
import { DashboardLeerling, LeerlingDetail } from '../../Details';
import { DetailsDashboard } from '../../base-details-panel/details.dashboard';
import { generateCssClassForLesregistratie } from '@cumlaude/shared-utils';
import { FilterName } from '../../../services/filter-config';
import { BarchartTableConfig } from '../../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes } from '../../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../../shared/dashboard/base-dashboard/dashboard-context';
import { ExportOptions } from '../../../services/export.service';
import { Table } from '@cumlaude/metadata';
import { AsyncPipe } from '@angular/common';
import { BarchartTableComponent } from '../../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../../filter-panel/filter-panel.component';
import { FilterBarComponent } from '../../../filter-bar/filter-bar.component';

interface LesregistratieredenenA extends Attributes {
	max: number;
}

@Component({
	selector: 'app-leerling-lesregistratieredenen-details-panel',
	templateUrl: './leerling-lesregistratieredenen-details-panel.component.html',
	styleUrls: ['./leerling-lesregistratieredenen-details-panel.component.scss'],
	imports: [FilterPanelComponent, DashboardHeaderComponent, BarchartTableComponent, AsyncPipe, FilterBarComponent],
})
export class LeerlingLesregistratieredenenDetailsPanelComponent
	extends BarchartTableConfig<Attributes, LesregistratieredenenA>
	implements OnInit, DetailsDashboard<LeerlingDetail>
{
	protected readonly dataService = inject(DataService);

	leerling$ = new ReplaySubject<DashboardLeerling>(1);

	override fixedBeforeGroups = 1;

	override flexibleMaxGroups = 0;

	filterExpressions?: FilterExpression[];

	filters: FilterName[] = ['lr_d_datum.per_nm_schooljaar', 'lr_nm_lesregistratie', 'lr_d_datum.per_d_datum'];

	groups: AttrPath[] = [['lr_nm_lesregistratie']];

	@ViewChild(DashboardHeaderComponent)
	dashboardHeaderComponent?: DashboardHeaderComponent;

	protected override singleAggregators = {
		max: maxOver('count_records'),
	};
	ngOnInit() {
		this.subscriptions.push(
			this.filterService.observeAsInput('lr_d_datum.per_nm_schooljaar').subscribe((val) => this.qp.dispatch('schooljaar', val))
		);
	}

	setSelected(selected: LeerlingDetail, schooljaar?: string): void {
		this.leerling$.next({
			leerling: selected,
			schooljaarInfo: schooljaar ?? selected.lb_nm_schooljaar,
		});
	}

	doDetailsExport(exportOptions: ExportOptions) {
		firstValueFrom(this.leerling$).then((leerling) =>
			this.doExport(this.filterExpressions!, this.getDashboardFilters(leerling.leerling), [], exportOptions)
		);
	}

	override getExportData(options: ExportDataOptions): Observable<Blob> {
		return this.dataService.getLesregistratieExportData(options);
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getLesregistratieData(options);
	}

	factTable = Table.fac_lr_lesregistratie;

	getDashboardFilters = memoize(LeerlingLesregistratieredenenDetailsPanelComponent._getDashboardFilters, (l) => l.lb_nr_leerling);

	private static _getDashboardFilters(leerling: LeerlingDetail): FilterExpression[] {
		return [new BasicFilterExpression(['lr_nr_leerling'], leerling.lb_nr_leerling)];
	}

	getSchooljaarFilterOverride = memoize(LeerlingLesregistratieredenenDetailsPanelComponent._getSchooljaarFilterOverride);

	private static _getSchooljaarFilterOverride(schooljaarInfo: string) {
		return { 'lr_d_datum.per_nm_schooljaar': schooljaarInfo };
	}

	override createMeasureColumns(
		_context: DashboardContext<Attributes, LesregistratieredenenA, LeerlingLesregistratieredenenDetailsPanelComponent>
	): ColumnDef<DataRow<LesregistratieredenenA>>[] {
		return [createMeasureColumn('Aantal', att('count_records'))];
	}

	override handleRowClick() {}

	override getBarchartQty(path: Path<LesregistratieredenenA, number[]>) {
		return 100 * percOfTotal('count_records', 'max')(path);
	}

	override makeBar(
		_attrs: Attributes,
		path: Path<LesregistratieredenenA, number[]>,
		_context: DashboardContext<Attributes, LesregistratieredenenA, LeerlingLesregistratieredenenDetailsPanelComponent>
	): BarInfo {
		const lr_nm_lesregistratie = last(path)!.k;
		return {
			size: 1,
			className: generateCssClassForLesregistratie(lr_nm_lesregistratie),
		};
	}
}
