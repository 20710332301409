import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, input, Input, OnChanges, Output, signal, SimpleChanges, viewChild, inject } from '@angular/core';
import { Option } from '../utils/option';
import { DEFAULT_EMPTY_STATE } from '../form-dropdown/form-dropdown.component';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { SingleSelectDropdownDialogComponent } from '@cumlaude/shared-components-dialogs';
import { firstValueFrom } from 'rxjs';

@Component({
	selector: 'app-single-select-dropdown',
	templateUrl: './single-select-dropdown.component.html',
	styleUrls: ['./single-select-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [],
})
export class SingleSelectDropdownComponent<T> implements OnChanges {
	private readonly dialog = inject(Dialog);
	private readonly overlay = inject(Overlay);

	@Input()
	showSearchInput: boolean = false;

	@Input()
	showEmpty: string = DEFAULT_EMPTY_STATE;

	@Input()
	searchInput: string = '';

	@Input()
	placeholder!: string;

	opties = input<Option<T>[]>([]);

	@Input()
	selectedOptie: Option<T> | undefined;

	@Output()
	onOptieClicked = new EventEmitter<T>();

	@Output()
	onSearchInput = new EventEmitter<string>();

	@Output()
	onShowDropdown = new EventEmitter<boolean>();

	showDropdown = signal(false);

	dropdownHostElement = viewChild.required<ElementRef>('dropdownHost');

	private dialogRef: DialogRef<Option<T>, SingleSelectDropdownDialogComponent<T>> | undefined;

	async openDialog() {
		this.onShowDropdown.emit(true);
		this.showDropdown.set(true);

		const dropdownElement = this.dropdownHostElement();
		const boundingClientRect = dropdownElement.nativeElement.getBoundingClientRect();

		this.dialogRef = this.dialog.open(SingleSelectDropdownDialogComponent<T>, {
			data: {
				opties: this.opties,
				selectedOptie: this.selectedOptie,
				showEmpty: this.showEmpty,
				showSearchInput: this.showSearchInput,
			},
			backdropClass: 'cdk-overlay-transparent-backdrop',
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(dropdownElement)
				.withFlexibleDimensions(false)
				.withPositions([
					{
						originX: 'center',
						originY: 'bottom',
						overlayX: 'center',
						overlayY: 'top',
						offsetY: 1,
					},
					{
						originX: 'center',
						originY: 'top',
						overlayX: 'center',
						overlayY: 'bottom',
						offsetY: -1,
					},
				]),
			width: boundingClientRect.width,
		});

		const instance = this.dialogRef.componentInstance!;
		const subscription = instance.onSearchInput?.subscribe((value) => this.onSearchInput.emit(value));
		instance.searchInput.set(this.searchInput);

		const newOption = await firstValueFrom(this.dialogRef.closed);
		if (newOption) {
			this.selectedOptie = newOption;
			this.onOptieClicked.emit(newOption.value);
		}

		subscription?.unsubscribe();
		this.onShowDropdown.emit(false);
		this.showDropdown.set(false);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ('searchInput' in changes && !changes['searchInput'].isFirstChange())
			this.dialogRef?.componentInstance!.searchInput.set(changes['searchInput'].currentValue);
	}
}
