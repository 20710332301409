import { Data, Route } from '@angular/router';
import { authGuard, NotFoundComponent, OAuthRouteComponent } from '@cumlaude/shared-authentication';
import { GebruikersComponent } from './beheer/gebruikers/gebruikers.component';
import { NewSessionComponent } from './core/new-session/new-session.component';
import { SomtodayComponent } from './core/somtoday/somtoday.component';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { DoorstroomComponent } from './dashboards/doorstroom/doorstroom.component';
import { GeslaagdComponent } from './dashboards/geslaagd/geslaagd.component';
import { InstroomComponent } from './dashboards/instroom/instroom.component';
import { KenmerkComponent } from './dashboards/kenmerk/kenmerk.component';
import { LeerlingComponent } from './dashboards/leerling/leerling.component';
import { OnderbouwsnelheidComponent } from './dashboards/onderbouwsnelheid/onderbouwsnelheid.component';
import { OvergangComponent } from './dashboards/overgang/overgang.component';
import { UitstroomComponent } from './dashboards/uitstroom/uitstroom.component';
import { PlaatsingAdviesComponent } from './dashboards/plaatsing-advies/plaatsing-advies.component';
import { CijfersOverzichtComponent } from './dashboards/cijfers-overzicht/cijfers-overzicht.component';
import { BovenbouwsuccesComponent } from './dashboards/bovenbouwsucces/bovenbouwsucces.component';
import { OnderwijspositieComponent } from './dashboards/onderwijspositie/onderwijspositie.component';
import { ExamencijfersComponent } from './dashboards/examencijfers/examencijfers.component';
import { ToetsComponent } from './dashboards/toets/toets.component';
import { MatrixComponent } from './dashboards/matrix/matrix.component';
import { VerschilSeCeComponent } from './dashboards/verschil-se-ce/verschil-se-ce.component';
import { CijfersSeCeComponent } from './dashboards/cijfers-se-ce/cijfers-se-ce.component';
import { AfwezigheidOverzichtComponent } from './dashboards/afwezigheid-overzicht/afwezigheid-overzicht.component';
import { EinduitstroomComponent } from './dashboards/uitstroom-vso/einduitstroom.component';
import { LeerlingDetailsPanelComponent } from './details/leerling/leerling-details-panel/leerling-details-panel.component';
import { DocentDetailsPanelComponent } from './details/docent/docent-details-panel/docent-details-panel.component';
import { DocentToetsDetailsPanelComponent } from './details/docent/docent-toets-details-panel/docent-toets-details-panel.component';
import { CijferlijstComponent } from './dashboards/cijferlijst/cijferlijst.component';
import { LeerlingAfwezigheidDetailsPanelComponent } from './details/leerling/leerling-afwezigheid-details-panel/leerling-afwezigheid-details-panel.component';
import { LeerlingAfwezigheidsredenenDetailsPanelComponent } from './details/leerling/leerling-afwezigheidsredenen-details-panel/leerling-afwezigheidsredenen-details-panel.component';
import { LeerlingCijferlijstDetailsPanelComponent } from './details/leerling/leerling-cijferlijst-details-panel/leerling-cijferlijst-details-panel.component';
import { LeerlingDoorstroomDetailsPanelComponent } from './details/leerling/leerling-doorstroom-details-panel/leerling-doorstroom-details-panel.component';
import { LesregistratiesComponent } from './dashboards/lesregistraties/lesregistraties.component';
import { UitzonderingDoorstroomComponent } from './dashboards/uitzondering/uitzondering-doorstroom.component';
import { LeerlingLesregistratiesDetailsPanelComponent } from './details/leerling/leerling-lesregistraties-details-panel/leerling-lesregistraties-details-panel.component';
import { UitzonderingBovenbouwsuccesComponent } from './dashboards/uitzondering/uitzondering-bovenbouwsucces.component';
import { UitzonderingOnderbouwsnelheidComponent } from './dashboards/uitzondering/uitzondering-onderbouwsnelheid.component';
import { DocentCijfersDetailsPanelComponent } from './details/docent/docent-cijfers-details-panel/docent-cijfers-details-panel.component';
import { CijfersDocentComponent } from './dashboards/cijfers-docent/cijfers-docent.component';
import { ToetsDetailsComponent } from './dashboards/toets-details/toets-details.component';
import { OnderwijsresultatenComponent } from './dashboards/onderwijsresultaten/onderwijsresultaten.component';
import { CohortrendementComponent } from './dashboards/cohortrendement/cohortrendement.component';
import { UitstroomIqComponent } from './dashboards/uitstroom-iq/uitstroom-iq.component';
import { TussentijdseUitstroomComponent } from './dashboards/uitstroom-vso/tussentijdse-uitstroom.component';
import { VestigingenComponent } from './beheer/vestigingen/vestigingen.component';
import { SchoolbreedComponent } from './beheer/schoolbreed/schoolbreed.component';
import { RollenComponent } from './beheer/rollen/rollen.component';
import { TeamComponent } from './beheer/team/team.component';
import { SectieComponent } from './beheer/sectie/sectie.component';
import { MentorComponent } from './beheer/docent-mentor/mentor.component';
import { BestuurComponent } from './beheer/bestuur/bestuur.component';
import { DocentComponent } from './beheer/docent-mentor/docent.component';
import { rolGuard } from './guards/rol-guard';
import { UitzonderingEinduitstroomComponent } from './dashboards/uitzondering/uitzondering-einduitstroom.component';
import { UitzonderingUitstroomIqComponent } from './dashboards/uitzondering/uitzondering-uitstroom-iq.component';
import { UitzonderingTussentijdseUitstroomComponent } from './dashboards/uitzondering/uitzondering-tussentijdse-uitstroom.component';
import { PrestatieanalyseComponent } from './dashboards/prestatieanalyse/prestatieanalyse.component';
import { CijfersExamensComponent } from './dashboards/cijfers-examens/cijfers-examens.component';
import { UitzonderingOnderwijspositieComponent } from './dashboards/uitzondering/uitzondering-onderwijspositie.component';
import { CijferkolommenComponent } from './beheer/cijferkolommen/cijferkolommen.component';
import { CijferkolommenDetailsComponent } from './beheer/cijferkolommen-details/cijferkolommen-details.component';
import { CohortdetailsComponent } from './dashboards/cohortdetails/cohortdetails.component';
import { NoMatchedSharedRouteComponent } from './core/no-matched-shared-route/no-matched-shared-route.component';
import { sharedMatcher } from './guards/shared-matcher';
import { RechtenComponent } from './beheer/rechten/rechten.component';
import { LeerlingLesregistratieredenenDetailsPanelComponent } from './details/leerling/leerling-lesregistratiesredenen-details-panel/leerling-lesregistratieredenen-details-panel.component';
import { VakkenComponent } from './dashboards/vakken/vakken.component';
import { LeerlingVakkenDetailsPanelComponent } from './details/leerling/leerling-vakken-details-panel/leerling-vakken-details-panel.component';
import { VakkenUitsluitenComponent } from './beheer/vakken-uitsluiten/vakken-uitsluiten.component';
import { BasisvaardigheidOverzichtComponent } from './dashboards/basisvaardigheid-overzicht/basisvaardigheid-overzicht.component';
import { LeerlingBasisvaardighedenDetailsPanelComponent } from './details/leerling/leerling-basisvaardigheden-details-panel/leerling-basisvaardigheden-details-panel.component';
import { vaardigheden, vaardigheid2route } from './services/vaardigheden';
import { BasisvaardighedenBeheerComponent } from './beheer/basisvaardigheden/basisvaardigheden-beheer.component';
import { SupportComponent } from './beheer/support/support.component';

export type CumLaudeRouteData = Data & {
	/**
	 * Naam het dashboard/menu item.
	 */
	name?: string;
	/**
	 * De groep van menus waar dit dasbhoard/menu item bij hoort. Op dit moment core (CumLaude dashboards) of beheer (CumLaude beheer schermen).
	 */
	base?: string;
	/**
	 * Bepaalt of het menu item in het top-level-menu moet worden weergegeven. Alleen toevoegen indien {{base}} niet is ingesteld.
	 */
	top?: boolean;
	/**
	 * Bepaalt of het menu item ook een root groep is. Gebeurt al automatisch indien deze op het onderste niveau in de route structuur zit.
	 */
	root?: boolean;
	/**
	 * Naam van het menu item indien deze afwijkt van de naam vna het dashboard.
	 */
	tab?: string;
	/**
	 * De vaste weergave opties van het dashboard. Wordt gebruikt om te bepalen welke weergave opties moeten worden behouden als je wisselt van dashboard. Worden niet gereset door de resetten knop.
	 */
	fixedWeergaveOpties?: string[];
	/**
	 * De flexibele weergave opties van het dashboard. Wordt gebruikt om te bepalen welke weergave opties moeten worden behouden als je wisselt van dashboard. Worden wel gereset door de resetten knop.
	 */
	flexibelWeergaveOpties?: string[];
	/**
	 * Bepaalt of de groepering query parameter behouden moet worden bij het wisselen van dashboard. Wordt alleen toegepast in het tab-menu.
	 */
	keepGroepering?: boolean;
};

export type CumLaudeRoute = Route & {
	data?: CumLaudeRouteData;
	children?: CumLaudeRoutes;
};

type CumLaudeRoutes = CumLaudeRoute[];

export const APP_ROUTES: CumLaudeRoutes = [
	{
		path: 'oauth',
		children: [
			{ path: '', component: OAuthRouteComponent },
			{ path: 'new', component: NewSessionComponent },
			{ path: 'somtoday', component: SomtodayComponent },
		],
	},
	{
		path: '',
		canActivate: [authGuard],
		canActivateChild: [authGuard],
		canMatch: [rolGuard],
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
			{
				path: 'dashboard',
				data: { name: 'Dashboard', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'mijn' },
					{ path: 'mijn', component: DashboardComponent },
				],
			},
			{
				path: 'onderwijsresultaten',
				data: { name: 'Onderwijsresultaten', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'onderwijsresultaten' },
					{
						path: 'onderwijsresultaten',
						data: {
							name: 'Onderwijsresultaten %prognose%',
							tab: 'Onderwijsresultaten',
							fixedWeergaveOpties: ['variant'],
							flexibelWeergaveOpties: ['indicator-over', 'toon-trend', 'inspectie-data'],
						},
						component: OnderwijsresultatenComponent,
					},
					{
						path: 'onderwijspositie',
						data: {
							name: 'Onderwijspositie t.o.v. advies PO %prognose%',
							tab: 'Onderwijspositie t.o.v. advies PO',
							fixedWeergaveOpties: ['variant'],
							flexibelWeergaveOpties: ['indicator-over', 'or-uitsluiten'],
						},
						component: OnderwijspositieComponent,
					},
					{
						path: 'onderbouwsnelheid',
						data: {
							name: 'Onderbouwsnelheid %prognose%',
							tab: 'Onderbouwsnelheid',
							fixedWeergaveOpties: ['variant'],
							flexibelWeergaveOpties: ['indicator-over', 'or-uitsluiten', 'brin-overgang'],
						},
						component: OnderbouwsnelheidComponent,
					},
					{
						path: 'bovenbouwsucces',
						data: {
							name: 'Bovenbouwsucces %prognose%',
							tab: 'Bovenbouwsucces',
							fixedWeergaveOpties: ['variant'],
							flexibelWeergaveOpties: ['indicator-over', 'or-uitsluiten', 'brin-overgang'],
						},
						component: BovenbouwsuccesComponent,
					},
					{
						path: 'examencijfers',
						data: {
							name: 'Examencijfers',
							fixedWeergaveOpties: ['variant'],
							flexibelWeergaveOpties: ['indicator-over', 'or-uitsluiten'],
						},
						component: ExamencijfersComponent,
					},
					{
						path: 'verschil-se-ce',
						data: {
							name: 'Verschil SE-CE',
							fixedWeergaveOpties: ['variant'],
							flexibelWeergaveOpties: ['indicator-over', 'or-uitsluiten'],
						},
						component: VerschilSeCeComponent,
					},
				],
			},
			{
				path: 'prestatieanalyse',
				data: { name: 'Prestatieanalyse', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'prestatieanalyse' },
					{
						path: 'prestatieanalyse',
						data: { name: 'Prestatieanalyse', fixedWeergaveOpties: ['variant'] },
						component: PrestatieanalyseComponent,
					},
					{ path: 'einduitstroom', data: { name: 'Einduitstroom', fixedWeergaveOpties: ['variant'] }, component: EinduitstroomComponent },
					{
						path: 'uitstroom-iq',
						data: { name: 'Uitstroom i.r.t. het IQ', fixedWeergaveOpties: ['variant'], flexibelWeergaveOpties: ['uitstroomiqweergave'] },
						component: UitstroomIqComponent,
					},
					{
						path: 'tussentijdse-uitstroom',
						data: { name: 'Tussentijdse uitstroom', fixedWeergaveOpties: ['variant'] },
						component: TussentijdseUitstroomComponent,
					},
				],
			},
			{
				path: 'basisvaardigheden',
				data: { name: 'Basisvaardigheden', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: vaardigheid2route(vaardigheden[0]) },
					...vaardigheden.map((vaardigheid) => ({
						path: vaardigheid2route(vaardigheid),
						component: BasisvaardigheidOverzichtComponent,
						data: {
							name: vaardigheid,
							bv_nm_vaardigheid: vaardigheid,
							fixedWeergaveOpties: ['aspect'],
							keepGroepering: true,
						},
					})),
				],
			},
			{
				path: 'cijfers',
				data: { name: 'Cijfers', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'overzicht' },
					{
						path: 'overzicht',
						data: { name: 'Cijferoverzicht', tab: 'Overzicht', fixedWeergaveOpties: ['variant'], flexibelWeergaveOpties: ['col'] },
						component: CijfersOverzichtComponent,
					},
					{ path: 'docent', data: { name: 'Docent', fixedWeergaveOpties: ['variant'] }, component: CijfersDocentComponent },
					{
						path: 'toets',
						data: { name: 'Toets', fixedWeergaveOpties: [], flexibelWeergaveOpties: ['cijfertype', 'dossier'] },
						component: ToetsComponent,
					},
					{
						path: 'toets-details',
						data: { name: 'Toets details', fixedWeergaveOpties: [], flexibelWeergaveOpties: ['cijfertype', 'dossier'] },
						component: ToetsDetailsComponent,
					},
					{
						path: 'se-ce',
						data: { name: 'SE-CE', fixedWeergaveOpties: ['variant'], flexibelWeergaveOpties: ['cijfersseceweergave'] },
						component: CijfersSeCeComponent,
					},
					{
						path: 'cijferlijst',
						data: { name: 'Cijferlijst', fixedWeergaveOpties: [], flexibelWeergaveOpties: ['tekortpunten', 'vakOrder'] },
						component: CijferlijstComponent,
					},
					{
						path: 'examens',
						data: {
							name: 'Examens %prognose%',
							tab: 'Examens',
							fixedWeergaveOpties: ['variant'],
							flexibelWeergaveOpties: ['afwijkingpercentiel'],
						},
						component: CijfersExamensComponent,
					},
				],
			},
			{
				path: 'doorstroom',
				data: { name: 'Doorstroom', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'idu' },
					{
						path: 'idu',
						data: { name: 'IDU', top: true },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'doorstroom' },
							{
								path: 'doorstroom',
								data: {
									name: 'Doorstroom %prognose%',
									tab: 'Doorstroom',
									fixedWeergaveOpties: ['variant'],
									flexibelWeergaveOpties: ['doorstroomweergave', 'brin-overgang'],
								},
								component: DoorstroomComponent,
							},
							{
								path: 'matrix',
								data: {
									name: 'Matrix %prognose%',
									tab: 'Matrix',
									fixedWeergaveOpties: ['variant'],
									flexibelWeergaveOpties: ['brin-overgang', 'eenheid'],
								},
								component: MatrixComponent,
							},
							{
								path: 'geslaagd',
								data: { name: 'Geslaagd %prognose%', tab: 'Geslaagd', fixedWeergaveOpties: ['variant'] },
								component: GeslaagdComponent,
							},
							{
								path: 'instroom',
								data: {
									name: 'Instroom %prognose%',
									tab: 'Instroom',
									fixedWeergaveOpties: ['variant'],
									flexibelWeergaveOpties: ['eenheid'],
								},
								component: InstroomComponent,
							},
							{
								path: 'uitstroom',
								data: {
									name: 'Uitstroom %prognose%',
									tab: 'Uitstroom',
									fixedWeergaveOpties: ['variant'],
									flexibelWeergaveOpties: ['eenheid'],
								},
								component: UitstroomComponent,
							},
							{
								path: 'overgang',
								data: {
									name: 'Overgang %prognose%',
									tab: 'Overgang',
									fixedWeergaveOpties: ['variant'],
									flexibelWeergaveOpties: ['eenheid'],
								},
								component: OvergangComponent,
							},
						],
					},
					{
						path: 'advies',
						data: { name: 'Advies', top: true },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'plaatsing' },
							{
								path: 'plaatsing',
								data: {
									name: 'Plaatsing / Advies %prognose%',
									tab: 'Plaatsing / Advies',
									fixedWeergaveOpties: ['variant'],
									flexibelWeergaveOpties: ['threshold', 'adviesType'],
								},
								component: PlaatsingAdviesComponent,
							},
						],
					},
					{
						path: 'cohort',
						data: { name: 'Cohortrendement', top: true },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'rendement' },
							{
								path: 'rendement',
								data: {
									name: 'Cohortrendement %prognose%',
									tab: 'Cohortrendement',
									fixedWeergaveOpties: ['variant'],
									flexibelWeergaveOpties: ['cohortrendementtype', 'cohortrendementweergave'],
								},
								component: CohortrendementComponent,
							},
							{
								path: 'details',
								data: { name: 'Cohortdetails', fixedWeergaveOpties: [], flexibelWeergaveOpties: ['cohortrendementtype', 'eenheid'] },
								component: CohortdetailsComponent,
							},
						],
					},
				],
			},
			{
				path: 'afwezigheid',
				data: { name: 'Afwezigheid', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'overzicht' },
					{
						path: 'overzicht',
						data: { name: 'Afwezigheid', tab: 'Overzicht', fixedWeergaveOpties: ['variant'], flexibelWeergaveOpties: ['tijdseenheid'] },
						component: AfwezigheidOverzichtComponent,
					},
					{
						path: 'lesregistraties',
						data: { name: 'Lesregistraties', fixedWeergaveOpties: ['variant'] },
						component: LesregistratiesComponent,
					},
				],
			},
			{
				path: 'leerling',
				data: { name: 'Leerling', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'leerling' },
					{
						path: 'leerling',
						data: { name: 'Leerling %prognose%', tab: 'Leerling', fixedWeergaveOpties: ['variant'], flexibelWeergaveOpties: ['eenheid'] },
						component: LeerlingComponent,
					},
					{
						path: 'kenmerk',
						data: { name: 'Kenmerk', fixedWeergaveOpties: ['variant'], flexibelWeergaveOpties: ['eenheid'] },
						component: KenmerkComponent,
					},
					{
						path: 'vakken',
						data: { name: 'Vakken', fixedWeergaveOpties: ['variant'], flexibelWeergaveOpties: ['eenheid', 'kenmerk'] },
						component: VakkenComponent,
					},
				],
			},
			{
				path: 'details',
				data: { name: 'Details', base: 'core' },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'leerling' },
					{
						path: 'leerling',
						data: { name: 'Leerling - %subject%', tab: 'Leerling', top: true },
						component: LeerlingDetailsPanelComponent,
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'doorstroom' },
							{
								path: 'doorstroom',
								data: {
									name: 'Doorstroom',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from'],
								},
								component: LeerlingDoorstroomDetailsPanelComponent,
							},
							{
								path: 'basisvaardigheden',
								data: {
									name: 'Basisvaardigheden',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from'],
								},
								redirectTo: `basisvaardigheden/${vaardigheid2route(vaardigheden[0])}`,
							},
							...vaardigheden.map((vaardigheid) => ({
								path: `basisvaardigheden/${vaardigheid2route(vaardigheid)}`,
								component: LeerlingBasisvaardighedenDetailsPanelComponent,
								data: { bv_nm_vaardigheid: vaardigheid },
							})),
							{
								path: 'cijferlijst',
								data: {
									name: 'Cijferlijst',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from', 'variant'],
									flexibelWeergaveOpties: ['cijfertype', 'dossier'],
								},
								component: LeerlingCijferlijstDetailsPanelComponent,
							},
							{
								path: 'afwezigheid',
								data: {
									name: 'Afwezigheid',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from'],
								},
								component: LeerlingAfwezigheidDetailsPanelComponent,
							},
							{
								path: 'afwezigheidsredenen',
								data: {
									name: 'Afwezigheidsredenen',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from'],
								},
								component: LeerlingAfwezigheidsredenenDetailsPanelComponent,
							},
							{
								path: 'lesregistraties',
								data: {
									name: 'Lesregistraties',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from'],
								},
								component: LeerlingLesregistratiesDetailsPanelComponent,
							},
							{
								path: 'lesregistratieredenen',
								data: {
									name: 'Lesregistratieredenen',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from'],
								},
								component: LeerlingLesregistratieredenenDetailsPanelComponent,
							},
							{
								path: 'vakken',
								data: { name: 'Vakken', fixedWeergaveOpties: ['selectie', 'adhoc', 'details-list', 'schooljaar', 'from'] },
								component: LeerlingVakkenDetailsPanelComponent,
							},
						],
					},
					{
						path: 'docent',
						data: { name: 'Docent - %subject%', tab: 'Docent', top: true },
						component: DocentDetailsPanelComponent,
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'cijfers' },
							{
								path: 'cijfers',
								data: { name: 'Cijfers', fixedWeergaveOpties: ['selectie', 'adhoc', 'schooljaar', 'from', 'variant'] },
								component: DocentCijfersDetailsPanelComponent,
							},
							{
								path: 'toets',
								data: {
									name: 'Toets',
									fixedWeergaveOpties: ['selectie', 'adhoc', 'schooljaar', 'from'],
									flexibelWeergaveOpties: ['cijfertype', 'dossier'],
								},
								component: DocentToetsDetailsPanelComponent,
							},
						],
					},
					{
						path: 'uitzondering',
						data: { name: 'Uitzonderingen', top: true },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'doorstroom' },
							{
								path: 'doorstroom',
								data: { name: 'Doorstroom', fixedWeergaveOpties: ['from'] },
								component: UitzonderingDoorstroomComponent,
							},
							{
								path: 'onderbouwsnelheid',
								data: { name: 'Onderbouwsnelheid', fixedWeergaveOpties: ['from'], flexibelWeergaveOpties: ['indicator-over'] },
								component: UitzonderingOnderbouwsnelheidComponent,
							},
							{
								path: 'onderwijspositie',
								data: { name: 'Onderwijspositie', fixedWeergaveOpties: ['from'], flexibelWeergaveOpties: ['indicator-over'] },
								component: UitzonderingOnderwijspositieComponent,
							},
							{
								path: 'bovenbouwsucces',
								data: { name: 'Bovenbouwsucces', fixedWeergaveOpties: ['from'], flexibelWeergaveOpties: ['indicator-over'] },
								component: UitzonderingBovenbouwsuccesComponent,
							},
							{
								path: 'einduitstroom',
								data: { name: 'Einduitstroom', fixedWeergaveOpties: ['from'] },
								component: UitzonderingEinduitstroomComponent,
							},
							{
								path: 'uitstroom-iq',
								data: { name: 'Uitstroom i.r.t. het IQ', fixedWeergaveOpties: ['from'] },
								component: UitzonderingUitstroomIqComponent,
							},
							{
								path: 'tussentijdse-uitstroom',
								data: { name: 'Tussentijdse uitstroom', fixedWeergaveOpties: ['from'] },
								component: UitzonderingTussentijdseUitstroomComponent,
							},
						],
					},
				],
			},
			{
				path: 'beheer',
				data: { root: true },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'gebruikers' },
					{ path: 'gebruikers', data: { name: 'Gebruikers', base: 'beheer' }, component: GebruikersComponent },
					{ path: 'vestigingen', data: { name: 'Vestigingen', base: 'beheer' }, component: VestigingenComponent },
					{
						path: 'rollen',
						data: { name: 'Rollen', base: 'beheer' },
						component: RollenComponent,
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'school' },
							{ path: 'school', data: { name: 'School' }, component: SchoolbreedComponent },
							{ path: 'team', data: { name: 'Team' }, component: TeamComponent },
							{ path: 'sectie', data: { name: 'Sectie' }, component: SectieComponent },
							{ path: 'docent', data: { name: 'Docent' }, component: DocentComponent },
							{ path: 'mentor', data: { name: 'Mentor' }, component: MentorComponent },
							{ path: 'bestuur', data: { name: 'Bestuur' }, component: BestuurComponent },
						],
					},
					{
						path: 'rechten',
						data: { name: 'Rechten', base: 'beheer' },
						component: RechtenComponent,
					},
					{
						path: 'cijferinstellingen',
						data: { name: 'Cijferinstellingen', base: 'beheer' },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: 'vakken_uitsluiten' },
							{ path: 'vakken_uitsluiten', data: { name: 'Vakken' }, component: VakkenUitsluitenComponent },
							{ path: 'cijferkolommen', data: { name: 'Cijferkolommen' }, component: CijferkolommenComponent },
							{ path: 'cijferkolommen-details', data: { name: 'Cijferkolommen details' }, component: CijferkolommenDetailsComponent },
						],
					},
					{
						path: 'basisvaardigheden',
						data: { name: 'Basisvaardigheden', base: 'beheer' },
						children: [
							{ path: '', pathMatch: 'full', redirectTo: vaardigheid2route(vaardigheden[0]) },
							...vaardigheden.map((vaardigheid) => ({
								path: vaardigheid2route(vaardigheid),
								component: BasisvaardighedenBeheerComponent,
								canDeactivate: [(component: BasisvaardighedenBeheerComponent) => component.tryDeactivate()],
								data: { name: vaardigheid, bv_nm_vaardigheid: vaardigheid },
							})),
						],
					},
					{
						path: 'support',
						data: { name: 'Support', base: 'beheer' },
						component: SupportComponent,
					},
				],
			},
			{
				path: 'shared',
				component: NoMatchedSharedRouteComponent,
				canMatch: [sharedMatcher],
			},
		],
	},
	{
		canActivate: [authGuard],
		canActivateChild: [authGuard],
		path: '**',
		component: NotFoundComponent,
	},
];
