import { Component, Input, OnChanges, inject } from '@angular/core';
import { BarInfo, BarInfoPct, buildPctStack } from '../../../../services/stacked-bars';
import { VBarOptions, VbarStyle } from '../vbar-batch/vbar-batch.component';
import { UrlService } from '../../../../services/url.service';
import { Attributes, Selection } from '../../base-dashboard/base-dashboard-config';
import { range, reverse, zipWith } from 'lodash-es';
import { Axis } from '../../../../services/axis';
import { getFormattedValue } from '../../../components/table/table/table.model';
import { Path } from '../../../../services/data-tree';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';

export type VMeasure = { type: 'string' | 'number' | 'percentage'; value: any; format?: string; visible?: boolean };

export const NO_MEASURE: VMeasure = { type: 'string', value: undefined };

export type VPartitionData = {
	stacks: BarInfo[][];
	measure: VMeasure;
	label: string;
	/** Hoogte van deze partition op de schaal van yAxis */
	qty: number | null;
	selection?: Selection;
	areas?: { className: string; qty?: number }[]; // qty undefined mag alleen op het eind, en vertaalt dan naar max
	tooltip?: TooltipElement[];
	path: Path<Attributes, number[]>;
};

let nextId = 0;

@Component({
	selector: 'app-vbar-series',
	templateUrl: './vbar-series.component.html',
	styleUrls: ['./vbar-series.component.scss'],
	imports: [TooltipDirective],
})
export class VbarSeriesComponent implements OnChanges {
	protected readonly urlService = inject(UrlService);

	@Input()
	partitions!: VPartitionData[];

	@Input() yAxis: Axis = { min: 0, max: 100, ticks: [] };

	/** Hoogte van de grafiek in pixels */
	@Input() barHeight = 100;

	@Input()
	style = VbarStyle.BAR;

	@Input()
	options?: VBarOptions;

	barWidth = 28;

	paddingTop = 18;

	paddingBottom = 40;

	gutterPx = 3;

	get paddingLeft() {
		const ticks = this.yAxis?.ticks ?? [];
		if (ticks.length === 0) return 0;

		return Math.max(10, ...ticks.map(({ label }) => label.length * 9));
	}

	get xrangeIncl() {
		return range(0, this.partitions.length + 1);
	}

	xlabels!: string[];

	/**
	 * Elke partition uit de input wordt omgezet in één pctStack: de verschillende stacks worden samengevoegd, met nieuwe bars voor gutters ertussenin.
	 */
	pctStacks!: BarInfoPct[][];

	measures!: string[];

	y0px = 100;

	/**
	 * zie getClipPathId()
	 */
	componentId = nextId++;

	ngOnChanges(): void {
		this.pctStacks = [];
		this.measures = [];
		this.xlabels = [];
		this.partitions.forEach((partitionData) => {
			const totalPx = this.qtyToPx(partitionData.qty);
			const stacks = totalPx >= 0 ? partitionData.stacks : reverse(partitionData.stacks).map((bars) => reverse(bars));
			this.pctStacks.push(buildPctStack(stacks, totalPx, this.gutterPx));
			this.measures.push(
				(partitionData.measure.visible ?? true)
					? getFormattedValue(partitionData.measure.value, partitionData.measure.type, partitionData.measure.format)
					: ''
			);
			this.xlabels.push(partitionData.label);
		});
		this.y0px = this.qtyToPx(this.yAxis.max);
	}

	getHeight() {
		return this.barHeight + this.paddingBottom + this.paddingTop;
	}

	getWidth() {
		return this.partitions.length * this.barWidth + this.paddingLeft + 0.5;
	}

	getViewBox() {
		return `0 0 ${this.getWidth()} ${this.getHeight()}`;
	}

	/**
	 * Coördinaten van de balkjes worden aangegeven t.o.v. de partition waar ze in zitten. Die loopt van (0,0) linksonder tot (1,100) rechtsboven (incl. margins).
	 */
	getPartitionTransform(i: number) {
		const px = this.qtyToPx(this.partitions[i].qty);
		const scaleY = px === 0 ? 1 : -px / 100;
		return `translate(${this.paddingLeft} ${this.y0px + this.paddingTop}) scale(${this.barWidth} ${scaleY}) translate(${i} 0)`;
	}

	getPartitionRectangles() {
		return this.partitions
			.map((p, i) => {
				const px = this.qtyToPx(p.qty);
				return {
					x: this.paddingLeft + (i + 0.2) * this.barWidth,
					y: this.y0px + this.paddingTop - (px > 0 ? px : 0),
					width: 0.6 * this.barWidth,
					height: Math.abs(px),
				};
			})
			.filter(({ height }) => height);
	}

	getBarTransform(i: number) {
		return `translate(${this.paddingLeft + i * this.barWidth} ${this.y0px + this.paddingTop})`;
	}

	/**
	 * (x,y) geeft aan waar het midden van de tekst komt, in het coördinatenstelsel van de stack.
	 * De tekst zelf krijgt de inverse scale zodat hij na de getPartitionTransform weer uitkomt op schermcoördinaten.
	 */
	getTextTransform(i: number, x: number, y: number) {
		const px = this.qtyToPx(this.partitions[i].qty);
		const scaleY = px === 0 ? 1 : -100 / px;
		return `translate(${x} ${y}) scale(${1 / this.barWidth} ${scaleY}) rotate(270)`;
	}

	/**
	 * Stelsel met de oorsprong bij de onderkant van de data.
	 */
	getXAxisTransform() {
		return `translate(${this.paddingLeft} ${this.barHeight + this.paddingTop})`;
	}

	/**
	 * Stelsel met de oorsprong bij de logische oorsprong van de data.
	 */
	getYAxisTransform() {
		return `translate(${this.paddingLeft} ${this.y0px + this.paddingTop})`;
	}

	/**
	 * Stelsel met y=0 een stukje boven de partitions en positieve y omlaag.
	 */
	getMeasureTransform(i: number) {
		const { qty } = this.partitions[i];
		const offsetPx = (qty ?? 0) >= 0 ? -8 : 8;
		return `translate(${this.paddingLeft + (i + 0.5) * this.barWidth} ${this.paddingTop + this.y0px + offsetPx - this.qtyToPx(qty)})`;
	}

	/**
	 * Een uniek id zodat voor elke bar een <clipPath> te definiëren is waarnaar later terug gerefereerd kan worden.
	 * Omdat het uniek moet zijn over de hele web page, krijgt ook elk vbar-series component een uniek (oplopend) id.
	 * Het zou handiger zijn als hier een functie in Angular voor was. https://github.com/angular/angular/issues/5145
	 */
	getClipPathId(i: number, j: number) {
		return `cp-${this.componentId}-${i}-${j}`;
	}

	onBarClick(record: BarInfo) {
		this.urlService.navigateToSelection(record.selection);
	}

	onTextClick(i: number) {
		this.urlService.navigateToSelection(this.partitions[i].selection);
	}

	getSVGAreas(i: number): { y: number; height: number; className: string }[] {
		const areas = this.partitions[i].areas ?? this.yAxis.areas;
		if (!areas) return [];

		const { min, max } = this.yAxis;
		const qties = areas.map(({ qty }) => qty ?? max);
		const classes = areas.map(({ className }) => className);
		return zipWith([min, ...qties.slice(0, -1)], [...qties], classes, (lo, hi, className) => ({
			y: -this.qtyToPx(hi),
			height: this.qtyToPx(hi - lo),
			className,
		}));
	}

	getSVGTicks() {
		return this.yAxis.ticks.map(({ qty, label }) => ({
			y: -this.qtyToPx(qty),
			label,
		}));
	}

	qtyToPx(qty: number | null) {
		if (qty === null) return 0;

		const { min, max } = this.yAxis;
		return (this.barHeight * qty) / (max - min);
	}
}
