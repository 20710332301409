import { Component, HostBinding, OnDestroy, HostAttributeToken, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppMenuService } from '../services/menu.service';

@Component({
	selector: 'app-top-level-menu',
	templateUrl: './top-level-menu.component.html',
	styleUrls: ['./top-level-menu.component.scss'],
	imports: [RouterLink, RouterLinkActive],
})
export class TopLevelMenuComponent implements OnDestroy {
	protected readonly appMenuService = inject(AppMenuService);

	openSubMenu?: string;

	@HostBinding('class.vertical')
	vertical: boolean;

	subscriptions: Subscription[] = [];

	constructor() {
		const vertical = inject(new HostAttributeToken('vertical'), { optional: true });
		const router = inject(Router);
		const activatedRoute = inject(ActivatedRoute);

		this.vertical = vertical !== null;

		this.subscriptions.push(
			router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map(() => activatedRoute.firstChild?.firstChild),
					filter((route) => route?.outlet === 'primary'),
					switchMap((route) => route!.url)
				)
				.subscribe((segments) => {
					this.close();
					const firstUrlPart = segments[0]?.path;
					for (const menuItem of this.appMenuService.topLevelMenu()) {
						if (!menuItem.subMenus) continue;

						if (firstUrlPart === menuItem.name && this.vertical) this.open(firstUrlPart);
					}
				})
		);
	}

	openIfHorizontal(name: string) {
		if (!this.vertical) this.open(name);
	}

	private open(name: string) {
		this.openSubMenu = name;
	}

	closeIfHorizontal(name: string) {
		if (!this.vertical && this.openSubMenu === name) this.close();
	}

	close() {
		this.openSubMenu = undefined;
	}

	toggle(name: string, event: Event) {
		this.cancelRouterIfVertical(event);
		if (this.openSubMenu === name) this.close();
		else this.open(name);
	}

	cancelRouterIfVertical(event: Event) {
		if (this.vertical) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
