import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { SIDEBAR_INPUT, SidebarComponent, SidebarInput } from './sidebar.component';
import { Observable, Observer, Subject } from 'rxjs';

export const SIDEBAR_DATA = new InjectionToken('sidebarData');

export type SidebarCloseRequest<R> = {
	result: R | undefined;
	/** Observer om true of false in te emitten om het sluiten wel of niet door te laten gaan. */
	allowClose: Observer<boolean>;
};

export class SidebarRef<R> {
	constructor(public overlayRef: OverlayRef) {}
	readonly closed = new Subject<R | undefined>();
	private readonly closeRequestedSubject = new Subject<SidebarCloseRequest<R>>();

	/**
	 * Registreert een subscriber die een closeRequest afhandelt. De subscriber moet een true of een false emitten in de
	 * allowClose-observer om het sluiten wel of niet door te laten gaan.
	 */
	closeRequested(): Observable<SidebarCloseRequest<R>> {
		this.closeRequestSubscriberCount++;
		return this.closeRequestedSubject.asObservable();
	}

	private closeRequestSubscriberCount = 0;

	/**
	 * Vraagt de sidebar om te sluiten. Als er subscribers zijn die een closeRequest afhandelen, dan worden deze aangeroepen.
	 * Als alle subscribers een true emitten, dan wordt de sidebar gesloten met de meegegeven result.
	 * Als er überhaupt geen subscribers zijn kunnen we direct sluiten.
	 */
	requestClose(result?: R) {
		if (this.closeRequestSubscriberCount == 0) {
			this.close(result);
		}

		const allowClose = new Subject<boolean>();
		const responses: boolean[] = [];
		const subscription = allowClose.subscribe((allow) => {
			responses.push(allow);
			// Als alle subscribers (asynchroon) antwoord hebben gegeven, en alle antwoorden zijn true, dan kunnen we sluiten.
			if (responses.length === this.closeRequestSubscriberCount) {
				if (responses.every(Boolean)) this.close(result);
				subscription.unsubscribe();
			}
		});
		this.closeRequestedSubject.next({ result, allowClose });
	}

	private close(result?: R) {
		this.closed.next(result);
		this.closed.complete();
		this.overlayRef.detach();
	}
}

/**
 * Service om sidebars te openen (op dezelfde manier als Dialog.open).
 */
@Injectable({
	providedIn: 'root',
})
export class SidebarService {
	private readonly overlay = inject(Overlay);

	open<R = unknown, D = unknown, C = unknown>(component: ComponentType<C>, config?: { data?: D | null }): SidebarRef<R> {
		const overlayRef = this.overlay.create({ panelClass: 'sidebar-overlay-pane', hasBackdrop: true });
		const sidebarRef = new SidebarRef<R>(overlayRef);

		// Injector waarmee het meegegeven component de data kan ophalen met @Inject(SIDEBAR_DATA) in de constructor of field = inject(SIDEBAR_DATA).
		// Ook de SidebarRef wordt meegegeven zodat het component de sidebar kan sluiten.
		const dataInjector = Injector.create({
			providers: [
				{ provide: SIDEBAR_DATA, useValue: config?.data },
				{ provide: SidebarRef, useValue: sidebarRef },
			],
		});

		// Het SidebarComponent krijgt het meegegeven component en de dataInjector mee als providers.
		const sidebarInput: SidebarInput<C> = { component, injector: dataInjector };
		const sidebarInjector = Injector.create({ providers: [{ provide: SIDEBAR_INPUT, useValue: sidebarInput }] });
		const componentPortal = new ComponentPortal(SidebarComponent, null, sidebarInjector);
		overlayRef.attach(componentPortal);
		overlayRef.backdropClick().subscribe(() => sidebarRef.requestClose());

		return sidebarRef;
	}
}
