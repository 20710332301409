<div class="dashboard-basisvaardigheden">
	<section class="dashboard">
		<div class="vaardigheden-tab">
			@for (vaardigheid of vaardigheden; track vaardigheid) {
				<div
					class="vaardigheid-tab-button"
					[class.selected]="vaardigheid === bv_nm_vaardigheid()"
					[routerLink]="getRouterLink(vaardigheid)"
					[attr.data-exportable]="vaardigheid === bv_nm_vaardigheid() ? true : undefined"
					queryParamsHandling="merge"
				>
					{{ vaardigheid }}
				</div>
			}
			<app-legenda class="right" [attribute]="['bv_fun_referentieniveau_bin']" [exclude]="legendaExclude()" [keyIsClassName]="true"></app-legenda>
		</div>
		@if (chartData()?.scores?.length) {
			<app-score-linechart [data]="chartData()!" data-exportable></app-score-linechart>
		} @else if (chartData()) {
			<div>Geen toetsen gevonden.</div>
		}
	</section>
</div>
