import { ChangeDetectionStrategy, Component, computed, input, Signal, signal, TemplateRef, ViewChild, inject } from '@angular/core';
import { TabMenuComponent } from '../../layout/tab-menu/tab-menu.component';
import { MultiSelectDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { Niveau, Vaardigheid } from '@cumlaude/metadata';
import { combineLatest, firstValueFrom, map, Observable, of } from 'rxjs';
import { RBasisvaardighedenNormTable, RVestiging } from '@cumlaude/service-contract';
import { AsyncPipe } from '@angular/common';
import { getHeleNiveaus, getNiveauLabel } from '../../services/vaardigheden';
import { getSchooljaarTovHuidig, prettyThingsList } from '@cumlaude/shared-utils';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMap } from 'rxjs/operators';
import { RestService } from '@cumlaude/shared-services';
import { cloneDeep, fromPairs, last, range, slice, sortBy, sum, union } from 'lodash-es';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { Dialog } from '@angular/cdk/dialog';
import { DataService } from '../../services/data.service';
import { Level, unnest } from '../../services/data-tree';
import { sortLike } from '../../services/labels';
import { ToastrService } from 'ngx-toastr';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';

@Component({
	selector: 'app-basisvaardigheden-beheer',
	templateUrl: './basisvaardigheden-beheer.component.html',
	styleUrls: ['./basisvaardigheden-beheer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TabMenuComponent, MultiSelectDropdownComponent, ButtonComponent, AsyncPipe, ConfirmDialogComponent, TooltipDirective],
})
export class BasisvaardighedenBeheerComponent {
	private readonly restService = inject(RestService);
	private readonly dialog = inject(Dialog);
	private readonly toastr = inject(ToastrService);
	private readonly dataService = inject(DataService);

	bv_nm_vaardigheid = input.required<Vaardigheid>();

	niveaus = computed(() => getHeleNiveaus(this.bv_nm_vaardigheid()).map((nr) => getNiveauLabel(nr)));

	vestigingIds = signal<string[] | undefined>(undefined);

	schooljaren = signal<string[]>([getSchooljaarTovHuidig()]);

	savedTable = signal<RBasisvaardighedenNormTable | undefined>(undefined);

	table = signal<RBasisvaardighedenNormTable | undefined>(undefined);

	dirty = signal(false);

	showErrors = signal(false);

	overwriteWarning = signal<string | undefined>(undefined);

	totals = computed(() => this.table()?.rows?.map((r) => sum(r.doelen)) ?? []);

	incorrectRows = computed(() => this.totals().flatMap((total, ix) => (isTotalOk(total) ? [] : [ix])));

	correct = computed(() => this.incorrectRows().length === 0);

	vestigingNamen: Signal<{ [vestigingId: string]: string }>;

	schooljaarOpties = range(1, -10)
		.map(getSchooljaarTovHuidig)
		.map((schooljaar) => new Option(schooljaar));

	vestigingOpties$: Observable<Option<RVestiging>[]>;

	@ViewChild('confirmLeaveDialog')
	confirmLeaveDialog!: TemplateRef<unknown>;

	constructor() {
		const vestigingen$ = this.restService.getBeheerVestigingen();
		this.vestigingOpties$ = vestigingen$.pipe(
			map((vestigingen) => vestigingen.map((vestiging) => new Option(vestiging, vestiging.naam, vestiging.actief ? undefined : 'inactive')))
		);
		this.vestigingNamen = toSignal<{ [vestigingId: string]: string }, {}>(
			vestigingen$.pipe(map((vs) => fromPairs(vs.map((v) => [v.vestigingId, v.naam])))),
			{ initialValue: {} }
		);

		const leerfasesPerVestiging$ = this.dataService.getLeerfaseData().pipe(map((resp) => unnest(resp.data)[0]![0]!));

		const table$ = combineLatest([
			toObservable(this.schooljaren),
			toObservable(this.bv_nm_vaardigheid),
			toObservable(this.vestigingIds),
			leerfasesPerVestiging$,
		]).pipe(
			switchMap(([schooljaren, vaardigheid, vestigingIds, leerfaseRoot]) => {
				if (vestigingIds === undefined || vestigingIds.length === 0 || schooljaren.length == 0) return of(undefined);
				return this.restService
					.getBasisvaardighedenNormen(schooljaren, vaardigheid, vestigingIds)
					.pipe(map((tables) => this.addLeerfases(tables, this.getVestigingLeerfases(leerfaseRoot, vestigingIds!))));
			}),
			takeUntilDestroyed()
		);
		table$.subscribe((val) => {
			this.replaceTable(val);
		});
	}

	getVestigingLeerfases(leerfaseRoot: Level<unknown, number[]>, vestigingIds: string[]) {
		let leerfases: string[] = [];
		for (const vestigingLvl of leerfaseRoot.c)
			if (vestigingIds.includes(vestigingLvl.k!))
				leerfases = union(leerfases, vestigingLvl.c.map((leerfaseLvl) => leerfaseLvl.k).filter((lf) => lf !== null) as string[]);
		return leerfases;
	}

	/**
	 * Voegt een lege rij toe voor elke leerfase die nog niet in de tabel staat en waar de betreffende vestiging(en) wel plaatsingen voor heeft (hebben).
	 * NB side effect: produceert een overwriteWarning als er meerdere tables terug zijn gekomen van de backend
	 */
	addLeerfases(tables: RBasisvaardighedenNormTable[], vestigingLeerfases: string[]): RBasisvaardighedenNormTable {
		const table = tables[0] ?? <RBasisvaardighedenNormTable>(<unknown>{
				$type: 'instelling.RBasisvaardighedenNormTable',
				vaardigheid: this.bv_nm_vaardigheid(),
				rows: [],
			});
		if (tables.length > 1) {
			const omschrijvingen = tables.slice(1).map((t) => `${this.vestigingNamen()[t.vestigingId]} (${t.schooljaar})`);
			const warning = `Let op: hiermee overschrijf je de eerder ingestelde norm voor ${prettyThingsList(omschrijvingen, 8)}.`;
			this.overwriteWarning.set(warning);
		} else {
			this.overwriteWarning.set(undefined);
		}

		const tableLeerfases = table.rows.map((row) => row.leerfase);
		const alleLeerfases = union(vestigingLeerfases, tableLeerfases);
		const alleNiveaus = sortLike(union(vestigingLeerfases.map(getNiveau), tableLeerfases.map(getNiveau)), Object.values(Niveau));

		const newRows = [];
		for (const niveau of alleNiveaus) {
			const lfs = sortBy(alleLeerfases.filter((lf) => getNiveau(lf) === niveau));
			for (const lf of lfs) {
				newRows.push(table.rows.find((row) => row.leerfase === lf) ?? this.createLeerfaseRow(lf, table.vaardigheid));
			}
		}

		return { ...table, rows: newRows };
	}

	createLeerfaseRow(leerfase: string, vaardigheid: Vaardigheid) {
		const doelen = getHeleNiveaus(vaardigheid).map(() => null);
		return { leerfase, doelen };
	}

	updateTable(rowIx: number, colIx: number, event: Event) {
		const element = this.getInputElement(rowIx, colIx);
		const doel = this.textToFraction(element.value);
		element.value = this.fractionToText(doel);
		this.table()!.rows[rowIx].doelen[colIx] = doel;
		this.table.update((x) => ({ ...x }) as RBasisvaardighedenNormTable);
		this.dirty.set(true);
	}

	replaceTable(table: RBasisvaardighedenNormTable | undefined) {
		this.table.set(this.copyTable(table));
		this.savedTable.set(table);
		this.dirty.set(false);
		this.showErrors.set(false);
	}

	copyTable(input: RBasisvaardighedenNormTable | undefined): RBasisvaardighedenNormTable | undefined {
		return cloneDeep(input);
	}

	revert() {
		this.table.set(this.copyTable(this.savedTable()!));
		this.dirty.set(false);
		this.showErrors.set(false);
	}

	onKeydown(rowIx: number, colIx: number, event: KeyboardEvent) {
		const element = this.getNextElement(rowIx, colIx, event.key);
		if (element) setTimeout(() => element.select());
	}

	async tryDeactivate() {
		if (!this.dirty()) return true;
		const opslaan = await firstValueFrom(this.dialog.open(this.confirmLeaveDialog).closed);
		if (opslaan) {
			return await this.trySave();
		}
		return true;
	}

	async trySave() {
		if (!this.correct()) {
			this.showErrors.set(true);
			return false;
		}
		const table = this.table()!;
		const vestigingTables = this.schooljaren().flatMap((schooljaar) =>
			this.vestigingIds()!.map((vestigingId) => ({ ...table, vestigingId, schooljaar }))
		);
		const tables = await firstValueFrom(this.restService.postBasisvaardighedenNormen(vestigingTables));
		this.replaceTable(tables[0]);
		this.toastr.success(`De normen zijn opgeslagen.`);
		this.overwriteWarning.set(undefined);

		return true;
	}

	getNextElement(rowIx: number, colIx: number, key: string): HTMLInputElement | undefined {
		const maxRow = this.table()!.rows.length - 1;
		const maxCol = this.niveaus().length - 1;
		const current = this.getInputElement(rowIx, colIx);
		const caretAtStart = current.selectionStart === 0 && current.selectionEnd === 0;
		const caretAtEnd = current.selectionStart === current.value.length;

		switch (key) {
			case 'ArrowUp':
				return this.getInputElement(Math.max(0, rowIx - 1), colIx);
			case 'ArrowDown':
				return this.getInputElement(Math.min(rowIx + 1, maxRow), colIx);
			case 'ArrowLeft':
				return caretAtStart ? this.getInputElement(rowIx, Math.max(0, colIx - 1)) : undefined;
			case 'ArrowRight':
				return caretAtEnd ? this.getInputElement(rowIx, Math.min(colIx + 1, maxCol)) : undefined;
		}
		return;
	}

	isValid(doel: null | number): boolean {
		return doel === null || (0 <= doel && doel <= 1);
	}

	fractionToText(fraction: number | null) {
		return fraction === null ? '' : `${Math.round(fraction * 100)}`;
	}

	textToFraction(text: string) {
		const digits = text.replaceAll(/\D+/g, '');
		return digits === '' ? null : Number(digits) / 100;
	}

	getInputId(rowIx: number, colIx: number) {
		return `inp-r${rowIx}c${colIx}`;
	}

	getInputElement(rowIx: number, colIx: number) {
		return document.getElementById(this.getInputId(rowIx, colIx)) as HTMLInputElement;
	}

	selecteerVestigingen(vestigingen: RVestiging[]) {
		this.vestigingIds.set(vestigingen.map((v) => v.vestigingId));
	}

	protected readonly Math = Math;
}

function isTotalOk(total: number) {
	return [0, 100].includes(Math.round(total * 100));
}

function getNiveauLeerjaar(leerfase: string): [string, number] {
	const parts = leerfase.split(' ');
	const niveau = slice(parts, 0, -1).join(' ');
	const leerjaar = Number(last(parts));
	return [niveau, leerjaar];
}

function getNiveau(leerfase: string): string {
	return getNiveauLeerjaar(leerfase)[0];
}
