import { Component } from '@angular/core';
import { CellDataType, TableCellComponent } from '../../table/table.model';
import { CijferlijstData, getCijferClass, getCijferOfLabel, getExportValue } from '../../../../../services/cijfers';

@Component({
	selector: 'app-cijferlijst-cell',
	templateUrl: './cijferlijst-cell.component.html',
	styleUrls: ['./cijferlijst-cell.component.scss'],
})
export class CijferlijstCellComponent implements TableCellComponent<CijferlijstData | null> {
	data!: CijferlijstData | null;
	className?: string;
	dataType: CellDataType = 'number';
	format = '1.1-1';

	getCijferClass = getCijferClass;
	getCijferOfLabel = getCijferOfLabel;
	getExportValue = getExportValue;
}
