import { Component, computed, input, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PasfotoService } from '../../../services/pasfoto.service';
import { UserService } from '../../../services/user.service';

export type PasfotoSize = 'size-2' | 'size-2-5' | 'size-3' | 'size-3-5';

@Component({
	selector: 'app-pasfoto',
	templateUrl: './pasfoto.component.html',
	styleUrls: ['./pasfoto.component.scss'],
})
export class PasfotoComponent {
	private readonly sanitizer = inject(DomSanitizer);
	protected readonly pasfotoService = inject(PasfotoService);
	protected readonly userService = inject(UserService);

	pasfoto = input<string | null>();

	pasfotoKey = input<string | undefined>();

	size = input.required<PasfotoSize>();

	hasPasfoto = computed(() => {
		const pasfoto = this.pasfotoSrc();
		return !!pasfoto;
	});

	pasfotoSrc = computed(() => {
		const pasfoto = this.pasfoto();
		if (pasfoto) return this.getSafeResourceUrl(pasfoto);

		const activeRol = this.userService.getActiveRol();
		if (!activeRol) return undefined;

		const pasfotoKey = this.pasfotoKey();
		if (pasfotoKey) {
			const pasfotoFromKey = this.pasfotoService.getPasfoto(pasfotoKey)();
			if (pasfotoFromKey) return this.getSafeResourceUrl(pasfotoFromKey);
		}

		return undefined;
	});

	private getSafeResourceUrl(pasfoto: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;charset=utf-8;base64, ' + pasfoto);
	}
}
