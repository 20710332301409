import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, DestroyRef, input, model, NgZone, output, ViewChild, inject } from '@angular/core';
import { PasfotoComponent } from '../../../shared/components/pasfoto/pasfoto.component';
import { filter, map, pairwise, throttleTime } from 'rxjs';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { LeerlingRecord } from '../leerlingselectie-bewerken-sidebar/leerlingselectie-bewerken-sidebar.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-leerling-checklist',
	templateUrl: './leerling-checklist.component.html',
	styleUrls: ['./leerling-checklist.component.scss'],
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, PasfotoComponent, CheckboxComponent],
})
export class LeerlingChecklistComponent implements AfterViewInit {
	private readonly ngZone = inject(NgZone);
	private readonly destroyRef = inject(DestroyRef);

	elementen = input<LeerlingRecord[]>([]);

	emptyState = input<string | undefined>('Geen resultaten gevonden');

	selectie = model<number[]>([]);

	nextPage = output<number>();

	click(leerling: LeerlingRecord) {
		this.selectie.update((selectie) => {
			if (selectie.includes(leerling.nummer)) return selectie.filter((nummer) => nummer !== leerling.nummer);
			return [...selectie, leerling.nummer];
		});
	}

	isChecked(leerling: LeerlingRecord) {
		return this.selectie().includes(leerling.nummer);
	}

	@ViewChild('scroller') scroller!: CdkVirtualScrollViewport;

	ngAfterViewInit(): void {
		this.scroller
			.elementScrolled()
			.pipe(
				map(() => this.scroller.measureScrollOffset('bottom')),
				pairwise(),
				filter(([y1, y2]) => y2 < y1 && y2 < 140),
				throttleTime(200),
				takeUntilDestroyed(this.destroyRef)
			)
			.subscribe(() => {
				this.ngZone.run(() => this.nextPage.emit(this.elementen().length));
			});
	}

	trackByIx(index: number, _item?: any) {
		return index;
	}
}
