import { AfterViewInit, ComponentRef, Directive, ElementRef, Input, NgZone, OnDestroy, inject } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TooltipComponent, TooltipElement } from './tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { isArray } from 'lodash-es';
import { fromEvent, Subject, takeUntil } from 'rxjs';

export type TooltipType = string | string[] | TooltipElement[];

const rightPosition: ConnectedPosition = {
	originX: 'end',
	originY: 'top',
	overlayX: 'start',
	overlayY: 'top',
	offsetX: 16,
	offsetY: 0,
};

const leftPosition: ConnectedPosition = {
	originX: 'start',
	originY: 'top',
	overlayX: 'end',
	overlayY: 'top',
	offsetX: -16,
	offsetY: 0,
};

const topPosition: ConnectedPosition = {
	originX: 'center',
	originY: 'top',
	overlayX: 'center',
	overlayY: 'bottom',
	offsetX: 0,
	offsetY: -16,
};

const bottomPosition: ConnectedPosition = {
	originX: 'center',
	originY: 'bottom',
	overlayX: 'center',
	overlayY: 'top',
	offsetX: 0,
	offsetY: 28,
};

const centerPosition: ConnectedPosition = {
	originX: 'center',
	originY: 'center',
	overlayX: 'center',
	overlayY: 'center',
	offsetX: 0,
	offsetY: 0,
};

@Directive({
	selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy, AfterViewInit {
	private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);
	private readonly elementRef = inject(ElementRef);
	private readonly ngZone = inject(NgZone);
	private readonly overlay = inject(Overlay);

	@Input('appTooltip')
	value?: TooltipType;

	@Input('tooltipPosition')
	position: 'bottom' | 'right' = 'bottom';

	@Input('tooltipClass')
	tooltipClass?: string;

	private overlayRef?: OverlayRef;

	private timeoutId?: number;

	destroy$ = new Subject<void>();

	ngAfterViewInit() {
		// zie https://blog.simplified.courses/the-danger-of-angular-host-listeners/
		this.ngZone.runOutsideAngular(() => {
			fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mouseenter')
				.pipe(takeUntil(this.destroy$))
				.subscribe((_e: MouseEvent) => {
					this.show();
				});
		});
		this.ngZone.runOutsideAngular(() => {
			fromEvent<MouseEvent>(this.elementRef.nativeElement, 'mouseleave')
				.pipe(takeUntil(this.destroy$))
				.subscribe((_e: MouseEvent) => {
					this.hide();
				});
		});
	}

	ngOnDestroy() {
		this.hide();
		this.destroy$.next();
	}

	show() {
		this.hide();

		this.timeoutId = setTimeout(() => {
			this.showTooltip();
		}, 500);
	}

	hide() {
		clearTimeout(this.timeoutId);
		this.overlayRef?.detach();
		this.overlayRef?.dispose();
	}

	private showTooltip() {
		if (!this.value || (isArray(this.value) && this.value.length == 0)) return;

		const positionStrategy = this.overlayPositionBuilder
			.flexibleConnectedTo(this.elementRef)
			.withFlexibleDimensions(false)
			.withPositions(this.getPositions());

		this.overlayRef = this.overlay.create({ positionStrategy });
		const tooltipPortal = new ComponentPortal(TooltipComponent);
		const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);

		const instance = tooltipRef.instance;
		if (this.isString(this.value)) {
			instance.singleColumnTooltip = [this.value];
		} else if (this.isStringArray(this.value)) {
			instance.singleColumnTooltip = this.value;
		} else if (this.isTooltipElementArray(this.value)) {
			instance.multiColumnTooltip = this.value;
		}

		instance.tooltipClass = this.tooltipClass;

		tooltipRef.changeDetectorRef.detectChanges();
		this.overlayRef.updatePosition();
	}

	private isString(value: TooltipType): value is string {
		return typeof value == 'string';
	}

	private isStringArray(value: TooltipType): value is string[] {
		return typeof value[0] == 'string';
	}

	private isTooltipElementArray(value: TooltipType): value is TooltipElement[] {
		return !this.isStringArray(value) && !this.isString(value);
	}

	private getPositions(): ConnectedPosition[] {
		switch (this.position) {
			case 'bottom':
				return [bottomPosition, topPosition, rightPosition, leftPosition, centerPosition];
			case 'right':
				return [rightPosition, leftPosition, bottomPosition, topPosition, centerPosition];
		}
	}
}
