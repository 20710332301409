import { Component, signal, Signal } from '@angular/core';
import { AttrPath, BasicFilterExpression, FilterExpression, InFilterExpression } from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { BovenbouwsuccesComponent } from '../bovenbouwsucces/bovenbouwsucces.component';
import { Prestatie } from '@cumlaude/metadata';
import { memoize } from 'lodash-es';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { CheckboxComponent } from '@cumlaude/shared-components-inputs';
import { OnderwijsresultatenUitsluitenFilterComponent } from '../../onderwijsresultaten-uitsluiten-filter/onderwijsresultaten-uitsluiten-filter.component';
import { SchooljaarIndicatorOverComponent } from '../../schooljaar-indicator-over/schooljaar-indicator-over.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { SuccesConditie } from '../doorstroom/doorstroom.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

// De groepering is niet door de gebruiker aanpasbaar
const permanentGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

// aantal leerlingen voor het betreffende schooljaar voor de betreffende bekostigingings-BRIN moet >= 20 zijn
const uitsluitenFilter = new BasicFilterExpression(['ds_fk_or_obscl', 'or_q_leerlingen'], 20, '>=');

const permanentFilters: FilterExpression[] = [
	new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1),
	new BasicFilterExpression(['ds_fk_br_vest_van', 'br_is_vso'], 0),
	new InFilterExpression(['ds_nr_leerjaar_van'], [1, 2]),
	new BasicFilterExpression(['ds_fk_lb_van', 'lb_is_pl_voorlopig'], 0),
];

export const onderbouwsnelheidFilters: FilterExpression[] = [...permanentFilters, uitsluitenFilter];

@Component({
	selector: 'app-onderbouwsnelheid',
	templateUrl: './onderbouwsnelheid.component.html',
	styleUrls: ['./onderbouwsnelheid.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		SchooljaarIndicatorOverComponent,
		OnderwijsresultatenUitsluitenFilterComponent,
		CheckboxComponent,
		BarchartTableComponent,
		FilterBarComponent,
	],
})
export class OnderbouwsnelheidComponent extends BovenbouwsuccesComponent {
	override actueelGroups = permanentGroups;

	override historieGroups: Signal<AttrPath[]> = signal([
		...permanentGroups, //
		['ds_nm_schooljaar_van'],
	]);

	override actueelFilters: FilterName[] = [
		'x_onderwijsresultaten_ds_schooljaar',
		'ds_fk_br_vest_van.br_co_brin',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_nm_obs_uitzondering_van',
		'ds_nm_idu',
	];

	override legendaExcludes: Prestatie[] = [Prestatie.AFGEWEZEN, Prestatie.GESLAAGD, Prestatie.GEEN];

	// Leerlingen voor onderbouwsnelheid al succesvol als er geen doublure is (ook afstroom)
	protected override get succesConditie(): SuccesConditie {
		return {
			attr: ['ds_is_bevorderd'],
			weging: 'bevorderd_weging',
			lln: 'bevorderd_lln',
		};
	}

	override getUitzonderingUrl(): string {
		return '/details/uitzondering/onderbouwsnelheid';
	}

	override getPermanentExpressions = memoize(this._getPermanentExpressions, JSON.stringify);

	protected override _getPermanentExpressions(uitsluiten: boolean) {
		return uitsluiten ? [...permanentFilters, uitsluitenFilter] : permanentFilters;
	}
}
