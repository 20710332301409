import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { fromPairs } from 'lodash-es';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';

export enum LoadingType {
	TABLE,
	EXPORT,
	NONE,
}

const DELAYS: { [key in LoadingType]: number } = {
	[LoadingType.TABLE]: 500,
	[LoadingType.EXPORT]: 200,
	[LoadingType.NONE]: 0,
};

enum LoadingValue {
	FALSE,
	TRUE,
	IMMEDIATE,
}

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);

	private isLoading$: { [name: string]: BehaviorSubject<LoadingValue> } = fromPairs(
		Object.values(LoadingType).map((v: string | LoadingType) => [v, new BehaviorSubject<LoadingValue>(LoadingValue.FALSE)])
	);

	/**
	 * @param type
	 * @param immediate true als het loading-icoon direct moet worden getoond, in plaats van na een delay
	 */
	start(type: LoadingType, immediate = false) {
		this.isLoading$[type].next(immediate ? LoadingValue.IMMEDIATE : LoadingValue.TRUE);
	}

	stop(type: LoadingType) {
		this.isLoading$[type].next(LoadingValue.FALSE);
	}

	shouldShowLoadingIndicator(type: LoadingType): Observable<boolean> {
		return this.isLoading$[type].pipe(
			distinctUntilChanged(),
			switchMap((value) => {
				switch (value) {
					case LoadingValue.FALSE:
						return of(false);
					case LoadingValue.TRUE:
						return of(true).pipe(delay(DELAYS[type]));
					case LoadingValue.IMMEDIATE:
						return of(true);
				}
			})
		);
	}

	getLoadingType(url: string) {
		if (url.startsWith(this.envConfig.dataUrl!)) {
			if (url.endsWith('/allfiltervalues') || url.endsWith('/activefiltervalues') || url.endsWith('/cancel-pending') || url.includes('details'))
				return LoadingType.NONE;
			if (url.endsWith('/exportdata') || url.endsWith('/tableexport')) return LoadingType.EXPORT;
			return LoadingType.TABLE;
		} else if (url.startsWith(this.envConfig.restUrl) && url.endsWith('/pdf')) {
			return LoadingType.EXPORT;
		}
		return LoadingType.NONE;
	}
}
