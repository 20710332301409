import { Component, ContentChild, Input, Output, inject } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { Observable, Subject } from 'rxjs';
import { ExportOptions, ExportService, ExportType } from '../../services/export.service';
import { switchMap } from 'rxjs/operators';
import { Table } from '@cumlaude/metadata';
import { LoadingService, LoadingType } from '../../services/loading.service';
import { AsyncPipe } from '@angular/common';
import { SvgSpinnerComponent } from '../svg-spinner/svg-spinner.component';
import { TabMenuComponent } from '../tab-menu/tab-menu.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';

@Component({
	selector: 'app-dashboard-container',
	templateUrl: './dashboard-container.component.html',
	styleUrls: ['./dashboard-container.component.scss'],
	imports: [TabMenuComponent, SvgSpinnerComponent, AsyncPipe],
})
export class DashboardContainerComponent {
	private readonly filterService = inject(FilterService);

	sidebarOpened$: Observable<boolean>;
	startExport$ = new Subject<void>();
	showSpinner$: Observable<boolean>;

	@Input()
	factTable?: Table;

	@Input()
	shareVisible: boolean = true;

	@Input()
	exportTypes = Object.values(ExportType);

	@ContentChild(DashboardHeaderComponent)
	private dashboardHeaderComponent?: DashboardHeaderComponent;

	/**
	 * emit wanneer de gebruiker de export-dialog met bevestigen heeft afgesloten
	 */
	@Output()
	doExport: Observable<ExportOptions>;

	constructor() {
		const exportService = inject(ExportService);
		const loadingService = inject(LoadingService);

		this.sidebarOpened$ = this.filterService.filterPanelOpened$;
		this.doExport = this.startExport$.pipe(
			switchMap(() => exportService.startExport(this.factTable!, this.exportTypes, this.dashboardHeaderComponent?.legendaComponent?.options))
		);
		this.showSpinner$ = loadingService.shouldShowLoadingIndicator(LoadingType.TABLE);
	}

	closeSidebar() {
		this.filterService.filterPanelOpened$.next(false);
	}
}
