import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, combineLatestWith, firstValueFrom, Observable, switchMap } from 'rxjs';
import { RestService } from '@cumlaude/shared-services';
import { UserService } from './user.service';
import { RGedeeldePaginaPersoon } from '@cumlaude/service-contract';

@Injectable({
	providedIn: 'root',
})
export class GedeeldePaginaPersoonService {
	protected readonly userService = inject(UserService);
	private readonly restService = inject(RestService);

	public list$: Observable<RGedeeldePaginaPersoon[]>;

	private refresh$ = new BehaviorSubject<void>(undefined);

	constructor() {
		this.list$ = this.userService.myAccount$.pipe(
			combineLatestWith(this.refresh$),
			switchMap(([me]) => this.restService.getGedeeldePaginaPersonen(me))
		);
	}

	refresh() {
		this.refresh$.next();
	}

	async delete(gpp: RGedeeldePaginaPersoon) {
		await firstValueFrom(this.restService.deleteGedeeldePaginaPersoon(gpp));
		this.refresh();
	}
}
