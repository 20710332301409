<app-dashboard-container
	class="dashboard-prestatieanalyse"
	[factTable]="factTable"
	[exportTypes]="exportTypes"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/prestatieanalyse_vso"
		[permanentFilterExpressions]="permanentFilterExpressions"
		[leerlingselectie]="false"
	></app-filter-panel>
	<app-filter-bar></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header
			[variant]="variant"
			[legenda]="[variant === 'Actueel' ? 'x_percentiel_neg_gem_pos' : 'x_prestatieanalyse_historie']"
			[legendaKeyIsClassName]="true"
		>
		</app-dashboard-header>
		<app-card-list
			[config]="this"
			[filters]="filterExpressions"
			[permanentFilters]="permanentFilterExpressions"
			[groups]="groups"
			[subgroups]="variant === 'Actueel' ? actueelSubgroups : historieSubgroups"
			[template]="cardTemplate"
			[variant]="variant"
			class="prestatieanalyse"
			data-exportable
		>
			<ng-template #cardTemplate let-row="row" let-context="context" let-group="group" let-variant="variant">
				<div class="card-title">
					<h3>{{ group.pv_fun_vest_brin }}</h3>
				</div>
				<div class="card-content">
					@for (rec of getRecords(row, context, variant); track trackByPathKey($index, rec)) {
						<div class="card-cell" (click)="onClick(rec, context)">
							@switch (variant) {
								@case ("Actueel") {
									<app-card-pa-cell [data]="makeActueelCell(rec, context)"></app-card-pa-cell>
								}
								@case ("Historie") {
									<app-card-pa-history-cell [data]="makeHistorieCell(rec)(context)"></app-card-pa-history-cell>
								}
							}
						</div>
					}
				</div>
			</ng-template>
		</app-card-list>
	</section>
</app-dashboard-container>
