import { computed, inject, Injectable, Signal } from '@angular/core';
import { CurrentUrlService, RestService } from '@cumlaude/shared-services';
import { RDatasnapResult, RDatasnapUrl } from '@cumlaude/service-contract';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { map, scan, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { differenceBy, fromPairs } from 'lodash-es';
import { UserService } from './user.service';
import { combineLatestEmptySafe, isDefined } from '@cumlaude/shared-utils';
import { QueryParamStateService } from './query-param-state.service';
import { toSignal } from '@angular/core/rxjs-interop';

export type UrlWithStatus = RDatasnapUrl & { status: UrlStatus };

export enum UrlStatus {
	EMPTY = '',
	UNCHANGED = 'ongewijzigd',
	CHANGED = 'gewijzigd',
	CREATED = 'nieuw',
	DELETED = 'verwijderd',
}

export type DatasnapResultKey = { urlId: string; resultId: string };

@Injectable({
	providedIn: 'root',
})
export class DatasnapUrlService {
	private readonly restService = inject(RestService);
	private readonly userService = inject(UserService);
	private readonly currentUrlService = inject(CurrentUrlService);
	private readonly qp = inject(QueryParamStateService);

	private isSupport$ = this.userService.myAccount$.pipe(map((account) => account.support));

	public currentSnapshotKey$ = combineLatestEmptySafe([this.isSupport$, this.qp.observe('snapshot')]).pipe(
		map(([support, snapshot]) => (support ? snapshot : undefined))
	);

	public currentSnapshotKey = toSignal(this.currentSnapshotKey$);

	public isViewingSnapshot = computed(() => Boolean(this.currentSnapshotKey()));

	public isViewing(result: RDatasnapResult): Signal<boolean> {
		return computed(() => {
			const currentKey = this.currentSnapshotKey();
			return isDefined(currentKey) && encodeKey(currentKey) === encodeKey(this.constructKey(result));
		});
	}

	updates$ = new Subject<RDatasnapUrl[]>();

	/**
	 * Haalt bij de eerste subscription de huidige DatasnapUrls op en emit daarna ook elke keer als updateUrls aangeroepen wordt.
	 */
	public urls$: Observable<UrlWithStatus[]> = this.restService.getDatasnapUrls().pipe(
		switchMap((init) => this.updates$.pipe(startWith(init), scan<RDatasnapUrl[], UrlWithStatus[], undefined>(mergeUrls, undefined))),
		shareReplay(1)
	);

	public resultsForCurrentUrl$: Observable<RDatasnapResult[]> = this.isSupport$.pipe(
		switchMap((support) =>
			support
				? combineLatestEmptySafe([this.currentUrlService.currentUrl$, this.urls$]).pipe(
						switchMap(([activeUrl, urls]) => {
							const matchingUrl = this.tryGetMatchingUrl(activeUrl, urls);
							if (matchingUrl) return this.getResults(matchingUrl.id!);
							else return of([]);
						})
					)
				: of([])
		)
	);

	resultsMap: { [id: string]: RDatasnapResult } = {};

	public getResult(key: DatasnapResultKey) {
		const cached = this.resultsMap[encodeKey(key)];
		if (cached) return of(cached);

		return this.getResults(key.urlId).pipe(map((results) => results.find((result) => result.id === key.resultId)!));
	}

	public getResults(urlId: string): Observable<RDatasnapResult[]> {
		return this.restService
			.getDatasnapResults(urlId)
			.pipe(tap((results) => results.forEach((result) => (this.resultsMap[encodeKey(this.constructKey(result))] = result))));
	}

	/**
	 * Kijkt of de huidige URL (negeer een evt. ?snapshot=...-parameter) overeenkomt met een actieve datasnapUrl, en zo ja, geef de datasnapUrl terug.
	 */
	private tryGetMatchingUrl(activeDashboardUrl: string, urls: UrlWithStatus[]) {
		return urls.find(
			(url) =>
				url.status !== UrlStatus.DELETED &&
				url.dashboardUrl === activeDashboardUrl.replace(/snapshot=\d+:\d+&/, '').replace(/[?&]snapshot=\d+:\d+/, '')
		);
	}

	async updateUrls(datasnapUrls: RDatasnapUrl[]) {
		this.updates$.next(await firstValueFrom(this.restService.postDatasnapUrls(datasnapUrls)));
	}

	public constructKey(result: RDatasnapResult): DatasnapResultKey {
		const resultId = result.id!;
		const match = [...result.links[0].href.matchAll(/\/datasnap_url\/(\d+)\//g)];
		const urlId = match![0][1];
		return { resultId, urlId };
	}
}

export function encodeKey(key: DatasnapResultKey) {
	return `${key.urlId}:${key.resultId}`;
}

/**
 * Vergelijkt de nieuwe URLs met de bestaande en voegt op basis daarvan de status toe. Dashboards die niet bij de nieuwe voorkomen blijven als "verwijderd" in de lijst staan.
 */
function mergeUrls(current: UrlWithStatus[] | undefined, update: RDatasnapUrl[]): UrlWithStatus[] {
	if (!current) {
		return update.map((url) => ({ ...url, status: UrlStatus.EMPTY }));
	}

	const currentMap = fromPairs(current.map((url) => [url.dashboardUrl, url]));
	const updated = update.map((url) => updateUrl(url, currentMap));

	const removed = differenceBy(current, update, (url) => url.dashboardUrl);
	return [...updated, ...removed.map((url) => ({ ...url, actief: false, status: UrlStatus.DELETED }))];
}

function updateUrl(incoming: RDatasnapUrl, currentMap: { [dashboardUrl: string]: UrlWithStatus }): UrlWithStatus {
	const current = currentMap[incoming.dashboardUrl];
	if (!current) {
		return { ...incoming, status: UrlStatus.CREATED };
	}
	if (current.dataUrl === incoming.dataUrl) {
		return { ...incoming, status: UrlStatus.UNCHANGED };
	}
	return { ...incoming, status: UrlStatus.CHANGED };
}
