import { Component, computed, inject } from '@angular/core';
import { Niveau, Prestatie, Table } from '@cumlaude/metadata';
import { isNil, memoize, partition, range } from 'lodash-es';
import { Observable } from 'rxjs';
import { Level, Path } from '../../services/data-tree';
import { doorstroom_afk } from '../../services/labels';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import {
	AttrPath,
	BasicFilterExpression,
	BasicFilterExpressionType,
	DataOptions,
	DataResponse,
	DataService,
	DoorstroomMeasure,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { maxOverMapped, noAgg0, SingleAggregator, sumOver } from '../../services/aggregation';
import { att, percOfRow } from '../../services/measures';
import { FilterName } from '../../services/filter-config';
import { deelVeilig, generateCssClassForString } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Router } from '@angular/router';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, getLevelFilters, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { DashboardVariant, DoorstroomWeergave } from '../../services/weergave-opties';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { PsName } from '../../services/page-state.service';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { CheckboxComponent, FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

export interface DoorstroomI extends Attributes {
	ds_is_succesvol: string;
	ds_is_bevorderd: string;
	'ds_fk_ilt_naar.ilt_nm_niveau': Niveau | null;
	ds_nr_leerjaar_naar: string | null;
	ds_nm_idu: Prestatie | null;
	ds_fun_brinvest_overgang?: string | null;
	ds_is_uitstroom_vavo: string;
	ds_is_uitstroom_extern: string;
	ds_is_prognose: string;
	ds_nr_bevorderd_weging: number;
	ds_nr_bevorderd_leerlingen: number;
	ds_nr_niet_bevorderd_weging: number;
	ds_nr_niet_bevorderd_leerlingen: number;
	ds_nr_succesvol_weging: number;
	ds_nr_succesvol_leerlingen: number;
	ds_nr_niet_succesvol_weging: number;
	ds_nr_niet_succesvol_leerlingen: number;
	ds_nr_opstroom_weging: number;
	ds_nr_opstroom_leerlingen: number;
	ds_nr_afstroom_weging: number;
	ds_nr_afstroom_leerlingen: number;
	ds_nr_bekend_weging: number;
	ds_nr_bekend_leerlingen: number;
	ds_nr_onbekend_weging: number;
	ds_nr_onbekend_leerlingen: number;
	ds_nr_weging: number;
	ds_nr_leerlingen: number;
}

export interface DoorstroomA extends Attributes {
	bevorderd_weging: number;
	bevorderd_lln: number;
	niet_bevorderd_weging: number;
	niet_bevorderd_lln: number;
	succesvol_weging: number;
	succesvol_lln: number;
	niet_succesvol_weging: number;
	niet_succesvol_lln: number;
	opstroom_weging: number;
	opstroom_lln: number;
	afstroom_weging: number;
	afstroom_lln: number;
	bekend_weging: number;
	bekend_lln: number;
	onbekend_weging: number;
	onbekend_lln: number;
	weging: number;
	ds_is_prognose: number;
	ds_nr_leerlingen: number;
}

export const OPTIONAL_2_DECIMALS = '1.0-2';

export type SuccesConditie = {
	attr: AttrPath;
	weging: 'succesvol_weging' | 'bevorderd_weging';
	lln: 'succesvol_lln' | 'bevorderd_lln';
};

@Component({
	selector: 'app-doorstroom',
	templateUrl: './doorstroom.component.html',
	styleUrls: ['./doorstroom.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		CheckboxComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FormDropdownComponent,
		FilterBarComponent,
	],
})
export class DoorstroomComponent extends BarchartTableConfig<DoorstroomI, DoorstroomA> {
	protected readonly router = inject(Router);
	protected readonly dataService = inject(DataService);

	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	selectedGroups = this.qp.signal_g(this.defaultGroups);

	fixedSubgroups = computed(() => (this.toonBrinOvergang() ? this.doorstroomLevelsMetOvergang : this.doorstroomLevelsZonderOvergang));

	historieGroups = computed(() => this.selectedGroups().slice(0, -1));

	partitionGroups = computed(() => [...this.selectedGroups(), ['ds_nm_schooljaar_van'] as AttrPath]);

	historieSubgroups = computed(() => [...this.selectedGroups().slice(-1), ['ds_nm_schooljaar_van'] as AttrPath, ...this.fixedSubgroups()]);

	override availableGroups: AttrPath[] = [
		['ds_fk_ll', 'll_nm_basisschooladvies_uni'],
		['ds_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
		['ds_fun_basisschooladvies_duo'],
		['ds_nm_klas_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nm_opleiding_van'],
		['ds_fk_ilt_van', 'ilt_nm_opleiding'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_ll', 'll_nm_geslacht'],
	];

	actueelFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_nm_idu',
	];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1), //
	];

	doorstroomLevelsZonderOvergang: AttrPath[] = [
		this.succesConditie.attr,
		['ds_nm_idu'],
		['ds_fk_ilt_naar', 'ilt_nm_niveau'],
		['ds_nr_leerjaar_naar'],
		['ds_is_prognose'],
		['ds_is_uitstroom_vavo'],
		['ds_is_uitstroom_extern'],
		['ds_is_bevorderd'],
	];

	doorstroomLevelsMetOvergang: AttrPath[] = [
		...this.doorstroomLevelsZonderOvergang, //
		['ds_fun_brinvest_overgang'],
	];

	toonBrinOvergang = this.qp.signal('brin-overgang');

	variant = this.qp.signal('variant');

	dashboardName!: string;

	legendaExcludes: Prestatie[] = [Prestatie.GEEN];

	weergaveOpties = Object.values(DoorstroomWeergave).map((val) => new Option(val));

	weergaveOptie!: DoorstroomWeergave;

	constructor() {
		super();

		this.subscriptions.push(
			this.urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title)),
			this.qp.observe('doorstroomweergave').subscribe((weergaveOptie) => {
				this.weergaveOptie = weergaveOptie;
				this.filterService.refresh();
			})
		);
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData({
			...options,
			m: [DoorstroomMeasure.LEERLINGEN, DoorstroomMeasure.DOORSTROOM_AANTALLEN],
			r: this.getRollupLevels(),
		});
	}

	getRollupLevels() {
		const actueelGroups = this.selectedGroups();
		const partitionGroups = this.partitionGroups();
		if (this.variant() === DashboardVariant.ACTUEEL) {
			return range(0, actueelGroups.length + 1);
		} else {
			return range(0, partitionGroups.length + 1);
		}
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected override singleAggregators: Partial<{ [ai in keyof DoorstroomA]: SingleAggregator<DoorstroomI, DoorstroomA[ai]> }> = {
		bevorderd_weging: noAgg0<'ds_nr_bevorderd_weging', DoorstroomI>('ds_nr_bevorderd_weging'),
		bevorderd_lln: noAgg0<'ds_nr_bevorderd_leerlingen', DoorstroomI>('ds_nr_bevorderd_leerlingen'),
		niet_bevorderd_weging: noAgg0<'ds_nr_niet_bevorderd_weging', DoorstroomI>('ds_nr_niet_bevorderd_weging'),
		niet_bevorderd_lln: noAgg0<'ds_nr_niet_bevorderd_leerlingen', DoorstroomI>('ds_nr_niet_bevorderd_leerlingen'),
		succesvol_weging: noAgg0<'ds_nr_succesvol_weging', DoorstroomI>('ds_nr_succesvol_weging'),
		succesvol_lln: noAgg0<'ds_nr_succesvol_leerlingen', DoorstroomI>('ds_nr_succesvol_leerlingen'),
		niet_succesvol_weging: noAgg0<'ds_nr_niet_succesvol_weging', DoorstroomI>('ds_nr_niet_succesvol_weging'),
		niet_succesvol_lln: noAgg0<'ds_nr_niet_succesvol_leerlingen', DoorstroomI>('ds_nr_niet_succesvol_leerlingen'),
		opstroom_weging: noAgg0<'ds_nr_opstroom_weging', DoorstroomI>('ds_nr_opstroom_weging'),
		opstroom_lln: noAgg0<'ds_nr_opstroom_leerlingen', DoorstroomI>('ds_nr_opstroom_leerlingen'),
		afstroom_weging: noAgg0<'ds_nr_afstroom_weging', DoorstroomI>('ds_nr_afstroom_weging'),
		afstroom_lln: noAgg0<'ds_nr_afstroom_leerlingen', DoorstroomI>('ds_nr_afstroom_leerlingen'),
		bekend_weging: noAgg0<'ds_nr_bekend_weging', DoorstroomI>('ds_nr_bekend_weging'),
		bekend_lln: noAgg0<'ds_nr_bekend_leerlingen', DoorstroomI>('ds_nr_bekend_leerlingen'),
		onbekend_weging: noAgg0<'ds_nr_onbekend_weging', DoorstroomI>('ds_nr_onbekend_weging'),
		onbekend_lln: noAgg0<'ds_nr_onbekend_leerlingen', DoorstroomI>('ds_nr_onbekend_leerlingen'),
		weging: sumOver<'ds_nr_weging', DoorstroomI, number>('ds_nr_weging'),
		ds_is_prognose: maxOverMapped<DoorstroomI>((v) => Number(v.ds_is_prognose)),
		ds_nr_leerlingen: noAgg0<'ds_nr_leerlingen', DoorstroomI>('ds_nr_leerlingen'),
	};

	override partitionBarData(rowRoot: Level<DoorstroomA, number[]>): Path<DoorstroomA, number[]>[][] {
		const [bevorderd, nietBevorderd] = partition(rowRoot.c, (c) => c.k === '1');
		return [...bevorderd.map((c) => c.r), ...nietBevorderd.map((c) => c.r)];
	}

	/**
	 * Bepaalt wanneer de doorstroom van een leerling succesvol is. Subclasses van dit dashboard kunnen een alternatief attribuut en waarde bepalen
	 */
	protected get succesConditie(): SuccesConditie {
		return {
			attr: ['ds_is_succesvol'],
			weging: 'succesvol_weging',
			lln: 'succesvol_lln',
		};
	}

	override makeBar(
		attrs: DoorstroomI,
		path: Path<DoorstroomA, number[]>,
		context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>
	): BarInfo {
		const {
			ds_nr_leerjaar_naar,
			ds_nm_idu,
			ds_is_prognose,
			ds_fun_brinvest_overgang,
			ds_is_uitstroom_vavo,
			ds_is_uitstroom_extern,
			'ds_fk_ilt_naar.ilt_nm_niveau': nm_niveau_naar,
			ds_nr_weging,
		} = attrs;

		const isOvergang = !isNil(ds_fun_brinvest_overgang);
		const brinvest_overgang = isOvergang ? ` (${ds_fun_brinvest_overgang})` : '';
		const vavo = ds_is_uitstroom_vavo === '1' ? ' (VAVO)' : '';
		const extern = ds_is_uitstroom_extern === '1' && ds_nm_idu !== Prestatie.GESLAAGD ? ' (ext)' : '';
		const prognose = Number(ds_is_prognose) ? ' (prognose)' : '';

		const tooltipElements: TooltipElement[] = [];
		const descriptionElements: string[] = [];
		if (nm_niveau_naar || ds_nr_leerjaar_naar || (ds_nm_idu !== Prestatie.GESLAAGD && ds_nm_idu !== Prestatie.AFGEWEZEN)) {
			tooltipElements.push(
				{ label: `Niveau`, value: `${this.displayService.display(nm_niveau_naar) + vavo + extern}` },
				{ label: `Leerjaar`, value: `${this.displayService.display(ds_nr_leerjaar_naar)}` }
			);
			descriptionElements.push(
				this.displayService.describe(nm_niveau_naar, ['ds_fk_ilt_naar', 'ilt_nm_niveau']),
				this.displayService.describe(ds_nr_leerjaar_naar, ['ds_nr_leerjaar_naar'])
			);
		}

		if (isOvergang) {
			tooltipElements.push({ label: `BRIN-overgang`, value: `${ds_fun_brinvest_overgang}` });
		}

		let position = context.groupNames.length;
		if (this.variant() === DashboardVariant.HISTORIE) position = position + 2;

		const pathElement = path[position].a;
		const aantal = ds_nm_idu == null ? pathElement.onbekend_weging : pathElement.bekend_weging;

		tooltipElements.push(
			{ label: `IDU`, value: `${this.displayService.display(ds_nm_idu) + prognose}` },
			{
				label: this.getCountRecordsHeader(),
				value: `${formatDecimal(ds_nr_weging, OPTIONAL_2_DECIMALS)} van ${formatDecimal(aantal, OPTIONAL_2_DECIMALS)}`,
			}
		);
		descriptionElements.push(this.displayService.describe(ds_nm_idu, ['ds_nm_idu']));

		if (ds_nm_idu !== null) {
			const percentage = Math.round(deelVeilig(ds_nr_weging, aantal) * 100);
			tooltipElements.push({ label: `Leerlingen (%)`, value: `${percentage}%` });
		}

		const description = descriptionElements.join(', ') + brinvest_overgang + vavo + extern + prognose;

		return {
			...super.makeBar(attrs, path, context),
			size: ds_nr_weging,
			text: doorstroom_afk(attrs) + brinvest_overgang + vavo + extern + prognose,
			description,
			className: generateCssClassForString(ds_nm_idu ? ds_nm_idu + prognose : null),
			tooltip: tooltipElements,
		};
	}

	override createMeasureColumns(context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): ColumnDef<DataRow<DoorstroomA>>[] {
		if (this.variant() === DashboardVariant.HISTORIE) return [];

		return [
			...this.getWeergaveColums(context),
			this.createLeerlingColumn('Leerlingen', att('bekend_weging'), { context, format: OPTIONAL_2_DECIMALS }, att('bekend_lln'), [
				new BasicFilterExpression(['ds_nm_idu'], null, '<>'),
				new BasicFilterExpression(['ds_nr_weging'], 0, '>'),
			]),
			createMeasureColumn('Onbekend', att('onbekend_weging'), {
				context,
				format: OPTIONAL_2_DECIMALS,
				clickHandler: (path) => this.handleUitzonderingRedirect(path, context),
			}),
		];
	}

	override isHistorieBatchVariant(): boolean {
		return this.variant() === DashboardVariant.HISTORIE && this.selectedGroups().length > 0;
	}

	protected getAtt(weergaveOptie: DoorstroomWeergave): [keyof DoorstroomA, keyof DoorstroomA] {
		switch (weergaveOptie) {
			case DoorstroomWeergave.SUCCESVOL:
				return ['succesvol_weging', 'succesvol_lln'];
			case DoorstroomWeergave.NIET_SUCCESVOL:
				return ['niet_succesvol_weging', 'niet_succesvol_lln'];
			case DoorstroomWeergave.BEVORDERD:
				return ['bevorderd_weging', 'bevorderd_lln'];
			case DoorstroomWeergave.NIET_BEVORDERD:
				return ['niet_bevorderd_weging', 'niet_bevorderd_lln'];
			case DoorstroomWeergave.OPSTROOM:
				return ['opstroom_weging', 'opstroom_lln'];
			case DoorstroomWeergave.AFSTROOM:
				return ['afstroom_weging', 'afstroom_lln'];
		}
	}

	private getExtraSelectionFilters(): FilterExpression[] {
		const doorstroomWeergave = this.weergaveOptie;
		const bevorderdSuccesvolAll = [
			DoorstroomWeergave.SUCCESVOL,
			DoorstroomWeergave.NIET_SUCCESVOL,
			DoorstroomWeergave.BEVORDERD,
			DoorstroomWeergave.NIET_BEVORDERD,
		];
		const bevorderdSuccesvolPositief = [DoorstroomWeergave.SUCCESVOL, DoorstroomWeergave.BEVORDERD];
		const bevorderd = [DoorstroomWeergave.BEVORDERD, DoorstroomWeergave.NIET_BEVORDERD];

		if (bevorderdSuccesvolAll.includes(doorstroomWeergave)) {
			const attr = <AttrPath>(bevorderd.includes(doorstroomWeergave) ? ['ds_is_bevorderd'] : ['ds_is_succesvol']);
			const type = bevorderdSuccesvolPositief.includes(doorstroomWeergave) ? '=' : '<>';
			return this.getBevorderdSuccesvolFilters(attr, type);
		} else return this.getDoorstroomStatusFilters(this.getPrestatie(doorstroomWeergave));
	}

	private getPrestatie(weergaveOptie: DoorstroomWeergave): Prestatie {
		switch (weergaveOptie) {
			case DoorstroomWeergave.OPSTROOM:
				return Prestatie.OPSTROOM;
			case DoorstroomWeergave.AFSTROOM:
				return Prestatie.AFSTROOM;
		}
		throw new Error('Geen mapping van weergave naar prestatie');
	}

	protected getWeergaveColums(context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): ColumnDef<DataRow<DoorstroomA>>[] {
		const doorstroomWeergave = this.weergaveOptie;
		const [weging, lln] = this.getAtt(doorstroomWeergave);
		const extraFilters = this.getExtraSelectionFilters();

		return [
			this.createLeerlingColumn(
				'% ' + doorstroomWeergave,
				percOfRow(weging, 'bekend_weging'),
				{ context, dataType: 'percentage' },
				att(lln),
				extraFilters
			),
			this.createLeerlingColumn(doorstroomWeergave, att(weging), { context, format: OPTIONAL_2_DECIMALS }, att(lln), extraFilters),
		];
	}

	protected getCountRecordsHeader() {
		return `# lln`;
	}

	getDoorstroomStatusFilters(doorstroomStatus: Prestatie) {
		return [new BasicFilterExpression(['ds_nm_idu'], doorstroomStatus, '='), new BasicFilterExpression(['ds_nr_weging'], 0, '>')];
	}

	protected getBevorderdSuccesvolFilters(attr: AttrPath, type: BasicFilterExpressionType) {
		return [
			new BasicFilterExpression(['ds_nm_idu'], null, '<>'),
			new BasicFilterExpression(attr, '1', type),
			new BasicFilterExpression(['ds_nr_weging'], 0, '>'),
		];
	}

	protected getBekendFilters() {
		return [new BasicFilterExpression(['ds_nm_idu'], null, '<>'), new BasicFilterExpression(['ds_nr_weging'], 0, '>')];
	}

	protected handleUitzonderingRedirect(
		path: Path<DoorstroomA, number[]>,
		context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>
	) {
		const params = {
			from: this.dashboardName,
		};

		for (const levelFilter of getLevelFilters(context, path)) {
			Object.assign(params, this.filterService.encodeFilterValue(<FilterName>levelFilter.attr.join('.'), [levelFilter.val]));
		}

		this.urlService.redirect([this.getUitzonderingUrl()], params);
	}

	getUitzonderingUrl() {
		return '/details/uitzondering/doorstroom';
	}

	getPartitionMeasure = memoize(this._getPartitionMeasure, JSON.stringify);

	_getPartitionMeasure(weergaveOptie: DoorstroomWeergave): PartitionMeasure<DoorstroomA> {
		return {
			type: 'percentage',
			getValue: percOfRow<keyof DoorstroomA, DoorstroomA>(this.getAtt(weergaveOptie)[0], 'bekend_weging'),
		};
	}

	override enrichTableModel(
		_context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>,
		tableModel: TableModel<DataRow<DoorstroomA>>
	) {
		tableModel.showFooters = this.variant() === DashboardVariant.ACTUEEL;
	}

	override onContextCreated(context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}

	getSelectedOption() {
		return this.weergaveOpties.find((value) => value.text === this.weergaveOptie);
	}

	override getSelectionConfig(context: DashboardContext<DoorstroomI, DoorstroomA, DoorstroomComponent>): SelectionConfig<DoorstroomA> | undefined {
		return {
			...super.getSelectionConfig(context)!,
			getSize: att('ds_nr_leerlingen'),
			extraFilters: [new BasicFilterExpression(['ds_nr_weging'], 0, '>')],
		};
	}

	protected readonly DashboardVariant = DashboardVariant;
}
