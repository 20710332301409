import { Component, Input, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

type DialogSizeClass =
	| 'size-7-75'
	| 'size-10-5'
	| 'size-12-25'
	| 'size-17'
	| 'size-18'
	| 'size-20'
	| 'size-22'
	| 'size-23'
	| 'size-25'
	| 'size-34'
	| 'size-44'
	| 'size-47';

@Component({
	selector: 'app-base-dialog',
	templateUrl: './base-dialog.component.html',
	styleUrls: ['./base-dialog.component.scss'],
	imports: [NgTemplateOutlet],
})
export class BaseDialogComponent {
	@Input()
	sizeClass!: DialogSizeClass;

	@Input()
	topBarTemplate!: TemplateRef<any>;

	@Input()
	bottomBarTemplate!: TemplateRef<any>;

	@Input()
	multiRow: boolean = false;

	@Input()
	noPadding: boolean = false;

	@Input()
	noGap: boolean = false;

	@Input()
	smallGap: boolean = false;

	@Input()
	scrollable: boolean = false;

	@Input()
	useParentHeight: boolean = false;
}
