import { Component, HostBinding, inject, input } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { QueryParamStateService } from '../services/query-param-state.service';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { FilterLabelComponent } from '../filter-label/filter-label.component';
import { HistoryService } from '@cumlaude/shared-services';
import { toSignal } from '@angular/core/rxjs-interop';
import { Selection } from '../shared/dashboard/base-dashboard/base-dashboard-config';
import { LeerlingSelectieService } from '../services/leerling-selectie.service';
import { map } from 'rxjs/operators';
import { DatasnapUrlService } from '../services/datasnap-url.service';

@Component({
	selector: 'app-filter-bar',
	templateUrl: './filter-bar.component.html',
	styleUrls: ['./filter-bar.component.scss'],
	imports: [ButtonComponent, FilterLabelComponent, AsyncPipe],
})
export class FilterBarComponent {
	protected readonly filterService = inject(FilterService);
	protected readonly qp = inject(QueryParamStateService);
	protected readonly historyService = inject(HistoryService);
	protected readonly leerlingSelectieService = inject(LeerlingSelectieService);
	protected readonly datasnapUrlService = inject(DatasnapUrlService);

	showTerug = input(false);
	terugPageNiet = input<string>();

	filterNames = toSignal(this.filterService.getFilledFiltersAndLeerlingselectie().pipe(map((pairs) => pairs.map(([name]) => name!))), {
		initialValue: [],
	});

	dataRootSelection = input<Selection | undefined>(undefined);

	@HostBinding('class.disabled')
	get disabled() {
		return this.datasnapUrlService.isViewingSnapshot();
	}

	openFilterPanel() {
		this.filterService.filterPanelOpened$.next(true);
	}

	resetDashboard() {
		this.qp.resetDashboard().then(() => this.filterService.clearActiveNonDefault());
	}

	goBack() {
		const urlPart = this.terugPageNiet();
		if (urlPart) this.historyService.backToPageNot(urlPart);
	}
}
