import { Component, OnInit, inject } from '@angular/core';
import {
	Attr,
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	DoorstroomMeasure,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { Observable } from 'rxjs';
import { Level, Path } from '../../services/data-tree';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { att, percOfRow } from '../../services/measures';
import { isUndefined, memoize, nth, range, sum } from 'lodash-es';
import { maxOver, noAgg0, sumIf, sumOver } from '../../services/aggregation';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { FilterName } from '../../services/filter-config';
import { getNiveauVerschil, onderwijspositie_0_5_10 } from '../../services/onderwijspositie';
import { BarInfo } from '../../services/stacked-bars';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Router } from '@angular/router';
import { formatPercent } from '@angular/common';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Table } from '@cumlaude/metadata';
import { DashboardVariant } from '../../services/weergave-opties';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { PsName } from '../../services/page-state.service';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { AttributeSelectorComponent } from '../../attribute-selector/attribute-selector.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface PlaatsingAdviesI extends Attributes {
	ds_fun_verschil_basisschooladvies_van: string | null;
	ds_fun_verschil_basisschooladvies_duo_van: string | null;
	ds_fun_verschil_basisschooladvies_herzien_van: string | null;
	ds_fun_basisschooladvies_duo: string | null;
	'ds_fk_ll.ll_nm_basisschooladvies_uni': string | null;
	'ds_fk_ll.ll_nm_basisschooladvies_uni_herzien': string | null;
	ds_nr_weging: number;
	ds_is_prognose: number;
	ds_nr_leerlingen: number;
}

interface PlaatsingAdviesA extends Attributes {
	verschil_weging: number;
	onbekend: number;
	onbekend_lln: number;
	bekend: number;
	bekend_lln: number;
	weging: number;
	ds_is_prognose: number;
	ds_nr_leerlingen: number;
}

@Component({
	selector: 'app-plaatsing-advies',
	templateUrl: './plaatsing-advies.component.html',
	styleUrls: ['./plaatsing-advies.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		AttributeSelectorComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FilterBarComponent,
	],
})
export class PlaatsingAdviesComponent extends BarchartTableConfig<PlaatsingAdviesI, PlaatsingAdviesA> implements OnInit {
	private readonly dataService = inject(DataService);
	protected readonly router = inject(Router);

	defaultGroups: AttrPath[] = [['ds_fk_ilt_van', 'ilt_nm_niveau'], ['ds_nr_leerjaar_van']];

	groups: AttrPath[] = this.defaultGroups;

	override availableGroups: AttrPath[] = [
		['ds_co_brin_svh'],
		['ds_nm_klas_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nm_opleiding_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_fk_ilt_van', 'ilt_nm_opleiding'],
		['ds_fk_ilt_van', 'ilt_abb_profiel'],
		['ds_fk_ll', 'll_nm_svh'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
	];

	actueelFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'x_peildatum',
		'x_advies_leerlinggroep',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_fk_ll.ll_nm_svh',
		'ds_co_brin_svh',
	];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [
		// Voorkom dubbelingen door doorstroom-record van relevante plaatsing naar zowel niet- als wel-relevante plaatsing
		new BasicFilterExpression(['ds_is_plaatsing_opeenvolgend'], 1),
	];

	adviesTypeOpties: AttrPath[] = [
		['ds_fun_verschil_basisschooladvies_van'],
		['ds_fun_verschil_basisschooladvies_herzien_van'],
		['ds_fun_verschil_basisschooladvies_duo_van'],
	];

	adviesType!: AttrPath;

	threshold!: number;

	thresholdOpties = [new Option(0, 'Alle'), ...[5, 10, 15, 20, 30, 50, 75, 100, 200].map((aantal) => new Option<number>(aantal, `≥ ${aantal}`))];

	variant!: DashboardVariant;

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe('threshold').subscribe((threshold) => this.setThreshold(threshold)),
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups)),
			this.qp.observe('adviesType').subscribe((adviesType) => (this.adviesType = adviesType))
		);
	}

	getSelectedThresholdOptie() {
		return this.thresholdOpties.find((option) => option.value === this.threshold)!;
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData({
			...options,
			threshold: this.threshold,
			m: [DoorstroomMeasure.LEERLINGEN],
			r: range(0, this.getRowLevel() + 1),
		});
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected override singleAggregators = {
		verschil_weging: {
			init: (attrs: PlaatsingAdviesI) => (onderwijspositie_0_5_10(relevantNiveauVerschil(attrs)) / 10) * attrs.ds_nr_weging,
			combine: (as: number[]) => sum(as),
		},
		bekend: sumIf<'ds_nr_weging', PlaatsingAdviesI>('ds_nr_weging', (attrs: PlaatsingAdviesI) => relevantNiveauVerschil(attrs) !== null),
		bekend_lln: sumIf<'count_records', PlaatsingAdviesI>('count_records', (attrs: PlaatsingAdviesI) => relevantNiveauVerschil(attrs) !== null),
		onbekend: sumIf<'ds_nr_weging', PlaatsingAdviesI>('ds_nr_weging', (attrs: PlaatsingAdviesI) => relevantNiveauVerschil(attrs) === null),
		onbekend_lln: sumIf<'count_records', PlaatsingAdviesI>('count_records', (attrs: PlaatsingAdviesI) => relevantNiveauVerschil(attrs) === null),
		weging: sumOver<'ds_nr_weging', PlaatsingAdviesI, number>('ds_nr_weging'),
		ds_is_prognose: maxOver<'ds_is_prognose', PlaatsingAdviesI>('ds_is_prognose'),
		ds_nr_leerlingen: noAgg0<'ds_nr_leerlingen', PlaatsingAdviesI>('ds_nr_leerlingen'),
	};

	override makeBar(
		attrs: PlaatsingAdviesI,
		path: Path<PlaatsingAdviesA, number[]>,
		context: DashboardContext<PlaatsingAdviesI, PlaatsingAdviesA, PlaatsingAdviesComponent>
	): BarInfo {
		const verschil_niv = relevantNiveauVerschil(attrs);
		const niveauVerschilLabel = getNiveauVerschil(verschil_niv!);
		const basisschooladvies = relevantBasisschooladvies(attrs);
		const kleur = verschil_niv === '+5' ? '5hv' : verschil_niv;

		const totalRow = nth(path, -4)?.a.weging;
		const rowCount = attrs.ds_nr_weging;
		const percentage = formatPercent(rowCount / totalRow!, 'nl-NL', '1.0-0');

		const tooltipElements: TooltipElement[] = [
			{ label: `Niveauverschil:`, value: `${Number(verschil_niv) / 10}` },
			{ label: 'Advies:', value: `${basisschooladvies}` },
			{ label: `Aantal:`, value: `${rowCount} van ${totalRow}` },
			{ label: `Percentage:`, value: `${percentage}` },
		];

		return {
			...super.makeBar(attrs, path, context),
			className: `verschil-${kleur}`,
			tooltip: tooltipElements,
			description: `${niveauVerschilLabel}`,
		};
	}

	override partitionBarData(rowRoot: Level<PlaatsingAdviesA, number[]>): Path<PlaatsingAdviesA, number[]>[][] {
		return [rowRoot.r.filter((path) => nth(path, -2)!.k !== null)];
	}

	override createMeasureColumns(
		context: DashboardContext<PlaatsingAdviesI, PlaatsingAdviesA, PlaatsingAdviesComponent>
	): ColumnDef<DataRow<PlaatsingAdviesA>>[] {
		if (this.variant === 'Historie') return [];
		return [
			createMeasureColumn('Advies verschil', percOfRow('verschil_weging', 'bekend'), { dataType: 'percentage', format: '+1.0-0' }),
			this.createLeerlingColumn('Leerlingen', att('bekend'), { context }, att('bekend_lln'), [
				new BasicFilterExpression(this.adviesType, null, '<>'),
				new BasicFilterExpression(['ds_nr_weging'], 0, '>'),
			]),
			this.createLeerlingColumn('Verschil onbekend', att('onbekend'), { context }, att('onbekend_lln'), [
				new BasicFilterExpression(this.adviesType, null),
				new BasicFilterExpression(['ds_nr_weging'], 0, '>'),
			]),
		];
	}

	override isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	// memoize, otherwise new array keeps triggering change detection
	determineSubgroups = memoize(PlaatsingAdviesComponent._determineSubgroups, JSON.stringify);

	/**
	 * Bepaling van subgroups op basis van gekozen adviestype. Bij wijziging van het aantal subgroups ook DoorstroomDataProvider.NR_SUBGROUPS_ADVIES_PLAATSING_DASHBOARD aanpassen.
	 */
	private static _determineSubgroups(adviesType: AttrPath | null): AttrPath[] {
		let basisschooladvies = getBasisschooladvies(adviesType!);
		return [[<Attr>(adviesType![0] + '_order')], adviesType!, basisschooladvies!];
	}

	getRowLevel() {
		return this.variant === DashboardVariant.ACTUEEL ? this.groups.length : this.getHistorieGroups(this.groups).length;
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(PlaatsingAdviesComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(groups: AttrPath[]) {
		return groups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(PlaatsingAdviesComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([groups, adviesType]: [AttrPath[], AttrPath | null]): AttrPath[] {
		console.log(adviesType, groups);
		return [...groups.slice(-1), ['ds_nm_schooljaar_van'], ...PlaatsingAdviesComponent._determineSubgroups(adviesType)];
	}

	override enrichTableModel(
		_context: DashboardContext<PlaatsingAdviesI, PlaatsingAdviesA, PlaatsingAdviesComponent>,
		tableModel: TableModel<DataRow<PlaatsingAdviesA>>
	) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	override onContextCreated(context: DashboardContext<PlaatsingAdviesI, PlaatsingAdviesA, PlaatsingAdviesComponent>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}

	override getSelectionConfig(
		context: DashboardContext<PlaatsingAdviesI, PlaatsingAdviesA, PlaatsingAdviesComponent>
	): SelectionConfig<PlaatsingAdviesA> | undefined {
		return {
			...super.getSelectionConfig(context)!,
			getSize: att('ds_nr_leerlingen'),
			extraFilters: [new BasicFilterExpression(['ds_nr_weging'], 0, '>')],
		};
	}

	partitionMeasure: PartitionMeasure<PlaatsingAdviesA> = {
		type: 'percentage',
		getValue: percOfRow<keyof PlaatsingAdviesA, PlaatsingAdviesA>('verschil_weging', 'bekend'),
		format: '+1.0-0',
	};

	setThreshold(threshold: number) {
		this.threshold = threshold;
		this.filterService.refresh();
	}

	protected readonly DashboardVariant = DashboardVariant;
}

function getBasisschooladvies(adviesType: AttrPath): AttrPath | null {
	if (adviesType[0] == 'ds_fun_verschil_basisschooladvies_van') {
		return ['ds_fk_ll', 'll_nm_basisschooladvies_uni'];
	} else if (adviesType[0] == 'ds_fun_verschil_basisschooladvies_herzien_van') {
		return ['ds_fk_ll', 'll_nm_basisschooladvies_uni_herzien'];
	} else if (adviesType[0] == 'ds_fun_verschil_basisschooladvies_duo_van') {
		return ['ds_fun_basisschooladvies_duo'];
	}
	return null;
}

function relevantNiveauVerschil(attrs: PlaatsingAdviesI): string | null {
	const { ds_fun_verschil_basisschooladvies_van, ds_fun_verschil_basisschooladvies_duo_van, ds_fun_verschil_basisschooladvies_herzien_van } = attrs;
	return [ds_fun_verschil_basisschooladvies_van, ds_fun_verschil_basisschooladvies_duo_van, ds_fun_verschil_basisschooladvies_herzien_van].filter(
		(x) => !isUndefined(x)
	)[0];
}

function relevantBasisschooladvies(attrs: PlaatsingAdviesI): string | null {
	const {
		'ds_fk_ll.ll_nm_basisschooladvies_uni': ll_nm_basisschooladvies,
		ds_fun_basisschooladvies_duo,
		'ds_fk_ll.ll_nm_basisschooladvies_uni_herzien': ll_nm_basisschooladvies_herzien,
	} = attrs;
	return [ll_nm_basisschooladvies, ds_fun_basisschooladvies_duo, ll_nm_basisschooladvies_herzien].filter((x) => !isUndefined(x))[0];
}
