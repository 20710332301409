import { Injectable, inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { APP_ROUTES } from '../app.routes';
import { firstValueFrom, Subject, timeout } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class DataRequestCollector {
	private readonly router = inject(Router);

	private url$ = new Subject<string>();

	registerUrl(url: string) {
		this.url$.next(url);
	}

	/**
	 * Navigeert naar alle data dashboards en geeft voor elk dashboard de eerste data URL
	 * terug die gedetecteerd wordt (binnen een timeout van 500 ms).
	 */
	async *collectRequests(): AsyncIterable<{ dashboardUrl: string; dataUrl: string }> {
		for (const path of collectPaths([], APP_ROUTES)) {
			const dashboardUrl = path.join('/');
			console.log(`DataRequestCollector: navigating to ${dashboardUrl}`);
			await this.router.navigateByUrl(dashboardUrl);
			try {
				const dataUrl = await firstValueFrom(this.url$.pipe(timeout(500)));
				console.log(`DataRequestCollector: detected data URL ${dataUrl}`);
				yield { dashboardUrl, dataUrl };
			} catch (err) {
				console.log(err);
			}
		}
	}
}

/**
 * Verzamelt alle paths van de data dashboards.
 */
function* collectPaths(startPath: string[], routes: Routes): Iterable<string[]> {
	for (const route of routes) {
		const path = route.path!;
		if (!['oauth', 'beheer', 'shared', 'details', '**'].includes(path)) {
			if (route.component) yield [...startPath, path];
			if (route.children) yield* collectPaths([...startPath, path], route.children);
		}
	}
}
