<app-button buttonType="text" icon="svg-add-user" class="new-leerlingselectie" [class.in-dropdown]="inDropdown" (clickit)="nieuweLeerlingselectie()">
	Nieuwe leerlingselectie
</app-button>

@if (searchInput !== undefined) {
	<input type="text" (input)="search($event)" [value]="searchInput" class="search-box" placeholder="Zoeken..." />
}
@if (isShowSelectAll()) {
	<a (click)="selectAllOrNone()">
		@if (allSelected()) {
			Deselecteer alle
		} @else {
			Selecteer alle
		}
	</a>
}

<app-multi-select-checkbox
	[class]="getClassName()"
	[legenda]="legenda"
	[minimum]="minimum"
	[blockedHover]="blockedHover"
	[options]="applyFilter(options(), searchInput)"
	[value]="getSelected(options())"
	(valueChange)="select($event)"
	[inDropdown]="inDropdown"
	[showEmpty]="showEmpty"
	[menuTemplate]="menu"
>
</app-multi-select-checkbox>

<ng-template #menu let-option="option">
	@if (!isAdHoc(option)) {
		<div class="menu">
			<div class="spacer"></div>
			<app-meatball-menu>
				<app-meatball-menu-item icon="icon-edit" (click)="editSelectie(option.value, $event)">Bewerken</app-meatball-menu-item>
				<app-meatball-menu-item icon="icon-delete" (click)="deleteSelectie(option.value, $event)">Verwijderen</app-meatball-menu-item>
			</app-meatball-menu>
			<div class="spacer"></div>
		</div>
	}
</ng-template>

<ng-template #confirmDeleteDialog let-selectie>
	<app-confirm-dialog caption="Leerlingselectie verwijderen" action="Verwijderen">
		Je staat op het punt om de leerlingselectie <b>{{ selectie.naam }}</b> te verwijderen. Weet je het zeker?
	</app-confirm-dialog>
</ng-template>
