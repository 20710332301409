<h2 class="title">{{ dashboardName }}</h2>
<div class="left-options">
	@if (leftOptionsTemplate) {
		<ng-container [ngTemplateOutlet]="leftOptionsTemplate"></ng-container>
	}
</div>
<div class="right-options">
	@if (rightOptionsTemplate) {
		<ng-container [ngTemplateOutlet]="rightOptionsTemplate"></ng-container>
	}
	@if (variant) {
		<app-toggle-buttons [options]="varianten" [value]="variant" (valueChange)="qp.dispatch('variant', $event)"></app-toggle-buttons>
	}
	@if (legenda) {
		<app-legenda
			[attribute]="legenda"
			[exclude]="legendaExclude"
			[includeNull]="legendaIncludeNull"
			[keyIsClassName]="legendaKeyIsClassName"
			[style]="legendaStyle"
		>
		</app-legenda>
	}
</div>
