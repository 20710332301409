import { Component } from '@angular/core';
import { AsyncPipe, TitleCasePipe } from '@angular/common';
import { TableComponent } from '../../shared/components/table/table/table.component';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent, MultiSelectDropdownComponent } from '@cumlaude/shared-components-inputs';
import { RRol } from '@cumlaude/service-contract';
import { DocentMentorComponent } from './docent-mentor.component';

@Component({
	selector: 'app-docent-mentor',
	templateUrl: '../account-screen.html',
	styleUrls: ['../account-screen.scss'],
	imports: [InstantSearchBoxComponent, ButtonComponent, MultiSelectDropdownComponent, TooltipDirective, TableComponent, AsyncPipe, TitleCasePipe],
})
export class DocentComponent extends DocentMentorComponent {
	override getRol(): RRol {
		return RRol.DOCENT;
	}

	override getRolLabel() {
		return 'Docent';
	}
}
