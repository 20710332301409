import { Component } from '@angular/core';
import { Sort } from '../../shared/components/table/table/table.component';
import {
	Att,
	AttrPath,
	BasicFilterExpression,
	CijferMeasure,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	ExportFilter,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { att } from '../../services/measures';
import { DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { CijferSeCeA, CijferSeCeI, VerschilSeCeComponent } from '../verschil-se-ce/verschil-se-ce.component';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { flatMap, get, initial, last, memoize, range } from 'lodash-es';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { Axis } from '../../services/axis';
import { CijfersSeCeWeergave, DashboardVariant } from '../../services/weergave-opties';
import { Observable } from 'rxjs';
import { BarInfo } from '../../services/stacked-bars';
import { generateCssClassForCijfer } from '@cumlaude/shared-utils';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { cijferOpties } from '../cijfers-overzicht/cijfers-overzicht.component';
import { TooltipElement, TooltipType } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';
import { DataTreeTableConfig } from '../../shared/dashboard/data-tree-table/data-tree-table-config';

@Component({
	selector: 'app-cijfers-se-ce',
	templateUrl: './cijfers-se-ce.component.html',
	styleUrls: ['./cijfers-se-ce.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FilterBarComponent,
	],
})
export class CijfersSeCeComponent extends VerschilSeCeComponent {
	defaultGroups: AttrPath[] = [['cf_fk_ilt', 'ilt_nm_niveau'], ['cf_nm_vak']];

	groups: AttrPath[] = this.defaultGroups;

	override fixedBeforeGroups = 0;

	override flexibleMaxGroups = Number.MAX_VALUE;

	override flexibleGroupsRemovable = true;

	override availableGroups: AttrPath[] = [
		['cf_fks_mw', 'mw_nm_medewerker'],
		['cf_nm_lesgroep'],
		['cf_nm_lesgroep_docenten'],
		['cf_nr_leerjaar'],
		['cf_fk_ilt', 'ilt_nm_niveau'],
		['cf_fk_ilt_vorig_sj', 'ilt_nm_niveau'],
		['cf_fk_ilt', 'ilt_abb_profiel'],
		['cf_fk_lb', 'lb_nm_uitstroomprofiel_vso'],
		['cf_nm_vak_uni'],
		['cf_nm_opleiding'],
		['cf_nm_vak'],
		['cf_nr_leerjaar_vak'],
		['cf_abb_onderwijssoort_vak'],
		['cf_fk_vk', 'vk_nm_vakkengroep'],
		['cf_fk_vk', 'vk_nm_vak'],
		['cf_nm_vestiging'],
		['cf_fk_lb_vorig_sj', 'lb_nm_vestiging'],
		['cf_fk_lb', 'lb_nm_leerfase'],
		['cf_fk_lb_vorig_sj', 'lb_nm_leerfase'],
		['cf_nm_leerling'],
	];

	actueelFilters: FilterName[] = [
		'cf_nm_schooljaar',
		'cf_co_brin',
		'cf_nm_vestiging',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nr_leerjaar',
		'cf_nm_vak',
		'cf_nm_lesgroep',
		'x_cijfer_metzonder_ce',
		'cf_map_examenstatus_met_prognose',
	];

	historieFilters: FilterName[] = [
		'x_cijfer_schooljaar_historie', //
		'x_cijfer_multiselect_schooljaar',
		...this.actueelFilters.filter((f) => !['cf_nm_lesgroep'].includes(f)),
	];

	weergave!: CijfersSeCeWeergave;

	weergaveOpties = Object.values(CijfersSeCeWeergave).map((val) => new Option(val));

	permanentFilterExpressions = [
		new BasicFilterExpression(['cf_is_diplomavak'], 1),
		// Combinatiecijfervakken HAVO/VWO niet tonen, beroepsgerichte vakken VMBO wel
		new CompoundFilterExpression(
			[
				new BasicFilterExpression(['cf_is_combinatiecijfervak'], 0),
				new BasicFilterExpression(['cf_fk_ilt', 'ilt_nm_niveau'], ['HAVO', 'VWO'], 'not in'),
			],
			'or'
		),
		new BasicFilterExpression(['cf_nm_kolomtype'], 'SE-cijfer'),
		new CompoundFilterExpression(
			[new BasicFilterExpression(['cf_fk_vk', 'vk_co_vak'], 1107, '<>'), new BasicFilterExpression(['cf_nm_schooljaar'], '2015/2016', '<')],
			'or'
		),
		// CL-3210 filter fac_or_onderwijsresultaten weggehaald op dit dashboard
	];

	protected override subscribeToQueryParams() {
		super.subscribeToQueryParams();
		this.subscriptions.push(this.qp.observe('cijfersseceweergave').subscribe((weergave) => (this.weergave = weergave)));
	}

	getSelectedWeergaveOptie() {
		return this.weergaveOpties.find((optie) => optie.value === this.weergave)!;
	}

	override getDefaultSort(): Sort {
		return { active: 'SE-CE', direction: 'desc' };
	}

	protected override createAantalColumn(
		context: DashboardContext<CijferSeCeI, CijferSeCeA, CijfersSeCeComponent>
	): ColumnDef<DataRow<CijferSeCeA>> {
		return this.createLeerlingColumn('Leerlingen', att('leerlingen'), { context }, att('leerlingen'));
	}

	protected override getFixedAfterGroups(): number {
		return this.fixedAfterGroups;
	}

	override isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	override setGroups(groups: AttrPath[] | undefined) {
		this.groups = groups ?? this.defaultGroups;
	}

	override getData(options: DataOptions): Observable<DataResponse<number[]>> {
		if (this.weergave === CijfersSeCeWeergave.VERSCHIL) return super.getData(options);
		return this.dataService.getCijfersData({
			...options,
			m: [this.weergave === CijfersSeCeWeergave.CE ? CijferMeasure.CE : CijferMeasure.SE],
			r: this.getRollupLevels(options),
		});
	}

	override partitionBarData(rowRoot: Level<CijferSeCeA, number[]>): Path<CijferSeCeA, number[]>[][] {
		if (this.weergave === CijfersSeCeWeergave.VERSCHIL) {
			return super.partitionBarData(rowRoot);
		}
		const path = rowRoot.r[0];
		if (!path) return [];
		const a = last(path)!.a;
		// genereer records met cijferAfgerond van 0 t/m 10
		return [range(0, 11).map((cijferAfgerond) => [...initial(path), { ...last(path)!, a: { ...a, cijferAfgerond } }])];
	}

	override makeBar(
		attrs: CijferSeCeI,
		path: Path<CijferSeCeA, number[]>,
		context: DashboardContext<CijferSeCeI, CijferSeCeA, VerschilSeCeComponent>
	): BarInfo {
		if (this.weergave === CijfersSeCeWeergave.VERSCHIL) {
			return super.makeBar(attrs, path, context);
		}
		const a = last(path)!.a;
		const cijferAfgerond = a.cijferAfgerond;
		// de measures cf_nr_aantal_0 t/m _10 bevatten het aantal resultaten per afgerond cijfer
		const size: number = get(attrs, 'cf_nr_aantal_' + cijferAfgerond, 0);

		return {
			size,
			className: generateCssClassForCijfer(cijferAfgerond),
			tooltip: this.createTooltip(a, size),
		};
	}

	override createTooltip(attributes: CijferSeCeA, size: number): TooltipType {
		const { cijferCe, cijferSe, cijferVerschilSeCe, leerlingen, cijferAfgerond } = attributes;
		if (this.variant === DashboardVariant.ACTUEEL) {
			if (this.weergave === CijfersSeCeWeergave.VERSCHIL) return super.createTooltip(attributes, size);
			return `Aantal ${cijferOpties(cijferAfgerond)}: ${size}`;
		}

		const tooltipElements: TooltipElement[] = [];
		let cijfer;
		switch (this.weergave) {
			case CijfersSeCeWeergave.VERSCHIL:
				cijfer = cijferVerschilSeCe;
				break;
			case CijfersSeCeWeergave.SE:
				cijfer = cijferSe;
				break;
			case CijfersSeCeWeergave.CE:
				cijfer = cijferCe;
				break;
		}

		if (cijfer !== null) {
			tooltipElements.push({ label: this.weergave, value: formatDecimal(cijfer, '1.2-2') });
		}

		tooltipElements.push({ label: 'Leerlingen', value: `${leerlingen ?? '0'}` });

		return tooltipElements;
	}

	override handleRowClick(
		context: DashboardContext<CijferSeCeI, CijferSeCeA, DataTreeTableConfig<CijferSeCeI, CijferSeCeA>>,
		path: Path<CijferSeCeA, number[]>
	) {
		const atts: Att[] = ['cf_nm_vak', 'cf_nm_lesgroep', 'cf_nm_leerling'];
		const groups = this.variant === DashboardVariant.ACTUEEL ? context.groupNames : [...context.groupNames, context.subgroupNames[0]];
		if (!this.handleZoomClick(atts, groups, path)) {
			// super.handleRowClick niet gebruiken, die heeft andere atts
			DataTreeTableConfig.prototype.handleRowClick.bind(this)(context, path);
		}
	}

	override getBarchartQty(path: Path<CijferSeCeA, number[]>): number | null {
		switch (this.weergave) {
			case CijfersSeCeWeergave.VERSCHIL:
				return super.getBarchartQty(path);
			case CijfersSeCeWeergave.CE:
				return getLeafA(path).cijferCe ?? 0;
			case CijfersSeCeWeergave.SE:
				return getLeafA(path).cijferSe ?? 0;
		}
	}

	override createXAxis(context: DashboardContext<CijferSeCeI, CijferSeCeA, CijfersSeCeComponent>): Axis {
		if (this.weergave === CijfersSeCeWeergave.VERSCHIL) {
			return super.createXAxis(context);
		}
		return { min: 0, max: 10, ticks: [] };
	}

	override getAlternatingGroupLevel(): number {
		return 1;
	}

	getPartitionMeasure = memoize(this._getPartitionMeasure.bind(this), JSON.stringify);

	private _getPartitionMeasure(weergave: CijfersSeCeWeergave): PartitionMeasure<CijferSeCeA> {
		return {
			type: 'number',
			getValue: (path) => {
				switch (weergave) {
					case CijfersSeCeWeergave.VERSCHIL:
						return getLeafA(path).cijferVerschilSeCe;
					case CijfersSeCeWeergave.SE:
						return getLeafA(path).cijferSe;
					case CijfersSeCeWeergave.CE:
						return getLeafA(path).cijferCe;
				}
			},
			format: '+1.2-2',
			visible: false,
		};
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(CijfersSeCeComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(CijfersSeCeComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups(selectedGroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['cf_nm_schooljaar']];
	}

	override enrichTableModel(
		_context: DashboardContext<CijferSeCeI, CijferSeCeA, CijfersSeCeComponent>,
		tableModel: TableModel<DataRow<CijferSeCeA>>
	) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	override createMeasureColumns(context: DashboardContext<CijferSeCeI, CijferSeCeA, CijfersSeCeComponent>): ColumnDef<DataRow<CijferSeCeA>>[] {
		if (this.variant === DashboardVariant.HISTORIE) return [];
		return super.createMeasureColumns(context);
	}

	override createYAxis(context: DashboardContext<CijferSeCeI, CijferSeCeA, CijfersSeCeComponent>): Axis {
		if (this.weergave === CijfersSeCeWeergave.VERSCHIL) {
			const afwijkingen = flatMap(context.dataRoot!.r, (row) => last(row)!.xr!.map((path) => getLeafA(path).cijferVerschilSeCe ?? 0));
			const min = Math.min(...afwijkingen, -1);
			const max = Math.max(...afwijkingen, 1);
			const standardTicks = [-1, 0, 1].map((afw) => ({ qty: afw, label: `${afw}` }));
			const extraTicks = min < -1 ? [{ qty: min, label: '' }] : [];
			return { min, max, ticks: [...extraTicks, ...standardTicks], areas: this.areas };
		} else {
			// ticks voor 0 t/m 10 met stapjes van 2
			const ticks = range(0, 12, 2).map((qty) => ({ qty, label: `${qty}` }));
			return { min: 0, max: 10, ticks };
		}
	}

	override getDisplayOptions(): ExportFilter[] {
		return [{ label: 'Weergave', value: this.weergave }];
	}

	protected readonly DashboardVariant = DashboardVariant;
}
