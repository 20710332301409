<app-dashboard-container
	class="dashboard-cijfers"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="actueelFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijfers"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header [legenda]="['x_cijfer_ov']" [leftOptionsTemplate]="leftOptions">
			<ng-template #leftOptions>
				<div class="weergave cijfertype">
					Cijfertype:
					<dt-form-dropdown
						[opties]="cijferkolomKeuzeOpties()"
						[selectedOptie]="getSelectedCijfertypeOptie()"
						(onOptieClicked)="qp.dispatch('cijfertype', $event)"
					></dt-form-dropdown>
				</div>
				@if (cijferkolomKeuze !== "Gemiddelde" && userService.bron() === InstellingBron.Somtoday) {
					<div class="weergave dossier">
						Toetsdossier:
						<dt-form-dropdown
							[opties]="dossierOpties"
							[selectedOptie]="getSelectedDossierOptie()"
							(onOptieClicked)="qp.dispatch('dossier', $event)"
						></dt-form-dropdown>
					</div>
				}
			</ng-template>
		</app-dashboard-header>
		<div>Let op: om de hoeveelheid data te beperken wordt er gefilterd op niveau en leerjaar.</div>
		@if (filterExpressions) {
			<app-barchart-table
				[tableGroups]="tableGroups"
				[filters]="filterExpressions"
				[permanentFilters]="getPermanentFilters(cijferkolomKeuze, dossier)"
				[groups]="fixedGroups"
				[subgroups]="fixedSubgroups"
				[showTitles]="true"
				[config]="this"
				[barchartWidth]="200"
			></app-barchart-table>
		}
	</section>
</app-dashboard-container>
