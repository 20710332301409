<div
	#button
	class="meatball-button"
	data-princexml-ignore
	data-html2canvas-ignore
	[class]="orientation === 'H' ? 'svg-meatball-menu' : 'svg-meatball-menu-vertical'"
	[class.grey]="size === 'M'"
	[class.large]="size === 'L'"
	(click)="toggleOpen()"
	[attr.data]="MEATBALL_ID"
></div>
<ng-template #dropdown>
	<div class="dropdown-anchor">
		<div
			class="dropdown"
			[delayClickOutsideInit]="true"
			(clickOutside)="hide()"
			[clickOutsideEvents]="'click,touchend'"
			(click)="hide()"
			[class.rounded-corners]="rounded"
		>
			<ng-content></ng-content>
		</div>
	</div>
</ng-template>
