import { Attributes, BaseDashboardConfig } from './base-dashboard-config';
import { FilterExpression } from '../../../services/data.service';
import { Level, PartialPath } from '../../../services/data-tree';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ErrorMessage, ErrorMessageEnum } from '@cumlaude/metadata';
import { BugsnagService } from '@cumlaude/bugsnag';

export type DashboardContext<I extends Attributes, A extends Attributes, C extends BaseDashboardConfig<I, A>> = {
	config: C;
	filter: FilterExpression;
	tableRootIndex: 0 | 1 | 3;
	groupNames: string[];
	subgroupNames: string[];
	measureNames: string[];
	dataRoot?: Level<A, number[]>;
	aggCombine: (c: Level<A, number[]>[], path: PartialPath<A, number[]>, all: A | undefined, allFiltered: A | undefined) => A | undefined;
	orderRoot?: Level<unknown, []>;
};

export function isHappyContext<C extends object>(context: C | ErrorMessage): context is C {
	return !('error' in context);
}

export function isErrorContext<C extends object>(context: C | ErrorMessage): context is ErrorMessage {
	return 'error' in context;
}

export function hasDataRoot<R, C extends { dataRoot?: R }>(
	context: C
): context is C & {
	dataRoot: NonNullable<C['dataRoot']>;
} {
	return Boolean(context.dataRoot);
}

export function parseErrorResponse(resp: HttpErrorResponse, bugsnag: BugsnagService): Observable<ErrorMessage> {
	try {
		const parsed = JSON.parse(resp.error);
		if (isErrorContext(parsed)) return of(parsed);
	} catch (e) {
		console.error(resp);
	}

	bugsnag.notify(resp);
	return of({ error: ErrorMessageEnum.UNKNOWN });
}
