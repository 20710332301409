import { Component, inject } from '@angular/core';
import { BaseDetailsPanelComponent, convert, convertDate } from '../../base-details-panel/base-details-panel.component';
import { Attr, BasicFilterExpression, CompoundFilterExpression, FilterExpression, ListOptions, TableResponse } from '../../../services/data.service';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { DetailsElementData, ElementDataDetail, Leerling, LeerlingDetail, LijstElementData } from '../../Details';
import { isNull } from 'lodash-es';
import { Observable } from 'rxjs';
import { SvgSpinnerComponent } from '../../../layout/svg-spinner/svg-spinner.component';
import { DetailPanelComponent } from '../../detail-panel/detail-panel.component';
import { LijstComponent } from '../../lijst/lijst.component';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { TabMenuComponent } from '../../../layout/tab-menu/tab-menu.component';
import { UserService } from '../../../services/user.service';
import { SelectionBarComponent } from '../../../filter-bar/selection-bar.component';
import { parseSearchInputToNumbers } from '@cumlaude/shared-utils';

const detailsDataCol: Attr[][] = [
	['lb_d_plaatsing_va'],
	['lb_nr_leerling'],
	['lb_fk_ll', 'll_nm_roepnaam'],
	['lb_fk_ll', 'll_nm_voorvoegsel'],
	['lb_fk_ll', 'll_nm_achternaam'],
	['lb_nm_klas'],
	['lb_nm_opleiding'],
	['lb_fk_ll', 'll_d_geboortedatum'],
	['lb_fk_pc_leerling', 'pc_nm_plaats'],
	['lb_fk_ll', 'll_nm_nationaliteit1'],
	['lb_fk_ll', 'll_nm_nationaliteit2'],
	['lb_fk_ll', 'll_nm_svh'],
	['lb_fk_ll', 'll_nm_svb'],
	['lb_fk_ll', 'll_nm_basisschooladvies_duo'],
	['lb_fk_ll', 'll_nm_basisschooladvies_uni'],
	['lb_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
	['lb_d_inschrijving'],
	['lb_d_uitschrijving'],
	['lb_fk_ll', 'll_des_uitschrijving'],
	['lb_fk_ll', 'll_des_vertrekstatus'],
	['lb_fk_ll', 'll_pic_pasfoto'],
	['lb_nm_schooljaar'],
	['lb_nr_leerjaar'],
];

const lijstDataCol: Attr[][] = [['ll_nm_achternaam'], ['ll_nm_voorvoegsel'], ['ll_nm_roepnaam'], ['ll_nr_leerling'], ['ll_pic_pasfoto']];

@Component({
	selector: 'app-leerling-details-panel',
	templateUrl: '../../base-details-panel/base-details-panel.component.html',
	styleUrls: ['../../base-details-panel/base-details-panel.component.scss'],
	imports: [
		TabMenuComponent,
		InstantSearchBoxComponent,
		LijstComponent,
		DetailPanelComponent,
		SvgSpinnerComponent,
		RouterOutlet,
		AsyncPipe,
		SelectionBarComponent,
	],
})
export class LeerlingDetailsPanelComponent extends BaseDetailsPanelComponent<Leerling, LeerlingDetail> {
	protected readonly userService = inject(UserService);

	getUrlPart(): string {
		return '/details/leerling';
	}

	getTitle(): string {
		return 'Leerlingen';
	}

	getType(): string {
		return 'leerling';
	}

	getElementKey(element: Leerling) {
		return element.ll_nr_leerling;
	}

	getLijstElementData(input: Leerling): LijstElementData {
		const title = [input.ll_nm_roepnaam, input.ll_nm_voorvoegsel, input.ll_nm_achternaam].filter(Boolean).join(' ');
		return {
			title,
			subTitle: input.ll_nr_leerling.toString(),
			pasfoto: input.ll_pic_pasfoto,
		};
	}

	getDetailsElementData(input: LeerlingDetail): DetailsElementData {
		const details = <ElementDataDetail[]>[
			{ label: 'Opleiding', waarde: input.lb_nm_opleiding },
			{ label: 'Geboortedatum', waarde: convertDate(input.ll_d_geboortedatum) },
			{ label: 'Woonplaats', waarde: input.pc_nm_plaats },
		];

		const nationaliteiten = [input.ll_nm_nationaliteit1, input.ll_nm_nationaliteit2].filter(Boolean);
		details.push({ label: 'Nationaliteit', waarde: nationaliteiten.join(', ') });

		details.push({ label: 'School van herkomst', waarde: convert(input.ll_nm_svh) });

		if (isNull(input.ll_nm_basisschooladvies_duo))
			details.push({ label: 'Basisschooladvies', waarde: convert(input.ll_nm_basisschooladvies_uni) });
		else details.push({ label: 'Basisschooladvies DUO', waarde: convert(input.ll_nm_basisschooladvies_duo) });

		if (!isNull(input.ll_nm_basisschooladvies_uni_herzien))
			details.push({ label: 'Heroverwogen basisschooladvies', waarde: input.ll_nm_basisschooladvies_uni_herzien });

		details.push({ label: 'Inschrijvingsdatum', waarde: convertDate(input.lb_d_inschrijving) });

		const datumUitschrijving = convertDate(input.lb_d_uitschrijving);
		if (datumUitschrijving != '-') {
			details.push(
				{ label: 'Uitschrijvingsdatum', waarde: datumUitschrijving },
				{ label: 'Uitschrijvingsreden', waarde: convert(input.ll_des_uitschrijving) },
				{ label: 'Vertrekstatus', waarde: convert(input.ll_des_vertrekstatus) }
			);

			const llNmSvb = input.ll_nm_svb;
			if (llNmSvb) {
				details.push({
					label: 'School van bestemming',
					waarde: convert(llNmSvb),
				});
			}
		}

		const title = [input.ll_nm_roepnaam, input.ll_nm_voorvoegsel, input.ll_nm_achternaam].filter(Boolean).join(' ');
		return {
			title,
			subTitle: [input.lb_nr_leerling.toString(), input.lb_nm_klas],
			pasfoto: input.ll_pic_pasfoto,
			details,
		};
	}

	getLijstData(options: Partial<ListOptions>): Observable<TableResponse<Leerling>> {
		return this.dataService.getLeerlingen<Leerling>({ ...options, col: lijstDataCol });
	}

	getDetailsData(key: number): Observable<TableResponse<LeerlingDetail>> {
		return this.dataService.getLeerlingDetails<LeerlingDetail>(detailsDataCol, key);
	}

	getFilters(searchInput: string): FilterExpression | undefined {
		if (searchInput === '') return undefined;

		// Als we meerdere nummers herkennen in de input, zoek dan alleen op exact nummer. Anders op (een deel van) nummer of naam.
		const nummers = parseSearchInputToNumbers(searchInput);
		if (nummers.length > 1) {
			return new BasicFilterExpression(['ll_nr_leerling'], nummers, 'in');
		}

		return new CompoundFilterExpression(
			[
				new BasicFilterExpression(['ll_nr_leerling'], `%${searchInput}%`, 'like'),
				new BasicFilterExpression(['ll_fun_volledige_naam'], `%${searchInput}%`, 'like_unaccent'),
			],
			'or'
		);
	}

	isSelectionVisible() {
		return true;
	}
}
