<app-dashboard-container (doExport)="this.doExport(filterExpressions!, [], [], $event)" [factTable]="factTable" class="dashboard-cijfers">
	<app-filter-panel
		(filterExpressionsChange)="filterExpressions = $event"
		[allFilters]="getAllFilters()"
		[defaultFilters]="variant() === DashboardVariant.ACTUEEL ? actueelFilters : historieFilters"
		[permanentFilterExpressions]="ownBrinFilter()"
		[leerlingselectie]="false"
		endpoint="/examencijfers"
	></app-filter-panel>
	<app-filter-bar></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header
			[legendaKeyIsClassName]="true"
			[legenda]="[afwijkingpercentiel() === AfwijkingPercentielWeergave.AFWIJKING ? 'x_afwijking_neg_pos' : 'x_percentiel_neg_gem_pos']"
			[variant]="variant()"
			[leftOptionsTemplate]="leftOptions"
		>
			<ng-template #leftOptions>
				<app-weergave-optie label="Weergave" param="afwijkingpercentiel" [value]="afwijkingpercentiel()" [enum]="AfwijkingPercentielWeergave">
				</app-weergave-optie>
			</ng-template>
		</app-dashboard-header>
		@if (prognose) {
			<div class="sub-title">
				@switch (variant()) {
					@case (DashboardVariant.ACTUEEL) {
						DUO heeft voor dit schooljaar (nog) geen landelijke examencijfers gepubliceerd. Voor het berekenen van het landelijk
						gemiddelde worden hier uitsluitend data van CumLaude gebruikt.
					}
					@case (DashboardVariant.HISTORIE) {
						DUO heeft voor {{ cumlaudeSchooljaren }} (nog) geen landelijke examencijfers gepubliceerd. Daarom wordt voor
						{{ cumlaudeSchooljaren?.includes(",") ? "deze jaren" : "dit jaar" }} het landelijk gemiddelde berekend met uitsluitend data
						van CumLaude.
					}
				}
			</div>
		}
		@switch (variant()) {
			@case (DashboardVariant.ACTUEEL) {
				@switch (afwijkingpercentiel()) {
					@case (AfwijkingPercentielWeergave.AFWIJKING) {
						<app-barchart-table
							[filters]="filterExpressions"
							[defaultGroups]="defaultGroups"
							[groups]="groups()"
							[subgroups]="subgroups()"
							[config]="this"
						>
						</app-barchart-table>
					}
					@case (AfwijkingPercentielWeergave.PERCENTIEL) {
						<app-data-tree-table
							[filters]="filterExpressions"
							[defaultGroups]="defaultGroups"
							[groups]="groups()"
							[subgroups]="subgroups()"
							[config]="this"
						>
						</app-data-tree-table>
					}
				}
			}
			@case (DashboardVariant.HISTORIE) {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="groups()"
					[subgroups]="subgroups()"
					[batchLevels]="1"
					[config]="this"
					[schooljaarPath]="['ekc_nm_schooljaar']"
					[style]="getVbarStyle()"
					[barchartSize]="112"
					[partitionMeasure]="partitionMeasure()"
				>
				</app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
