/**
 * Atts / AttrPaths die eindigen op deze attributen worden niet getoond als filter/groepering als je rol Bestuur uitoefent.
 *
 * Houd deze in sync met de blocklist in de backend! (AttributeRestrictedQuery)
 * Uitgecommente waarden komen (nog) niet voor in de OpenAPI yaml (komen dus niet door de typechecher).
 */
import { Attr } from './data.service';
import { ExtraFilterNames } from './filter-config';

export const bestuurBlocklist: (Attr | ExtraFilterNames)[] = [
	'aw_nr_leerling',
	'cf_nm_leerling',
	'cf_nr_leerling',
	'ds_nr_leerling',
	'lb_nr_leerling',
	'll_d_geboortedatum',
	'll_d_in_nederland',
	'll_nm_achternaam',
	'll_nm_leerling',
	'll_nm_roepnaam',
	'll_nm_voorvoegsel',
	'll_nr_leerling',
	'll_pic_pasfoto',
	'll_pk_key',
	'lr_nr_leerling',
	'mw_abb_geslacht',
	'mw_abb_medewerker',
	'mw_co_externid',
	'mw_fun_volledige_naam',
	'mw_nm_achternaam',
	'mw_nm_emailadres',
	'mw_nm_medewerker',
	'mw_nm_roepnaam',
	'mw_nm_voorvoegsel',
	'mw_nr_medewerker',
	'mw_pic_pasfoto',
	'mw_pk_key',
	'x_leerlingselectie',
];
