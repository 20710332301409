/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum OnderwijspositieStatus {
  INFORMATIE_VOLLEDIG = 'Informatie volledig',
  OPLEIDING_VOOR_LJ_3_NIET_CORRECT_IN_SYSTEEM = 'Opleiding voor lj3 niet correct in systeem',
  ADVIES_NIET_CORRECT_IN_SYSTEEM = 'Advies niet correct in systeem',
  EXAMENSTATUS_NIET_CORRECT_IN_SYSTEEM = 'Examenstatus niet correct in systeem',
  ADVIES_PLAATSING_VOLGEND_SCHOOLJAAR_NOG_NIET_BEKEND = '(Advies)plaatsing volgend schooljaar nog niet bekend',
  DOORSTROOM_NIET_AF_TE_LEIDEN_UIT_VERTREKSTATUS = 'Doorstroom niet af te leiden uit vertrekstatus',
  ONBEKENDE_DOORSTROOM = 'Onbekende doorstroom',
  DOORSTROOM_KAN_DOOR_ONBEKENDE_OORZAAK_NIET_WORDEN_BEPAALD = 'Doorstroom kan door onbekende oorzaak niet worden bepaald',
  UITSTROOM_NAAR_ONBEKEND_NIVEAU = 'Uitstroom naar onbekend niveau'
}
