import { Component, OnInit } from '@angular/core';
import { find } from 'lodash-es';
import { Option, SingleSelectDropdownComponent, SingleSelectListComponent } from '@cumlaude/shared-components-inputs';
import { DateRangeComponentParams, MultiSelectComponentParams, SingleSelectComponentParams } from '../../services/filter-config';
import { BaseSelectFilterComponent } from '../base-select-filter/base-select-filter.component';

@Component({
	selector: 'app-single-select-filter',
	templateUrl: './single-select-filter.component.html',
	styleUrls: ['./single-select-filter.component.scss'],
	imports: [SingleSelectListComponent, SingleSelectDropdownComponent],
})
export class SingleSelectFilterComponent<T> extends BaseSelectFilterComponent<T, T> implements OnInit {
	currentValue?: T = undefined;

	override ngOnInit() {
		super.ngOnInit();
		this.subscriptions.push(
			this.filterService.getFilterStateAsInput(this.filterName).subscribe((val) => {
				this.currentValue = val;
			})
		);
	}

	processComponentParams(
		componentParams: SingleSelectComponentParams<any> | MultiSelectComponentParams<any> | DateRangeComponentParams,
		valueAllowed: ((val: any) => boolean) | undefined
	) {
		const { defaultOptions, fixedOptions } = <SingleSelectComponentParams<T>>componentParams;
		this.processFixedAndDefaultOptions(valueAllowed, fixedOptions, defaultOptions);
	}

	getSelected(options: Option<T>[]): Option<T> {
		return find(options, (option) => this.valueMatches(option, this.currentValue))!;
	}

	valueMatches(option: Option<T>, value: T | undefined): boolean {
		return option.value === value;
	}

	select(optie: T) {
		this.filterService.setFilterInput(this.filterName, optie);
		this.searchInputChange$.next('');
	}

	search(value: string) {
		this.searchInputChange$.next(value);
	}

	protected mapValueToOption(option: T, inactive: boolean): Option<T> {
		return {
			value: option,
			text: this.filterService.configs[this.filterName]!.valueString(option),
			style: inactive ? 'disabled' : undefined,
		};
	}

	showDropdown(state: boolean) {
		if (state) this.options.set(this.generateOptions());
	}
}
