import { afterNextRender, Directive, ElementRef, Injector, inject, AfterViewInit } from '@angular/core';
import { Focusable } from './focusable';

const FOCUSABLE_ELEMENTS = ['button', '[href]', 'input', 'select', 'textarea', '[tabindex]:not([tabindex="-1"])'].join(',');

/**
 * Directive die automatisch na de volgende render de focus plaatst op het element waarop deze is toegepast.
 * Werkt op native HTML-elementen, Angular componenten die de Focusable abstracte klasse implementeren,
 * of plaatst anders de focus op het eerste focusbare element binnen het host-element.
 * Dit is een alternatief voor het autofocus-attribuut van HTML5, dat niet altijd goed werkt in Angular.
 *
 * Voorbeeld van gebruik:
 *
 * ```html
 * <!-- Op een HTML element -->
 * <input autofocus type="text">
 *
 * <!-- Op een container (zal eerste focusbare element focussen) -->
 * <div autofocus>
 *   <button>Deze krijgt focus</button>
 *   <input type="text">
 * </div>
 *
 * <!-- Op een component die Focusable implementeert -->
 * <my-focusable-component autofocus></my-focusable-component>
 *
 * <!-- Op een component die niet Focusable implementeert maar wel focusbare elementen rendert -->
 * <my-component autofocus></my-component>
 * ```
 */
@Directive({
	selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
	private readonly host = inject(ElementRef);
	private readonly injector = inject(Injector);

	private readonly component: Focusable | null;

	constructor() {
		this.component = this.injector.get(Focusable, null);
	}

	ngAfterViewInit() {
		afterNextRender(
			() => {
				if (this.host.nativeElement instanceof HTMLElement && this.host.nativeElement.matches(FOCUSABLE_ELEMENTS)) {
					this.host.nativeElement.focus();
				} else if (this.component instanceof Focusable) {
					this.component.focus();
				} else {
					// Zoek het eerste focusbare element binnen het host-element
					const focusableElements = this.host.nativeElement.querySelectorAll(FOCUSABLE_ELEMENTS);
					if (focusableElements.length > 0) {
						(focusableElements[0] as HTMLElement).focus();
					}
				}
			},
			{ injector: this.injector }
		);
	}
}
