import { Component, Input, inject } from '@angular/core';
import { TableCellComponent } from '../../../components/table/table/table.model';
import { VbarSeriesComponent, VPartitionData } from '../vbar-series/vbar-series.component';
import { Axis } from '../../../../services/axis';
import { AttrPath } from '../../../../services/data.service';
import { DisplayService } from '../../../../services/display.service';

export interface VbarSeriesData {
	series: VPartitionData[];
	key: string | null;
	attr: AttrPath | undefined;
}

export enum VbarStyle {
	BAR = 'BAR',
	CIRCLE = 'CIRCLE',
}

export interface VBarOptions {
	showPartitionRectangle?: boolean; // Toont een rand om de partition
}

export interface VbarBatchData {
	batch: VbarSeriesData[];
	barHeight: number;
	yAxis: Axis;
	style: VbarStyle;
	options?: VBarOptions;
}

@Component({
	selector: 'app-vbar-batch',
	templateUrl: './vbar-batch.component.html',
	styleUrls: ['./vbar-batch.component.scss'],
	imports: [VbarSeriesComponent],
})
export class VbarBatchComponent implements TableCellComponent<VbarBatchData> {
	private readonly displayService = inject(DisplayService);

	@Input() data!: VbarBatchData;

	getLabel(key: string | null, attr: AttrPath | undefined) {
		return this.displayService.display(key, attr);
	}
}
