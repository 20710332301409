<app-dashboard-container
	class="dashboard-se-ce-verschil dashboard-cijfers"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/cijfers"
		[permanentFilterExpressions]="permanentFilterExpressions"
	></app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header
			[variant]="variant"
			[legenda]="weergave === 'SE-CE verschil' ? ['cf_fun_cijferverschil_se_ce_afgerond'] : ['x_cijfer_ov']"
			[legendaKeyIsClassName]="weergave === 'SE-CE verschil'"
			[leftOptionsTemplate]="leftOptions"
		>
			<ng-template #leftOptions>
				<div class="weergave cijfersseceweergave">
					Weergave:
					<dt-form-dropdown
						[opties]="weergaveOpties"
						[selectedOptie]="getSelectedWeergaveOptie()"
						(onOptieClicked)="qp.dispatch('cijfersseceweergave', $event)"
					></dt-form-dropdown>
				</div>
			</ng-template>
		</app-dashboard-header>
		@for (w of [weergave]; track w) {
			<!-- Rerender de tabel bij wijziging van weergave -->
			@if (variant === "Actueel") {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions"
					[defaultGroups]="defaultGroups"
					[groups]="groups"
					[config]="this"
				></app-barchart-table>
			}
		}
		@if (variant === "Historie") {
			<app-vbarchart-table
				[filters]="filterExpressions"
				[permanentFilters]="permanentFilterExpressions"
				[defaultGroups]="defaultGroups"
				[groups]="getHistorieGroups(groups)"
				[subgroups]="getHistorieSubgroups(groups)"
				[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
				[config]="this"
				[schooljaarPath]="['cf_nm_schooljaar']"
				[partitionMeasure]="getPartitionMeasure(weergave)"
				[options]="{ showPartitionRectangle: weergave === 'SE-CE verschil' }"
			>
			</app-vbarchart-table>
		}
	</section>
</app-dashboard-container>
