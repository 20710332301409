import { Component, OnInit, inject } from '@angular/core';
import { FilterName } from '../../services/filter-config';
import { AttrPath, DataOptions, DataResponse, DataService, ExportDataOptions, FilterExpression } from '../../services/data.service';
import { last, memoize, range, sum } from 'lodash-es';
import {
	BARCHART_COLUMN_NAME,
	ColumnType,
	createMeasureColumn,
	DataRow,
	DUMMY_ROW_ID,
	getInitialAttributes,
	getSubgroupAttributes,
} from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Observable } from 'rxjs';
import { ColumnDef, createColumnDef, createDefaultHeaderCellDef } from '../../shared/components/table/table/table.model';
import { att, count_records } from '../../services/measures';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { HbarPartitionComponent } from '../../shared/components/hbar/hbar-partition.component';
import { BarInfo } from '../../services/stacked-bars';
import { maxOver, noAgg0 } from '../../services/aggregation';
import { generateCssClassForLesregistratie, generateCssClassForString } from '@cumlaude/shared-utils';
import { LabelCellComponent } from '../../shared/components/table/cells/label-cell/label-cell.component';
import { formatNumber } from '@angular/common';
import { PivotTableConfig } from '../../shared/dashboard/pivot-table/pivot-table-config';
import { Attributes, BaseDashboardConfig, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Table } from '@cumlaude/metadata';
import { DashboardVariant, Interval } from '../../services/weergave-opties';
import { getCellPath, getInitialAttributesForCell, PivotTableComponent } from '../../shared/dashboard/pivot-table/pivot-table.component';
import { FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { LinechartTableConfig } from '../../shared/dashboard/linechart-table/linechart-table-config';
import { PointInfo } from '../../shared/dashboard/linechart-table/linechart/linechart.component';
import { Axis, createYAxis } from '../../services/axis';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { ExportType } from '../../services/export.service';
import { LinechartTableComponent } from '../../shared/dashboard/linechart-table/linechart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface LesregistratiesI extends Attributes {
	lr_nm_lesregistratie: string;
	lr_nr_leerlingen: number;
}

export interface LesregistratiesA extends Attributes {
	max_count: number;
	aantalLeerlingen: number;
}

@Component({
	selector: 'app-lesregistraties',
	templateUrl: './lesregistraties.component.html',
	styleUrls: ['./lesregistraties.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		PivotTableComponent,
		LinechartTableComponent,
		FilterBarComponent,
	],
})
export class LesregistratiesComponent extends PivotTableConfig<LesregistratiesI, LesregistratiesA> implements OnInit {
	protected readonly dataService = inject(DataService);

	actueelFilters: FilterName[] = [
		'lr_d_datum.per_nm_schooljaar',
		'lr_fk_lb.lb_co_brin',
		'lr_fk_vs.vs_nm_vestiging',
		'lr_fk_ilt.ilt_nm_niveau',
		'lr_nr_leerjaar',
		'lr_is_geoorloofd',
		'lr_nm_klas',
		'lr_nm_lesregistratie',
		'lr_nm_vak',
		'lr_d_datum.per_d_datum',
	];

	historieFilters: FilterName[] = [
		'lr_d_datum.per_nm_schooljaar', // is onzichtbaar en filtert niet, maar is alleen om het schooljaar van de actueel-variant vast te houden
		'x_lr_schooljaar_historie',
		'x_lr_multiselect_schooljaar',
		'lr_fk_ilt.ilt_nm_niveau',
		'lr_is_geoorloofd',
		'lr_nr_leerjaar',
		'lr_nm_klas',
		'lr_nm_lesregistratie',
		'lr_nm_vak',
		'lr_fk_vs.vs_nm_vestiging',
		'lr_d_datum.per_d_datum',
	];

	protected actueelExportTypes = Object.values(ExportType);
	protected historieExportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.DATA];

	defaultGroups: AttrPath[] = [['lr_fk_ilt', 'ilt_nm_niveau'], ['lr_nr_leerjaar']];

	selectedGroups: AttrPath[] = this.defaultGroups;

	fixedSubgroups: AttrPath[] = [['lr_nm_lesregistratie']];

	override availableGroups: AttrPath[] = [
		['lr_fk_ilt', 'ilt_nm_niveau'],
		['lr_fk_ilt', 'ilt_abb_profiel'],
		['lr_nr_leerjaar'],
		['lr_nm_klas'],
		['lr_fk_ll', 'll_nm_leerling'],
		['lr_is_geoorloofd'],
		['lr_des_lesregistratie'],
		['lr_nm_opleiding'],
		['lr_fks_mw', 'mw_nm_medewerker'],
		['lr_fk_vs', 'vs_nm_vestiging'],
		['lr_nm_vak'],
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions: FilterExpression[] = [];

	variant!: DashboardVariant;

	interval!: Interval;

	intervalOpties = Object.values(Interval).map((val) => new Option(val));

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe('interval').subscribe((interval) => {
				this.interval = interval;
			}),
			this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups))
		);
	}

	override singleAggregators = {
		max_count: maxOver('count_records'),
		aantalLeerlingen: noAgg0('lr_nr_leerlingen'),
	};

	factTable = Table.fac_lr_lesregistratie;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		const totaalLevel = this.selectedGroups.length + (this.fixedSubgroups.length - 1);
		return this.dataService.getLesregistratieData({ ...options, r: range(0, totaalLevel + 1) });
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getLesregistratieExportData(options);
	}

	override createMeasureColumns(
		context: DashboardContext<LesregistratiesI, LesregistratiesA, LesregistratiesComponent>
	): ColumnDef<DataRow<LesregistratiesA>>[] {
		return [
			createMeasureColumn('Aantal registraties', count_records, { context }),
			this.createLeerlingColumn('Leerlingen', att('aantalLeerlingen'), { context }),
		];
	}

	override columnOrder: ColumnType[] = [ColumnType.GROUP, ColumnType.PIVOT, ColumnType.MEASURE];

	override createPivotColumns(
		columnRoot: Level<LesregistratiesA, number[]>,
		context: DashboardContext<LesregistratiesI, LesregistratiesA, LesregistratiesComponent>
	): ColumnDef<DataRow<LesregistratiesA>>[] {
		return columnRoot.r.map((path) => this.createPivotColumn(path, context));
	}

	private createPivotColumn(
		path: Path<LesregistratiesA, number[]>,
		context: DashboardContext<LesregistratiesI, LesregistratiesA, LesregistratiesComponent>
	): ColumnDef<DataRow<LesregistratiesA>> {
		const { subgroupNames, measureNames } = context;
		const attrs = getSubgroupAttributes<LesregistratiesI>(subgroupNames, path);
		const { lr_nm_lesregistratie } = attrs;
		const BARCHART_CLASS = 'cdk-column-' + BARCHART_COLUMN_NAME;

		const colIndex = last(path)!.i;
		const colKey = `lesregistratie-${colIndex}`;
		const coldef = createColumnDef<DataRow<LesregistratiesA>>(colKey);
		coldef.sortable = true;
		coldef.header = createDefaultHeaderCellDef(colKey, lr_nm_lesregistratie);
		coldef.header.class = BARCHART_CLASS;

		coldef.body.component = HbarPartitionComponent;
		coldef.body.class = BARCHART_CLASS;
		coldef.body.getValue = (row: DataRow<LesregistratiesA>) => {
			if (row._id === DUMMY_ROW_ID) {
				// wordt aangeroepen bij sorteren, geef hier de measure terug ipv BarInfo
				return sum(
					last(row._path)!.r.map((path) => getInitialAttributesForCell(subgroupNames, measureNames, path, colIndex)?.count_records ?? 0)
				);
			}

			const cellPath = getCellPath(row._path, colIndex);
			if (!cellPath) return { qty: null, stacks: [], axis: { min: 0, max: 100, ticks: [] } };

			const { count_records, lr_nm_lesregistratie } = getInitialAttributes<LesregistratiesI>(subgroupNames, measureNames, cellPath);

			const maxSize = row._path[0].a.max_count;
			const barInfo: BarInfo = {
				size: count_records,
				selection: this.createSelection(context, cellPath),
				className: generateCssClassForLesregistratie(lr_nm_lesregistratie),
			};
			return {
				barWidth: 100,
				stacks: [[barInfo]],
				qty: count_records,
				axis: { min: 0, max: maxSize, ticks: [] },
				text: formatNumber(count_records, 'nl-NL', '1.0-0'),
			};
		};

		coldef.footer.component = LabelCellComponent; // Align left
		const footerVal = last(path)!.a.count_records;
		coldef.footer.getValue = () => formatNumber(footerVal, 'nl-NL', '1.0-0');
		coldef.footer.class = BARCHART_CLASS;

		return coldef;
	}

	getSelectedIntervalOptie() {
		return this.intervalOpties.find((optie) => optie.value === this.interval)!;
	}

	get intervalSubgroups(): AttrPath[] {
		switch (this.interval) {
			case Interval.MAAND:
				return [
					['lr_d_datum', 'per_nm_schooljaar'],
					['lr_d_datum', 'per_nr_maand'],
				];
			case Interval.SCHOOLJAAR:
				return [['lr_d_datum', 'per_nm_schooljaar']];
			case Interval.WEEK:
				return [
					['lr_d_datum', 'per_nm_schooljaar'],
					['lr_d_datum', 'per_nr_isoweek'],
				];
		}
	}

	get nrIntervalSubgroups(): 1 | 2 {
		return <1 | 2>this.intervalSubgroups.length;
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(LesregistratiesComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(LesregistratiesComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([intervalSubgroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...fixedSubgroups, ...intervalSubgroups];
	}

	linechartConfig = new (class extends LinechartTableConfig<LesregistratiesI, LesregistratiesA> {
		constructor(private dashboard: LesregistratiesComponent) {
			super();
		}

		override makePoints(
			r: Path<LesregistratiesA, number[]>[],
			_context: DashboardContext<LesregistratiesI, LesregistratiesA, LinechartTableConfig<LesregistratiesI, LesregistratiesA>>,
			group: Level<LesregistratiesA, number[]>
		): PointInfo[] {
			const lineClass = generateCssClassForString(group.k);
			const qty = r.length ? getLeafA(r[0]).count_records : 0;
			return [
				{ lineClass, qty },
				{ lineClass: `${lineClass}-fill`, qty },
			];
		}

		override getYAxis(path: Path<LesregistratiesA, number[]>): Axis {
			return createYAxis([0, getLeafA(path).max_count], 5, (val) => formatDecimal(val, '1.0-1'));
		}

		override getLineNames(path: Path<LesregistratiesA, number[]>): { [lineClass: string]: string } {
			const line = last(path)!.k!;
			return { [generateCssClassForString(line)]: line };
		}

		factTable = Table.fac_aw_aanwezigheid;
		getData = this.dashboard.getData.bind(this.dashboard);
		override getSelectionConfig = this.dashboard.getSelectionConfig.bind(this.dashboard);
		override dataRootSelection = this.dashboard.dataRootSelection;
		override getExportData = this.dashboard.getExportData.bind(this.dashboard);
		override singleAggregators = this.dashboard.singleAggregators;
		override availableGroups = this.dashboard.availableGroups;
		override enrichTableModel = this.dashboard.enrichTableModel.bind(this.dashboard);
	})(this);

	override getSelectionConfig(
		context: DashboardContext<LesregistratiesI, LesregistratiesA, BaseDashboardConfig<LesregistratiesI, LesregistratiesA>>
	): SelectionConfig<LesregistratiesA> | undefined {
		return { ...super.getSelectionConfig(context)!, getSize: att('aantalLeerlingen') };
	}

	protected readonly DashboardVariant = DashboardVariant;
}
