<div class="root">
	@for (menuItem of appMenuService.topLevelMenu(); track menuItem.url) {
		@if (menuItem.subMenus) {
			<div (mouseenter)="openIfHorizontal(menuItem.name)" (mouseleave)="closeIfHorizontal(menuItem.name)">
				<a
					class="root-item"
					[routerLink]="menuItem.url"
					[queryParams]="menuItem.queryParams"
					[queryParamsHandling]="menuItem.queryParamsHandling"
					[class.ingeklapt]="openSubMenu !== menuItem.name"
					[class.uitgeklapt]="openSubMenu === menuItem.name"
					routerLinkActive="active"
				>
					<span (click)="toggle(menuItem.name, $event)">{{ menuItem.name }}</span>
				</a>
				<div class="sub">
					@for (subMenuItem of menuItem.subMenus; track subMenuItem.url) {
						<a
							class="sub-item"
							[routerLink]="subMenuItem.url"
							[queryParams]="subMenuItem.queryParams"
							[queryParamsHandling]="subMenuItem.queryParamsHandling"
							routerLinkActive="active"
						>
							{{ subMenuItem.name }}
						</a>
					}
				</div>
			</div>
		} @else {
			<a
				class="root-item"
				[routerLink]="menuItem.url"
				[queryParams]="menuItem.queryParams"
				[queryParamsHandling]="menuItem.queryParamsHandling"
				routerLinkActive="active"
			>
				{{ menuItem.name }}
			</a>
		}
	}
</div>
