import { Component, computed, inject, signal } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { BaseDialogComponent } from '@cumlaude/shared-components-dialogs';
import { Categorie, Groepering, GroeperingListComponent } from '../groepering-list/groepering-list.component';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { AttrPath } from '../../../services/data.service';
import { CategorieFields } from '../../../services/metadata';
import { attrLabel } from '../../../services/labels';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

export interface AlleGroeperingenOptions {
	activeGroepering?: AttrPath;
	alleGroeperingen: CategorieFields[];
	userGroups: AttrPath[];
}

@Component({
	selector: 'app-alle-groeperingen-dialog',
	templateUrl: './alle-groeperingen-dialog.component.html',
	styleUrls: ['./alle-groeperingen-dialog.component.scss'],
	imports: [BaseDialogComponent, GroeperingListComponent, InstantSearchBoxComponent],
})
export class AlleGroeperingenDialogComponent {
	protected readonly options = inject<AlleGroeperingenOptions>(DIALOG_DATA);
	protected readonly dialogRef = inject<DialogRef<Groepering, AlleGroeperingenDialogComponent>>(DialogRef);

	groeperingFilter = signal<string>('');

	groeperingen = computed<Categorie[]>(() => {
		const filterString = this.groeperingFilter();
		const { activeGroepering, alleGroeperingen, userGroups } = this.options;

		return alleGroeperingen
			.map((categorie) => {
				const groeperingen = categorie.atts
					.filter((groepering) => {
						if (activeGroepering?.join('.') === groepering.att) return true;

						return !userGroups.some((userGroep) => userGroep.join('.') === groepering.att);
					})
					.filter((groepering) => {
						if (filterString === '') return true;

						return (
							includesIgnoreCaseAndDiacritics(groepering.label, filterString) ||
							groepering.searchKeys?.some((searchKey) => includesIgnoreCaseAndDiacritics(searchKey, filterString)) ||
							includesIgnoreCaseAndDiacritics(groepering.categorie, filterString)
						);
					})
					.map((groepering) => {
						const attrPath = <AttrPath>groepering.att.split('.');
						return <Groepering>{
							attrPath: attrPath,
							naam: groepering.label ?? attrLabel(attrPath),
							current: activeGroepering?.join('.') === groepering.att,
						};
					})
					.sort((groupA, groupB) => groupA.naam.localeCompare(groupB.naam));

				return {
					naam: categorie.name,
					groeperingen,
				};
			})
			.filter((categorie) => categorie.groeperingen.length > 0)
			.sort((categorieA, categorieB) => categorieA.naam.localeCompare(categorieB.naam));
	});
}
