import { Component, computed, OnInit, Signal, inject } from '@angular/core';
import { Sort } from '../../shared/components/table/table/table.component';
import { Observable } from 'rxjs';
import { maxDeelVanJaar, maxOver, maxOverMapped, noAgg0, sumOver } from '../../services/aggregation';
import { Path } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	DoorstroomMeasure,
	ExportDataOptions,
	FilterExpression,
	xAggExcept,
} from '../../services/data.service';
import { att, att0, count_records, percOfTotal } from '../../services/measures';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { defaultDoorstroomActueelFilters, defaultDoorstroomHistorieFilters, FilterName } from '../../services/filter-config';
import { memoize, range } from 'lodash-es';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, BaseDashboardConfig, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Table } from '@cumlaude/metadata';
import { Axis, createYAxis } from '../../services/axis';
import { DashboardVariant, Eenheid } from '../../services/weergave-opties';
import { PsName } from '../../services/page-state.service';
import { BarInfo } from '../../services/stacked-bars';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { WeergaveOptieComponent } from '../../shared/components/weergave-optie/weergave-optie.component';
import { formatPercent } from '@angular/common';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface UitstroomI extends Attributes {
	ds_nr_weging: number;
	ds_is_prognose: string;
	xa: { [nr: number]: { ds_nr_weging: number } };
	ds_nr_leerlingen: number;
}

interface UitstroomA extends Attributes {
	max: number;
	weging: number;
	ds_is_prognose: number;
	maxPctJaar: number;
	ds_nr_leerlingen: number;
}

enum MeasureColumn {
	LEERLINGEN = 'Leerlingen',
	PERCENTAGE = 'Percentage',
}

const historieSchooljaarGroup: AttrPath = ['ds_nm_schooljaar_van'];

@Component({
	selector: 'app-instroom',
	templateUrl: './uitstroom.component.html',
	styleUrls: ['./uitstroom.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		WeergaveOptieComponent,
		FilterBarComponent,
	],
})
export class UitstroomComponent extends BarchartTableConfig<UitstroomI, UitstroomA> implements OnInit {
	private readonly dataService = inject(DataService);

	defaultGroups: AttrPath[] = [['ds_fk_ll', 'll_nm_svb']];

	groups: AttrPath[] = this.defaultGroups;

	override availableGroups: AttrPath[] = [
		['ds_co_brin_svh'],
		['ds_nm_klas_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_fk_ll', 'll_nm_onderwijssoort_svb'],
		['ds_nm_opleiding_van'],
		['ds_fk_ilt_van', 'ilt_nm_opleiding'],
		['ds_fk_ll', 'll_nm_svb'],
		['ds_fk_ll', 'll_nm_svh'],
		['ds_fk_ll', 'll_nm_schoolsoort_svb'],
		['ds_fk_ll', 'll_des_uitschrijving'],
		['ds_fk_vs_van', 'vs_nm_vestiging'],
		['ds_fk_vs_naar', 'vs_nm_vestiging'],
		['ds_fk_ll', 'll_nm_geslacht'],
	];

	actueelFilters: FilterName[] = [...defaultDoorstroomActueelFilters, 'ds_nm_uitstroomtype_in_schooljaar', 'ds_nm_uitstroommoment_in_schooljaar'];

	historieFilters: FilterName[] = [
		...defaultDoorstroomHistorieFilters,
		'ds_nm_uitstroomtype_in_schooljaar',
		'ds_nm_uitstroommoment_in_schooljaar',
		'ds_fk_ll.ll_nm_svb',
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [
		new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1),
		new BasicFilterExpression(['ds_nm_uitstroom_in_schooljaar'], null, '<>'),
	];

	variant = this.qp.signal('variant');

	eenheid = this.qp.signal('eenheid');

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups)));
	}

	factTable = Table.fac_ds_doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		const xa = this.variant() === DashboardVariant.ACTUEEL ? [] : [xAggExcept(options.g!, historieSchooljaarGroup)];
		return this.dataService.getDoorstroomData({ ...options, xa, m: [DoorstroomMeasure.LEERLINGEN], r: range(0, this.getRowLevel() + 1) });
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected override singleAggregators = {
		max: maxOver('ds_nr_weging'),
		weging: sumOver<'ds_nr_weging', UitstroomI, number>('ds_nr_weging'),
		ds_is_prognose: maxOverMapped<UitstroomI>((v) => Number(v.ds_is_prognose)),
		maxPctJaar: maxDeelVanJaar('ds_nr_weging'),
		ds_nr_leerlingen: noAgg0<'ds_nr_leerlingen', UitstroomI>('ds_nr_leerlingen'),
	};

	override createMeasureColumns(context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): ColumnDef<DataRow<UitstroomA>>[] {
		if (this.variant() === DashboardVariant.HISTORIE) return [];

		return [
			this.createLeerlingColumn(MeasureColumn.LEERLINGEN, att('weging'), { context, format: '1.0-2' }, count_records),
			this.createLeerlingColumn(MeasureColumn.PERCENTAGE, percOfTotal('weging', 'weging'), { context, dataType: 'percentage' }, count_records),
		];
	}

	override isHistorieBatchVariant(): boolean {
		return this.variant() === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	override getBarchartQty(path: Path<UitstroomA, number[]>) {
		if ((this.variant() === DashboardVariant.HISTORIE && this.eenheid()) == Eenheid.PERCENTAGE) return att0('maxPctJaar')(path);
		else return att0('weging')(path);
	}

	override createXAxis(context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): Axis {
		return { min: 0, max: context.dataRoot!.a.max, ticks: [] };
	}

	override createYAxis(context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): Axis {
		if (!context.dataRoot) return super.createYAxis(context);
		if (this.eenheid() === Eenheid.AANTAL) {
			return createYAxis([0, context.dataRoot.a.max]);
		} else {
			return createYAxis([0, context.dataRoot.a.maxPctJaar], 5, (val) => formatPercent(val, 'nl_NL'));
		}
	}

	override getDefaultSort(): Sort {
		return { active: MeasureColumn.LEERLINGEN, direction: 'desc' };
	}

	partitionMeasure: Signal<PartitionMeasure<UitstroomA>> = computed(() => {
		if (this.eenheid() === Eenheid.AANTAL)
			return {
				type: 'number',
				getValue: att('weging'),
				format: '1.0-2',
			};
		else
			return {
				type: 'percentage',
				getValue: att('maxPctJaar'),
			};
	});

	getRowLevel() {
		return this.variant() === DashboardVariant.ACTUEEL ? this.groups.length : this.getHistorieGroups(this.groups).length;
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(UitstroomComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(UitstroomComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), historieSchooljaarGroup, ...fixedSubgroups];
	}

	override enrichTableModel(_context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>, tableModel: TableModel<DataRow<UitstroomA>>) {
		tableModel.showFooters = this.variant() === DashboardVariant.ACTUEEL;
	}

	override onContextCreated(context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}

	override getSelectionConfig(context: DashboardContext<UitstroomI, UitstroomA, UitstroomComponent>): SelectionConfig<UitstroomA> | undefined {
		return {
			...super.getSelectionConfig(context)!,
			getSize: att('ds_nr_leerlingen'),
			extraFilters: [new BasicFilterExpression(['ds_nr_weging'], 0, '>')],
		};
	}

	override makeBar(
		attrs: UitstroomI,
		path: Path<UitstroomA, number[]>,
		context: DashboardContext<UitstroomI, UitstroomA, BaseDashboardConfig<UitstroomI, UitstroomA>>
	): BarInfo {
		const bar = super.makeBar(attrs, path, context);
		return { ...bar, className: Number(attrs.ds_is_prognose) ? 'dashboard-prognose' : bar.className, description: 'Uitstroom' };
	}

	protected readonly DashboardVariant = DashboardVariant;
	protected readonly Eenheid = Eenheid;
}
