<app-dashboard-container
	class="dashboard-matrix"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions, [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions"
	>
	</app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header
			[variant]="variant"
			[legenda]="['ds_nm_idu']"
			[legendaIncludeNull]="true"
			[legendaExclude]="legendaExcludes"
			[leftOptionsTemplate]="leftOptions"
		>
			<ng-template #leftOptions>
				<div class="weergave eenheid">
					Eenheid
					<dt-form-dropdown
						[opties]="eenheidOpties"
						[selectedOptie]="getSelectedEenheidOptie()"
						(onOptieClicked)="qp.dispatch('eenheid', $event)"
					></dt-form-dropdown>
				</div>
				<app-checkbox
					label="Toon BRIN-overgang"
					[value]="toonBrinOvergang"
					(valueChange)="qp.dispatch('brin-overgang', $event)"
				></app-checkbox>
			</ng-template>
		</app-dashboard-header>
		<app-pivot-table
			[groups]="groups"
			[subgroups]="fixedSubgroups"
			[columnKeys]="isColumnKey"
			[columnSort]="columnSort"
			[filters]="filterExpressions"
			[permanentFilters]="permanentFilterExpressions"
			[config]="this"
		></app-pivot-table>
	</section>
</app-dashboard-container>
