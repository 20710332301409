import { inject } from '@angular/core';
import { CanActivateFn, RedirectCommand } from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (_, state) => {
	const authService = inject(AuthService);
	authService.storeRequestedUrl(state.url);

	if (authService.isLoggedIn()) return true;

	return new RedirectCommand(authService.oauthUrlTree, {
		skipLocationChange: true,
	});
};
