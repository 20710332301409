import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RestLoggingService } from '../services/rest.logging.service';
import { DataserviceHttpHeaders } from '@cumlaude/service-contract';

@Injectable()
export class DashboardNameInterceptor implements HttpInterceptor {
	private readonly restLoggingService = inject(RestLoggingService);

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let currentDashboard = this.restLoggingService.currentDashboard();
		if (currentDashboard !== null) {
			let clone = req.clone({
				headers: req.headers.set(DataserviceHttpHeaders.X_DASHBOARD, currentDashboard),
			});

			return next.handle(clone);
		}

		return next.handle(req);
	}
}
