import {
	DRIE_SCHOOLJAREN_EXCL_2019,
	DRIE_SCHOOLJAREN_EXCL_2020,
	getOnderwijsresultatenSchooljarenRange,
	getSchooljaarTovHuidig,
	isNotNull,
	isoDate,
	stripTime,
} from '@cumlaude/shared-utils';
import {
	Bekostigingstype,
	BovenbouwsuccesUitzondering,
	Cijferkolomtype,
	EinduitstroomVsoUitzondering,
	ExamencijfersUitzondering,
	ExamenStatus,
	ExamenStatusMetPrognose,
	Instroomtype,
	OnderbouwsnelheidUitzondering,
	OnderwijspositieUitzondering,
	PlaatsingUitzondering,
	PrestatieanalyseVsoUitzondering,
	TussentijdseUitstroomVsoUitzondering,
	UitstroomIqStatus,
	Uitstroomtype,
} from '@cumlaude/metadata';
import { AbsentieKlasse, AbsentieKlasseHistorie } from './label-enums';
import {
	Att,
	Attr,
	AttrPath,
	BasicFilterExpression,
	BetweenFilterExpression,
	CompoundFilterExpression,
	createSelectionExpression,
	DataService,
	FilterComponent,
	FilterExpression,
	InFilterExpression,
	isBooleanAttribute,
	isDateAttribute,
	QueryFilterExpression,
} from './data.service';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { attrLabel } from './labels';
import { defaults, flatMap, isArray, isUndefined, range, without } from 'lodash-es';
import { SingleSelectFilterComponent } from '../filters/single-select-filter/single-select-filter.component';
import { MultiSelectFilterComponent } from '../filters/multi-select-filter/multi-select-filter.component';
import { computed, Injectable, Type, inject } from '@angular/core';
import { SchooljaarHistorieFilterComponent } from '../schooljaar-historie-filter/schooljaar-historie-filter.component';
import { SchooljaarHistorieOption } from '../schooljaar-historie-filter/schooljaar-historie-option';
import { EnumService } from '../core/services/enum.service';
import { QpName } from './query-param-state.service';
import { UserService } from './user.service';
import { InstellingBron, RLeerlingSelectie } from '@cumlaude/service-contract';
import { DisplayService } from './display.service';
import { DashboardVariant } from './weergave-opties';
import { LeerlingSelectieFilterComponent } from '../filters/leerling-selectie-filter/leerling-selectie-filter.component';
import { LeerlingSelectieId, LeerlingSelectieService } from './leerling-selectie.service';
import {
	DateRangeFilterComponent,
	DateRangeState,
	DateRangeView,
	formatDateRangeState,
	getRelativeDateRange,
} from '../filters/date-range-filter/date-range-filter.component';
import { magisterUnsupportedCijferkolomtypes } from './magister-blocklist';
import { somtodayUnsupportedCijferkolomtypes } from './somtoday-blocklist';
import { toSignal } from '@angular/core/rxjs-interop';
import { SortOrder } from './sort-order';

type ExtraAbsentieFilterNames = 'x_abs_geoorloofd' | 'x_aw_schooljaar_historie' | 'x_aw_multiselect_schooljaar';

type ExtraCijferFilterNames =
	| 'x_cijfer_leerjaar'
	| 'x_cijfer_metzonder_ce'
	| 'x_cijfer_multiselect_schooljaar'
	| 'x_cijfer_niveau'
	| 'x_cijfer_schooljaar_historie'
	| 'x_cijferlijst_cijfertype'
	| 'x_cijfertype'
	| 'x_details_periode'
	| 'x_gem_periode'
	| 'x_cijferlijst_gem_periode'
	| 'x_onderwijsresultaten_cf_schooljaar';

type ExtraDoorstroomFilterNames =
	| 'x_advies_leerlinggroep'
	| 'x_doorstroom_multiselect_schooljaar'
	| 'x_doorstroom_schooljaar_historie'
	| 'x_met_overgang'
	| 'x_onderwijsresultaten_ds_schooljaar'
	| 'x_peildatum'
	| 'x_prestatieanalyse_ds_schooljaar';

type ExtraExamenCijfersFilterNames = 'x_ekc_schooljaar_historie' | 'x_ekc_multiselect_schooljaar';

type ExtraLesregistratieFilterNames = 'x_lr_schooljaar_historie' | 'x_lr_multiselect_schooljaar';

type ExtraOnderwijsresultatenFilterNames =
	| 'x_onderwijsresultaten_or_schooljaar'
	| 'x_onderwijsresultaten_or_schooljaar_historie'
	| 'x_onderwijsresultaten_or_multiselect_schooljaar';

type ExtraPrestatieanalyseFilterNames = 'x_pv_schooljaar_historie' | 'x_pv_multiselect_schooljaar';

type ExtraVakkeuzeFilterNames = 'x_vakkeuze_peildatum' | 'x_vkk_schooljaar_historie' | 'x_vkk_multiselect_schooljaar';

export type ExtraFilterNames =
	| 'x_leerlingselectie'
	| ExtraAbsentieFilterNames
	| ExtraCijferFilterNames
	| ExtraDoorstroomFilterNames
	| ExtraExamenCijfersFilterNames
	| ExtraLesregistratieFilterNames
	| ExtraOnderwijsresultatenFilterNames
	| ExtraPrestatieanalyseFilterNames
	| ExtraVakkeuzeFilterNames;

export type FilterName = Att | ExtraFilterNames;

export type DateRangeComponentParams = {
	/**
	 * Verwijst naar het filter waarmee een enkel schooljaar gekozen wordt.
	 */
	schooljaarActueelFilterName: FilterName;
	/**
	 * Verwijst naar het filter waarmee een periode van X schooljaren gekozen wordt.
	 */
	periodeFilterName?: FilterName;
	/**
	 * Verwijst naar het multiselect-filter waarmee een aantal specifieke schooljaren gekozen worden.
	 */
	periodeAangepastFilterName?: FilterName;
	/**
	 * Verwijst naar het schooljaar-filter bij onderwijsresultaten waarmee een schooljaar gekozen wordt incl. de 2/1/0 voorafgaande schooljaren afhankelijk van de gekozen indicator-over-setting.
	 */
	schooljaarIndicatorFilterName?: FilterName;
};

export type SingleSelectComponentParams<T> = {
	/**
	 * De lijst met opties als deze vastliggen en niet bij de backend opgehaald moeten worden.
	 */
	fixedOptions?: T[];
	/**
	 * De lijst met opties als deze default zijn en weergegeven kunnen worden tot de backend de lijst aanlevert met actuele waardes in de database.
	 */
	defaultOptions?: T[];
};

export type MultiSelectComponentParams<T> = {
	/**
	 * De lijst met opties als deze vastliggen en niet bij de backend opgehaald moeten worden.
	 */
	fixedOptions?: T;
	/**
	 * De lijst met opties als deze default zijn en weergegeven kunnen worden tot de backend de lijst aanlevert met actuele waardes in de database.
	 */
	defaultOptions?: T;
	/**
	 * Het minimale aantal waardes dat geselecteerd moet worden.
	 */
	minimum?: number;
	/**
	 * Informatie bericht on hover als wijzigen van de selectie geblokeerd is.
	 */
	blockedHover?: string;
	/**
	 * Toggle om aan te geven of dit filter van het type legenda is.
	 */
	legenda?: boolean;
	/**
	 * Toggle om aan te geven of dit filter van het type text is.
	 */
	text?: boolean;
};

type InitFunction<I> = (states: Partial<{ [name in FilterName]: Observable<any> }>, onFetch: () => void) => Observable<I | null | undefined>;

export type FilterConfig<I, T> = {
	att?: Att;
	label: string;
	searchKeys: string[];
	component: Type<FilterComponent<any>>;
	componentParams?: SingleSelectComponentParams<T> | MultiSelectComponentParams<T> | DateRangeComponentParams;
	valueAllowed: ((val: T) => boolean) | undefined;
	init: I | null | undefined | Observable<I | null | undefined> | InitFunction<I>;
	createExpression: (val: T) => FilterExpression | undefined;
	optional: boolean;
	showNullValue: boolean;
	first: boolean;
	staticOptions: boolean;
	fixedOrder: boolean;
	encode: (val: T | null) => string;
	decode: (s: string) => I | null | undefined;
	stateToInput: (state: T) => I;
	valueString: (val: I) => string;
	sortOrder: SortOrder;
	visible: (states: Partial<{ [name in FilterName]: Observable<any> }>) => Observable<boolean>;
	default: () => boolean;
	classNames?: string;
	state?: (
		inputs: Partial<{ [name in FilterName | QpName]: Observable<any> }>,
		states: Partial<{ [name in FilterName]: Observable<any> }>
	) => Observable<T | null | undefined>;
};

enum PeildatumOption {
	ACTUEEL = 'Actueel',
	OKTOBER = '1 oktober',
	JANUARI = '1 januari',
	FEBRUARI = '1 februari',
	APRIL = '1 april',
	JULI = '1 juli',
}

enum AdviesLeerlingGroep {
	INSTROMERS = 'Instromers',
	EXAMENKANDIDATEN = 'Examenkandidaten',
}

export type PeriodeFilterValue = { option: SchooljaarHistorieOption; inclHuidig: boolean };

@Injectable({
	providedIn: 'root',
})
export class FilterConfigService {
	private readonly dataService = inject(DataService);
	private readonly displayService = inject(DisplayService);
	private readonly leerlingSelectieService = inject(LeerlingSelectieService);
	protected readonly userService = inject(UserService);

	private readonly restVestigingen = toSignal(this.userService.rVestigingen$, { initialValue: [] });
	private readonly isMultiBrin = computed(() => {
		const rVestigingen = this.restVestigingen();
		return (
			new Set(
				rVestigingen
					.map((rVestiging) => rVestiging.vestigingBrins)
					.filter((brins) => brins)
					.flatMap((brins) => brins)
					.filter((brin) => brin.length === 6)
					.map((brin) => brin.slice(0, 4))
			).size > 1
		);
	});

	cijferTypeExpression = (option: Cijferkolomtype) => {
		switch (option) {
			case Cijferkolomtype.LAATSTE_GEMIDDELDE:
				return new BasicFilterExpression(['cf_is_laatste_gemiddelde'], 1);
			case Cijferkolomtype.LAATSTE_RAPPORTCIJFER:
				return new BasicFilterExpression(['cf_is_laatste_rapportcijfer'], 1);
			case Cijferkolomtype.CE_CIJFER:
				return new BasicFilterExpression(['cf_is_hoogste_ce'], 1);
			case Cijferkolomtype.EINDCIJFER:
				return new BasicFilterExpression(['cf_is_hoogste_eind'], 1);
			case undefined:
				return new InFilterExpression(
					['cf_nm_kolomtype'],
					[
						Cijferkolomtype.TOETS,
						Cijferkolomtype.GEMIDDELDE,
						Cijferkolomtype.RAPPORTCIJFER,
						Cijferkolomtype.SE_CIJFER,
						Cijferkolomtype.CE_CIJFER,
						Cijferkolomtype.EINDCIJFER,
					].filter((val) => this.cijferKolomTypeAllowed(val))
				);
			default:
				return new BasicFilterExpression(['cf_nm_kolomtype'], option);
		}
	};

	private readonly absentieSchooljaarFilters: DateRangeComponentParams = {
		schooljaarActueelFilterName: 'aw_nm_schooljaar',
		periodeFilterName: 'x_aw_schooljaar_historie',
		periodeAangepastFilterName: 'x_aw_multiselect_schooljaar',
	};

	absentieFilters: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		'aw_d_datum.per_d_datum': this.createDateFilter('aw_d_datum.per_d_datum', this.absentieSchooljaarFilters),
		aw_nm_schooljaar: this.createSchooljaarActueelFilter('aw_nm_schooljaar'),
		aw_fun_nationaliteit: this.createNationaliteitFilter('aw_fun_nationaliteit', 'aw_fk_ll'),
		aw_is_abs_geoorloofd: this.completeMultiSelectFilterConfig({
			att: 'aw_is_abs_geoorloofd',
			componentParams: {
				legenda: true,
				fixedOptions: Object.values(AbsentieKlasse),
			},
			valueString: (vals: AbsentieKlasse[]) => vals?.map((val) => this.displayService.display(val)).join(', '),
			createExpression: (vals: AbsentieKlasse[]) =>
				isUndefined(vals)
					? new QueryFilterExpression(['aw_is_abs_geoorloofd'])
					: new InFilterExpression(
							['aw_is_abs_geoorloofd'],
							vals.map((val: AbsentieKlasse) => (val === AbsentieKlasse.Geoorloofd ? 1 : 0))
						),
			optional: true,
		}),
		'aw_fk_ilt.ilt_nm_niveau': this.completeMultiSelectFilterConfig({
			att: 'aw_fk_ilt.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
		}),
		'aw_fk_lb.lb_co_brin': this.completeFilterConfig({
			att: 'aw_fk_lb.lb_co_brin',
			default: () => this.isMultiBrin(),
		}),
		'aw_fk_lb.lb_d_examen': this.createDateFilter('aw_fk_lb.lb_d_examen', this.absentieSchooljaarFilters),
		'aw_fk_lb.lb_d_plaatsing_tm': this.createDateFilter('aw_fk_lb.lb_d_plaatsing_tm', this.absentieSchooljaarFilters),
		'aw_fk_lb.lb_d_plaatsing_va': this.createDateFilter('aw_fk_lb.lb_d_plaatsing_va', this.absentieSchooljaarFilters),
		'aw_fk_ll.ll_d_geboortedatum': this.completeMultiSelectFilterConfig({ att: 'aw_fk_ll.ll_d_geboortedatum' }), // geen datepicker
		aw_nm_abs_reden: this.completeMultiSelectFilterConfig({
			att: 'aw_nm_abs_reden',
		}),
		aw_nm_klas: this.completeMultiSelectFilterConfig({
			att: 'aw_nm_klas',
		}),
		aw_nm_vak: this.completeMultiSelectFilterConfig({
			att: 'aw_nm_vak',
		}),
		aw_nr_leerjaar: this.completeMultiSelectFilterConfig({
			att: 'aw_nr_leerjaar',
			componentParams: {
				text: true,
			},
		}),
		x_abs_geoorloofd: {
			// dit is eigenlijk geen filter maar een weergave-optie
			label: 'Absentie',
			searchKeys: [],
			component: MultiSelectFilterComponent,
			componentParams: {
				legenda: true,
				fixedOptions: Object.values(AbsentieKlasseHistorie),
			},
			valueAllowed: undefined,
			init: undefined,
			stateToInput: (x: AbsentieKlasseHistorie[]) => x,
			valueString: (vals: AbsentieKlasseHistorie[]) => vals?.map((val) => this.displayService.display(val)).join(', '),
			createExpression: (_val) => undefined,
			optional: true,
			showNullValue: true,
			first: false,
			staticOptions: true,
			fixedOrder: false,
			default: () => true,
			sortOrder: SortOrder.ASC,
			visible: () => of(true),
			encode: (val) => JSON.stringify(val),
			decode: (s) => JSON.parse(s),
		},
		x_aw_schooljaar_historie: this.createPeriodeFilter('aw_nm_schooljaar'),
		x_aw_multiselect_schooljaar: this.createPeriodeAangepastFilter(
			'x_aw_multiselect_schooljaar',
			'aw_nm_schooljaar',
			'x_aw_schooljaar_historie',
			1
		),
		'aw_fk_br_vest.br_co_brin': this.createBrinVestFilter('aw_fk_br_vest.br_co_brin'),
		'aw_fk_lb_vorig_sj.lb_co_brin_vestiging': this.createBrinVestFilter('aw_fk_lb_vorig_sj.lb_co_brin_vestiging'),
		'aw_fk_lb_volgend_sj.lb_co_brin_vestiging': this.createBrinVestFilter('aw_fk_lb_volgend_sj.lb_co_brin_vestiging'),
	};

	private readonly cijferSchooljaarFilters: DateRangeComponentParams = {
		schooljaarActueelFilterName: 'cf_nm_schooljaar',
		periodeFilterName: 'x_cijfer_schooljaar_historie',
		periodeAangepastFilterName: 'x_cijfer_multiselect_schooljaar',
		schooljaarIndicatorFilterName: 'x_onderwijsresultaten_cf_schooljaar',
	};

	private readonly initCijferperiode =
		(cijfertypeFilter: FilterName, multi: boolean) => (states: Partial<{ [name in FilterName]: Observable<any> }>, onFetch: () => void) =>
			states[cijfertypeFilter]!.pipe(
				switchMap((cijfertype) => {
					if (![Cijferkolomtype.GEMIDDELDE, Cijferkolomtype.RAPPORTCIJFER].includes(cijfertype)) return of(undefined);

					return combineLatest(
						(<Att[]>['cf_nm_schooljaar', 'cf_nm_vestiging', 'cf_fk_ilt.ilt_nm_niveau', 'cf_nr_leerjaar', 'cf_nm_vak']).map(
							(att) => states[att] ?? of(undefined)
						)
					).pipe(
						switchMap(([[schooljaar], vestiging, niveau, leerjaar, vak]) => {
							onFetch();
							return this.dataService
								.fetchFirstFilterValue(
									'/cijferkolommen',
									['ck_nm_periode'],
									[
										schooljaar ? new BasicFilterExpression(['ck_nm_schooljaar'], schooljaar) : null,
										vestiging ? new BasicFilterExpression(['ck_nm_vestiging'], vestiging) : null,
										niveau ? new BasicFilterExpression(['ck_fk_ilt', 'ilt_nm_niveau'], niveau, 'in') : null,
										leerjaar ? new BasicFilterExpression(['ck_nr_leerjaar'], leerjaar, 'in') : null,
										vak ? new BasicFilterExpression(['ck_nm_vak'], vak, 'in') : null,
									].filter(isNotNull)
								)
								.pipe(map((value) => (multi ? [value] : value)));
						})
					);
				})
			);

	basisvaardighedenFilters: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		'bv_fk_ilt.ilt_nm_niveau': this.completeMultiSelectFilterConfig({
			att: 'bv_fk_ilt.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
		}),
		'bv_fk_lb.lb_co_brin': this.completeFilterConfig({
			att: 'bv_fk_lb.lb_co_brin',
			default: () => this.isMultiBrin(),
		}),
		bv_fun_nationaliteit: this.createNationaliteitFilter('bv_fun_nationaliteit', 'bv_fk_ll'),
		bv_nm_schooljaar: this.createSchooljaarActueelFilter('bv_nm_schooljaar'),
		bv_nm_aanbieder: this.completeMultiSelectFilterConfig({
			att: 'bv_nm_aanbieder',
			componentParams: {
				text: true,
			},
		}),
		bv_nm_toets: this.completeMultiSelectFilterConfig({ att: 'bv_nm_toets' }),
		bv_nm_toetsmoment: this.completeMultiSelectFilterConfig({
			att: 'bv_nm_toetsmoment',
			componentParams: { text: true },
		}),
		bv_nr_leerjaar: this.completeMultiSelectFilterConfig({
			att: 'bv_nr_leerjaar',
			componentParams: {
				text: true,
			},
		}),
		'bv_fk_lb.lb_co_brin_vestiging': this.createBrinVestFilter('bv_fk_lb.lb_co_brin_vestiging'),
		'bv_fk_lb_vorig_sj.lb_co_brin_vestiging': this.createBrinVestFilter('bv_fk_lb_vorig_sj.lb_co_brin_vestiging'),
		'bv_fk_lb_volgend_sj.lb_co_brin_vestiging': this.createBrinVestFilter('bv_fk_lb_volgend_sj.lb_co_brin_vestiging'),
	};

	cijferFilters: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		'cf_fk_ilt.ilt_nm_niveau': this.completeMultiSelectFilterConfig({
			att: 'cf_fk_ilt.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
		}),
		cf_co_brin: this.completeFilterConfig({
			att: 'cf_co_brin',
			default: () => this.isMultiBrin(),
		}),
		'cf_fk_lb.lb_d_examen': this.createDateFilter('cf_fk_lb.lb_d_examen', this.cijferSchooljaarFilters),
		'cf_fk_lb.lb_d_plaatsing_tm': this.createDateFilter('cf_fk_lb.lb_d_plaatsing_tm', this.cijferSchooljaarFilters),
		'cf_fk_lb.lb_d_plaatsing_va': this.createDateFilter('cf_fk_lb.lb_d_plaatsing_va', this.cijferSchooljaarFilters),
		'cf_fk_ll.ll_d_geboortedatum': this.completeMultiSelectFilterConfig({ att: 'cf_fk_ll.ll_d_geboortedatum' }), // geen datepicker
		'cf_fk_ll.ll_nm_geslacht': this.completeMultiSelectFilterConfig({
			att: 'cf_fk_ll.ll_nm_geslacht',
			componentParams: {
				text: true,
			},
		}),
		'cf_fk_vs.vs_nm_vestiging': this.completeFilterConfig({ att: 'cf_fk_vs.vs_nm_vestiging' }), // CL-4189 staat hiertussen vanwege legacy favorieten
		'cf_fk_vs_bekostiging.vs_nm_vestiging': this.completeFilterConfig({
			att: 'cf_fk_vs_bekostiging.vs_nm_vestiging',
			label: 'Vestiging',
		}),
		cf_is_alternatievenormering: this.completeFilterConfig({
			att: 'cf_is_alternatievenormering',
			componentParams: {
				fixedOptions: [0, 1],
			},
			init: 0,
			optional: false,
		}),
		cf_abb_kolomkop: this.completeFilterConfig({ att: 'cf_abb_kolomkop' }),
		cf_fun_nationaliteit: this.createNationaliteitFilter('cf_fun_nationaliteit', 'cf_fk_ll'),
		cf_fun_schooljaar_leerfase: this.createSchooljaarActueelFilter('cf_fun_schooljaar_leerfase'),
		cf_map_examenstatus_met_prognose: this.completeMultiSelectFilterConfig({
			att: 'cf_map_examenstatus_met_prognose',
			init: [ExamenStatusMetPrognose.GESLAAGD, ExamenStatusMetPrognose.AFGEWEZEN],
		}),
		cf_map_idu: this.completeMultiSelectFilterConfig({
			att: 'cf_map_idu',
			componentParams: {
				legenda: true,
			},
		}),
		cf_nm_excijf_uitzondering: this.completeMultiSelectFilterConfig({
			att: 'cf_nm_excijf_uitzondering',
			init: [ExamencijfersUitzondering.REGULIER_EXAMENCIJFER],
		}),
		cf_nm_lesgroep: this.completeMultiSelectFilterConfig({
			att: 'cf_nm_lesgroep',
		}),
		cf_nm_schooljaar: this.createSchooljaarActueelFilter('cf_nm_schooljaar'),
		cf_nm_vak: this.completeMultiSelectFilterConfig({
			att: 'cf_nm_vak',
		}),
		cf_nr_leerjaar: this.completeMultiSelectFilterConfig({
			att: 'cf_nr_leerjaar',
			componentParams: {
				text: true,
			},
		}),

		x_cijfer_metzonder_ce: this.completeFilterConfig({
			componentParams: {
				fixedOptions: [true, false],
			},
			init: true,
			label: 'Toon vakken',
			createExpression: (val) => (val ? new BasicFilterExpression(['cf_nr_cijfer_ce'], null, '<>') : undefined),
			valueString: (val) => EnumService.booleanLegendaEnumEntry(['x_cijfer_metzonder_ce'], val),
		}),
		x_cijfer_multiselect_schooljaar: this.createPeriodeAangepastFilter(
			'x_cijfer_multiselect_schooljaar',
			'cf_nm_schooljaar',
			'x_cijfer_schooljaar_historie'
		),
		x_cijfer_leerjaar: this.completeMultiSelectFilterConfig({
			att: 'cf_nr_leerjaar',
			componentParams: {
				text: true,
			},
			init: this.dataService.fetchFirstFilterValueAsArray<number>('/cijfers', ['cf_nr_leerjaar']),
		}),
		x_cijfer_niveau: this.completeMultiSelectFilterConfig({
			att: 'cf_fk_ilt.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
			init: this.dataService.fetchFirstFilterValueAsArray<string>('/cijfers', ['cf_fk_ilt', 'ilt_nm_niveau']),
		}),
		x_cijfer_schooljaar_historie: this.createPeriodeFilter('cf_nm_schooljaar', SchooljaarHistorieOption.AFGELOPEN_5),
		x_cijferlijst_cijfertype: this.completeFilterConfig({
			componentParams: {
				fixedOptions: [
					Cijferkolomtype.LAATSTE_GEMIDDELDE,
					Cijferkolomtype.LAATSTE_RAPPORTCIJFER,
					Cijferkolomtype.GEMIDDELDE,
					Cijferkolomtype.RAPPORTCIJFER,
					Cijferkolomtype.SE_CIJFER,
					Cijferkolomtype.CE_CIJFER,
					Cijferkolomtype.EINDCIJFER,
				],
			},
			valueAllowed: this.cijferKolomTypeAllowed.bind(this),
			init: Cijferkolomtype.LAATSTE_GEMIDDELDE,
			label: 'Cijfertype',
			optional: false,
			createExpression: this.cijferTypeExpression,
		}),
		x_cijfertype: this.completeFilterConfig({
			componentParams: {
				fixedOptions: Object.values(Cijferkolomtype),
			},
			valueAllowed: this.cijferKolomTypeAllowed.bind(this),
			init: Cijferkolomtype.LAATSTE_GEMIDDELDE,
			label: 'Cijfertype',
			optional: false,
			createExpression: this.cijferTypeExpression,
		}),
		x_details_periode: this.completeFilterConfig({
			att: 'cf_fun_periode',
			optional: true,
			valueString: (val) => `Periode ${val}`,
		}),
		x_gem_periode: this.completeMultiSelectFilterConfig({
			att: 'cf_fun_periode',
			init: this.initCijferperiode('x_cijfertype', true),
			valueString: (val) => `Periode ${val}`,
			state: (inputs, states) => {
				const internalSubject: Subject<number> = new ReplaySubject(1);
				inputs['x_gem_periode']!.subscribe(internalSubject);
				return combineLatest([states['x_cijfertype']!, internalSubject]).pipe(
					map(([cijfertype, periode]: [Cijferkolomtype, number]) => {
						return [Cijferkolomtype.GEMIDDELDE, Cijferkolomtype.RAPPORTCIJFER].includes(cijfertype) ? periode : undefined;
					})
				);
			},
			optional: false,
			visible: (states) =>
				states['x_cijfertype']!.pipe(map((cijfertype) => [Cijferkolomtype.GEMIDDELDE, Cijferkolomtype.RAPPORTCIJFER].includes(cijfertype))),
		}),
		x_cijferlijst_gem_periode: this.completeFilterConfig({
			att: 'cf_fun_periode',
			init: this.initCijferperiode('x_cijferlijst_cijfertype', false),
			valueString: (val) => `Periode ${val}`,
			state: (inputs, states) => {
				const internalSubject: Subject<number> = new ReplaySubject(1);
				inputs['x_cijferlijst_gem_periode']!.subscribe(internalSubject);
				return combineLatest([states['x_cijferlijst_cijfertype']!, internalSubject]).pipe(
					map(([cijfertype, periode]: [Cijferkolomtype, number]) =>
						[Cijferkolomtype.GEMIDDELDE, Cijferkolomtype.RAPPORTCIJFER].includes(cijfertype) ? periode : undefined
					)
				);
			},
			optional: false,
			visible: (states) =>
				states['x_cijferlijst_cijfertype']!.pipe(
					map((cijfertype) => [Cijferkolomtype.GEMIDDELDE, Cijferkolomtype.RAPPORTCIJFER].includes(cijfertype))
				),
		}),

		x_onderwijsresultaten_cf_schooljaar: this.createSchooljaarIndicatorFilter('x_onderwijsresultaten_cf_schooljaar', 'cf_nm_schooljaar', 'exc'),
		'cf_fk_br_vest.br_co_brin': this.createBrinVestFilter('cf_fk_br_vest.br_co_brin'),
		'cf_fk_lb_vorig_sj.lb_co_brin_vestiging': this.createBrinVestFilter('cf_fk_lb_vorig_sj.lb_co_brin_vestiging'),
		'cf_fk_lb_volgend_sj.lb_co_brin_vestiging': this.createBrinVestFilter('cf_fk_lb_volgend_sj.lb_co_brin_vestiging'),
	};

	cijferkolommenFilters: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		ck_abb_kolomkop: this.completeFilterConfig({ att: 'ck_abb_kolomkop' }),

		ck_nm_opleiding: this.completeMultiSelectFilterConfig({ att: 'ck_nm_opleiding' }),
		ck_nm_schooljaar: this.createSchooljaarActueelFilter('ck_nm_schooljaar'),
		ck_nm_vak: this.completeMultiSelectFilterConfig({ att: 'ck_nm_vak' }),
	};

	private readonly doorstroomSchooljaarFilters: DateRangeComponentParams = {
		schooljaarActueelFilterName: 'ds_nm_schooljaar_van',
		periodeFilterName: 'x_doorstroom_schooljaar_historie',
		periodeAangepastFilterName: 'x_doorstroom_multiselect_schooljaar',
		schooljaarIndicatorFilterName: 'x_onderwijsresultaten_ds_schooljaar',
	};

	doorstroomFilters: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		'ds_fk_ilt_naar.ilt_nm_niveau': this.completeMultiSelectFilterConfig({
			att: 'ds_fk_ilt_naar.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
		}),
		'ds_fk_ilt_van.ilt_nm_niveau': this.completeMultiSelectFilterConfig({
			att: 'ds_fk_ilt_van.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
		}),
		'ds_fk_lb_van.lb_co_brin': this.completeFilterConfig({
			att: 'ds_fk_lb_van.lb_co_brin',
			default: () => this.isMultiBrin(),
		}),
		'ds_fk_lb_van.lb_d_examen': this.createDateFilter('ds_fk_lb_van.lb_d_examen', this.doorstroomSchooljaarFilters),
		'ds_fk_lb_van.lb_d_plaatsing_tm': this.createDateFilter('ds_fk_lb_van.lb_d_plaatsing_tm', this.doorstroomSchooljaarFilters),
		'ds_fk_lb_van.lb_d_plaatsing_va': this.createDateFilter('ds_fk_lb_van.lb_d_plaatsing_va', this.doorstroomSchooljaarFilters),
		'ds_fk_ll.ll_d_geboortedatum': this.completeMultiSelectFilterConfig({ att: 'ds_fk_ll.ll_d_geboortedatum' }), // geen datepicker
		'ds_fk_ll.ll_is_cohortstatus_tot_volledig': this.completeBooleanFilterConfig({
			att: 'ds_fk_ll.ll_is_cohortstatus_tot_volledig',
			label: 'Cohortstatus (tot)',
			sortOrder: SortOrder.ASC,
		}),

		'ds_fk_ll.ll_nm_basisschooladvies_uni': this.completeMultiSelectFilterConfig({
			att: 'ds_fk_ll.ll_nm_basisschooladvies_uni',
		}),
		'ds_fk_ll.ll_nm_geslacht': this.completeMultiSelectFilterConfig({
			att: 'ds_fk_ll.ll_nm_geslacht',
			componentParams: {
				text: true,
			},
		}),
		'ds_fk_ll.ll_nm_svb': this.completeMultiSelectFilterConfig({
			att: 'ds_fk_ll.ll_nm_svb',
		}),
		'ds_fk_ll.ll_nm_svh': this.completeMultiSelectFilterConfig({
			att: 'ds_fk_ll.ll_nm_svh',
		}),
		'ds_fk_vs_bekostiging_van.vs_nm_vestiging': this.completeFilterConfig({
			att: 'ds_fk_vs_bekostiging_van.vs_nm_vestiging',
			label: 'Vestiging',
		}),

		ds_fun_basisschooladvies_duo: this.completeMultiSelectFilterConfig({
			att: 'ds_fun_basisschooladvies_duo',
		}),
		ds_nm_cohortstatus_vanaf: this.completeFilterConfig({
			att: 'ds_nm_cohortstatus_vanaf',
		}),
		ds_nm_cohortstatus_examenopstroom: this.completeFilterConfig({
			att: 'ds_nm_cohortstatus_examenopstroom',
		}),
		ds_fun_nationaliteit: this.createNationaliteitFilter('ds_fun_nationaliteit', 'ds_fk_ll'),
		ds_fun_niveau_bbs_van: this.completeMultiSelectFilterConfig({
			att: 'ds_fun_niveau_bbs_van',
			componentParams: {
				text: true,
			},
		}),
		ds_fun_uitstroom_iq_status: this.completeMultiSelectFilterConfig({
			att: 'ds_fun_uitstroom_iq_status',
			init: without(Object.values(UitstroomIqStatus), UitstroomIqStatus.INFORMATIE_VOLLEDIG),
		}),

		ds_map_examenstatus: this.completeMultiSelectFilterConfig({
			att: 'ds_map_examenstatus',
			init: [ExamenStatus.GESLAAGD, ExamenStatus.AFGEWEZEN],
			componentParams: {
				legenda: true,
			},
		}),
		ds_map_examenstatus_met_prognose: this.completeMultiSelectFilterConfig({
			att: 'ds_map_examenstatus_met_prognose',
			init: [
				ExamenStatusMetPrognose.GESLAAGD,
				ExamenStatusMetPrognose.AFGEWEZEN,
				ExamenStatusMetPrognose.GESLAAGD_PROGNOSE,
				ExamenStatusMetPrognose.AFGEWEZEN_PROGNOSE,
			],
			componentParams: {
				legenda: true,
			},
		}),

		ds_nm_bbs_uitzondering_van: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_bbs_uitzondering_van',
			init: [BovenbouwsuccesUitzondering.REGULIER_BOVENBOUWSUCCES],
		}),
		ds_nm_bekostigingstype_van: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_bekostigingstype_van',
			init: [Bekostigingstype.STANDAARD, Bekostigingstype.EXTERN_ONDERWIJS],
		}),
		ds_nm_einduitstroom_vso: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_einduitstroom_vso',
			componentParams: {
				legenda: true,
			},
		}),
		ds_nm_einduitstroom_vso_uitzondering: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_einduitstroom_vso_uitzondering',
			init: without(Object.values(EinduitstroomVsoUitzondering), EinduitstroomVsoUitzondering.INFORMATIE_VOLLEDIG),
		}),

		ds_nm_idu: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_idu',
			componentParams: {
				legenda: true,
			},
		}),
		'ds_fk_lb_van.lb_nm_instroomtype_in_schooljaar': this.completeMultiSelectFilterConfig({
			att: 'ds_fk_lb_van.lb_nm_instroomtype_in_schooljaar',
			init: [Instroomtype.EXTERN],
		}),
		ds_nm_obs_uitzondering_van: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_obs_uitzondering_van',
			init: [OnderbouwsnelheidUitzondering.REGULIERE_ONDERBOUWSNELHEID],
		}),
		ds_nm_op_uitzondering_van: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_op_uitzondering_van',
			init: [OnderwijspositieUitzondering.REGULIERE_ONDERWIJSPOSITIE],
		}),
		ds_nm_plaatsing_uitzondering: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_plaatsing_uitzondering',
			init: [PlaatsingUitzondering.PLAATSING_TELT_MEE],
		}),
		ds_nm_prestatieanalyse_vso_uitzondering: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_prestatieanalyse_vso_uitzondering',
			init: [PrestatieanalyseVsoUitzondering.TELT_MEE_VOOR_PRESTATIEANALYSE],
		}),
		ds_nm_schooljaar_van: this.createSchooljaarActueelFilter('ds_nm_schooljaar_van'),
		ds_nm_tussentijdse_uitstroom_vso: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_tussentijdse_uitstroom_vso',
			componentParams: {
				legenda: true,
			},
		}),
		ds_nm_tussentijdse_uitstroom_vso_uitzondering: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_tussentijdse_uitstroom_vso_uitzondering',
			init: without(Object.values(TussentijdseUitstroomVsoUitzondering), TussentijdseUitstroomVsoUitzondering.INFORMATIE_VOLLEDIG),
		}),
		ds_nm_uitstroomtype_in_schooljaar: this.completeMultiSelectFilterConfig({
			att: 'ds_nm_uitstroomtype_in_schooljaar',
			init: [Uitstroomtype.EXTERN],
		}),

		ds_nr_leerjaar_van: this.completeMultiSelectFilterConfig({
			att: 'ds_nr_leerjaar_van',
			componentParams: {
				text: true,
			},
		}),
		ds_nr_leerjaar_naar: this.completeMultiSelectFilterConfig({
			att: 'ds_nr_leerjaar_naar',
			componentParams: {
				text: true,
			},
		}),

		x_advies_leerlinggroep: this.completeFilterConfig({
			componentParams: {
				fixedOptions: Object.values(AdviesLeerlingGroep),
			},
			label: 'Leerlinggroep',
			createExpression: (option: AdviesLeerlingGroep) => {
				switch (option) {
					case AdviesLeerlingGroep.INSTROMERS:
						return new CompoundFilterExpression([
							new BasicFilterExpression(['ds_is_instroom_extern_van'], 1),
							new BasicFilterExpression(['ds_nr_leerjaar_van'], 1),
						]);
					case AdviesLeerlingGroep.EXAMENKANDIDATEN:
						return new BasicFilterExpression(['ds_fk_ilt_van', 'ilt_nr_examenjaar'], undefined, '=', ['ds_nr_leerjaar_van']);
				}
			},
		}),

		x_doorstroom_multiselect_schooljaar: this.createPeriodeAangepastFilter(
			'x_doorstroom_multiselect_schooljaar',
			'ds_nm_schooljaar_van',
			'x_doorstroom_schooljaar_historie'
		),
		x_doorstroom_schooljaar_historie: this.createPeriodeFilter('ds_nm_schooljaar_van', SchooljaarHistorieOption.AFGELOPEN_5),

		'ds_fk_br_vest_naar.br_co_brin': this.createBrinVestFilter('ds_fk_br_vest_naar.br_co_brin'),
		'ds_fk_br_vest_van.br_co_brin': this.createBrinVestFilter('ds_fk_br_vest_van.br_co_brin'),
		'ds_fk_lb_vorig_sj.lb_co_brin_vestiging': this.createBrinVestFilter('ds_fk_lb_vorig_sj.lb_co_brin_vestiging'),

		x_met_overgang: this.completeMultiSelectFilterConfig({
			componentParams: {
				fixedOptions: [1, 0],
				legenda: true,
			},
			label: 'Overgang',
			createExpression: (_val) => undefined,
			valueString: (vals: number[]) => vals.map((val) => EnumService.booleanLegendaEnumEntry(['x_met_overgang'], Boolean(val))).join(', '),
		}),

		x_onderwijsresultaten_ds_schooljaar: this.createSchooljaarIndicatorFilter(
			'x_onderwijsresultaten_ds_schooljaar',
			'ds_nm_schooljaar_van',
			'bbs'
		),

		x_peildatum: this.completeFilterConfig({
			componentParams: {
				fixedOptions: Object.values(PeildatumOption),
			},
			label: 'Peildatum',
			init: PeildatumOption.OKTOBER,
			optional: false,
			createExpression: createDoorstroomPeildatumExpression,
		}),

		x_prestatieanalyse_ds_schooljaar: this.completeFilterConfig({
			att: 'ds_nm_schooljaar_van',
			init: getSchooljaarTovHuidig(),
			optional: false,
			first: true,
			sortOrder: SortOrder.DESC,
			valueString: (val) => `${parseInt(val.substring(0, 4)) + 1} (${val})`,
		}),
		lb_nm_schooljaar: this.completeFilterConfig({
			att: 'lb_nm_schooljaar',
			init: getSchooljaarTovHuidig(),
			optional: false,
			first: true,
			sortOrder: SortOrder.DESC,
		}),
	};

	private readonly lesregistratieSchooljaarFilters: DateRangeComponentParams = {
		schooljaarActueelFilterName: 'lr_d_datum.per_nm_schooljaar',
		periodeFilterName: 'x_lr_schooljaar_historie',
		periodeAangepastFilterName: 'x_lr_multiselect_schooljaar',
	};

	lesregistratieFilters: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		'lr_d_datum.per_d_datum': this.createDateFilter('lr_d_datum.per_d_datum', this.lesregistratieSchooljaarFilters),
		'lr_d_datum.per_nm_schooljaar': this.createSchooljaarActueelFilter('lr_d_datum.per_nm_schooljaar'),

		x_lr_schooljaar_historie: this.createPeriodeFilter('lr_d_datum.per_nm_schooljaar'),
		x_lr_multiselect_schooljaar: this.createPeriodeAangepastFilter(
			'x_lr_multiselect_schooljaar',
			'lr_d_datum.per_nm_schooljaar',
			'x_lr_schooljaar_historie',
			1
		),

		'lr_fk_br_vest.br_co_brin': this.createBrinVestFilter('lr_fk_br_vest.br_co_brin'),
		'lr_fk_lb_volgend_sj.lb_co_brin_vestiging': this.createBrinVestFilter('lr_fk_lb_volgend_sj.lb_co_brin_vestiging'),
		'lr_fk_lb_vorig_sj.lb_co_brin_vestiging': this.createBrinVestFilter('lr_fk_lb_vorig_sj.lb_co_brin_vestiging'),
		'lr_fk_ilt.ilt_nm_niveau': this.completeMultiSelectFilterConfig({
			att: 'lr_fk_ilt.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
		}),

		'lr_fk_lb.lb_co_brin': this.completeFilterConfig({
			att: 'lr_fk_lb.lb_co_brin',
			default: () => this.isMultiBrin(),
		}),
		'lr_fk_lb.lb_d_examen': this.createDateFilter('lr_fk_lb.lb_d_examen', this.lesregistratieSchooljaarFilters),
		'lr_fk_lb.lb_d_plaatsing_tm': this.createDateFilter('lr_fk_lb.lb_d_plaatsing_tm', this.lesregistratieSchooljaarFilters),
		'lr_fk_lb.lb_d_plaatsing_va': this.createDateFilter('lr_fk_lb.lb_d_plaatsing_va', this.lesregistratieSchooljaarFilters),
		'lr_fk_ll.ll_d_geboortedatum': this.completeMultiSelectFilterConfig({ att: 'lr_fk_ll.ll_d_geboortedatum' }), // geen datepicker

		lr_fun_nationaliteit: this.createNationaliteitFilter('lr_fun_nationaliteit', 'lr_fk_ll'),

		lr_nm_klas: this.completeMultiSelectFilterConfig({
			att: 'lr_nm_klas',
		}),
		lr_nm_lesregistratie: this.completeMultiSelectFilterConfig({
			att: 'lr_nm_lesregistratie',
			componentParams: {
				legenda: true,
			},
		}),

		lr_nr_leerjaar: this.completeMultiSelectFilterConfig({
			att: 'lr_nr_leerjaar',
			componentParams: {
				text: true,
			},
		}),
	};

	onderwijsresultaatFilters: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		or_nm_soort_onderwijs: this.completeMultiSelectFilterConfig({
			att: 'or_nm_soort_onderwijs',
			showNullValue: false,
			componentParams: {
				text: true,
			},
		}),
		x_onderwijsresultaten_or_multiselect_schooljaar: this.completeMultiSelectFilterConfig<string[], [string[], number]>({
			att: 'or_nm_schooljaar',
			init: range(-5, 0).map(getSchooljaarTovHuidig),
			optional: false,
			first: true,
			sortOrder: SortOrder.DESC,
			componentParams: { minimum: 2, blockedHover: 'Selecteer minstens 2 schooljaren.' },
			state: (inputs, states) => {
				const internalSubject: Subject<[string[], number]> = new ReplaySubject(1);
				combineLatest([inputs['x_onderwijsresultaten_or_multiselect_schooljaar']!, inputs['indicator-over']!]).subscribe(internalSubject);
				return combineLatest([states['x_onderwijsresultaten_or_schooljaar_historie']!, internalSubject]).pipe(
					map(([[{ option }], [schooljaren, aantalJaren]]: [[{ option: SchooljaarHistorieOption }], [string[], number]]) =>
						option === SchooljaarHistorieOption.AANGEPAST ? [schooljaren, aantalJaren] : undefined
					)
				);
			},
			stateToInput: (value: [string[], number] | undefined) => {
				return isUndefined(value) ? [] : value[0];
			},
			createExpression: (value: [string[], number] | undefined) => {
				if (isUndefined(value)) return undefined;

				const [sj, aantal]: [string[], number] = value;

				return createMultiSchooljarenExpression(sj, aantal);
			},
			visible: (states) =>
				states['x_onderwijsresultaten_or_schooljaar_historie']!.pipe(map((st) => st?.[0]?.option === SchooljaarHistorieOption.AANGEPAST)),
		}),
		x_onderwijsresultaten_or_schooljaar: this.completeFilterConfig<string, [string, number, DashboardVariant]>({
			att: 'or_nm_schooljaar',
			init: getSchooljaarTovHuidig(-1),
			optional: false,
			first: true,
			sortOrder: SortOrder.DESC,
			state: (inputs) => {
				const internalSubject: Subject<[string, number, DashboardVariant]> = new ReplaySubject(1);
				combineLatest([inputs['x_onderwijsresultaten_or_schooljaar']!, inputs['indicator-over']!, inputs['variant']!]).subscribe(
					internalSubject
				);
				return internalSubject;
			},
			stateToInput: ([sj, _aantal]) => sj,
			createExpression: ([sj, aantal, variant]) =>
				variant === DashboardVariant.ACTUEEL
					? createMultiSchooljarenExpression(getOnderwijsresultatenSchooljarenRange(sj, 3, 'bbs'), aantal)
					: undefined,
			valueString: (sj) => `${parseInt(sj.substring(0, 4)) + 2} (${sj})`,
			visible: (states) =>
				states['x_onderwijsresultaten_or_schooljaar']!.pipe(map(([_sj, _aantal, variant]) => variant === DashboardVariant.ACTUEEL)),
		}),
		x_onderwijsresultaten_or_schooljaar_historie: this.completeFilterConfig<PeriodeFilterValue, [PeriodeFilterValue, number]>({
			component: SchooljaarHistorieFilterComponent,
			label: 'Periode',
			init: { option: SchooljaarHistorieOption.AFGELOPEN_5, inclHuidig: true },
			optional: false,
			first: true,
			state: (inputs) => {
				const internalSubject: Subject<[PeriodeFilterValue, number]> = new ReplaySubject(1);
				combineLatest([inputs['x_onderwijsresultaten_or_schooljaar_historie']!, inputs['indicator-over']!]).subscribe(internalSubject);
				return internalSubject;
			},
			stateToInput: ([{ option, inclHuidig }, _aantal]) => ({ option, inclHuidig }),
			valueString: ({ option }) => `${option}`,
			createExpression: ([{ option, inclHuidig }, aantalJaren]) => {
				const evtHuidig = inclHuidig ? 0 : -1;
				switch (option) {
					case SchooljaarHistorieOption.AFGELOPEN_3:
						return createMultiSchooljarenExpression(
							getOnderwijsresultatenSchooljarenRange(getSchooljaarTovHuidig(evtHuidig), 3, 'bbs'),
							aantalJaren
						);
					case SchooljaarHistorieOption.AFGELOPEN_5:
						return createMultiSchooljarenExpression(
							getOnderwijsresultatenSchooljarenRange(getSchooljaarTovHuidig(evtHuidig), 5, 'bbs'),
							aantalJaren
						);
					case SchooljaarHistorieOption.AFGELOPEN_10:
						return createMultiSchooljarenExpression(
							getOnderwijsresultatenSchooljarenRange(getSchooljaarTovHuidig(evtHuidig), 10, 'bbs'),
							aantalJaren
						);
					case SchooljaarHistorieOption.AANGEPAST:
						return undefined;
				}
			},
		}),
		or_co_brinvest: this.completeFilterConfig({
			att: 'or_co_brinvest',
			valueString: (brinvest: string) => this.displayService.generateBrinVestigingValueString(brinvest),
		}),
	};

	prestatieanalyseFilters = {
		pv_nm_schooljaar: this.completeFilterConfig<string, string>({
			att: 'pv_nm_schooljaar',
			init: getSchooljaarTovHuidig(),
			optional: false,
			first: true,
			sortOrder: SortOrder.DESC,
			valueString: (val) => `${parseInt(val.substring(0, 4)) + 1} (${val})`,
		}),
		x_pv_schooljaar_historie: this.createPeriodeFilter('pv_nm_schooljaar'),
		x_pv_multiselect_schooljaar: this.createPeriodeAangepastFilter('x_pv_multiselect_schooljaar', 'pv_nm_schooljaar', 'x_pv_schooljaar_historie'),
	};

	examencijfersFilters = {
		ekc_nm_cijfertype: this.completeFilterConfig({
			att: 'ekc_nm_cijfertype',
			optional: false,
			init: Cijferkolomtype.CE_CIJFER,
			valueString: (val) => this.displayService.display(val).replace(/-?cijfer/, ''),
		}),
		ekc_nm_niveau: this.completeMultiSelectFilterConfig({
			att: 'ekc_nm_niveau',
			componentParams: {
				text: true,
			},
		}),
		ekc_nm_schooljaar: this.createSchooljaarActueelFilter('ekc_nm_schooljaar'),
		'ekc_fk_br_vest.br_co_brin': this.createBrinVestFilter('ekc_fk_br_vest.br_co_brin'),
		'ekc_fk_vk.vk_nm_vak': this.completeMultiSelectFilterConfig({
			att: 'ekc_fk_vk.vk_nm_vak',
		}),
		x_ekc_schooljaar_historie: this.createPeriodeFilter('ekc_nm_schooljaar', SchooljaarHistorieOption.AFGELOPEN_5),
		x_ekc_multiselect_schooljaar: this.createPeriodeAangepastFilter(
			'x_ekc_multiselect_schooljaar',
			'ekc_nm_schooljaar',
			'x_ekc_schooljaar_historie'
		),
	};

	vakkeuzeFilters = {
		'vkk_fk_ilt.ilt_nm_niveau': this.completeMultiSelectFilterConfig({
			att: 'vkk_fk_ilt.ilt_nm_niveau',
			componentParams: {
				text: true,
			},
		}),
		'vkk_fk_lb.lb_co_brin': this.completeFilterConfig({
			att: 'vkk_fk_lb.lb_co_brin',
			default: () => this.isMultiBrin(),
		}),

		vkk_fun_nationaliteit: this.createNationaliteitFilter('vkk_fun_nationaliteit', 'vkk_fk_ll'),

		vkk_nm_schooljaar: this.createSchooljaarActueelFilter('vkk_nm_schooljaar'),
		vkk_nm_vak: this.completeMultiSelectFilterConfig({
			att: 'vkk_nm_vak',
		}),

		vkk_nr_leerjaar: this.completeMultiSelectFilterConfig({
			att: 'vkk_nr_leerjaar',
			componentParams: {
				text: true,
			},
		}),

		x_vkk_schooljaar_historie: this.createPeriodeFilter('vkk_nm_schooljaar', SchooljaarHistorieOption.AFGELOPEN_5),
		x_vkk_multiselect_schooljaar: this.createPeriodeAangepastFilter(
			'x_vkk_multiselect_schooljaar',
			'vkk_nm_schooljaar',
			'x_vkk_schooljaar_historie'
		),
		x_vakkeuze_peildatum: this.completeFilterConfig({
			componentParams: {
				fixedOptions: Object.values(PeildatumOption),
			},
			label: 'Peildatum',
			init: PeildatumOption.OKTOBER,
			optional: false,
			createExpression: createVakkeuzePeildatumExpression,
		}),
		'vkk_fk_lb.lb_co_brin_vestiging': this.createBrinVestFilter('vkk_fk_lb.lb_co_brin_vestiging'),
		'vkk_fk_lb_vorig_sj.lb_co_brin_vestiging': this.createBrinVestFilter('vkk_fk_lb_vorig_sj.lb_co_brin_vestiging'),
		'vkk_fk_lb_volgend_sj.lb_co_brin_vestiging': this.createBrinVestFilter('vkk_fk_lb_volgend_sj.lb_co_brin_vestiging'),
	};

	initialConfigs: Partial<{ [name in FilterName]: FilterConfig<any, any> }> = {
		...this.absentieFilters,
		...this.basisvaardighedenFilters,
		...this.cijferFilters,
		...this.cijferkolommenFilters,
		...this.doorstroomFilters,
		...this.examencijfersFilters,
		...this.lesregistratieFilters,
		...this.onderwijsresultaatFilters,
		...this.prestatieanalyseFilters,
		...this.vakkeuzeFilters,
		x_leerlingselectie: this.createLeerlingSelectieFilter('x_leerlingselectie'),
	};

	initialQpInputs: Partial<{ [qp in QpName]: any }> = {
		'indicator-over': 3.1,
		variant: DashboardVariant.ACTUEEL,
	};

	cijferKolomTypeAllowed(cijfertype: Cijferkolomtype): boolean {
		return !(
			this.userService.bron() === InstellingBron.Somtoday ? somtodayUnsupportedCijferkolomtypes : magisterUnsupportedCijferkolomtypes
		).includes(cijfertype);
	}

	completeDefaultFilterConfig(config: Partial<FilterConfig<any, any>>): FilterConfig<any, any> {
		if (config.att) {
			const attrPath = toAttrPath(config.att);
			if (isBooleanAttribute(attrPath)) {
				return this.completeBooleanFilterConfig(<Partial<FilterConfig<number, number>> & { att: Att }>config);
			}
			if (isDateAttribute(attrPath)) {
				return this.createDateFilter(config.att);
			}
		}
		return this.completeMultiSelectFilterConfig(config);
	}

	completeFilterConfig<I, T>(config: Partial<FilterConfig<I, T>>): FilterConfig<I, T> {
		const attrPath = toAttrPath(config.att);
		const label = config.label ?? attrLabel(attrPath);
		const createExpression = (val: T) => (isUndefined(val) ? new QueryFilterExpression(attrPath) : new BasicFilterExpression(attrPath, val));
		const valueString = (val: T) => this.displayService.display(val, attrPath);
		return defaults(config, {
			label,
			searchKeys: [],
			init: undefined,
			component: SingleSelectFilterComponent,
			componentParams: {},
			valueAllowed: undefined,
			createExpression,
			optional: true,
			showNullValue: true,
			first: false,
			default: () => true,
			staticOptions: this.isStaticOptions(config),
			fixedOrder: false,
			valueString,
			stateToInput: (x: T) => x,
			sortOrder: SortOrder.ASC,
			visible: () => of(true),
			encode: (val: T | null | undefined) => (val === undefined ? '' : JSON.stringify(val)),
			decode: decodeSingle,
		});
	}

	private isStaticOptions<I, T>(config: Partial<FilterConfig<I, T>>): boolean {
		const componentParams = config.componentParams;
		if (isUndefined(componentParams)) return false;

		return (componentParams as SingleSelectComponentParams<T> | MultiSelectComponentParams<T>).fixedOptions !== undefined;
	}

	completeMultiSelectFilterConfig<I, T>(config: Partial<FilterConfig<I, T>>): FilterConfig<I, T> {
		const attrPath = toAttrPath(config.att);
		const label = config.label ?? attrLabel(attrPath);
		const createExpression = (val: any[]) => (isUndefined(val) ? new QueryFilterExpression(attrPath) : new InFilterExpression(attrPath, val));
		const valueString = (vals: any[]) => {
			const valueStrings = vals?.map((val) => this.displayService.display(val, attrPath));
			return valueStrings?.join(valueStrings.some((v) => v?.includes(',')) ? '; ' : ', ');
		};

		const enumValues = EnumService.enumValues(attrPath);
		const defaultValues = enumValues.length > 0 ? enumValues : undefined;

		return defaults(
			{
				...config,
				componentParams: defaults(config.componentParams, {
					defaultOptions: defaultValues,
				}),
			},
			this.completeFilterConfig({
				label,
				component: MultiSelectFilterComponent,
				createExpression,
				valueString,
				decode: decodeMulti,
			})
		);
	}

	completeBooleanFilterConfig(config: Partial<FilterConfig<number, number>> & { att: Att }): FilterConfig<number, number> {
		const attrPath = toAttrPath(config.att);
		return this.completeFilterConfig({
			...config,
			componentParams: {
				defaultOptions: [1, 0],
			},
			component: SingleSelectFilterComponent,
			sortOrder: SortOrder.DESC,
			valueString: (val) =>
				val === null ? this.displayService.display(val, attrPath) : EnumService.booleanLegendaEnumEntry(attrPath, Boolean(val)),
		});
	}

	createBrinVestFilter(att: Att) {
		return this.completeMultiSelectFilterConfig({
			att,
			valueString: (brinvests: string[]) => this.displayService.generateBrinVestigingenValueString(brinvests),
		});
	}

	createDateFilter(att: Att, schooljaarFilters?: DateRangeComponentParams): FilterConfig<DateRangeState, DateRangeState> {
		return this.completeFilterConfig<DateRangeState, DateRangeState>({
			att,
			component: DateRangeFilterComponent,
			componentParams: schooljaarFilters,
			staticOptions: true,
			createExpression: (value: DateRangeState) => {
				if (isUndefined(value)) return undefined;

				const from = value.range ? isoDate(value.range.from) : null;
				const to = value.range ? isoDate(value.range.to) : null;
				return new BetweenFilterExpression(toAttrPath(att), from, to);
			},
			encode(val: DateRangeState | null) {
				if (!val?.view) return '';
				if (val.view === DateRangeView.AFGELOPEN) return JSON.stringify({ view: val.view, relative: val.relative });
				if (!val.range?.from || !val.range?.to) return JSON.stringify({ view: val.view });
				return JSON.stringify({ range: { from: isoDate(val.range.from), to: isoDate(val.range.to) }, view: val.view });
			},
			decode(s: string) {
				if (!s) return undefined;
				const val = JSON.parse(s);
				if (val.view === DateRangeView.AFGELOPEN)
					return { view: val.view, relative: val.relative, range: getRelativeDateRange(val.relative) };
				const from = val.range?.from;
				const to = val.range?.to;
				const range = from && to ? { from: stripTime(from), to: stripTime(to) } : undefined;
				return { range, view: val.view };
			},
			valueString: (val) => formatDateRangeState(val) ?? 'Kies datum...',
		});
	}

	createNationaliteitFilter(att: Att, fkLlAttr: Attr) {
		return this.completeMultiSelectFilterConfig({
			att,
			createExpression: (value: string[]) => {
				return new CompoundFilterExpression(
					[
						new InFilterExpression([fkLlAttr, 'll_nm_nationaliteit1'], value),
						new InFilterExpression([fkLlAttr, 'll_nm_nationaliteit2'], value),
					],
					'or'
				);
			},
		});
	}

	/** Maakt een schooljaar-filter dat alleen filtert en alleen zichtbaar is op Actueel-dashboards */
	createSchooljaarActueelFilter(sjAtt: Att) {
		return this.completeFilterConfig({
			att: sjAtt,
			init: getSchooljaarTovHuidig(),
			optional: false,
			first: true,
			sortOrder: SortOrder.DESC,
			fixedOrder: true,
			classNames: 'no-minwidth',
			state: (inputs) => combineLatest([inputs[sjAtt]!, inputs['variant']!]),
			stateToInput: (value: [string, DashboardVariant] | undefined) => value?.[0],
			createExpression: (value: [string, DashboardVariant] | undefined) => {
				if (isUndefined(value) || value[1] === DashboardVariant.HISTORIE) return undefined;
				return new BasicFilterExpression(toAttrPath(sjAtt), value[0]);
			},
			visible: (states) => states[sjAtt]!.pipe(map((value: [string, DashboardVariant] | undefined) => value?.[1] === DashboardVariant.ACTUEEL)),
		});
	}

	createSchooljaarIndicatorFilter(filterName: FilterName, sjAtt: Att, indicator: string) {
		return this.completeFilterConfig<string, [string, number]>({
			att: sjAtt,
			init: getSchooljaarTovHuidig(-1),
			optional: false,
			first: true,
			sortOrder: SortOrder.DESC,
			fixedOrder: true,
			state: (inputs) => {
				const internalSubject: Subject<[string, number]> = new ReplaySubject(1);
				combineLatest([inputs[filterName]!, inputs['indicator-over']!]).subscribe(internalSubject);
				return internalSubject;
			},
			stateToInput: ([sj, _aantal]) => sj,
			createExpression: ([sj, aantal]) => createSchooljarenRangeExpression(sjAtt, sj, aantal, indicator),
			valueString: (sj) => `${parseInt(sj.substring(0, 4)) + 2} (${sj})`,
		});
	}

	createPeriodeFilter(att: Att, defaultOption: SchooljaarHistorieOption = SchooljaarHistorieOption.AFGELOPEN_3) {
		const attrPath: AttrPath = <AttrPath>att.split('.');
		return this.completeFilterConfig<PeriodeFilterValue, PeriodeFilterValue>({
			component: SchooljaarHistorieFilterComponent,
			label: 'Periode',
			init: { option: defaultOption, inclHuidig: true },
			optional: false,
			first: true,
			valueString: ({ option }) => `${option}`,
			createExpression: ({ option, inclHuidig }) => {
				const evtHuidig = inclHuidig ? 1 : 0;
				switch (option) {
					case SchooljaarHistorieOption.AFGELOPEN_3:
						return new InFilterExpression(attrPath, range(-3 + evtHuidig, evtHuidig).map(getSchooljaarTovHuidig));
					case SchooljaarHistorieOption.AFGELOPEN_5:
						return new InFilterExpression(attrPath, range(-5 + evtHuidig, evtHuidig).map(getSchooljaarTovHuidig));
					case SchooljaarHistorieOption.AFGELOPEN_10:
						return new InFilterExpression(attrPath, range(-10 + evtHuidig, evtHuidig).map(getSchooljaarTovHuidig));
					case SchooljaarHistorieOption.AANGEPAST:
						return undefined;
				}
			},
		});
	}

	createPeriodeAangepastFilter(
		periodeAangepastFilterName: FilterName,
		schooljaarAtt: Att,
		periodeFilterName: FilterName,
		minimumSchooljaren: number = 2
	) {
		return this.completeMultiSelectFilterConfig({
			att: schooljaarAtt,
			optional: false,
			componentParams: { minimum: minimumSchooljaren, blockedHover: `Selecteer minstens ${minimumSchooljaren} schooljaren.` },
			first: true,
			init: range(-2, 1).map(getSchooljaarTovHuidig),
			sortOrder: SortOrder.DESC,
			fixedOrder: true,
			state: (inputs, states) => {
				const internalSubject: Subject<string[]> = new ReplaySubject(1);
				inputs[periodeAangepastFilterName]!.subscribe(internalSubject);
				return combineLatest([states[periodeFilterName]!, internalSubject]).pipe(
					map(([{ option }, schooljaren]: [{ option: SchooljaarHistorieOption }, string[]]) =>
						option === SchooljaarHistorieOption.AANGEPAST ? schooljaren : undefined
					)
				);
			},
			visible: (states) => states[periodeFilterName]!.pipe(map((st) => st?.option === SchooljaarHistorieOption.AANGEPAST)),
		});
	}

	createLeerlingSelectieFilter(name: FilterName) {
		return this.completeMultiSelectFilterConfig<LeerlingSelectieId[], RLeerlingSelectie[]>({
			component: LeerlingSelectieFilterComponent,
			state: (inputs) =>
				inputs[name]!.pipe(
					switchMap((sels: LeerlingSelectieId[]) =>
						sels && sels.length > 0 ? combineLatest(sels.map((sel) => this.leerlingSelectieService.get(sel.id))) : of(undefined)
					)
				),
			stateToInput: (options) => options?.map((sel) => ({ naam: sel.naam, id: sel.id! })),
			createExpression: (val) => {
				if (val === undefined) return undefined;
				return new CompoundFilterExpression(
					val.map((sel) => createSelectionExpression(sel)),
					'or'
				);
			},
			label: 'Leerlingselectie',
			valueString: (selectie) => selectie?.map((sel) => sel.naam).join(', '),
			searchKeys: ['Eigen filter', 'Persoonlijk', 'Opgeslagen', 'Favoriet'],
		});
	}
}

export const defaultDoorstroomActueelFilters: FilterName[] = [
	'ds_nm_schooljaar_van',
	'ds_fk_lb_van.lb_co_brin',
	'ds_fk_vs_van.vs_nm_vestiging',
	'ds_fk_ilt_van.ilt_nm_niveau',
	'ds_nr_leerjaar_van',
];

export const defaultDoorstroomHistorieFilters: FilterName[] = [
	'x_doorstroom_schooljaar_historie',
	'x_doorstroom_multiselect_schooljaar',
	...defaultDoorstroomActueelFilters,
];

// CL-4189 robuust voor opgeslagen favorieten bij filters die vroeger multi waren
function decodeSingle(s: string) {
	if (s === '') return undefined;
	const val = JSON.parse(s);
	return isArray(val) ? val[0] : val;
}

// CL-4189 robuust voor opgeslagen favorieten bij filters die vroeger single waren
function decodeMulti(s: string) {
	if (s === '') return undefined;
	const val = JSON.parse(s);
	return isArray(val) ? val : [val];
}

export function toAttrPath(att?: Att): AttrPath {
	return att ? <AttrPath>att.split('.') : [];
}

function createMultiSchooljarenExpression(schooljaren: string[], aantalJaren: number) {
	return new CompoundFilterExpression(
		flatMap(['op', 'obs', 'bbs', 'exc'], (co_indicator) =>
			schooljaren.map((group) => createPeiljaarSchooljarenExpression('or_nm_schooljaar', group, aantalJaren, co_indicator))
		),
		'or'
	);
}

function createPeiljaarSchooljarenExpression(att: Att, val: string, aantalJaren: number, co_indicator: string) {
	const peiljaar = Number(val.split('/')[1]) + 1;

	const schooljaarFilter = createSchooljarenRangeExpression(
		att,
		val,
		co_indicator === 'op' && aantalJaren === DRIE_SCHOOLJAREN_EXCL_2020 ? DRIE_SCHOOLJAREN_EXCL_2019 : aantalJaren,
		co_indicator
	);
	const indicatorFilter = new BasicFilterExpression(['or_co_indicator'], co_indicator);
	const peiljaarFilter = new BasicFilterExpression(['pj_nr_peiljaar'], peiljaar);

	return new CompoundFilterExpression([peiljaarFilter, schooljaarFilter, indicatorFilter]);
}

function createSchooljarenRangeExpression(att: Att, val: string, aantalJaren: number, indicator: string) {
	return new InFilterExpression(toAttrPath(att), getOnderwijsresultatenSchooljarenRange(val, aantalJaren, indicator));
}

function createVakkeuzePeildatumExpression(option: PeildatumOption): FilterExpression | undefined {
	switch (option) {
		case PeildatumOption.ACTUEEL:
			return new BasicFilterExpression(['vkk_fun_is_vakkeuze_peildatum_actueel'], 1);
		case PeildatumOption.OKTOBER:
			return new BasicFilterExpression(['vkk_fun_is_vakkeuze_peildatum_1okt'], 1);
		case PeildatumOption.JANUARI:
			return new BasicFilterExpression(['vkk_fun_is_vakkeuze_peildatum_1jan'], 1);
		case PeildatumOption.FEBRUARI:
			return new BasicFilterExpression(['vkk_fun_is_vakkeuze_peildatum_1feb'], 1);
		case PeildatumOption.APRIL:
			return new BasicFilterExpression(['vkk_fun_is_vakkeuze_peildatum_1april'], 1);
		case PeildatumOption.JULI:
			return new BasicFilterExpression(['vkk_fun_is_vakkeuze_peildatum_1juli'], 1);
	}
}

function createDoorstroomPeildatumExpression(option: PeildatumOption): FilterExpression | undefined {
	switch (option) {
		case PeildatumOption.ACTUEEL:
			return new BasicFilterExpression(['ds_fun_is_plaatsing_peildatum_actueel'], 1);
		case PeildatumOption.OKTOBER:
			return new BasicFilterExpression(['ds_fun_is_plaatsing_peildatum_1okt'], 1);
		case PeildatumOption.JANUARI:
			return new BasicFilterExpression(['ds_fun_is_plaatsing_peildatum_1jan'], 1);
		case PeildatumOption.FEBRUARI:
			return new BasicFilterExpression(['ds_fun_is_plaatsing_peildatum_1feb'], 1);
		case PeildatumOption.APRIL:
			return new BasicFilterExpression(['ds_fun_is_plaatsing_peildatum_1april'], 1);
		case PeildatumOption.JULI:
			return new BasicFilterExpression(['ds_fun_is_plaatsing_peildatum_1juli'], 1);
	}
}
