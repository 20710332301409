import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { FilterName } from '../services/filter-config';
import { FilterInputDirective } from '../filter-panel/filter-input.directive';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { LeerlingSelectieFilterComponent } from '../filters/leerling-selectie-filter/leerling-selectie-filter.component';
import { isEmpty } from 'lodash-es';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-filter-label',
	templateUrl: './filter-label.component.html',
	styleUrls: ['./filter-label.component.scss'],
	imports: [FilterInputDirective, NgClickOutsideDelayOutsideDirective, ButtonComponent, AsyncPipe, LeerlingSelectieFilterComponent],
})
export class FilterLabelComponent implements OnInit {
	private readonly filterService = inject(FilterService);
	readonly destroyRef = inject(DestroyRef);

	@Input()
	filterName!: FilterName;

	value!: Observable<string>;

	public showDropdown = false;

	ngOnInit() {
		this.value = this.filterService.getFilterStateAsInputLabel(this.filterName);
		this.value.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			if (!this.filterService.isMultiSelect(this.filterName) && this.showDropdown) this.showDropdown = false;
		});
	}

	toggleDropdown() {
		this.showDropdown = !this.showDropdown;
	}

	getTitle(): Observable<string> {
		return this.value.pipe(map((val) => `${this.getLabel()}: ${val}`));
	}

	containsAdHoc(): Observable<boolean> {
		return of(false);
	}

	getLabel(): string {
		return this.filterService.configs[this.filterName]!.label;
	}

	removeFilter() {
		this.filterService.setFilterInput(this.filterName, undefined).then(() => this.filterService.deactivateIfNeeded([this.filterName]));
	}

	getClassNames(): string {
		return this.filterService.configs[this.filterName]?.classNames ?? '';
	}

	isOptional(): boolean {
		return this.filterService.isOptional(this.filterName);
	}

	isEmptyLeerlingselectie(): Observable<boolean> {
		return this.value.pipe(map((val) => (this.isDetailsList() || this.filterName === 'x_leerlingselectie') && isEmpty(val)));
	}

	isDetailsList() {
		return false;
	}
}
