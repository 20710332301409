<app-dashboard-container
	class="dashboard-cohortrendement"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions(), [], $event)"
>
	<app-filter-panel
		[defaultFilters]="variant() === DashboardVariant.ACTUEEL ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/doorstroom"
		[permanentFilterExpressions]="permanentFilterExpressions()"
	>
	</app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header [variant]="variant()" [legenda]="[legenda()]" [leftOptionsTemplate]="leftOptions">
			<ng-template #leftOptions>
				<div class="weergave cohortrendementtype">
					Type
					<dt-form-dropdown
						[opties]="typeOpties"
						[selectedOptie]="typeOptie()"
						(onOptieClicked)="qp.dispatch('cohortrendementtype', $event)"
					></dt-form-dropdown>
				</div>
				<div class="weergave cohortrendementweergave">
					Weergave
					<dt-form-dropdown
						[opties]="weergaveOpties"
						[selectedOptie]="weergaveOptie()"
						(onOptieClicked)="qp.dispatch('cohortrendementweergave', $event)"
					></dt-form-dropdown>
				</div>
			</ng-template>
		</app-dashboard-header>
		@switch (variant()) {
			@case (DashboardVariant.ACTUEEL) {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions()"
					[defaultGroups]="defaultGroups"
					[groups]="groups()"
					[subgroups]="subgroups()"
					[config]="this"
				>
				</app-barchart-table>
			}
			@case (DashboardVariant.HISTORIE) {
				<app-vbarchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions()"
					[defaultGroups]="defaultGroups"
					[groups]="getHistorieGroups(groups())"
					[subgroups]="getHistorieSubgroups(groups(), subgroups())"
					[batchLevels]="isHistorieBatchVariant() ? 1 : 0"
					[config]="this"
					[partitionMeasure]="getPartitionMeasure(weergaveOptie())"
					[schooljaarPath]="['ds_nm_schooljaar_van']"
				>
				</app-vbarchart-table>
			}
		}
	</section>
</app-dashboard-container>
