<app-dashboard-container
	class="dashboard-basisvaardigheden"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, permanentFilterExpressions(), [], $event)"
>
	<app-filter-panel
		[defaultFilters]="actueelFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/basisvaardigheden"
		[permanentFilterExpressions]="permanentFilterExpressions()"
	></app-filter-panel>
	<app-filter-bar [dataRootSelection]="dataRootSelection()"></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header
			[legenda]="['bv_fun_referentieniveau_bin']"
			[legendaExclude]="legendaExclude()"
			[legendaKeyIsClassName]="true"
			[rightOptionsTemplate]="rightOptions"
		>
			<ng-template #rightOptions>
				<app-toggle-buttons
					[options]="[DashboardAspect.GEMIDDELDE, DashboardAspect.VERDELING]"
					[value]="aspect()"
					(valueChange)="qp.dispatch('aspect', $event)"
				>
				</app-toggle-buttons>
			</ng-template>
		</app-dashboard-header>
		<div>
			Bekijk de <a href="https://cumlaude.zendesk.com/hc/nl/articles/28313126475409-Basisvaardigheden">handleiding</a> voor meer uitleg over
			onze rekenwijze.
		</div>
		@switch (aspect()) {
			@case (DashboardAspect.GEMIDDELDE) {
				<app-data-tree-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions()"
					[defaultGroups]="defaultGroups"
					[groups]="selectedGroups()"
					[subgroups]="subgroups()"
					[config]="this"
				></app-data-tree-table>
			}
			@case (DashboardAspect.VERDELING) {
				<app-barchart-table
					[filters]="filterExpressions"
					[permanentFilters]="permanentFilterExpressions()"
					[defaultGroups]="defaultGroups"
					[groups]="groupsVerdeling()"
					[subgroups]="subgroupsVerdeling()"
					[config]="this"
				></app-barchart-table>
			}
		}
	</section>
</app-dashboard-container>
