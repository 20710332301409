import { Component, computed, signal, inject } from '@angular/core';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import {
	AttrPath,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
	InFilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { Observable } from 'rxjs';
import { MultiAggregator, weightedAverage, weightedAverageAll, xAgg0 } from '../../services/aggregation';
import { ColumnDef, createDefaultFooterCellDef, createDefaultHeaderCellDef, TableModel } from '../../shared/components/table/table/table.model';
import { ColumnType, createMeasureColumn, DataRow, DataTreeTableComponent } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { ALL_STRING, DataTree, getLeafA, Path, unnest } from '../../services/data-tree';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { BarInfo } from '../../services/stacked-bars';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { SinglePercentielHbarComponent } from '../prestatieanalyse/single-percentiel-hbar/single-percentiel-hbar.component';
import { Cijferkolomtype, Table } from '@cumlaude/metadata';
import { flatMap, intersection, isEmpty, isNil, last, nth, uniq } from 'lodash-es';
import { map, tap } from 'rxjs/operators';
import { Sort } from '../../shared/components/table/table/table.component';
import { PercentielSchaalverdelingComponent } from '../prestatieanalyse/percentiel-schaalverdeling/percentiel-schaalverdeling.component';
import { VbarSeriesData, VbarStyle } from '../../shared/dashboard/vbarchart-table/vbar-batch/vbar-batch.component';
import { att, count_records } from '../../services/measures';
import { formatNumber } from '@angular/common';
import { Axis } from '../../services/axis';
import { PartitionMeasure, VbarchartTableComponent, VBarExportColumnDef } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { AfwijkingPercentielWeergave, DashboardVariant } from '../../services/weergave-opties';
import { VPartitionData } from '../../shared/dashboard/vbarchart-table/vbar-series/vbar-series.component';
import { createAggFunctions } from '../../shared/dashboard/base-dashboard/base-dashboard.component';
import { PsName } from '../../services/page-state.service';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { RVestiging } from '@cumlaude/service-contract';
import { WeergaveOptieComponent } from '../../shared/components/weergave-optie/weergave-optie.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface CijfersExamenI extends Attributes {
	ekc_nr_gem_cijfer: number;
	ekc_nr_perc_cijfer: number | null;
	xa: { [bitset: number]: { ekc_nr_gem_cijfer: number | null } };
}

interface CijfersExamenA extends Attributes {
	ekc_nr_gem_cijfer: number;
	ekc_nr_perc_cijfer: number | null;
	ekc_nr_gem_cijfer_landelijk: number | null;
	ekc_nr_gem_cijfer_landelijk_historie: number;
}

/**
 * Als weightedAverage, maar pakt alleen op het root-niveau de _ALL (wanneer aanwezig).
 * Wordt gebruikt om het totaal-percentiel aan te passen.
 */
function weightedAverageAllAtRoot(
	attribute: keyof CijfersExamenA,
	initialAttribute: keyof CijfersExamenI
): MultiAggregator<typeof attribute, CijfersExamenI, CijfersExamenA, number | null> {
	const orig = weightedAverage(attribute, initialAttribute);
	const combine = (as: CijfersExamenA[], keys: (string | null)[], all: number | null | undefined) => {
		if (keys.length == 0 && all !== undefined) return all;
		return orig.combine(as, keys, all);
	};
	return { ...orig, combine };
}

const vestigingGroup: AttrPath = ['ekc_fk_br_vest', 'br_co_brin'];

const vakKeyGroups: AttrPath[] = [
	['ekc_fk_vk', 'vk_nm_vak'],
	['ekc_fk_vk', 'vk_co_vak'],
];

@Component({
	selector: 'app-cijfers-examens',
	templateUrl: './cijfers-examens.component.html',
	styleUrls: ['./cijfers-examens.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		DataTreeTableComponent,
		VbarchartTableComponent,
		WeergaveOptieComponent,
		FilterBarComponent,
	],
})
export class CijfersExamensComponent extends BarchartTableConfig<CijfersExamenI, CijfersExamenA> {
	protected readonly dataService = inject(DataService);

	defaultGroups: AttrPath[] = [['ekc_fk_vk', 'vk_nm_vak']];

	override fixedBeforeGroups = 1;

	prognose = false;

	cumlaudeSchooljaren: string | undefined;

	override availableGroups: AttrPath[] = [...vakKeyGroups, ['ekc_fk_vk', 'vk_nm_vakkengroep']];

	actueelFilters: FilterName[] = ['ekc_nm_schooljaar', 'ekc_fk_br_vest.br_co_brin', 'ekc_nm_niveau', 'ekc_fk_vk.vk_nm_vak', 'ekc_nm_cijfertype'];

	historieFilters: FilterName[] = ['x_ekc_schooljaar_historie', 'x_ekc_multiselect_schooljaar', ...this.actueelFilters];

	filterExpressions?: FilterExpression[];

	factTable = Table.fac_ekc_examenkandidaten_en_cijfers;

	cijfertype = Cijferkolomtype.CE_CIJFER;

	ownBrinFilter = signal<FilterExpression[]>(<FilterExpression[]>[]);

	afwijkingpercentiel = this.qp.signal('afwijkingpercentiel');

	variant = this.qp.signal('variant');

	selectedGroups = this.qp.signal_g(this.defaultGroups);

	protected override singleAggregators = {
		ekc_nr_gem_cijfer_landelijk_historie: xAgg0<'ekc_nr_gem_cijfer', CijfersExamenI>('ekc_nr_gem_cijfer'),
	};

	protected override multiAggregators = [
		weightedAverage('ekc_nr_gem_cijfer', 'ekc_nr_gem_cijfer'),

		// Het percentiel in de totaalregel wordt uit de root _ALL gehaald. Die is daar niet door de backend in gestopt, maar door de functie patchPercentiel van dit dashboard.
		// Die functie gebruikt ook deze zelfde aggregator om zonodig percentielen van de vestigingen op root-niveau samen te voegen.
		weightedAverageAllAtRoot('ekc_nr_perc_cijfer', 'ekc_nr_perc_cijfer'),

		// landelijk gemiddelde komt uit _ALL op vestigingniveau (eigen vestigingen zitten in een HAVING-filter)
		weightedAverageAll('ekc_nr_gem_cijfer_landelijk', 'ekc_nr_gem_cijfer'),
	];

	constructor() {
		super();

		this.subscriptions.push(
			this.filterService.observe('ekc_nm_cijfertype').subscribe((cijfertype: Cijferkolomtype) => {
				this.cijfertype = cijfertype;
			}),
			this.userService.rVestigingen$.subscribe((vestigingen: RVestiging[]) => {
				const vestigingBrins = uniq(flatMap(vestigingen, (vestiging) => vestiging.vestigingBrins));
				this.ownBrinFilter.set([new InFilterExpression(vestigingGroup, vestigingBrins)]);
			})
		);
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		const { f, having } = this.dataService.moveToHaving([vestigingGroup], options);
		const xa = this.variant() === DashboardVariant.HISTORIE ? [[options.g!.length - 2]] : [];
		return this.dataService
			.getExamencijfersData({
				...options,
				f,
				r: [0, this.getRollupLevel()],
				having: new CompoundFilterExpression([...(having ? [having] : []), ...this.ownBrinFilter()]),
				xa,
			})
			.pipe(
				map(this.patchPercentiel.bind(this)),
				tap((response) => {
					this.cumlaudeSchooljaren = response.metadata!.get('cumlaudeSchooljaren');
					this.prognose = !isEmpty(this.cumlaudeSchooljaren);
					this.pageStateService.dispatch(PsName.prognose, String(this.prognose));
				})
			);
	}

	/**
	 * Haalt het percentiel voor de totaalregel uit het resultaat van de "order" query en stopt het onder de root _ALL in de
	 * normale data (voordat die in een DataTree wordt omgezet).
	 */
	patchPercentiel(resp: DataResponse<number[]>): DataResponse<number[]> {
		const { data, order } = resp;
		const { aggInit, aggCombine } = createAggFunctions<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>(this, [], resp.measures); // subgroups hebben we hier niet nodig
		const orderRecs = unnest(order!, undefined, undefined, aggInit, aggCombine);
		if (!orderRecs.length) return resp;
		const orderRootPath = orderRecs[0].slice(0, 1);
		const percentielTotaal = att<'ekc_nr_perc_cijfer', CijfersExamenA>('ekc_nr_perc_cijfer')(orderRootPath);
		(<number[]>(<Map<string, DataTree<number[]>>>data).get(ALL_STRING)).splice(
			resp.measures.findIndex((s) => s === 'ekc_nr_perc_cijfer'),
			1,
			percentielTotaal!
		);
		return resp;
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getExamencijfersExportData(options);
	}

	override getDefaultSort(tableModel: TableModel<DataRow<CijfersExamenA>>): Sort {
		if (this.afwijkingpercentiel() === AfwijkingPercentielWeergave.PERCENTIEL) return { active: 'Percentiel', direction: 'desc' };
		return super.getDefaultSort(tableModel);
	}

	getCijfertypeKop(landelijk: boolean) {
		const prefix = landelijk ? 'Landelijk ' : '';
		switch (this.cijfertype) {
			case Cijferkolomtype.CE_CIJFER:
				return `${prefix}CE`;
			case Cijferkolomtype.SE_CIJFER:
				return `${prefix}SE`;
			case Cijferkolomtype.EINDCIJFER:
				return `${prefix}Eind`;
			default:
				throw new Error(`Ongeldig cijfertype ${this.cijfertype}`);
		}
	}

	createWeergaveColumns() {
		if (this.afwijkingpercentiel() === AfwijkingPercentielWeergave.AFWIJKING) {
			return [createMeasureColumn<CijfersExamenI, CijfersExamenA>('Afwijking', (path) => this.getAfwijking(path), { format: '+1.2-2' })];
		}

		const percentielBar = createMeasureColumn<CijfersExamenI, CijfersExamenA>(
			'percentielBar',
			(path) => ({ percentiel: this.getPercentiel(path), heightPx: 36, widthPx: 128 }),
			{
				component: SinglePercentielHbarComponent,
				columnType: ColumnType.BARCHART,
			}
		);
		percentielBar.header = {
			...createDefaultHeaderCellDef('percentielBar', ''),
			component: PercentielSchaalverdelingComponent,
		};
		percentielBar.footer = {
			...createDefaultFooterCellDef(),
			component: PercentielSchaalverdelingComponent,
		};

		return [
			percentielBar,
			createMeasureColumn<CijfersExamenI, CijfersExamenA>('Percentiel', (path) => getLeafA(path).ekc_nr_perc_cijfer, {
				dataType: 'percentage',
			}),
		];
	}

	/**
	 * We weten alleen het correcte aantal leerlingen per vak, en niet op een hoger aggregatieniveau zoals vakkengroep.
	 * Aggregeren over vestigingen heen (=optellen) kan wel.
	 */
	createLeerlingenColumn() {
		return this.isLeerlingenBerekenbaar()
			? [createMeasureColumn<CijfersExamenI, CijfersExamenA>('Leerlingen', (path) => getLeafA(path).count_records)]
			: [];
	}

	isLeerlingenBerekenbaar = computed(() => {
		const groups = this.groupsAndSubgroups().map((attrPath) => attrPath.join('.'));
		const vakKeys = vakKeyGroups.map((attrPath) => attrPath.join('.'));
		return intersection(groups, vakKeys).length > 0;
	});

	override createMeasureColumns(): ColumnDef<DataRow<CijfersExamenA>>[] {
		if (this.variant() === DashboardVariant.ACTUEEL)
			return [
				...this.createWeergaveColumns(),
				createMeasureColumn(this.getCijfertypeKop(false), (path) => getLeafA(path).ekc_nr_gem_cijfer, { format: '1.2-2' }),
				createMeasureColumn(this.getCijfertypeKop(true), (path) => this.getLandelijkCijfer(path), { format: '1.2-2' }),
				...this.createLeerlingenColumn(),
			];
		else return [];
	}

	protected override getFixedAfterGroups(): number {
		return this.variant() === DashboardVariant.HISTORIE ? 0 : 1;
	}

	partitionMeasure = computed<PartitionMeasure<CijfersExamenA>>(() => {
		if (this.afwijkingpercentiel() === AfwijkingPercentielWeergave.AFWIJKING) {
			return {
				type: 'number',
				getValue: (path) => this.getAfwijking(path),
				format: '+1.2-2',
				visible: false,
			};
		}

		return {
			type: 'percentage',
			getValue: (path) => {
				const pct = this.getPercentiel(path);
				return pct !== null ? pct / 100 : null;
			},
			format: '1.0-0',
			visible: false,
		};
	});

	override getPartitionTooltip(path: Path<CijfersExamenA, number[]>): TooltipElement[] {
		return this.getTooltip(path);
	}

	getTooltip(path: Path<CijfersExamenA, number[]>): TooltipElement[] {
		const tooltips: TooltipElement[] = [];

		if (this.afwijkingpercentiel() === AfwijkingPercentielWeergave.AFWIJKING) {
			const afwijking = this.getAfwijking(path) ?? 0;
			tooltips.push({ label: 'Afwijking', value: formatNumber(afwijking, 'nl-NL', '1.2-2') });
		} else {
			const percentiel = this.getPercentiel(path);
			tooltips.push({ label: 'Percentiel', value: percentiel === null ? 'Onb' : `${formatNumber(percentiel, 'nl-NL', '1.0-0')}%` });
		}

		const cijfer = att<'ekc_nr_gem_cijfer', CijfersExamenA>('ekc_nr_gem_cijfer')(path);
		tooltips.push({ label: this.cijfertype, value: cijfer == null ? 'Onb' : formatNumber(cijfer, 'nl-NL', '1.2-2') });

		if (this.isLeerlingenBerekenbaar()) {
			tooltips.push({ label: 'Leerlingen', value: `${count_records(path)}` });
		}

		return tooltips;
	}

	override makeBar(_attrs: CijfersExamenI, path: Path<CijfersExamenA, number[]>): BarInfo {
		const afwijking = this.getAfwijking(path) ?? 0;
		return {
			size: 100,
			className: afwijking < 0 ? 'afwijkingnegatief' : 'afwijkingpositief',
			tooltip: this.getTooltip(path),
		};
	}

	override getBarchartQty(path: Path<CijfersExamenA, number[]>): number | null {
		if (this.afwijkingpercentiel() === AfwijkingPercentielWeergave.AFWIJKING) return this.getAfwijking(path) ?? 0;
		else return this.getPercentiel(path);
	}

	getVbarStyle(): VbarStyle {
		if (this.afwijkingpercentiel() === AfwijkingPercentielWeergave.AFWIJKING) return VbarStyle.BAR;
		else return VbarStyle.CIRCLE;
	}

	override createXAxis(context: DashboardContext<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>): Axis {
		const afwijkingen = flatMap(context.dataRoot!.r, (row) => last(row)!.xr!.map((path) => this.getAfwijking(path) ?? 0));
		const min = Math.min(...afwijkingen, -1);
		const max = Math.max(...afwijkingen, 1);
		return { min, max, ticks: [] };
	}

	override createYAxis(context: DashboardContext<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>): Axis {
		if (this.afwijkingpercentiel() === AfwijkingPercentielWeergave.AFWIJKING) {
			const afwijkingen = flatMap(context.dataRoot!.r, (row) => last(row)!.xr!.map((path) => this.getAfwijking(path) ?? 0));
			const min = Math.min(...afwijkingen, -1);
			const max = Math.max(...afwijkingen, 1);
			const standardTicks = [-1, 0, 1].map((afw) => ({ qty: afw, label: `${afw}` }));
			const extraTicks = min < -1 ? [{ qty: min, label: '' }] : [];
			return { min, max, ticks: [...extraTicks, ...standardTicks] };
		} else {
			return { min: 0, max: 100, ticks: [0, 25, 50, 75, 100].map((qty) => ({ qty, label: `${qty}%` })) };
		}
	}

	getRollupLevel() {
		return this.groupsAndSubgroups().findIndex((path) => path.join('.') === vestigingGroup.join('.'));
	}

	getLandelijkCijfer(path: Path<CijfersExamenA, number[]>) {
		const landelijkLevel = this.getRollupLevel();
		if (path.length > landelijkLevel) {
			return nth(path, landelijkLevel)!.a.ekc_nr_gem_cijfer_landelijk;
		} else {
			return getLeafA(path).ekc_nr_gem_cijfer_landelijk;
		}
	}

	getAfwijking(path: Path<CijfersExamenA, number[]>): number | null {
		const { ekc_nr_gem_cijfer } = getLeafA(path);
		const ekc_nr_gem_cijfer_landelijk = this.getLandelijkCijfer(path);
		// bij schooljaren zonder data zijn bovenstaande undefined!
		if (isNil(ekc_nr_gem_cijfer) || isNil(ekc_nr_gem_cijfer_landelijk)) return null;
		return ekc_nr_gem_cijfer - ekc_nr_gem_cijfer_landelijk;
	}

	/**
	 * NB als er niet op vestigingen gegroepeerd wordt, komen verschillende vestigingen van de school in subgroups.
	 * De "Percentiel" kolom toont dan het gewogen gemiddelde van de vestigings-percentielen.
	 * (niet het percentiel van het cijfer gemiddeld over de vestigingen)
	 */
	getPercentiel(path: Path<CijfersExamenA, number[]>): number | null {
		const frac = getLeafA(path).ekc_nr_perc_cijfer;
		return isNil(frac) ? null : frac * 100;
	}

	/**
	 * Dit dashboard vraagt aan de backend altijd een groepering op vestiging (anders kunnen de percentielen niet uitgerekend worden).
	 */
	groupsAndSubgroups = computed<AttrPath[]>(() => {
		const maybeSchooljaar: AttrPath[] = this.variant() === DashboardVariant.HISTORIE ? [['ekc_nm_schooljaar']] : [];
		return [['ekc_nm_niveau'], ...this.selectedGroups(), vestigingGroup, ...maybeSchooljaar];
	});

	groups = computed(() => this.groupsAndSubgroups().slice(0, this.nrTableGroups()));

	subgroups = computed(() => this.groupsAndSubgroups().slice(this.nrTableGroups()));

	/**
	 * Het aantal groeperingen van de tabel. Deze bestaan uit de vaste niveau-groepering, de custom groeperingen en de BRIN.
	 * Bij de Historie-variant (vbarchart) telt de laatste groepering niet mee, die wordt een batch (behalve
	 * als dit de niveau-groepering is).
	 */
	nrTableGroups = computed(() => {
		const standard = 1 + this.selectedGroups().length + 1;
		return this.variant() === DashboardVariant.ACTUEEL ? standard : Math.max(1, standard - 1);
	});

	override enrichTableModel(
		_context: DashboardContext<CijfersExamenI, CijfersExamenA, CijfersExamensComponent>,
		tableModel: TableModel<DataRow<CijfersExamenA>>
	) {
		tableModel.showFooters = this.variant() === DashboardVariant.ACTUEEL && this.afwijkingpercentiel() === AfwijkingPercentielWeergave.PERCENTIEL;
		tableModel.rowsClickable = false;
	}

	override getExportTablePartitionColumns(
		partition: VPartitionData,
		ix: number,
		getValue: (rowModel: DataRow<CijfersExamenA>) => any
	): VBarExportColumnDef<CijfersExamenA>[] {
		return [
			{
				name: 'Percentiel',
				type: 'percentage',
				format: '1.0-0',
				groupName: partition.label,
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) =>
					getLeafA(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path).ekc_nr_perc_cijfer,
			},
			{
				name: 'Afwijking',
				type: 'number',
				format: '+1.1-1',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) => this.getAfwijking(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path),
			},
			{
				name: 'Gemiddelde',
				type: 'number',
				format: '1.2-2',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) =>
					getLeafA(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path).ekc_nr_gem_cijfer,
			},
			{
				name: 'Landelijk',
				type: 'number',
				format: '1.2-2',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) =>
					getLeafA(<Path<CijfersExamenA, number[]>>seriesData.series[ix].path).ekc_nr_gem_cijfer_landelijk_historie,
			},
			{
				name: 'Leerlingen',
				type: 'number',
				format: '1.0',
				getValue,
				unwrapChildren: (seriesData: VbarSeriesData) => {
					const count = getLeafA(seriesData.series[ix].path).count_records;
					return count ? count : null;
				},
			},
		];
	}

	protected readonly DashboardVariant = DashboardVariant;
	protected readonly AfwijkingPercentielWeergave = AfwijkingPercentielWeergave;
}
