import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	private readonly authService = inject(OAuthService);
	private readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);

	private urls: string[];

	constructor() {
		this.urls = [...(this.envConfig.dataUrl ? [this.envConfig.dataUrl] : []), this.envConfig.restUrl];
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.urls.some((url) => req.url.startsWith(url))) {
			const authHeader = this.authService.authorizationHeader();
			// Clone the request to add the new header.
			const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
			// Pass on the cloned request instead of the original request.
			return next.handle(authReq);
		} else {
			return next.handle(req);
		}
	}
}
