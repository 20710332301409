import { Component, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Att } from '../../../services/data.service';
import { flatMap, intersection, sortBy } from 'lodash-es';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { CheckboxComponent, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { BaseDialogComponent, DialogHeaderComponent } from '@cumlaude/shared-components-dialogs';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';
import { CategorieField, CategorieFields } from '../../../services/metadata';

type KolomRow = {
	kolomKey: Att;
	categorieKey: string;
	label: string;
	example?: string;
	searchKeys: string[];
	field: CategorieField;
};

type CategorieRow = { categorieKey: string; label: string };

type Row = KolomRow | CategorieRow;

function isCategorie(row: Row): row is CategorieRow {
	return !('kolomKey' in row);
}

function isKolom(row: Row): row is KolomRow {
	return 'kolomKey' in row;
}

@Component({
	selector: 'app-kolommen-dialog',
	templateUrl: './kolommen-dialog.component.html',
	styleUrls: ['./kolommen-dialog.component.scss'],
	imports: [BaseDialogComponent, DialogHeaderComponent, CheckboxComponent, InstantSearchBoxComponent, ButtonComponent, AsyncPipe],
})
export class KolommenDialogComponent {
	private dialogData = inject<{
    categorieen: CategorieFields[];
    selection: CategorieField[];
}>(DIALOG_DATA);
	protected dialogRef = inject<DialogRef<CategorieField[]>>(DialogRef);

	filter$ = new BehaviorSubject('');
	filtered$: Observable<Row[]>;

	// onveranderlijk
	allRows!: Row[];
	allKolomKeys!: Set<Att>;
	allCategorieKeys!: Set<string>;

	// component state
	showSelection = false;
	selection!: Set<Att>; // geselecteerde kolommen (keys)
	opened!: Set<string>; // opengeklapte categorieën (keys)

	isCategorie = isCategorie;
	isKolom = isKolom;

	constructor() {
		this.determineRowsAndKeys();
		this.setInitialState();

		this.filtered$ = this.filter$.pipe(map((zoek) => filterRows(this.allRows, zoek)));
	}

	determineRowsAndKeys(): void {
		const { categorieen } = this.dialogData;
		this.allKolomKeys = new Set(categorieen.flatMap((cat) => cat.atts.map((att) => <Att>att.att)));
		this.allCategorieKeys = new Set(categorieen.map((cat) => cat.name));

		this.allRows = flatMap(categorieen, ({ name, atts }: CategorieFields) => this.generateCategorieRows(name, atts));
	}

	private generateCategorieRows(label: string, atts: CategorieField[]) {
		const kolomRows = generateKolomRows(atts, label);

		if (kolomRows.length == 0) return [];

		return [{ categorieKey: label, label }, ...kolomRows];
	}

	setInitialState() {
		this.selection = new Set(
			intersection(
				this.dialogData.selection.map((att) => <Att>att.att),
				[...this.allKolomKeys]
			)
		);
		this.opened = new Set(this.allCategorieKeys);
	}

	setShowSelection(value: boolean) {
		this.showSelection = value;
		if (value) {
			this.filter$.next('');
			this.opened = new Set(this.allCategorieKeys);
		}
	}

	toggleKolomSelected(row: KolomRow) {
		if (this.selection.has(row.kolomKey)) this.selection.delete(row.kolomKey);
		else this.selection.add(row.kolomKey);
	}

	toggleCategorieOpened(row: CategorieRow) {
		if (this.opened.has(row.categorieKey)) this.opened.delete(row.categorieKey);
		else this.opened.add(row.categorieKey);
	}

	isCategorieSelected(categorieKey: string) {
		return this.allRows
			.filter((row) => this.isKolom(row) && this.selection.has(row.kolomKey))
			.some((row) => (<KolomRow>row).categorieKey === categorieKey);
	}

	getSelectionFields(): CategorieField[] {
		const selectedKolomRows = this.allRows.filter((row) => this.isKolom(row)).filter((row) => this.selection.has((<KolomRow>row).kolomKey));
		return sortBy(selectedKolomRows, 'label').map((row) => (<KolomRow>row).field);
	}

	isSelectionFull() {
		return this.selection.size === this.allKolomKeys.size;
	}

	selectAllOrNone() {
		if (this.isSelectionFull()) {
			this.selection = new Set();
			this.showSelection = false;
		} else {
			this.selection = new Set(this.allKolomKeys);
		}
	}
}

function generateKolomRows(atts: CategorieField[], categorieKey: string): KolomRow[] {
	const kolommen: KolomRow[] = atts.map((att) => ({
		kolomKey: <Att>att.att,
		categorieKey,
		label: att.label || `### ${att.att}`,
		example: att.example,
		searchKeys: att.searchKeys ?? [],
		field: att,
	}));
	return sortBy(kolommen, ['label']);
}

function filterRows(allRows: (KolomRow | CategorieRow)[], zoekFilter: string) {
	if (zoekFilter.length === 0) return allRows;

	const kolommenFilteredByContent = <KolomRow[]>allRows.filter((row) => isKolom(row) && kolomContentContains(row, zoekFilter));
	const kolomKeysFilteredByContent = new Set(kolommenFilteredByContent.map(({ kolomKey }) => kolomKey));

	const categorieenFilteredByLabel = <CategorieRow[]>(
		allRows.filter((row) => isCategorie(row) && includesIgnoreCaseAndDiacritics(row.label, zoekFilter))
	);
	const categorieKeysFilteredByLabel = new Set(categorieenFilteredByLabel.map(({ categorieKey }) => categorieKey));

	const categorieKeysFilteredByKolomContent = new Set(kolommenFilteredByContent.map(({ categorieKey }) => categorieKey));

	return allRows.filter(
		(row) =>
			(isCategorie(row) && (categorieKeysFilteredByLabel.has(row.categorieKey) || categorieKeysFilteredByKolomContent.has(row.categorieKey))) ||
			(isKolom(row) && (kolomKeysFilteredByContent.has(row.kolomKey) || categorieKeysFilteredByLabel.has(row.categorieKey)))
	);
}

function kolomContentContains({ label, example, searchKeys }: KolomRow, zoekFilter: string) {
	return (
		includesIgnoreCaseAndDiacritics(label, zoekFilter) ||
		includesIgnoreCaseAndDiacritics(example, zoekFilter) ||
		searchKeys.some((searchKey) => includesIgnoreCaseAndDiacritics(searchKey, zoekFilter))
	);
}
