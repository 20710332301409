import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, InjectionToken, Injector, Type } from '@angular/core';

export interface SidebarInput<C> {
	component: Type<C>;
	injector?: Injector;
}

export const SIDEBAR_INPUT = new InjectionToken<SidebarInput<Component>>('sidebarComponent');

const enterLeaveAnimation = trigger('enterLeaveAnimation', [
	transition(':enter', [style({ transform: 'translateX(100%)' }), animate('150ms ease-in', style({ transform: 'translateX(0%)' }))]),
	transition(':leave', [style({ transform: 'translateX(0%)' }), animate('150ms ease-in', style({ transform: 'translateX(100%)' }))]),
]);

/**
 * Sidebar component die een custom component mee krijgt en de sidebar met animaties in- en uitschuift.
 */
@Component({
	selector: 'app-sidebar-component',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
	animations: [enterLeaveAnimation],
})
export class SidebarComponent {
	readonly sidebarInput = inject(SIDEBAR_INPUT);

	@HostBinding('@enterLeaveAnimation') animate = true;
}
