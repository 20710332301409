<section>
	<h2>Support</h2>
	<div class="container">
		<div class="title header">Data snapshot URLs verzamelen</div>
		<div class="content">
			<div>Deze functionaliteit gaat automatisch alle dashboards langs en verzamelt de requests naar de dataservice. Deze worden vervolgens op gezette tijden opnieuw uitgevoerd om data snapshots te maken.</div>
			<div class="table">
				@for (url of (datasnapUrlService.urls$ | async); track url.dashboardUrl) {
					<div>{{url.dashboardUrl}}</div>
					<div class="status" [class]="url.status">{{url.status}}</div>
				} @empty {
					<div></div><div class="status">Nog geen URLs opgeslagen.</div>
				}
			</div>
			<app-button (clickit)="collectUrls()">Update data-URLs</app-button>
		</div>
	</div>
</section>
