@if (data?.percentage) {
	<span
		[class]="getClass()"
		[appTooltip]="data?.tooltip"
		[routerLink]="urlService.getRouterLink(data?.selection)"
		[queryParams]="urlService.getSelectionQueryParams(data?.selection)"
	>
		{{ data?.percentage! | extendedPercent: format }}
	</span>
} @else {
	<span
		[class]="getClass()"
		[appTooltip]="data?.tooltip"
		[routerLink]="urlService.getRouterLink(data?.selection)"
		[queryParams]="urlService.getSelectionQueryParams(data?.selection)"
	>
		{{ data?.ds_nr_weging | extendedDecimal: format }}
	</span>
}
