import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { MEATBALL_ID } from '@cumlaude/shared-utils';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';

@Component({
	selector: 'app-meatball-menu',
	templateUrl: './meatball-menu.component.html',
	styleUrls: ['./meatball-menu.component.scss'],
	imports: [NgClickOutsideDelayOutsideDirective],
})
export class MeatballMenuComponent implements OnDestroy {
	private readonly overlay = inject(Overlay);
	private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);
	private readonly viewContainerRef = inject(ViewContainerRef);

	open = false;

	private overlayRef: OverlayRef | undefined;

	@ViewChild('dropdown')
	dropdown!: TemplateRef<any>;

	@ViewChild('button')
	button!: ElementRef<HTMLElement>;

	@Input()
	size: 'M' | 'L' = 'M';

	@Input()
	orientation: 'H' | 'V' = 'H';

	@Input()
	rounded = false;

	@Output()
	opened = new EventEmitter<boolean>();

	ngOnDestroy(): void {
		this.hide();
	}

	toggleOpen() {
		if (!this.open) {
			this.overlayRef = this.overlay.create({ positionStrategy: this.getPositionStrategy() });
			this.overlayRef.attach(new TemplatePortal(this.dropdown, this.viewContainerRef));
			this.overlayRef.outsidePointerEvents().subscribe((event) => {
				event.preventDefault();
				event.stopPropagation();
				this.hide();
			});
			this.show();
		} else this.hide();
	}

	show() {
		this.open = true;
		this.opened.emit(true);
	}

	hide() {
		this.open = false;
		this.overlayRef?.dispose();
		this.opened.emit(false);
	}

	private getPositionStrategy() {
		return this.overlayPositionBuilder
			.flexibleConnectedTo(this.button)
			.withFlexibleDimensions(false)
			.withPositions([
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
			]);
	}

	protected readonly MEATBALL_ID = MEATBALL_ID;
}
