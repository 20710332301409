import { Component, Input, OnChanges, SimpleChanges, inject } from '@angular/core';
import { Attr, AttrPath, DoorstroomPath, Plaatsing, PlaatsingChild } from '../../../services/data.service';
import { findLast, flatMap, isNil, last, nth, partition, range } from 'lodash-es';
import { generateCssClassForString, getSchooljaarBegin, getSchooljaarEind, isoDate } from '@cumlaude/shared-utils';
import { Prestatie } from '@cumlaude/metadata';
import { TooltipDirective, TooltipElement } from '@cumlaude/shared-components-overlays';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { attrLabel } from '../../../services/labels';
import { DisplayService } from '../../../services/display.service';

// Het hoogste niveau van de layout bestaat uit rijen.
type Row = PlaatsingRow | DoorstroomRow;

// PlaatsingRow is een "hoge" rij en bevat alternerend PlaatsingCells en (pijltje-naar-rechts) DoorstroomCells (evt. beginnend met een aantal lege).
type PlaatsingRow = { plaatsingen: (PlaatsingCell | DoorstroomCell)[] };
// DoorstroomRow is een "lage" rij en kan alleen DoorstroomCells bevatten (eerst evt. een paar lege, en dan de echte).
type DoorstroomRow = { doorstroom: DoorstroomCell[] };

// NB een lege PlaatsingCell resp. DoorstroomCell zijn van elkaar te onderscheiden doordat de ene null is en de andere undefined
type PlaatsingCell = (Plaatsing & { tooltip: TooltipElement[]; class: string }) | null;
// Een DoorstroomCell kan zowel een pijltje-naar-rechts als pijltje-omhoog representeren.
type DoorstroomCell = { class: string; label?: string } | undefined;

@Component({
	selector: 'app-doorstroom-path',
	templateUrl: './doorstroom-path.component.html',
	styleUrls: ['./doorstroom-path.component.scss'],
	imports: [TooltipDirective],
})
export class DoorstroomPathComponent implements OnChanges {
	private readonly displayService = inject(DisplayService);

	@Input()
	path!: DoorstroomPath;

	rows!: Row[];

	ngOnChanges(changes: SimpleChanges): void {
		// getRows() aanroepen vanuit template gaat niet goed icm tooltip
		if ('path' in changes) this.rows = this.getRows(changes['path'].currentValue);
	}

	isPlaatsingRow(row: Row): row is PlaatsingRow {
		return 'plaatsingen' in row;
	}

	isPlaatsingCell(cell: PlaatsingCell | DoorstroomCell): cell is PlaatsingCell {
		return cell === null || cell?.class === 'plaatsing' || cell?.class === 'prognose';
	}

	/**
	 * Genereert de layout. Aanname is dat de plaatsingen in "path" ook daadwerkelijk 1 aaneengesloten pad vormen. Elke plaatsing bevat dan in
	 * children[0] de doorstroom naar de volgende (of geslaagd/uitstroom).
	 *
	 * Het algoritme loopt de plaatsingen een voor een af in de natuurlijke volgorde, en voegt steeds extra rijen/cells toe aan het einde.
	 * (De rijen komen in omgekeerde volgorde op het scherm door "flex-direction: column-reverse".)
	 */
	getRows(path: DoorstroomPath): Row[] {
		const rows: Row[] = [];
		let col = 0; // huidige kolom, wordt hoger bij afwijkende doorstroom
		let lastLj = 0; // leerjaar van de vorige plaatsing
		let lastDs = undefined; // doorstroom vanuit de vorige plaatsing

		const definitievePlaatsingen = path.filter((plaatsing) => plaatsing.lb_is_pl_voorlopig == 0);
		for (const plaatsing of definitievePlaatsingen) {
			const plaatsingWithTooltip = { ...plaatsing, tooltip: this.getTooltip(plaatsing), class: 'plaatsing' };

			if (plaatsing.lb_nr_leerjaar > lastLj) {
				// Leerjaar is hoger dan vorige plaatsing, dus deze komt op een nieuwe rij.
				// Als col > 0 begint de nieuwe rij met lege plaatsing/doorstroomCells.

				const emptyPlaatsingCells = flatMap(range(col), (_) => [null, undefined]);
				if (lastDs && !['doorstroom', 'geslaagd'].includes(lastDs.class) && !isNil(lastLj)) {
					// Bij afwijkende doorstroom schuiven we ook een kolom naar rechts (schuin oversteken dus).
					// Er is dan geen doorstroom-rij (pijltje omhoog), maar een pijltje naar rechts.
					rows.push({ plaatsingen: [...emptyPlaatsingCells, null, lastDs, plaatsingWithTooltip] });
					col++; // we blijven voortaan in deze kolom.
				} else {
					// Normale doorstroom: voeg een doorstroom-rij toe (pijltje omhoog).
					if (lastDs) {
						const emptyDoorstroomCells = range(col).map((_) => undefined);
						rows.push({ doorstroom: [...emptyDoorstroomCells, lastDs] });
					}
					// ... en een plaatsing-rij.
					rows.push({ plaatsingen: [...emptyPlaatsingCells, plaatsingWithTooltip] });
				}
			} else if (isNil(plaatsing.lb_nr_leerjaar) && rows.length === 0) {
				// Leerjaar leeg is en er geen bestaande rijen zijn, moet er een nieuwe rij komen.

				rows.push({ plaatsingen: [plaatsingWithTooltip] });
			} else {
				// Leerjaar zelfde of lager: we gaan een kolom naar rechts. Doorstroom en plaatsing worden aan de bestaande rij toegevoegd.

				const rowPlaatsingen = (last(rows) as PlaatsingRow).plaatsingen;
				if (lastDs) rowPlaatsingen.push(lastDs);
				rowPlaatsingen.push(plaatsingWithTooltip);
				col++; // we blijven voortaan in deze kolom.
			}

			lastDs = generatePlaatsingDoorstroomCell(plaatsing);
			lastLj = plaatsing.lb_nr_leerjaar;
		}
		if (lastDs?.label) {
			const emptyDoorstroomCells = range(col).map((_) => undefined);
			if (lastDs) rows.push({ doorstroom: [...emptyDoorstroomCells, lastDs] });
		}

		const laatstePlaatsing = last(definitievePlaatsingen);
		if (laatstePlaatsing) {
			this.addPrognoses(laatstePlaatsing, rows, col);
		} else {
			this.addVoorlopigePlaatsingAsPrognose(rows, path[0]);
		}

		return rows;
	}

	private addPrognoses(laatstePlaatsing: Plaatsing, rows: Row[], col: number) {
		const lastPlaatsingRow = findLast(rows, this.isPlaatsingRow)!;

		const [prognosesGelijk, prognosesHoger] = partition(
			laatstePlaatsing.children.filter((child) => child.ds_is_prognose === 1 && child.ds_is_examenprognose === 0),
			(prognose: PlaatsingChild) => prognose.ds_nr_leerjaar_naar && prognose.ds_nr_leerjaar_naar <= laatstePlaatsing.lb_nr_leerjaar
		);

		// Plaats doublure-prognoses (lj is gelijk of lager) naast de laatste plaatsing.
		// De bijbehorende doorstroom-cel komt eronder, niet ertussen.
		if (prognosesGelijk.length) {
			let lastDoorstroomRow = nth(rows, -2);
			if (!lastDoorstroomRow || this.isPlaatsingRow(lastDoorstroomRow)) {
				// Als er voor de laatste plaatsing-row geen doorstroom-row stond (bijv omdat het de enige plaatsing was of na een doublure/afstroom),
				// voeg dan een doorstroom-row in als voorlaatste rij met voldoende lege cellen.
				lastDoorstroomRow = { doorstroom: range(col + 1).map((_) => undefined) };
				rows.splice(-1, 0, lastDoorstroomRow);
			}
			for (const prognose of prognosesGelijk) {
				const prognosePlaatsing = createPrognosePlaatsing(prognose);
				lastDoorstroomRow.doorstroom.push(generatePrognoseDoorstroomCell(prognose));
				lastPlaatsingRow.plaatsingen.push(undefined, {
					...prognosePlaatsing,
					class: 'prognose',
					tooltip: this.getTooltip(prognosePlaatsing),
				});
			}
		}

		// Plaats doorstroom-prognoses (lj is hoger) naast elkaar in de rij boven de laatste plaatsing met een lege tussen-rij.
		if (prognosesHoger.length) {
			const doorstroomRow: DoorstroomRow = { doorstroom: range(col).map((_) => undefined) };
			const plaatsingRow: PlaatsingRow = { plaatsingen: range(col).flatMap((_) => [null, undefined]) };
			rows.push({ doorstroom: [undefined] }, doorstroomRow, plaatsingRow);
			for (const prognose of prognosesHoger) {
				const prognosePlaatsing = createPrognosePlaatsing(prognose);
				doorstroomRow.doorstroom.push(generatePrognoseDoorstroomCell(prognose));
				plaatsingRow.plaatsingen.push(
					{
						...prognosePlaatsing,
						class: 'prognose',
						tooltip: this.getTooltip(prognosePlaatsing),
					},
					undefined
				);
			}
		}
	}

	private addVoorlopigePlaatsingAsPrognose(rows: Row[], voorlopigePlaatsing: Plaatsing) {
		const doorstroomRow: DoorstroomRow = { doorstroom: [generatePrognoseDoorstroomCell(voorlopigePlaatsing.children[0])] };
		const plaatsingRow: PlaatsingRow = {
			plaatsingen: [
				{
					...voorlopigePlaatsing,
					class: 'prognose',
					tooltip: this.getTooltip(voorlopigePlaatsing),
				},
				undefined,
			],
		};
		rows.push({ doorstroom: [undefined] }, doorstroomRow, plaatsingRow);
	}

	getTooltip(plaatsing: Plaatsing): TooltipElement[] {
		const createTooltipFields = (fields: (keyof Plaatsing & Attr)[]) =>
			fields
				.filter((field) => Boolean(plaatsing[field]))
				.map((field) => ({
					label: attrLabel([field]),
					value: this.displayService.display(plaatsing[field], <AttrPath>[field]),
				}));
		const leerfase = `${plaatsing.lb_nr_leerjaar ?? ''} ${plaatsing.ilt_nm_niveau ?? ''}`.trim();
		return [
			...createTooltipFields(['lb_nm_schooljaar', 'lb_nm_klas', 'lb_nm_opleiding', 'lb_nm_opleiding_bekostiging']),
			...(leerfase ? [{ label: 'Leerfase', value: leerfase }] : []),
			...createTooltipFields(['ilt_nm_profiel', 'lb_d_plaatsing_va', 'lb_d_plaatsing_tm']),
		];
	}
}

/** Converteert een prognose (als PlaatsingChild) naar een Plaatsing-object */
function createPrognosePlaatsing(prognose: PlaatsingChild): Plaatsing {
	return {
		lb_nm_schooljaar: prognose.ds_nm_schooljaar_naar,
		lb_nm_klas: prognose.ds_nm_klas_naar,
		lb_nr_leerjaar: prognose.ds_nr_leerjaar_naar,
		vs_nm_vestiging: prognose.ds_is_uitstroom_extern === 1 ? 'Uitstroom (ext)' : prognose.ds_nm_vestiging_naar,
		lb_d_plaatsing_va: isoDate(getSchooljaarBegin(prognose.ds_nm_schooljaar_naar)),
		lb_d_plaatsing_tm: isoDate(getSchooljaarEind(prognose.ds_nm_schooljaar_naar)),
		ilt_nm_niveau: prognose.ilt_nm_niveau,
		ilt_nm_profiel: prognose.ilt_nm_profiel,
		lb_nm_opleiding: prognose.ds_nm_opleiding_naar,
		lb_nm_opleiding_bekostiging: '',
		lb_is_pl_voorlopig: 1,
		children: [],
	};
}

function generatePlaatsingDoorstroomCell(plaatsing: Plaatsing): DoorstroomCell {
	// Kijk alleen naar echte opvolgers, geen prognose-plaatsingen.
	// Examenprognose wordt wel hier getoond.
	const children = plaatsing.children.filter((child) => child.ds_is_prognose !== 1 || child.ds_is_examenprognose === 1);
	if (children.length > 1)
		console.error(
			`Plaatsing ${plaatsing.lb_nm_schooljaar} (${plaatsing.lb_nr_leerjaar} ${plaatsing.ilt_nm_niveau}) heeft ${children.length} opvolgers, dit zou niet voor moeten komen.`
		);

	const child = plaatsing.children[0];
	if (!child) return undefined;

	const prognose = child.ds_is_prognose ? '-prognose' : '';
	const cssClass = generateCssClassForString(child.ds_nm_idu ? child.ds_nm_idu + prognose : null);
	const label = generateDoorstroomLabel(child);
	return { class: cssClass, label };
}

function generatePrognoseDoorstroomCell(prognose: PlaatsingChild): DoorstroomCell {
	return {
		class: generateCssClassForString((prognose.ds_nm_idu ?? 'onbekend') + '-prognose'),
		label: `Prognose: ${formatDecimal(prognose.ds_nr_weging * 100, '1.0-0')}%`,
	};
}

function generateDoorstroomLabel(child: PlaatsingChild): string | undefined {
	if (child.ds_is_examenprognose === 1) return `${child.ds_nm_idu} (prognose)`;
	if (child.ds_nm_idu === Prestatie.GESLAAGD) return child.ds_nm_idu;
	if (child.ds_is_uitstroom_extern === 1) return 'Uitstroom (ext)';
	return undefined;
}
