import { computed, inject, Injectable, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AttrPath } from './data.service';
import {
	AfwijkingPercentielWeergave,
	CijferColumn,
	CijferkolomKeuze,
	CijfersSeCeWeergave,
	CohortrendementType,
	CohortrendementWeergave,
	DashboardAspect,
	DashboardVariant,
	DoorstroomWeergave,
	Dossier,
	Eenheid,
	Interval,
	Tijdseenheid,
	UitstroomIqWeergave,
} from './weergave-opties';
import { DRIE_SCHOOLJAREN_EXCL_2020 } from '@cumlaude/shared-utils';
import { Sort } from '../shared/components/table/table/table.component';
import { isEqual, isUndefined } from 'lodash-es';
import { toSignal } from '@angular/core/rxjs-interop';
import { CurrentUrlService } from '@cumlaude/shared-services';
import { LeerlingSelectieId } from './leerling-selectie.service';
import { CumLaudeRoute } from '../app.routes';
import { DatasnapResultKey, encodeKey } from './datasnap-url.service';

type Codec<T> = {
	def: T;
	encode: (val: T) => string | string[];
	decode: (str: string[]) => T;
};

function singleCodec<T>(def: T): Codec<T> {
	return { def, encode: (x) => `${x}`, decode: (x) => <T>(<unknown>x[0]) };
}

function multiCodec<T>(def: T[]): Codec<T[]> {
	return { def, encode: (xs) => xs.map((x) => <string>(<unknown>x)), decode: (xs) => xs.map((x) => <T>(<unknown>x)) };
}

function numberCodec(def: number): Codec<number> {
	return { def, encode: (x) => `${x}`, decode: (x) => Number(x[0]) };
}

function numberOrUndefinedCodec(def: number | undefined): Codec<number | undefined> {
	return { def, encode: (x) => (x === undefined ? '' : `${x}`), decode: (x) => (x[0] === '' ? undefined : Number(x[0])) };
}

function stringOrUndefinedCodec(def: string | undefined): Codec<string | undefined> {
	return { def, encode: (x) => x ?? '', decode: (x) => (x[0] === '' ? undefined : x[0]) };
}

function booleanCodec(def: boolean): Codec<boolean> {
	return { def, encode: (x) => `${Number(x)}`, decode: (x) => Boolean(Number(x[0])) };
}

function jsonMultiCodec<T>(def: T[]): Codec<T[]> {
	return {
		def,
		encode: (xs) => (xs.length === 0 ? [''] : xs.map((x) => JSON.stringify(x))),
		decode: (xs) => (xs[0] === '' ? [] : xs.map((x) => JSON.parse(x))),
	};
}

const groupsCodec: Codec<AttrPath[]> = {
	def: [],
	encode: (groups: AttrPath[]) => groups.map((gr) => gr.join('.')).join(':'),
	decode: (g: string[]) => {
		if (!g[0]) return [];
		return g[0].split(':').map((group) => <AttrPath>group.split('.'));
	},
};

const sortCodec: Codec<Sort> = {
	def: { active: '', direction: '' },
	encode: (sortOrder) => {
		return `${sortOrder.active}:${sortOrder.direction}`;
	},
	decode: (sortOrder) => {
		if (!sortOrder[0]) return { active: '', direction: '' };

		const [active, direction] = sortOrder[0].split(':');
		return <Sort>{ active, direction };
	},
};

const datasnapResultCodec: Codec<DatasnapResultKey | undefined> = {
	def: undefined,
	encode: (key) => {
		if (key === undefined) return '';
		return encodeKey(key);
	},
	decode: (params) => {
		if (!params[0]) return undefined;
		const [urlId, resultId] = params[0].split(':');
		return { urlId, resultId };
	},
};

function attrPathCodec(def: AttrPath): Codec<AttrPath> {
	return { def, encode: (attrPath) => attrPath.join('.'), decode: (pathStr) => <AttrPath>pathStr[0].split('.') };
}

/**
 * nieuwe parameter? update de bugsnag allowlist:
 * console.log(['g', ...Object.keys(configs)]);
 */
const configs = {
	adviesType: attrPathCodec(['ds_fun_verschil_basisschooladvies_van']),
	aspect: singleCodec(DashboardAspect.GEMIDDELDE),
	afwijkingpercentiel: singleCodec(AfwijkingPercentielWeergave.PERCENTIEL),
	'brin-overgang': booleanCodec(false),
	cijfersseceweergave: singleCodec(CijfersSeCeWeergave.VERSCHIL),
	cijfertype: singleCodec(CijferkolomKeuze.TOETS),
	cohortrendementtype: singleCodec<CohortrendementType>('Cohortrendement tot'),
	cohortrendementweergave: singleCodec<CohortrendementWeergave>('Cohortrendement'),
	col: multiCodec([CijferColumn.Cijfer, CijferColumn.Tekortpunten, CijferColumn.Leerlingen, CijferColumn.PercOnvoldoende]),
	doorstroomweergave: singleCodec(DoorstroomWeergave.SUCCESVOL),
	dossier: singleCodec(Dossier.VOORTGANG),
	'details-list': jsonMultiCodec<LeerlingSelectieId>([]),
	eenheid: singleCodec(Eenheid.AANTAL),
	'indicator-over': numberCodec(DRIE_SCHOOLJAREN_EXCL_2020),
	'inspectie-data': booleanCodec(true),
	interval: singleCodec(Interval.MAAND),
	kenmerk: attrPathCodec(['ds_is_apcg_van']),
	'or-uitsluiten': booleanCodec(true),
	selectie: numberOrUndefinedCodec(undefined),
	schooljaar: stringOrUndefinedCodec(undefined),
	sortOrder: sortCodec,
	snapshot: datasnapResultCodec,
	tekortpunten: numberCodec(0),
	tijdseenheid: singleCodec(Tijdseenheid.UREN),
	threshold: numberCodec(0),
	'toon-trend': booleanCodec(true),
	uitstroomiqweergave: singleCodec<UitstroomIqWeergave>('Verschilpunt'),
	vakOrder: numberCodec(0),
	variant: singleCodec(DashboardVariant.ACTUEEL),
};

export type QpName = keyof typeof configs;

export type QpValue<N extends QpName> = (typeof configs)[N]['def'];

interface FixedWeergaveOptiesPerDashboard {
	[dashboardUrl: string]: string[];
}

/**
 * Utility om de toestand van dashboard-opties op te slaan in de URL query parameters.
 * Het patroon is als volgt:
 * - De optie is een state variabele in het dashboard
 * - Het component die de optie aanpast, past niet deze variabele aan, maar doet een "qp.dispatch(...)" (qp = deze service). Dit past de query parameter
 *   in de URL aan.
 * - Dashboard subscribet (qp.observe) op deze query parameter, en past de state variabele aan bij verandering.
 *
 * Voeg voor een nieuwe query parameter een entry toe hier in de "configs".
 */
@Injectable({
	providedIn: 'root',
})
export class QueryParamStateService {
	readonly currentUrlService = inject(CurrentUrlService);
	readonly router = inject(Router);
	readonly activatedRoute = inject(ActivatedRoute);

	queryParams = toSignal(
		this.activatedRoute.queryParamMap.pipe(map((paramMap) => paramMap.keys.map((key) => ({ key, value: paramMap.get(key) }))))
	);

	private allFixedWeergaveOpties = computed(() => this.getFixedWeergaveOptiesPerDashboard(this.router.config));

	private currentFixedWeergaveOpties = computed(() => {
		const currentUrl = this.currentUrlService.currentUrl();
		if (!currentUrl) return;

		const dashboard = currentUrl.split('?')[0];
		return this.allFixedWeergaveOpties()[dashboard];
	});

	/** ingestelde queryparams/filters (excl. fixed weergaveopties) */
	private filteredQueryParams = computed(() => {
		const queryParams = this.queryParams();
		if (!queryParams) return undefined;

		const currentFixedWeergaveOpties = this.currentFixedWeergaveOpties();
		if (!currentFixedWeergaveOpties) return undefined;

		return queryParams
			.filter(({ key }) => !currentFixedWeergaveOpties.includes(key))
			.filter(({ key, value }) => !(key === 'indicator-over' && value === '3.2'));
	});

	/** true als er queryparams of filters zijn ingesteld (afgezien van de fixed weergaveopties) */
	hasQueryParams = computed(() => {
		const queryParams = this.filteredQueryParams();
		return queryParams ? queryParams.length > 0 : false;
	});

	observe<T extends QpName>(paramName: T, overrideDefault?: QpValue<T>): Observable<QpValue<T>> {
		return this.activatedRoute.queryParamMap.pipe(
			map((paramMap) => paramMap.getAll(paramName)),
			distinctUntilChanged(isEqual),
			map((s) => {
				if (s.length > 0) return configs[paramName].decode(s);
				else return overrideDefault ?? configs[paramName].def;
			})
		);
	}

	signal<T extends QpName>(paramName: T, overrideDefault?: QpValue<T>): Signal<QpValue<T>> {
		return toSignal(this.observe(paramName, overrideDefault));
	}

	observe_g(): Observable<AttrPath[] | undefined> {
		return this.activatedRoute.queryParamMap.pipe(
			map((paramMap) => (paramMap.has('g') ? paramMap.getAll('g') : undefined)),
			distinctUntilChanged(isEqual),
			map((value) => (value ? groupsCodec.decode(value) : undefined))
		);
	}

	signal_g(): Signal<AttrPath[] | undefined>;

	signal_g(defaultGroups: AttrPath[]): Signal<AttrPath[]>;

	signal_g(defaultGroups?: AttrPath[]): Signal<AttrPath[] | undefined> {
		const sig = toSignal(this.observe_g());
		return computed(() => sig() ?? defaultGroups);
	}

	dispatch<T extends QpName>(paramName: T, value: QpValue<T> | undefined, extras: NavigationExtras = {}) {
		this.router.navigate([], {
			...extras,
			queryParams: { ...this.activatedRoute.snapshot.queryParams, ...this.encodeQueryParam(paramName, value) },
		});
	}

	dispatch_g(value: AttrPath[]) {
		this.router.navigate([], {
			queryParams: { ...this.activatedRoute.snapshot.queryParams, ...this.encodeGroupQueryParam(value) },
		});
	}

	encodeQueryParam<T extends QpName>(paramName: T, value: QpValue<T> | undefined): Params {
		return { [paramName]: value === undefined ? undefined : (<Codec<QpValue<T>>>configs[paramName]).encode(value) };
	}

	encodeGroupQueryParam(value: AttrPath[]): Params {
		return { ['g']: groupsCodec.encode(value) };
	}

	resetQueryParams() {
		return this.router.navigate([], { queryParams: {} });
	}

	resetDashboard(): Promise<boolean> {
		const filteredQueryParams = this.filteredQueryParams();
		if (!filteredQueryParams) return Promise.resolve(true);

		return this.router.navigate([], {
			queryParams: this.getQueryParametersNulled(filteredQueryParams.map(({ key }) => key)),
			queryParamsHandling: 'merge',
		});
	}

	getAllQueryParamOptions() {
		return [...Object.keys(configs), 'g', 'adhoc', 'from'];
	}

	getNotThisDashboardQueryParamOptions(weergaveOpties?: string[]) {
		return this.getAllQueryParamOptions().filter((param) => !weergaveOpties?.includes(param));
	}

	getQueryParametersNulled(keys: string[]) {
		// maak object met null als value voor alle keys
		return Object.fromEntries(keys.map((key) => [key, null]));
	}

	private getFixedWeergaveOptiesPerDashboard(routes: CumLaudeRoute[], parentUrl: string[] = []): FixedWeergaveOptiesPerDashboard {
		const dashboardWeergaveOpties: FixedWeergaveOptiesPerDashboard = {};
		for (const route of routes) {
			const path = route.path;
			if (isUndefined(path)) continue;

			const url = [...parentUrl, path];

			const children = route.children;
			if (children) {
				Object.assign(dashboardWeergaveOpties, this.getFixedWeergaveOptiesPerDashboard(children, url));
			}

			const fixedWeergaveOpties = route.data?.fixedWeergaveOpties;
			if (fixedWeergaveOpties) {
				dashboardWeergaveOpties[url.join('/')] = fixedWeergaveOpties;
			}
		}
		return dashboardWeergaveOpties;
	}
}
