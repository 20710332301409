import { Component, HostBinding, input, Input, output, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

type SidebarSizeClass = 'size-30-vw' | 'size-20' | 'size-22' | 'size-23' | 'size-25' | 'size-34' | 'size-44' | 'size-47';

@Component({
	selector: 'app-base-sidebar',
	templateUrl: './base-sidebar.component.html',
	styleUrl: './base-sidebar.component.scss',
	imports: [NgTemplateOutlet],
})
export class BaseSidebarComponent {
	@Input()
	bottomBarTemplate!: TemplateRef<any>;

	@HostBinding('class')
	@Input()
	sizeClass: SidebarSizeClass = 'size-30-vw';

	icon = input<string>();

	title = input<string>();

	close = output<Event>();

	iconClick = output<Event>();
}
