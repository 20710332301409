import { Component, inject } from '@angular/core';
import { FilterInputDirective } from '../filter-panel/filter-input.directive';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { QueryParamStateService } from '../services/query-param-state.service';
import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { FilterLabelComponent } from './filter-label.component';
import { LeerlingSelectieFilterComponent } from '../filters/leerling-selectie-filter/leerling-selectie-filter.component';
import { AD_HOC_SELECTION_ID, LeerlingSelectieService } from '../services/leerling-selectie.service';
import { combineLatestEmptySafe } from '@cumlaude/shared-utils';
import { Observable } from 'rxjs';

/**
 * Deze subclass werkt niet via de FilterService maar via de 'details-list' query parameter.
 */
@Component({
	selector: 'app-filter-label-details-list',
	templateUrl: './filter-label.component.html',
	styleUrls: ['./filter-label.component.scss'],
	imports: [FilterInputDirective, NgClickOutsideDelayOutsideDirective, ButtonComponent, AsyncPipe, LeerlingSelectieFilterComponent],
})
export class FilterLabelDetailsListComponent extends FilterLabelComponent {
	private readonly qp = inject(QueryParamStateService);
	private readonly leerlingSelectieService = inject(LeerlingSelectieService);

	override ngOnInit() {
		this.value = combineLatestEmptySafe([this.leerlingSelectieService.observeDetailsList(), this.leerlingSelectieService.adHocSelectieId$]).pipe(
			map(([ids, adHoc]) => ids.map(({ naam, id }) => (id === AD_HOC_SELECTION_ID ? adHoc!.naam : naam)).join(', '))
		);
	}

	override containsAdHoc(): Observable<boolean> {
		return this.leerlingSelectieService.observeDetailsList().pipe(map((ids) => ids.some(({ id }) => id === AD_HOC_SELECTION_ID)));
	}

	override getLabel(): string {
		return 'Selectie';
	}

	override removeFilter() {
		this.qp.dispatch('details-list', []);
	}

	override isOptional(): boolean {
		return true;
	}

	override isDetailsList() {
		return true;
	}
}
