import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { AuthService } from '@cumlaude/shared-authentication';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RRol } from '@cumlaude/service-contract';
import { UserService } from '../../services/user.service';
import { HelpDialogComponent } from '../../dialogs/overig/help-dialog/help-dialog.component';
import { FavorietenDialogComponent } from '../../dialogs/favorieten/favorieten-dialog/favorieten-dialog.component';
import { RouterLink } from '@angular/router';
import { FeedbackDialogComponent } from '../../dialogs/overig/feedback-dialog/feedback-dialog.component';
import { Dialog } from '@angular/cdk/dialog';
import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';
import { TopLevelMenuComponent } from '@cumlaude/shared-components-menu';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { ReleaseNotesDialogComponent } from '../../dialogs/release-notes-dialog/release-notes-dialog.component';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { UrlService } from '../../services/url.service';

const menuPositions: ConnectedPosition[] = [
	{
		originX: 'start',
		originY: 'bottom',
		overlayX: 'start',
		overlayY: 'top',
	},
	{
		originX: 'end',
		originY: 'bottom',
		overlayX: 'end',
		overlayY: 'top',
	},
];

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	imports: [TopLevelMenuComponent, NgClickOutsideDelayOutsideDirective, RouterLink, AsyncPipe],
})
export class HeaderComponent {
	private readonly authService = inject(AuthService);
	private readonly userService = inject(UserService);
	private readonly userAuthenticationService = inject(UserAuthenticationService);
	private readonly dialog = inject(Dialog);
	private readonly overlay = inject(Overlay);
	private readonly envConfig = inject<EnvConfiguration>(ENV_CONFIG);
	readonly urlService = inject(UrlService);

	ikBenBeheerder$: Observable<boolean>;
	instellingName$: Observable<string>;
	multiAccount$: Observable<boolean>;
	credentialsSub$: Observable<string | undefined>;

	submenu: boolean = false;

	@ViewChild('bookmarkButton')
	bookmarkButton!: ElementRef;

	@ViewChild('releaseNotesButton')
	releaseNotesButton!: ElementRef;

	constructor() {
		const userService = this.userService;

		this.ikBenBeheerder$ = userService.rollen$.pipe(map((rollen) => rollen.includes(RRol.BEHEERDER)));
		this.instellingName$ = this.userService.myAccount$.pipe(map((account) => account.instelling.naam));
		this.multiAccount$ = this.userService.myAccount$.pipe(map((account) => account.multiAccount));
		this.credentialsSub$ = this.userService.myAccount$.pipe(map((account) => account.credentialsSub));
	}

	openHelp() {
		this.dialog.open(HelpDialogComponent);
	}

	openFeedback() {
		this.dialog.open(FeedbackDialogComponent);
	}

	uitloggen() {
		this.userAuthenticationService.clearSessionStorage();
		this.authService.logoff();
	}

	getUserDisplayName() {
		return this.authService.getUserDisplayName();
	}

	openBookmark() {
		this.dialog.open<void>(FavorietenDialogComponent, {
			positionStrategy: this.overlay.position().flexibleConnectedTo(this.bookmarkButton).withPositions(menuPositions),
		});
	}

	openReleaseNotes() {
		this.dialog.open<void>(ReleaseNotesDialogComponent, {
			positionStrategy: this.overlay.position().flexibleConnectedTo(this.releaseNotesButton).withPositions(menuPositions),
		});
	}

	switch() {
		firstValueFrom(this.userService.myAccount$).then((myAccount) => {
			if (myAccount.multiAccount) {
				this.authService.clearSessionStorage();
				window.open(`${this.envConfig.authUrl}/switch?subjectToImpersonate=${encodeURIComponent(myAccount.credentialsSub!)}`, '_self');
			}
		});
	}

	wijzigWachtwoord() {
		window.open(`${this.envConfig.authUrl}/change-password`, '_self');
	}
}
