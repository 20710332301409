import { Injectable, OnDestroy, signal, inject } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { AuthService } from '@cumlaude/shared-authentication';
import { RestService } from '@cumlaude/shared-services';
import {
	InstellingBron,
	RCumLaudeAccount,
	RCumLaudeAccountAdditionalObjectKey,
	RCumLaudeModule,
	RInstelling,
	RRol,
	RVestiging,
} from '@cumlaude/service-contract';
import { intersection, isNil, isUndefined } from 'lodash-es';
import { Attr, AttrPath } from './data.service';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ExtraFilterNames, FilterName } from './filter-config';
import { bestuurBlocklist } from './bestuur-blocklist';
import { magisterBlocklist } from './magister-blocklist';
import { geenVSOBlocklist } from './geen-vso-blocklist';
import { geenVOBlocklist } from './geen-vo-blocklist';
import { somtodayBlocklist } from './somtoday-blocklist';
import { UserBugsnagService } from '@cumlaude/user-bugsnag';

export const rolOrder: RRol[] = [RRol.SCHOOLBREED, RRol.TEAMLEIDER, RRol.SECTIELEIDER, RRol.DOCENT, RRol.MENTOR, RRol.BESTUUR];

export function rollenForModules(bestuurEnabled: boolean, modules: RCumLaudeModule[]): RRol[] {
	let rollen = [RRol.SCHOOLBREED, RRol.TEAMLEIDER];

	if (modules.includes(RCumLaudeModule.SECTIE)) rollen.push(RRol.SECTIELEIDER);
	if (modules.includes(RCumLaudeModule.DOCENT)) rollen.push(RRol.DOCENT, RRol.MENTOR);
	if (bestuurEnabled) rollen.push(RRol.BESTUUR);

	return rollen;
}

@Injectable({
	providedIn: 'root',
})
export class UserService implements OnDestroy {
	rolChanged$ = new ReplaySubject<RRol>(1);

	rollen$: Observable<RRol[]>;

	/** Lijst vestigingen uit de REST-service. Geeft alleen vestigingen waarvoor CumLaude actief is. Bevat ook de bijbehorende BRIN-nummers. */
	rVestigingen$: Observable<RVestiging[]>;

	beheerRVestigingen$: Observable<RVestiging[]>;

	myAccount$: Observable<RCumLaudeAccount>;

	instelling$: Observable<RInstelling>;

	isSomtoday$: Observable<boolean>;

	modules$: Observable<RCumLaudeModule[]>;

	private vso: boolean = false;

	private vo: boolean = false;

	useBestuurBlocklist = false;
	useMagisterBlockList = false;
	useSomtodayBlockList = false;
	useNoVSOBlockList = false;
	useNoVOBlockList = false;

	bron = signal<InstellingBron | undefined>(undefined);

	private readonly subscriptions: Subscription[] = [];

	constructor() {
		const authService = inject(AuthService);
		const restService = inject(RestService);
		const bugsnag = inject(UserBugsnagService);

		this.initActiveRol();
		this.myAccount$ = authService.loggedIn$.pipe(
			filter((x) => x),
			switchMap(() => restService.getMyAccount()),
			shareReplay(1)
		);
		this.instelling$ = this.myAccount$.pipe(switchMap((account) => restService.getInstelling(account.instelling)));
		this.isSomtoday$ = this.instelling$.pipe(map((instelling) => instelling.bron === InstellingBron.Somtoday));
		this.modules$ = this.instelling$.pipe(map((instelling) => instelling.modules));
		this.rVestigingen$ = this.rolChanged$.pipe(
			switchMap((rol) => restService.getVestigingen({ cumlaudeActief: rol !== RRol.BESTUUR, vanBestuur: rol === RRol.BESTUUR })),
			shareReplay(1)
		);
		this.beheerRVestigingen$ = this.myAccount$.pipe(
			filter((account) => account.rollen.includes(RRol.BEHEERDER)),
			switchMap(() => restService.getBeheerVestigingen())
		);
		this.rollen$ = this.myAccount$.pipe(
			map(({ additionalObjects }) => additionalObjects![RCumLaudeAccountAdditionalObjectKey.AUTORISATIES].rollen)
		);

		this.subscriptions.push(
			this.rollen$.subscribe((rollen) => {
				const activeRol = this.getActiveRol();
				if (isUndefined(activeRol) || !rollen.includes(activeRol)) this.changeRol(intersection(rolOrder, rollen)[0]);
			}),
			this.rVestigingen$.subscribe((vestigingen) => {
				this.vso = vestigingen.some((vestiging) => vestiging.vso);
				this.vo = vestigingen.some((vestiging) => vestiging.vo);
				this.useNoVSOBlockList = !this.vso;
				this.useNoVOBlockList = !this.vo;
			}),
			this.rolChanged$.subscribe((rol) => {
				bugsnag.updateRol(rol);
				this.useBestuurBlocklist = rol === RRol.BESTUUR;
			}),
			this.myAccount$.subscribe((account) => bugsnag.updateAccount(account)),
			this.instelling$.subscribe((instelling) => {
				bugsnag.updateInstelling(instelling);
				this.bron.set(instelling.bron);
				this.useMagisterBlockList = this.bron() === InstellingBron.Magister;
				this.useSomtodayBlockList = this.bron() === InstellingBron.Somtoday;
			})
		);
	}

	/**
	 * Wijzigt de rol in de session storage en brengt dan alle onderdelen die er van afhankelijk zijn op de hoogte dat de rol gewijzigd is.
	 */
	changeRol(rol: RRol) {
		if (isUndefined(rol)) sessionStorage.removeItem('rol');
		else sessionStorage.setItem('rol', rol);

		this.rolChanged$.next(rol);
	}

	isAttAllowed(name: FilterName) {
		return name.split('.').every((attr) => this.isAttrAllowed(<Attr | ExtraFilterNames>attr));
	}

	isAttrPathAllowed(attrPath: AttrPath) {
		return attrPath.every((attr) => this.isAttrAllowed(attr));
	}

	isAttrAllowed(attr: Attr | ExtraFilterNames) {
		const bestuur = !this.useBestuurBlocklist || !bestuurBlocklist.includes(attr);
		const magister = !this.useMagisterBlockList || !magisterBlocklist.includes(attr);
		const somtoday = !this.useSomtodayBlockList || !somtodayBlocklist.includes(attr);
		const noVSO = !this.useNoVSOBlockList || !geenVSOBlocklist.includes(attr);
		const noVO = !this.useNoVOBlockList || !geenVOBlocklist.includes(attr);
		return bestuur && magister && somtoday && noVO && noVSO;
	}

	/**
	 * Vertaalt de rol enum naar de naam die de gebruiker kent.
	 */
	getRolName(rol: RRol): string {
		switch (rol) {
			case RRol.DOCENT:
				return 'Docent';
			case RRol.SECTIELEIDER:
				return 'Sectie';
			case RRol.SCHOOLBREED:
				return 'School';
			case RRol.TEAMLEIDER:
				return 'Team';
			case RRol.MENTOR:
				return 'Mentor';
			case RRol.BESTUUR:
				return 'Bestuur';
			default:
				return '';
		}
	}

	/**
	 * Vertaalt de rol enum naar de titel van de gebruiker, indien mogelijk.
	 */
	getRolDescription(rol: RRol | undefined): string {
		switch (rol) {
			case RRol.SCHOOLBREED:
				return 'Schoolbreed';
			case RRol.TEAMLEIDER:
				return 'Teamleider';
			case RRol.SECTIELEIDER:
				return 'Sectieleider';
			case RRol.DOCENT:
				return 'Docent';
			case RRol.MENTOR:
				return 'Mentor';
			case RRol.BESTUUR:
				return 'Bestuur';
			default:
				return '';
		}
	}

	getAccountRolDescription(rol: RRol | undefined, account: RCumLaudeAccount): string {
		const rolDescription = this.getRolDescription(rol);

		let autorisaties: string[] = [];
		const autorisatieData = account.additionalObjects![RCumLaudeAccountAdditionalObjectKey.AUTORISATIES];
		switch (rol) {
			case RRol.TEAMLEIDER:
				autorisaties = autorisatieData.teamleider;
				break;
			case RRol.SECTIELEIDER:
				autorisaties = autorisatieData.sectieleider;
				break;
			case RRol.DOCENT:
				autorisaties = autorisatieData.docent;
				break;
			case RRol.MENTOR:
				autorisaties = autorisatieData.mentor;
				break;
		}

		if (autorisaties.length > 0) return `${rolDescription}: ${autorisaties.join(', ')}`;
		return rolDescription;
	}

	/**
	 * Initialiseert de diverse onderdelen wat de rol is die uit de session storage komt.
	 */
	private initActiveRol() {
		const activeRol = this.getActiveRol();
		if (activeRol) this.changeRol(activeRol);
	}

	/**
	 * Geeft de actieve rol terug uit de session storage.
	 */
	getActiveRol(): RRol | undefined {
		const rol = sessionStorage.getItem('rol');
		if (!isNil(rol)) return <RRol>rol;
		return undefined;
	}

	ngOnDestroy() {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}
}
