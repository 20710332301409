import { Component, inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { Sort, SortDirection } from '../../../components/table/table/table.component';

import { AttrPath } from '../../../../services/data.service';
import { QueryParamStateService } from '../../../../services/query-param-state.service';
import { Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface SortHeader {
	sortTarget: AttrPath;
	defaultDirection: SortDirection;
	label: string;
}

@Component({
	selector: 'app-card-sort-headers',
	templateUrl: './card-sort-headers.component.html',
	styleUrls: ['./card-sort-headers.component.scss'],
})
export class CardSortHeadersComponent implements OnChanges, OnDestroy {
	protected readonly qp = inject(QueryParamStateService);

	@Input()
	sortHeaders!: SortHeader[];

	sortHeaders$: Subject<SortHeader[]> = new Subject();

	@Input()
	sortState!: Sort;

	private subscriptions: Subscription[] = [];

	constructor() {
		this.subscriptions.push(
			this.sortHeaders$
				.pipe(switchMap((sortHeaders) => this.qp.observe('sortOrder', this.getDefaultSortOrder(sortHeaders))))
				.subscribe((sortOrder) => {
					this.sortState = sortOrder;
				})
		);
	}

	ngOnChanges() {
		this.sortHeaders$.next(this.sortHeaders);
	}

	private getDefaultSortOrder(sortHeaders: SortHeader[]): Sort {
		if (sortHeaders.length == 0) return { active: '', direction: '' };

		const defaultSortHeader = sortHeaders[0];
		return {
			active: defaultSortHeader.sortTarget.join('.'),
			direction: defaultSortHeader.defaultDirection,
		};
	}

	ngOnDestroy(): void {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	changeSortOrder(sortHeader: SortHeader) {
		let sortTarget = this.sortState.active;
		let direction = this.sortState.direction;
		if (sortHeader.sortTarget.join('.') === this.sortState.active) {
			if (direction === 'asc') direction = 'desc';
			else direction = 'asc';
		} else {
			sortTarget = sortHeader.sortTarget.join('.');
			direction = sortHeader.defaultDirection;
		}

		this.qp.dispatch('sortOrder', {
			active: sortTarget,
			direction: direction,
		});
	}
}
