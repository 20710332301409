import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, shareReplay } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class CurrentUrlService {
	readonly router = inject(Router);

	currentUrl$ = this.router.events.pipe(
		filter((event) => event instanceof NavigationEnd),
		map((event) => event.urlAfterRedirects),
		shareReplay(1)
	);

	currentUrl = toSignal(this.currentUrl$);
}
