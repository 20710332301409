import { Component, computed, OnInit, Signal, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Path } from '../../services/data-tree';
import { AttrPath, DataOptions, DataResponse, DataService, ExportDataOptions, FilterExpression } from '../../services/data.service';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { maxDeelVanJaar, maxOver, noAgg0 } from '../../services/aggregation';
import { att, att0, percOfParent } from '../../services/measures';
import { FilterName } from '../../services/filter-config';
import { DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { memoize, range } from 'lodash-es';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, SelectionConfig } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { Table } from '@cumlaude/metadata';
import { DashboardVariant, Eenheid } from '../../services/weergave-opties';
import { Axis, createYAxis } from '../../services/axis';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { WeergaveOptieComponent } from '../../shared/components/weergave-optie/weergave-optie.component';
import { formatPercent } from '@angular/common';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';
import { BarInfo } from '../../services/stacked-bars';

interface VakkenI extends Attributes {
	vkk_nr_leerlingen: number;
	xa: { [nr: number]: { vkk_nr_leerlingen: number } };
}

interface VakkenA extends Attributes {
	max: number;
	aantalLeerlingen: number;
	maxPctJaar: number;
}

@Component({
	selector: 'app-vakken',
	templateUrl: './vakken.component.html',
	styleUrls: ['./vakken.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		WeergaveOptieComponent,
		FilterBarComponent,
	],
})
export class VakkenComponent extends BarchartTableConfig<VakkenI, VakkenA> implements OnInit {
	private readonly dataService = inject(DataService);

	defaultGroups: AttrPath[] = [['vkk_fk_ilt', 'ilt_nm_niveau'], ['vkk_nr_leerjaar']];

	selectedGroups: AttrPath[] = this.defaultGroups;

	override availableGroups: AttrPath[] = [
		['vkk_fk_ilt', 'ilt_nm_niveau'],
		['vkk_fk_ilt', 'ilt_nm_opleiding'],
		['vkk_fk_ilt', 'ilt_abb_profiel'],
		['vkk_fk_lb', 'lb_nm_opleiding'],
		['vkk_nr_leerjaar'],
		['vkk_fk_vs', 'vs_nm_vestiging'],
		['vkk_fk_lb', 'lb_nm_klas'],
		['vkk_nm_lesgroep'],
		['vkk_fk_ll', 'll_nm_leerling'],
		['vkk_fk_vk', 'vk_nm_vak'],
		['vkk_fk_vk', 'vk_nm_vak_uni'],
		['vkk_nr_leerjaar_vak'],
		['vkk_nm_niveau_vak'],
		['vkk_fk_vk', 'vk_nm_vakkengroep'],
		['vkk_nm_soort_vakpositie'],
	];

	actueelFilters: FilterName[] = [
		'vkk_nm_schooljaar',
		'x_vakkeuze_peildatum',
		'vkk_fk_lb.lb_co_brin',
		'vkk_fk_vs.vs_nm_vestiging',
		'vkk_fk_ilt.ilt_nm_niveau',
		'vkk_nr_leerjaar',
		'vkk_nm_vak',
	];

	historieFilters: FilterName[] = [
		'x_vkk_schooljaar_historie', //
		'x_vkk_multiselect_schooljaar',
		...this.actueelFilters.filter((f) => !['vkk_nm_lesgroep'].includes(f)),
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [];

	variant = this.qp.signal('variant');

	eenheid = this.qp.signal('eenheid');

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)));
	}

	factTable = Table.fac_vkk_vakkeuze;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		// xagg: distinct #lln waarbij vak uit de groeperingen is gelaten (maar schooljaar er wel in zit)
		const xa = this.variant() === DashboardVariant.ACTUEEL ? [] : [[this.selectedGroups.length]];
		return this.dataService.getVakkeuzeData({
			...options,
			r: range(0, this.selectedGroups.length + 1),
			xa,
		});
	}

	override getExportData(options: ExportDataOptions) {
		return this.dataService.getVakkeuzeExportData(options);
	}

	protected override singleAggregators = {
		max: maxOver('vkk_nr_leerlingen'),
		aantalLeerlingen: noAgg0('vkk_nr_leerlingen'),
		maxPctJaar: maxDeelVanJaar('vkk_nr_leerlingen'),
	};

	override createMeasureColumns(context: DashboardContext<VakkenI, VakkenA, VakkenComponent>): ColumnDef<DataRow<VakkenA>>[] {
		if (this.variant() === DashboardVariant.HISTORIE) return [];

		return [
			this.createLeerlingColumn(
				'Percentage',
				percOfParent('aantalLeerlingen', 'aantalLeerlingen'),
				{ context, dataType: 'percentage' },
				att('aantalLeerlingen')
			),
			this.createLeerlingColumn('Leerlingen', att('aantalLeerlingen'), { context }),
		];
	}

	override makeBar(attrs: VakkenI, path: Path<VakkenA, number[]>, context: DashboardContext<VakkenI, VakkenA, VakkenComponent>): BarInfo {
		return {
			...super.makeBar(attrs, path, context),
			description: 'Leerlingen',
		};
	}

	override getSelectionConfig(context: DashboardContext<VakkenI, VakkenA, VakkenComponent>): SelectionConfig<VakkenA> | undefined {
		return { ...super.getSelectionConfig(context)!, getSize: att('aantalLeerlingen') };
	}

	protected override getFixedAfterGroups(): number {
		return this.variant() === DashboardVariant.HISTORIE ? 0 : 1;
	}

	override enrichTableModel(_context: DashboardContext<VakkenI, VakkenA, VakkenComponent>, tableModel: TableModel<DataRow<VakkenA>>) {
		tableModel.showFooters = this.variant() === DashboardVariant.ACTUEEL;
	}

	historieSubGroups: AttrPath[] = [['vkk_nm_vak'], ['vkk_nm_schooljaar']];

	// memoize, otherwise new array keeps triggering change detection
	getActueelGroups = memoize(VakkenComponent._getActueelGroups, JSON.stringify);

	private static _getActueelGroups(selectedGroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups, ['vkk_nm_vak']];
	}

	override createYAxis(context: DashboardContext<VakkenI, VakkenA, VakkenComponent>): Axis {
		if (!context.dataRoot) return super.createYAxis(context);
		if (this.eenheid() === Eenheid.AANTAL) {
			return createYAxis([0, context.dataRoot.a.max]);
		} else {
			return createYAxis([0, context.dataRoot.a.maxPctJaar], 5, (val) => formatPercent(val, 'nl_NL'));
		}
	}

	override getBarchartQty(path: Path<VakkenA, number[]>) {
		if (this.variant() === DashboardVariant.ACTUEEL) return 100 * percOfParent('aantalLeerlingen', 'aantalLeerlingen')(path);
		else if (this.eenheid() === Eenheid.AANTAL) return att0('aantalLeerlingen')(path);
		else return att0('maxPctJaar')(path);
	}

	partitionMeasure: Signal<PartitionMeasure<VakkenA>> = computed(() => {
		if (this.eenheid() === Eenheid.AANTAL)
			return {
				type: 'number',
				getValue: att('aantalLeerlingen'),
				format: '1.0-0',
			};
		else
			return {
				type: 'percentage',
				getValue: att('maxPctJaar'),
			};
	});

	protected readonly DashboardVariant = DashboardVariant;
	protected readonly Eenheid = Eenheid;
}
