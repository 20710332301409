import { Component, OnDestroy, OnInit } from '@angular/core';
import { EinduitstroomVso, UitstroomtypeVso } from '@cumlaude/metadata';
import { Attr } from '../../services/data.service';
import { Attributes } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { uitstroomVsoActueelFilters, UitstroomVsoComponent } from './uitstroom-vso.component';
import { FilterName } from '../../services/filter-config';
import { VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { FilterBarComponent } from '../../filter-bar/filter-bar.component';

interface EinduitstroomI extends Attributes {
	ds_nm_einduitstroom_vso: EinduitstroomVso;
}

@Component({
	selector: 'app-einduitstroom',
	templateUrl: './uitstroom-vso.component.html',
	styleUrls: ['./uitstroom-vso.component.scss'],
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
		FilterBarComponent,
	],
})
export class EinduitstroomComponent extends UitstroomVsoComponent<EinduitstroomI> implements OnInit, OnDestroy {
	protected attr: keyof EinduitstroomI & Attr = 'ds_nm_einduitstroom_vso';
	protected positiefWaarde = EinduitstroomVso.VERVOLGONDERWIJS;
	protected className = 'dashboard-einduitstroom';
	protected positiefLabel = 'Vervolgonderwijs';

	override actueelFilters: FilterName[] = [...uitstroomVsoActueelFilters, this.attr];

	protected getUitstroomtype(): UitstroomtypeVso {
		return UitstroomtypeVso.EINDUITSTROOM;
	}

	protected getUitzonderingUrl(): string {
		return '/details/uitzondering/einduitstroom';
	}
}
