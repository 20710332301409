<app-dashboard-container
	class="dashboard-onderwijsresultaten"
	[factTable]="factTable"
	(doExport)="this.doExport(filterExpressions!, getPermanentFilters(useInspectieData()), getDisplayOptions(), $event)"
>
	<app-filter-panel
		[defaultFilters]="variant() === 'Actueel' ? actueelFilters : historieFilters"
		[allFilters]="getAllFilters()"
		(filterExpressionsChange)="filterExpressions = $event"
		endpoint="/onderwijsresultaten"
		[permanentFilterExpressions]="getFilterPanelPermanentFilters(useInspectieData())"
		[leerlingselectie]="false"
	></app-filter-panel>
	<app-filter-bar></app-filter-bar>
	<section class="dashboard">
		<app-dashboard-header
			[variant]="variant()"
			[legenda]="[variant() === 'Actueel' ? 'x_onderwijsresultaten_actueel' : 'x_onderwijsresultaten_historie']"
			[legendaKeyIsClassName]="true"
			[leftOptionsTemplate]="leftOptions"
			[rightOptionsTemplate]="rightOptions"
		>
			<ng-template #leftOptions>
				<app-schooljaar-indicator-over schooljaarFilterName="x_onderwijsresultaten_or_schooljaar"></app-schooljaar-indicator-over>
			</ng-template>
			<ng-template #rightOptions>
				<app-button class="right" buttonType="secondary" icon="icon-settings" (clickit)="openInstellingen()" #instellingen>
					Instellingen
				</app-button>
			</ng-template>
		</app-dashboard-header>
		@if (variant() === "Actueel") {
			<div class="caption">
				<span>{{ getSchooljarenCaption() }}</span>
				@if (schooljaar) {
					<span class="svg-info" [appTooltip]="getSchooljarenTooltip()"></span>
				}
			</div>
		}
		<app-card-list
			[config]="this"
			[filters]="filterExpressions"
			[permanentFilters]="getPermanentFilters(useInspectieData())"
			[groups]="groups"
			[subgroups]="subGroups"
			[template]="cardTemplate"
			[sortMeasure]="isTweeOnvoldoendesMeasure"
			[elementPageBreak]="true"
			class="onderwijsresultaat"
			data-exportable
		>
			<ng-template #cardTemplate let-row="row" let-context="context" let-group="group">
				<div class="card-title">
					@if (row[1].a.aantal_onvoldoendes > 1) {
						<div class="svg-alert"></div>
					}
					<h3>
						{{ group.or_co_brinvest }}
						@if (row[1].a.or_nm_vestigingen) {
							({{ row[1].a.or_nm_vestigingen }})
						}
					</h3>
				</div>
				<div class="card-content">
					<div [class.card-actueel]="variant() === 'Actueel'" [class.card-single-historie]="variant() === 'Historie'">
						<div class="card-cell-op" (click)="onClick(row, 'op')">
							<app-card-or-cell
								[inputRows]="getCellRows(variant(), row, 'op', peiljaar!, peiljaren, aantalJaren, context.filter)"
								title="Onderwijspositie"
								[variant]="variant()"
								[template]="onderwijsPositieTemplate"
							>
								<ng-template #onderwijsPositieTemplate let-cellRow="cellRow">
									<app-card-or-single-cell [variant]="variant()" [row]="cellRow"> </app-card-or-single-cell>
								</ng-template>
							</app-card-or-cell>
						</div>
						<div class="card-cell-obs" (click)="onClick(row, 'obs')">
							<app-card-or-cell
								[inputRows]="getCellRows(variant(), row, 'obs', peiljaar!, peiljaren, aantalJaren, context.filter)"
								title="Onderbouwsnelheid"
								[variant]="variant()"
								[template]="onderbouwsnelheidTemplate"
							>
								<ng-template #onderbouwsnelheidTemplate let-cellRow="cellRow">
									<app-card-or-single-cell [variant]="variant()" [row]="cellRow"> </app-card-or-single-cell>
								</ng-template>
							</app-card-or-cell>
						</div>
					</div>
					<div [class.card-actueel]="variant() === 'Actueel'" [class.card-row-historie]="variant() === 'Historie'">
						<div class="card-cell-bbs" (click)="onClick(row, 'bbs')">
							<app-card-or-cell
								[inputRows]="getCellRows(variant(), row, 'bbs', peiljaar!, peiljaren, aantalJaren, context.filter)"
								title="Bovenbouwsucces"
								[variant]="variant()"
								[template]="bovenbouwSuccesTemplate"
							>
								<ng-template #bovenbouwSuccesTemplate let-cellRow="cellRow">
									<app-card-or-row-cell [variant]="variant()" [row]="cellRow"> </app-card-or-row-cell>
								</ng-template>
							</app-card-or-cell>
						</div>
						<div class="card-cell-exc" (click)="onClick(row, 'exc')">
							<app-card-or-cell
								[inputRows]="getCellRows(variant(), row, 'exc', peiljaar!, peiljaren, aantalJaren, context.filter)"
								title="Examencijfers"
								[peiljaar]="peiljaar!"
								[aantalJaren]="aantalJaren"
								[variant]="variant()"
								[template]="examencijfersTemplate"
							>
								<ng-template #examencijfersTemplate let-cellRow="cellRow">
									<app-card-or-row-cell [variant]="variant()" [row]="cellRow"> </app-card-or-row-cell>
								</ng-template>
							</app-card-or-cell>
						</div>
					</div>
				</div>
			</ng-template>
		</app-card-list>
	</section>
</app-dashboard-container>
