import { Component, inject } from '@angular/core';
import { BaseSidebarComponent, SIDEBAR_DATA, SidebarRef } from '@cumlaude/shared-components-overlays';
import { RDatasnapResult } from '@cumlaude/service-contract';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { QueryParamStateService } from '../services/query-param-state.service';
import { DatasnapUrlService } from '../services/datasnap-url.service';
import { formatDate } from '@angular/common';

export type DatasnapResultSidebarData = RDatasnapResult[];

@Component({
	selector: 'app-datasnap-result-sidebar',
	templateUrl: './datasnap-result-sidebar.component.html',
	styleUrl: './datasnap-result-sidebar.component.scss',
	imports: [BaseSidebarComponent, ButtonComponent],
})
export class DatasnapResultSidebarComponent {
	protected readonly sidebarRef = inject<SidebarRef<void>>(SidebarRef);
	protected readonly data = inject<DatasnapResultSidebarData>(SIDEBAR_DATA);
	protected readonly qp = inject(QueryParamStateService);
	protected readonly datasnapUrlService = inject(DatasnapUrlService);

	loadOrClearSnapshot(result?: RDatasnapResult) {
		if (!result || this.datasnapUrlService.isViewing(result)()) {
			this.qp.dispatch('snapshot', undefined);
		} else {
			this.qp.dispatch('snapshot', this.datasnapUrlService.constructKey(result));
		}
	}

	resultTitle(result: RDatasnapResult) {
		return formatDate(result.tsRequest, 'dd MMMM yyyy - hh:mm', 'nl-NL');
	}
}
