export function deelVeilig(teller: number, noemer: number): number {
	return noemer !== 0 ? teller / noemer : 0;
}

export function intValueFromInputElement($event: Event): number | undefined {
	const value = parseInt(($event.target as HTMLInputElement).value);
	return isNaN(value) ? undefined : value;
}

export function isNumeric(n: any) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

export function addDefined(x: number | undefined, y: number | undefined): number | undefined {
	if (x === undefined && y === undefined) return undefined;

	return (x ?? 0) + (y ?? 0);
}

/**
 * Rondt een getal af op een bepaald aantal decimalen.
 * @param value Het getal dat moet worden afgerond.
 * @param decimals Het aantal decimalen waarop moet worden afgerond.
 * @returns Het afgeronde getal.
 */
export function roundToDecimals(value: number, decimals: number): number {
	const multiplier = Math.pow(10, decimals);
	return Math.round(value * multiplier) / multiplier;
}
