<span [class]="dataType" [appTooltip]="tooltip" [class.clickable]="Boolean(data.selection)">{{ getFormattedValue() }}</span>
@if (data.selection) {
	<div class="menu-anchor">
		<app-meatball-menu orientation="V" [rounded]="true">
			<div class="menu">
				<div class="menu-item" (click)="slaSelectieOp()">
					<div class="button svg-add-user"></div>
					<div>Opslaan als leerlingselectie</div>
				</div>
			</div>
		</app-meatball-menu>
	</div>
}
